import React, { useState, useEffect, useMemo } from 'react';
import {
    Box,
    Button,
    Modal,
    TextField,
    Typography,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Paper,
    DialogActions,
    DialogContent,
    FormControlLabel,
    Checkbox,
    Alert,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import api from '../Common/axiosSetup';
import { useToast } from '../../Contexts/ToastContext';
import { getUserTimeZone, utcToServerMidnight, toServerMidnightUTC, SERVER_TIMEZONE } from '../../Utility/Timezone';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import DeleteIcon from '@mui/icons-material/Delete';

const getUserDisplayName = (user) => {
    const firstName = user?.first_name?.trim();
    const lastName = user?.last_name?.trim();
    
    if (firstName && lastName) {
        return `${firstName} ${lastName}`;
    }
    return user?.email || '';
};

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '800px',
    height: '90vh',
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: 1,
    display: 'flex',
    flexDirection: 'column'
};

const EditUserSchedule = ({ open, onClose, onSuccess, schedule }) => {
    const theme = useTheme();
    const { addToast } = useToast();
    const [users, setUsers] = useState([]);
    const [formData, setFormData] = useState({
        user_id: '',
        schedule_type: 'On Shift',
        start: null,
        end: null,
        all_day: true,
        description: null
    });
    const [error, setError] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);

    // Get user's timezone
    const userTZ = useMemo(() => getUserTimeZone(), []);

    const formatDate = (dateString) => {
        if (!dateString) return null;
        // Use the ISO string to preserve the UTC date
        const result = new Date(new Date(dateString).toISOString());
        return result;
    };

    // Fetch users when modal opens
    useEffect(() => {
        if (open) {
            fetchUsers();
        }
    }, [open]);

    // Update form data when schedule changes
    useEffect(() => {
        if (schedule) {            
            setFormData({
                user_id: schedule.user_id,
                schedule_type: schedule.schedule_type,
                start: (() => {
                    const date = new Date(schedule.start);
                    date.setHours(date.getHours() + 12);
                    return date;
                })(),
                end: schedule.end,
                all_day: true,
                description: null
            });
        }
    }, [schedule]);

    const fetchUsers = async () => {
        try {
            const token = localStorage.getItem('token');
            const response = await api.get('/api/user', {
                headers: { Authorization: `Bearer ${token}` }
            });
            setUsers(response.data);
        } catch (error) {
            setError('Failed to fetch users');
            addToast('Error fetching users', {
                color: 'danger',
                autoHide: true,
                delay: 5000
            });
        }
    };

    const handleSubmit = async () => {
        if (isSubmitting) return;
        
        try {
            setIsSubmitting(true);
            const startConverted = formData.start ? toServerMidnightUTC(formData.start, userTZ, SERVER_TIMEZONE)?.toISOString() : null;
            const endConverted = formData.end ? toServerMidnightUTC(formData.end, userTZ, SERVER_TIMEZONE)?.toISOString() : null;
            
            const scheduleData = {
                user_id: formData.user_id,
                schedule_type: formData.schedule_type,
                start: startConverted,
                end: endConverted,
                all_day: true,
                description: null
            };

            const token = localStorage.getItem('token');
            const response = await api.put(`/api/user_schedule/${schedule.id}`, scheduleData, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            addToast('Schedule updated successfully', {
                color: 'success',
                autoHide: true,
                delay: 5000
            });

            if (onSuccess) {
                onSuccess(response.data);
            }
            handleClose();
        } catch (error) {
            const errorMessage = error.response?.data?.message;
            const errorDetails = error.response?.data?.errors?.join('\n• ');
            const fullError = errorDetails
                ? `${errorMessage}:\n• ${errorDetails}`
                : errorMessage || 'Error updating schedule';
            
            setError(fullError);
            addToast(fullError, {
                color: 'danger',
                autoHide: true,
                delay: 5000
            });
        } finally {
            setIsSubmitting(false);
        }
    };

    const handleDelete = async () => {
        if (isDeleting) return;
        
        try {
            setIsDeleting(true);
            
            const token = localStorage.getItem('token');
            await api.delete(`/api/user_schedule/${schedule.id}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            addToast('Schedule deleted successfully', {
                color: 'success',
                autoHide: true,
                delay: 5000
            });

            if (onSuccess) {
                onSuccess(null);
            }
            handleClose();
        } catch (error) {
            const errorMessage = error.response?.data?.message;
            const errorDetails = error.response?.data?.errors?.join('\n• ');
            const fullError = errorDetails
                ? `${errorMessage}:\n• ${errorDetails}`
                : errorMessage || 'Error deleting schedule';
            
            setError(fullError);
            addToast(fullError, {
                color: 'danger',
                autoHide: true,
                delay: 5000
            });
        } finally {
            setIsDeleting(false);
        }
    };

    const clearForm = () => {
        if (schedule) {
            setFormData({
                user_id: schedule.user_id,
                schedule_type: schedule.schedule_type,
                start: (() => {
                    const date = new Date(schedule.start);
                    date.setHours(date.getHours() + 12);
                    return date;
                })(),
                end: new Date(schedule.end),
                all_day: true,
                description: null
            });
        }
        setError('');
    };

    const handleClose = () => {
        setError('');
        onClose();
    };

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="edit-schedule-modal"
        >
            <Box sx={modalStyle}>
                {/* Fixed Header */}
                <Box sx={{ 
                    p: 3, 
                    borderBottom: 1, 
                    borderColor: 'divider',
                    bgcolor: 'background.paper',
                    flexShrink: 0
                }}>
                    <Typography variant="h5" component="h2" gutterBottom>
                        EDIT SCHEDULE
                    </Typography>
                    <Typography variant="body1" color="text.secondary" sx={{ mt: 1 }}>
                        Update the schedule by modifying the details below. Fields marked with * are required.
                    </Typography>
                </Box>

                {/* Scrollable Content */}
                <Box sx={{ 
                    flex: 1,
                    overflowY: 'auto',
                    p: 3
                }}>
                    {error && (
                        <Alert 
                            severity="error" 
                            sx={{ 
                                mb: 2,
                                '& .MuiAlert-message': {
                                    whiteSpace: 'pre-line'
                                }
                            }}
                        >
                            {error}
                        </Alert>
                    )}

                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
                        <Box sx={{ mb: 2 }}>
                            <FormControl fullWidth required>
                                <InputLabel>User</InputLabel>
                                <Select
                                    value={formData.user_id}
                                    onChange={(e) => setFormData(prev => ({ ...prev, user_id: e.target.value }))}
                                    label="User"
                                    disabled
                                >
                                    {users.map((user) => (
                                        <MenuItem key={user.id} value={user.id}>
                                            {getUserDisplayName(user)}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box>

                        <Box sx={{ mb: 2 }}>
                            <FormControl fullWidth required>
                                <InputLabel>Schedule Type</InputLabel>
                                <Select
                                    value={formData.schedule_type}
                                    onChange={(e) => setFormData(prev => ({ ...prev, schedule_type: e.target.value }))}
                                    label="Schedule Type"
                                >
                                    <MenuItem value="On Shift">On Shift</MenuItem>
                                    <MenuItem value="Days Off">Days Off</MenuItem>
                                </Select>
                            </FormControl>
                        </Box>

                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <Box sx={{ mb: 2, display: 'flex', gap: 2 }}>
                                <DatePicker
                                    label="Start Date *"
                                    value={formatDate(formData.start)}
                                    onChange={(date) => setFormData(prev => ({ ...prev, start: date?.toISOString() }))}
                                    slotProps={{
                                        textField: {
                                            required: true,
                                            error: !formData.start,
                                            helperText: !formData.start ? "Start date is required" : ""
                                        }
                                    }}
                                />
                                <DatePicker
                                    label="End Date *"
                                    value={formatDate(formData.end)}
                                    onChange={(date) => setFormData(prev => ({ ...prev, end: date?.toISOString() }))}
                                    slotProps={{
                                        textField: {
                                            required: true,
                                            error: !formData.end,
                                            helperText: !formData.end ? "End date is required" : ""
                                        }
                                    }}
                                />
                            </Box>
                        </LocalizationProvider>
                    </Box>
                </Box>

                {/* Fixed Footer */}
                <Box sx={{ 
                    p: 3, 
                    borderTop: 1, 
                    borderColor: 'divider',
                    bgcolor: 'background.paper',
                    display: 'flex', 
                    justifyContent: 'flex-end', 
                    gap: 2,
                    mt: 'auto',
                    flexShrink: 0
                }}>
                    <Button 
                        onClick={handleDelete}
                        color="error"
                        startIcon={<DeleteIcon />}
                        disabled={isDeleting}
                    >
                        {isDeleting ? 'Deleting...' : 'Delete'}
                    </Button>
                    <Button 
                        onClick={clearForm}
                        color="inherit"
                        startIcon={<RestartAltIcon />}
                    >
                        Reset Form
                    </Button>
                    <Button onClick={handleClose} color="inherit">
                        Cancel
                    </Button>
                    <Button
                        onClick={handleSubmit}
                        variant="contained"
                        disabled={isSubmitting}
                    >
                        {isSubmitting ? 'Saving...' : 'Save Changes'}
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default EditUserSchedule; 