import React, { useState, useMemo, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Card, Form, Button, Alert, Badge } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faCheck, faTimes, faBan, faUndo } from '@fortawesome/free-solid-svg-icons';
import { useTheme } from '@mui/material/styles';
import { useAuth } from '../../Contexts/AuthContext';
import api from '../../Component/Common/axiosSetup';
import { useToast } from '../../Contexts/ToastContext';
import { adjustDbDate, formatDbDate } from '../../Utility/dateUtils';
import { useConnectivity } from '../../Contexts/ConnectivityContext';

function WorkOrderDetails({ visits, error: visitsError, onWorkOrderUpdate, metadata, headerRight }) {
    const [comments, setComments] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const { visitId, workOrderId } = useParams();
    const navigate = useNavigate();
    const { addToast } = useToast();
    const theme = useTheme();
    const { authenticatedEmail } = useAuth();
    const { isOnline } = useConnectivity();

    // Find the visit and work order from the visits array
    const visit = visits.find(v => v.id === visitId);
    const workOrder = visit?.work_orders.find(w => w.id === workOrderId);

    // Check if this is a tomorrow's visit
    const isTomorrowVisit = useMemo(() => {
        if (!visit?.visit_date) {
            return false;
        }

        const visitDate = adjustDbDate(visit.visit_date);
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        const tomorrow = new Date(today);
        tomorrow.setDate(tomorrow.getDate() + 1);
        const dayAfterTomorrow = new Date(tomorrow);
        dayAfterTomorrow.setDate(dayAfterTomorrow.getDate() + 1);
        
        return visitDate >= tomorrow && visitDate < dayAfterTomorrow;
    }, [visit]);

    const isLate = useMemo(() => {
        if (!workOrder?.due_date || workOrder.status === 10 || workOrder.status === 20) return false;
        const dueDate = adjustDbDate(workOrder.due_date);
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        return dueDate < today;
    }, [workOrder]);

    // Check if work order is completed or cancelled
    const isCompleted = workOrder?.status === 10 || workOrder?.status === 20;
    const isUnclaimed = !visit?.claimed_by;

    // Check if this is a today's visit (for work order completion)
    const isTodayVisit = useMemo(() => {
        if (!visit?.visit_date) return false;
        const visitDate = adjustDbDate(visit.visit_date);
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        const tomorrow = new Date(today);
        tomorrow.setDate(tomorrow.getDate() + 1);
        return visitDate >= today && visitDate < tomorrow;
    }, [visit]);

    // If not a today's visit, show warning
    useEffect(() => {
        if (!isTodayVisit) {
            addToast('Work orders can only be completed for today\'s visits', {
                color: 'warning',
                autoHide: true,
                delay: 5000,
                requireAck: true
            });
        }
    }, [isTodayVisit, addToast]);

    // Get priority text and badge variant
    const getPriorityInfo = (priority) => {
        const priorityValues = metadata?.priority?.constraints?.allowed_values || {
            "0": "Critical",
            "5": "High",
            "10": "Scheduled"
        };
        
        const text = priorityValues[priority] || 'Unknown';
        let variant;

        switch(text) {
            case 'Critical':
                variant = 'danger';
                break;
            case 'High':
                variant = 'warning';
                break;
            case 'Scheduled':
                variant = 'primary';
                break;
            default:
                variant = 'primary';
        }

        return { text, variant };
    };

    const getWorkOrderTypeVariant = (type) => {
        switch(type) {
            case 'Maintenance':
                return 'warning';
            case 'Routine Maintenance':
                return 'primary';
            case 'Exception':
                return 'danger';
            default:
                return 'primary';
        }
    };

    const getStatusText = (status) => {
        if (status === 10) return 'Completed';
        if (status === 20) return 'Cancelled';
        return 'Requested';
    };

    // Handle work order completion or cancellation
    const handleWorkOrderAction = async (status) => {
        if (!isTodayVisit) {
            const errorMessage = 'Cannot complete work orders for visits not scheduled for today';
            setError(errorMessage);
            addToast(errorMessage, {
                autoHide: true,
                delay: 5000,
                color: 'danger',
                requireAck: true
            });
            return;
        }

        if (!comments.trim()) {
            const errorMessage = 'Please add comments before completing or cancelling the work order.';
            setError(errorMessage);
            addToast(errorMessage, {
                autoHide: true,
                delay: 5000,
                color: 'danger',
                requireAck: true
            });
            return;
        }

        setIsSubmitting(true);
        setError('');
        setSuccess('');

        try {
            await onWorkOrderUpdate(visitId, workOrderId, status, comments.trim());
            navigate(isTomorrowVisit ? `/mobile/work-management/tomorrow/${visitId}` : `/mobile/work-management/${visitId}`);
        } finally {
            setIsSubmitting(false);
        }
    };

    if (!visit || !workOrder) {
        return (
            <div className="container-fluid p-0">
                <div className="px-3 pt-2">
                    <Button 
                        variant="primary"
                        onClick={() => navigate(isTomorrowVisit ? `/mobile/work-management/tomorrow/${visitId}` : `/mobile/work-management/${visitId}`)}
                        className="mb-3"
                    >
                        <FontAwesomeIcon icon={faArrowLeft} className="me-2" />
                        Back to Visit
                    </Button>
                    <Alert variant="danger">
                        {!visit ? 'Visit not found.' : 'Work order not found.'}
                    </Alert>
                </div>
            </div>
        );
    }

    const priorityInfo = getPriorityInfo(workOrder.priority);
    const statusVariant = workOrder.status === 10 ? 'success' : workOrder.status === 20 ? 'warning' : 'primary';
    const statusText = getStatusText(workOrder.status);

    return (
        <div className="container-fluid p-0">
            {/* Header */}
            <div className="px-3 pt-2">
                <div className="d-flex align-items-center justify-content-between mb-3">
                    <Button 
                        variant="primary"
                        onClick={() => navigate(isTomorrowVisit ? `/mobile/work-management/tomorrow/${visitId}` : `/mobile/work-management/${visitId}`)}
                        style={{ minWidth: '120px' }}
                    >
                        <FontAwesomeIcon icon={faArrowLeft} className="me-2" />
                        Back to Visit
                    </Button>
                    {headerRight}
                </div>
                <hr className="custom-separator"/>
            </div>

            {/* Status Messages */}
            {(error || visitsError) && (
                <div className="px-3 mb-3">
                    <Alert variant="danger">
                        {error || visitsError}
                    </Alert>
                </div>
            )}
            {success && (
                <div className="px-3 mb-3">
                    <Alert variant="success">
                        {success}
                    </Alert>
                </div>
            )}

            {/* Work Order Details */}
            <div className="px-3">
                <div className="d-flex flex-column gap-2 mb-3">
                    <div className="d-flex justify-content-between align-items-center">
                        <div style={{ width: '40%' }}>
                            <strong className="fs-6">Work Order Type:</strong>
                        </div>
                        <div style={{ width: '60%' }}>
                            <Badge 
                                bg={getWorkOrderTypeVariant(workOrder.type)}
                                className="px-3 py-2 fs-6 w-100 text-center"
                            >
                                {workOrder.type}
                            </Badge>
                        </div>
                    </div>
                    <div className="d-flex justify-content-between align-items-center">
                        <div style={{ width: '40%' }}>
                            <strong className="fs-6">Priority:</strong>
                        </div>
                        <div style={{ width: '60%' }}>
                            <Badge 
                                bg={priorityInfo.variant}
                                className="px-3 py-2 fs-6 w-100 text-center"
                            >
                                {priorityInfo.text}
                            </Badge>
                        </div>
                    </div>
                    <div className="d-flex justify-content-between align-items-center">
                        <div style={{ width: '40%' }}>
                            <strong className="fs-6">Work Order Status:</strong>
                        </div>
                        <div style={{ width: '60%' }}>
                            <Badge 
                                bg={statusVariant}
                                className="px-3 py-2 fs-6 w-100 text-center"
                            >
                                {statusText}
                            </Badge>
                        </div>
                    </div>
                    <div className="d-flex justify-content-between align-items-center mb-2">
                        <div style={{ width: '40%' }}>
                            <strong className="fs-6">Start Date:</strong>
                        </div>
                        <div style={{ width: '60%' }}>
                            <span className="fs-6">{formatDbDate(workOrder.start_date)}</span>
                        </div>
                    </div>
                    <div className="d-flex justify-content-between align-items-center mb-2">
                        <div style={{ width: '40%' }}>
                            <strong className="fs-6">Due Date:</strong>
                        </div>
                        <div style={{ width: '60%' }} className="d-flex align-items-center gap-2">
                            <span className="fs-6">{formatDbDate(workOrder.due_date)}</span>
                            {isLate && (
                                <Badge bg="warning">
                                    Late
                                </Badge>
                            )}
                        </div>
                    </div>
                </div>

                <hr className="custom-separator"/>

                {/* Notes Section */}
                <div className="mb-3">
                    <strong className="fs-6 d-block mb-2">Work Order Notes:</strong>
                    <p className="mb-3">{workOrder.notes || 'No notes available'}</p>
                </div>

                {/* Comments Section */}
                <div className="mb-3">
                    <strong className="fs-6 d-block mb-2">Comments:</strong>
                    <p className="mb-3">{workOrder.comments || 'No comments available'}</p>
                </div>

                {/* Status Update Section */}
                {!isCompleted && visit.claimed_by === authenticatedEmail && (
                    <div className="mb-3">
                        <strong className="fs-6 d-block mb-2">Update Status:</strong>
                        <Form.Group className="mb-3">
                            <Form.Label>Add Comments</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={3}
                                value={comments}
                                onChange={(e) => setComments(e.target.value)}
                                placeholder="Enter your comments here..."
                            />
                        </Form.Group>
                        <div className="d-flex flex-column gap-2">
                            <Button
                                variant="success"
                                onClick={() => handleWorkOrderAction('Completed')}
                                disabled={isSubmitting || !isTodayVisit}
                                className="w-100 text-white"
                            >
                                <FontAwesomeIcon icon={faCheck} className="me-2" />
                                Mark as Complete
                            </Button>
                            <Button
                                variant="warning"
                                onClick={() => handleWorkOrderAction('Cancelled')}
                                disabled={isSubmitting || !isTodayVisit}
                                className="w-100 text-white"
                            >
                                <FontAwesomeIcon icon={faBan} className="me-2" />
                                Mark as Cancelled
                            </Button>
                        </div>
                    </div>
                )}

                {/* Unclaimed Visit Message */}
                {!visit.claimed_by && (
                    <>
                        <hr className="custom-separator"/>
                        <div className="text-center mt-3">
                            <small className="text-muted">
                                You haven't claimed this visit yet. Work orders are view only.
                            </small>
                        </div>
                    </>
                )}

                {/* Completed Work Order Message */}
                {isCompleted && visit.claimed_by === authenticatedEmail && (
                    <>
                        <hr className="custom-separator"/>
                        <div className="text-center mt-3">
                            <small className="text-muted">
                                This work order has been {workOrder.status === 10 ? 'completed' : 'cancelled'}. No further updates are possible.
                            </small>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
}

export default WorkOrderDetails; 