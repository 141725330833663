import React, { useState, useEffect } from 'react';
import {
    Modal,
    Box,
    Typography,
    Button,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Autocomplete,
    Chip,
    CircularProgress,
    Alert,
    RadioGroup,
    FormControlLabel,
    Radio,
    FormLabel,
    Divider,
    IconButton,
    Grid,
    Dialog,
    DialogContent,
    DialogContentText,
    DialogActions
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useToast } from '../../Contexts/ToastContext';
import api from '../Common/axiosSetup';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import CloseIcon from '@mui/icons-material/Close';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { format } from 'date-fns';

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '800px',
    height: '90vh',
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: 1,
    display: 'flex',
    flexDirection: 'column'
};

const EditVisit = ({ open, onClose, onSuccess, visit, metadata, facMetadata }) => {
    const theme = useTheme();
    const { addToast } = useToast();
    const [loading, setLoading] = useState(false);
    const [availableWorkOrders, setAvailableWorkOrders] = useState([]);
    const [conflicts, setConflicts] = useState(null);
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
    const [formData, setFormData] = useState({
        facility_id: '',
        visit_date: null,
        visit_type: '',
        work_orders: []
    });
    const [workOrderMetadata, setWorkOrderMetadata] = useState({});
    const [originalWorkOrders, setOriginalWorkOrders] = useState([]);

    const formatWorkOrderId = (id) => {
        if (!id) return '';
        const idStr = id.toString();
        if (idStr.length <= 8) return idStr;
        return `${idStr.slice(0, 4)}-${idStr.slice(-4)}`;
    };

    const formatDate = (dateString) => {
        if (!dateString) return null;
        const date = new Date(dateString);
        // Convert UTC to Regina time
        const reginaDate = new Date(date.toLocaleString('en-US', { timeZone: 'America/Regina' }));
        reginaDate.setHours(12, 0, 0, 0); // Set to noon to avoid timezone shifting
        return reginaDate;
    };

    // Initialize form and original state when visit changes
    useEffect(() => {
        if (visit) {
            const workOrders = visit.work_orders || [];
            setFormData({
                facility_id: visit.resourceId,
                facility_name: visit.facility_name,
                facility_zone: visit.facility_zone,
                visit_date: formatDate(visit.start),
                visit_type: visit.visit_type,
                work_orders: [...workOrders]
            });
            setOriginalWorkOrders([...workOrders]);
        }
    }, [visit]);

    // Reset state when modal is closed without saving
    useEffect(() => {
        if (!open) {
            setFormData(prev => ({
                ...prev,
                work_orders: [...originalWorkOrders]
            }));
            if (visit) {
                fetchAvailableWorkOrders(visit.resourceId);
            }
        }
    }, [open]);

    // Fetch work order metadata on modal open
    useEffect(() => {
        const fetchWorkOrderMetadata = async () => {
            try {
                const token = localStorage.getItem('token');
                const response = await api.get('/api/data-registry/metadata', {
                    headers: { Authorization: `Bearer ${token}` },
                    params: { table_name: 'work_order_active' }
                });
                setWorkOrderMetadata(response.data || {});
            } catch (error) {
                console.error('Error fetching work order metadata:', error);
            }
        };

        if (open) {
            fetchWorkOrderMetadata();
        }
    }, [open]);

    // Helper function to get text values from metadata
    const getMetadataText = (field, value) => {
        const fieldMeta = workOrderMetadata[field];
        if (!fieldMeta?.constraints?.allowed_values) return value;
        
        return fieldMeta.constraints.allowed_values[value] || value;
    };

    const fetchAvailableWorkOrders = async (facilityId) => {
        if (!facilityId) return;
        
        try {
            const token = localStorage.getItem('token');
            const response = await api.get(`/api/work-orders/available/${facilityId}`, {
                headers: { Authorization: `Bearer ${token}` }
            });
            
            // Filter out work orders that are already on the visit
            const existingIds = formData.work_orders.map(wo => wo.id);
            const filteredAvailable = response.data.filter(wo => !existingIds.includes(wo.id));
            
            setAvailableWorkOrders(filteredAvailable);
        } catch (error) {
            console.error('Error fetching available work orders:', error);
            addToast('Error fetching available work orders', {
                color: 'danger',
                autoHide: true,
                delay: 5000
            });
        }
    };

    // Only fetch available work orders when the modal opens
    useEffect(() => {
        if (open && formData.facility_id) {
            fetchAvailableWorkOrders(formData.facility_id);
        }
    }, [open, formData.facility_id]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        try {
            const token = localStorage.getItem('token');
            // Only send work orders in the update
            const response = await api.put(`/api/visits/${visit.id}`, {
                work_orders: formData.work_orders
            }, {
                headers: { Authorization: `Bearer ${token}` }
            });

            // If response is 204 (no content), it means the visit was deleted
            if (response.status === 204) {
                onSuccess(null, true); // Pass true to indicate this was an intentional deletion
                onClose();
                return;
            }

            onSuccess(response.data);
            onClose();
            setFormData({
                facility_id: '',
                visit_date: null,
                visit_type: '',
                work_orders: []
            });
            setConflicts(null);
        } catch (error) {
            const errorMessage = error.response?.data?.message;
            const errorDetails = error.response?.data?.errors?.join('\n• ');
            const fullError = errorDetails 
                ? `${errorMessage}:\n• ${errorDetails}` 
                : (errorMessage || 'Error updating visit');
            
            addToast(fullError, {
                color: 'danger',
                autoHide: false,
                delay: 15000,
                requireAck: true
            });
        } finally {
            setLoading(false);
        }
    };

    const handleDelete = async () => {
        setLoading(true);
        try {
            const token = localStorage.getItem('token');
            await api.delete(`/api/visits/${visit.id}`, {
                headers: { Authorization: `Bearer ${token}` }
            });
            onSuccess(null, true); // Pass true to indicate this was an intentional deletion
            onClose();
        } catch (error) {
            const errorMessage = error.response?.data?.message;
            const errorDetails = error.response?.data?.errors?.join('\n• ');
            const fullError = errorDetails 
                ? `${errorMessage}:\n• ${errorDetails}` 
                : (errorMessage || 'Error deleting visit');
            
            addToast(fullError, {
                color: 'danger',
                autoHide: false,
                delay: 15000,
                requireAck: true
            });
        } finally {
            setLoading(false);
            setShowDeleteConfirm(false);
        }
    };

    return (
        <>
            <Modal 
                open={open} 
                onClose={onClose}
                disableRestoreFocus
                keepMounted={false}
            >
                <Box sx={modalStyle}>
                    {/* Fixed Header */}
                    <Box sx={{ 
                        p: 3, 
                        borderBottom: 1, 
                        borderColor: 'divider',
                        bgcolor: 'background.paper',
                        flexShrink: 0
                    }}>
                        <Box display="flex" alignItems="center" justifyContent="space-between">
                            <Typography variant="h5" component="h2" gutterBottom>
                                EDIT VISIT
                            </Typography>
                            <IconButton
                                onClick={onClose}
                                size="large"
                            >
                                <CloseIcon />
                            </IconButton>
                        </Box>
                        <Typography variant="body1" color="text.secondary" sx={{ mt: 1 }}>
                            Update visit details below. Fields marked with * are required.
                        </Typography>
                    </Box>

                    <Box component="form" onSubmit={handleSubmit} sx={{ p: 3, flex: 1, overflow: 'auto' }}>
                        {conflicts && (
                            <Alert 
                                severity="warning" 
                                sx={{ mb: 2 }}
                            >
                                <Typography variant="subtitle2" gutterBottom>
                                    Schedule Conflicts Detected:
                                </Typography>
                                {conflicts.map((conflict, index) => (
                                    <Typography key={index} variant="body2">
                                        • Visit {conflict.visit_id} ({new Date(conflict.start).toLocaleString()} - {new Date(conflict.end).toLocaleString()})
                                    </Typography>
                                ))}
                            </Alert>
                        )}

                        <Grid container spacing={2}>
                            {/* Facility Info - Not Editable */}
                            <Grid item xs={6}>
                                <TextField
                                    fullWidth
                                    label="Facility"
                                    value={visit?.facility_name || ''}
                                    disabled
                                    sx={{ 
                                        mb: 2,
                                        '& .MuiInputBase-root': {
                                            height: '56px'
                                        }
                                    }}
                                />
                            </Grid>

                            <Grid item xs={6}>
                                <TextField
                                    fullWidth
                                    label="Zone"
                                    value={visit?.facility_zone || ''}
                                    disabled
                                    sx={{ 
                                        mb: 2,
                                        '& .MuiInputBase-root': {
                                            height: '56px'
                                        }
                                    }}
                                />
                            </Grid>

                            {/* Visit Type - Not Editable */}
                            <Grid item xs={6}>
                                <FormControl fullWidth required disabled>
                                    <InputLabel>Visit Type</InputLabel>
                                    <Select
                                        value={formData.visit_type}
                                        label="Visit Type"
                                    >
                                        <MenuItem value={0}>Routine Maintenance Visit</MenuItem>
                                        <MenuItem value={1}>Exception Visit</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>

                            {/* Visit Date */}
                            <Grid item xs={6}>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        label="Visit Date"
                                        value={formData.visit_date}
                                        disabled
                                        renderInput={(params) => <TextField {...params} required fullWidth />}
                                    />
                                </LocalizationProvider>
                            </Grid>

                            {/* Work Orders Section */}
                            <Grid item xs={12}>
                                <Box sx={{ 
                                    border: 1,
                                    borderColor: 'divider',
                                    borderRadius: 1,
                                    p: 2
                                }}>
                                    <Typography variant="subtitle1" gutterBottom>
                                        Work Orders on Visit
                                    </Typography>
                                    
                                    {/* Warning message */}
                                    <Alert severity="warning" sx={{ mb: 2 }}>
                                        If you remove all work orders from the visit and save, the visit will be deleted
                                    </Alert>

                                    {/* Work Orders Summary */}
                                    {formData.work_orders?.map((workOrder) => (
                                        <Box key={workOrder.id} sx={{ 
                                            p: 1, 
                                            mb: 1, 
                                            border: 1, 
                                            borderColor: 'divider',
                                            borderRadius: 1,
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'flex-start'
                                        }}>
                                            <Box sx={{ flex: 1 }}>
                                                <Typography variant="body1">
                                                    {formatWorkOrderId(workOrder.id)} ({workOrder.type})
                                                </Typography>
                                                <Typography variant="body2" color="textSecondary">
                                                    Priority: {getMetadataText('priority', workOrder.priority)} | Due: {format(formatDate(workOrder.due_timestamp), 'yyyy-MM-dd')} | Status: {getMetadataText('status', workOrder.status)}
                                                </Typography>
                                                <Typography variant="body2" color="textSecondary" sx={{ 
                                                    mt: 0.5,
                                                    whiteSpace: 'nowrap',
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis',
                                                    maxWidth: '600px'
                                                }}>
                                                    Notes: {workOrder.notes || 'No notes'}
                                                </Typography>
                                            </Box>
                                            <IconButton 
                                                size="small" 
                                                onClick={() => {
                                                    setAvailableWorkOrders(prev => [...prev, workOrder]);
                                                    setFormData(prev => ({
                                                        ...prev,
                                                        work_orders: prev.work_orders.filter(wo => wo.id !== workOrder.id)
                                                    }));
                                                }}
                                                sx={{ color: 'error.main', ml: 1 }}
                                            >
                                                <CloseIcon />
                                            </IconButton>
                                        </Box>
                                    ))}

                                    {/* Add Work Orders */}
                                    <Box sx={{ mt: 2 }}>
                                        <Typography variant="subtitle1" sx={{ mt: 2, mb: 1 }}>Add Work Orders</Typography>
                                        <Autocomplete
                                            multiple
                                            options={availableWorkOrders}
                                            value={[]}  // Always empty since we handle selection manually
                                            onChange={(event, newValue) => {
                                                setFormData(prev => ({
                                                    ...prev,
                                                    work_orders: [...prev.work_orders, ...newValue]
                                                }));
                                                setAvailableWorkOrders(prev => 
                                                    prev.filter(wo => !newValue.find(selected => selected.id === wo.id))
                                                );
                                            }}
                                            getOptionLabel={(option) => `${formatWorkOrderId(option.id)} (${option.type})`}
                                            renderOption={(props, option) => (
                                                <li {...props}>
                                                    <Box sx={{ width: '100%' }}>
                                                        <Typography variant="subtitle2">
                                                            {formatWorkOrderId(option.id)} ({option.type})
                                                        </Typography>
                                                        <Typography variant="body2" color="text.secondary">
                                                            Priority: {getMetadataText('priority', option.priority)} | Due: {format(formatDate(option.due_timestamp), 'yyyy-MM-dd')} | Status: {getMetadataText('status', option.status)}
                                                        </Typography>
                                                        <Typography variant="body2" color="text.secondary" sx={{ 
                                                            mt: 0.5,
                                                            whiteSpace: 'nowrap',
                                                            overflow: 'hidden',
                                                            textOverflow: 'ellipsis'
                                                        }}>
                                                            Notes: {option.notes || 'No notes'}
                                                        </Typography>
                                                    </Box>
                                                </li>
                                            )}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    variant="outlined"
                                                    placeholder="Select work orders to add"
                                                />
                                            )}
                                        />
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>

                    <Divider />
                    <Box sx={{ p: 2, display: 'flex', justifyContent: 'space-between' }}>
                        <Box>
                            <Button 
                                variant="contained"
                                color="error"
                                onClick={() => setShowDeleteConfirm(true)}
                            >
                                Delete Visit
                            </Button>
                        </Box>
                        <Box sx={{ display: 'flex', gap: 1 }}>
                            <Button
                                startIcon={<RestartAltIcon />}
                                onClick={() => {
                                    setFormData(prev => ({
                                        ...prev,
                                        work_orders: [...originalWorkOrders]
                                    }));
                                    if (visit) {
                                        fetchAvailableWorkOrders(visit.resourceId);
                                    }
                                }}
                                sx={{ textTransform: 'none' }}
                            >
                                Reset Changes
                            </Button>
                            <Button onClick={onClose} sx={{ textTransform: 'none' }}>Cancel</Button>
                            <Button 
                                type="submit" 
                                variant="contained" 
                                disabled={loading}
                                onClick={handleSubmit}
                            >
                                {loading ? <CircularProgress size={24} /> : 'Save Changes'}
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Modal>

            {/* Delete Confirmation Dialog */}
            <Dialog
                open={showDeleteConfirm}
                onClose={() => setShowDeleteConfirm(false)}
            >
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete this visit? This will remove all work orders from the visit.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button 
                        onClick={() => setShowDeleteConfirm(false)} 
                        color="inherit"
                        disabled={loading}
                    >
                        Cancel
                    </Button>
                    <Button 
                        onClick={handleDelete}
                        variant="contained"
                        color="error"
                        disabled={loading}
                    >
                        {loading ? <CircularProgress size={24} /> : 'Delete Visit'}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default EditVisit; 