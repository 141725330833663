import React, { useState, useEffect } from 'react';
import { ListGroup, Badge, Alert, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHandPaper, faChevronDown, faChevronRight, faLocationArrow } from '@fortawesome/free-solid-svg-icons';
import { useToast } from '../../Contexts/ToastContext';
import { calculateDistance, getNavigationLink, useUserLocation } from '../../Utility/navigationUtils';

function VisitList({ unclaimedVisits, outstandingVisits, completedVisits, error, onErrorClose, metadata, onClaimVisit, title, headerRight }) {
    const navigate = useNavigate();
    const theme = useTheme();
    const { addToast } = useToast();
    const userLocation = useUserLocation();

    // Check if we're in tomorrow's view
    const isTomorrowView = title === "Tomorrow's Visits";

    // Collapse state with localStorage persistence
    const [collapseState, setCollapseState] = useState(() => {
        const savedState = localStorage.getItem('visitListCollapseState');
        return savedState ? JSON.parse(savedState) : {
            unclaimed: false,
            outstanding: false,
            completed: false
        };
    });

    // Save collapse state to localStorage whenever it changes
    useEffect(() => {
        localStorage.setItem('visitListCollapseState', JSON.stringify(collapseState));
    }, [collapseState]);

    const toggleSection = (section) => {
        setCollapseState(prev => ({
            ...prev,
            [section]: !prev[section]
        }));
    };

    // Section header with collapse toggle and count
    const renderSectionHeader = (title, count, section) => (
        <div className="px-3 mb-2">
            <div 
                className="d-flex align-items-center cursor-pointer" 
                onClick={() => toggleSection(section)}
                style={{ cursor: 'pointer' }}
            >
                <FontAwesomeIcon 
                    icon={collapseState[section] ? faChevronRight : faChevronDown} 
                    className="me-2"
                    style={{ width: '12px' }}
                />
                <h5 className="mb-0">{title} ({count})</h5>
            </div>
        </div>
    );

    // Get priority text and badge variant
    const getPriorityInfo = (priority) => {
        const priorityValues = metadata?.priority?.constraints?.allowed_values || {
            "0": "Critical",
            "5": "High",
            "10": "Scheduled"
        };
        
        const text = priorityValues[priority] || 'Unknown';
        let variant;

        switch(text) {
            case 'Critical':
                variant = 'danger';
                break;
            case 'High':
                variant = 'warning';
                break;
            case 'Scheduled':
                variant = 'primary';
                break;
            default:
                variant = 'primary';
        }

        return { text, variant };
    };

    // Get highest priority from work orders
    const getHighestPriority = (workOrders) => {
        if (!workOrders || workOrders.length === 0) return null;
        return Math.min(...workOrders.map(wo => wo.priority));
    };

    // Get status badge color
    const getStatusColor = (status) => {
        if (status === 10) return theme.palette.success.main; // Completed
        if (status === 20) return theme.palette.grey[500]; // Cancelled
        return theme.palette.warning.main; // Not Complete
    };

    const getStatusText = (status) => {
        if (status === 10) return 'Completed';
        if (status === 20) return 'Cancelled';
        return 'Not Complete';
    };

    // Get work order count badge variant
    const getWorkOrderCountBadge = (count) => {
        if (count > 5) return 'danger';
        if (count > 2) return 'warning';
        return 'info';
    };

    const getWorkOrderTypeVariant = (type) => {
        switch(type) {
            case 'Maintenance':
                return 'warning';
            case 'Routine Maintenance':
                return 'primary';
            case 'Exception':
                return 'danger';
            default:
                return 'primary';
        }
    };

    // Get visit type info
    const getVisitTypeInfo = (visitType) => {
        const text = visitType === 1 ? "Exception" : "Routine";
        const variant = visitType === 1 ? 'danger' : 'primary';
        return { text, variant };
    };

    const handleNavigationClick = (e, visit) => {
        e.stopPropagation();
        if (!visit.facility?.surface_latitude || !visit.facility?.surface_longitude) {
            addToast('Coordinates not available for this site. Please contact support to get this added.', {
                color: 'warning',
                autoHide: true,
                delay: 5000
            });
            return;
        }
        window.open(getNavigationLink(visit.facility.surface_latitude, visit.facility.surface_longitude), '_blank');
    };

    const renderVisitItem = (visit, showClaimButton = false) => {
        const visitTypeInfo = getVisitTypeInfo(visit.visit_type);
        const highestPriority = getHighestPriority(visit.work_orders);
        const priorityInfo = highestPriority !== null ? getPriorityInfo(highestPriority) : null;

        // Calculate distance if we have both user location and facility coordinates
        const distance = userLocation && visit.facility?.surface_latitude && visit.facility?.surface_longitude
            ? calculateDistance(
                userLocation.lat,
                userLocation.lon,
                parseFloat(visit.facility.surface_latitude),
                parseFloat(visit.facility.surface_longitude)
            )
            : null;

        return (
            <ListGroup.Item
                key={visit.id}
                action
                onClick={() => navigate(isTomorrowView ? `/mobile/work-management/tomorrow/${visit.id}` : `/mobile/work-management/${visit.id}`)}
                className="px-3 py-3"
            >
                <div className="d-flex justify-content-between align-items-start mb-3">
                    <h6 className="mb-0 fs-5">{visit.facility.name}</h6>
                </div>

                {/* Facility Info Table */}
                <div className="visit-details mb-3">
                    <div className="d-flex justify-content-between align-items-center mb-2">
                        <div style={{ width: '40%' }}>
                            <strong className="fs-6">UWI:</strong>
                        </div>
                        <div style={{ width: '60%' }}>
                            <span className="fs-6">{visit.facility.uwi}</span>
                        </div>
                    </div>
                    <div className="d-flex justify-content-between align-items-center mb-2">
                        <div style={{ width: '40%' }}>
                            <strong className="fs-6">Zone:</strong>
                        </div>
                        <div style={{ width: '60%' }}>
                            <span className="fs-6">{visit.facility.zone}</span>
                        </div>
                    </div>
                    <div className="d-flex justify-content-between align-items-center mb-2">
                        <div style={{ width: '40%' }}>
                            <strong className="fs-6">Navigation:</strong>
                        </div>
                        <div style={{ width: '60%' }}>
                            <Button
                                variant="outline-secondary"
                                size="sm"
                                onClick={(e) => handleNavigationClick(e, visit)}
                                className="w-100"
                            >
                                <FontAwesomeIcon icon={faLocationArrow} className="me-2" />
                                Navigate {distance ? `(${distance}km)` : ''}
                            </Button>
                        </div>
                    </div>
                </div>

                {/* Visit Info Table */}
                <div className="visit-info mb-3">
                    <div className="d-flex justify-content-between align-items-center mb-2">
                        <div style={{ width: '40%' }}>
                            <strong className="fs-6">Visit Type:</strong>
                        </div>
                        <div style={{ width: '60%' }}>
                            <Badge 
                                bg={visitTypeInfo.variant}
                                className="px-3 py-2 fs-6 w-100 text-center"
                            >
                                {visitTypeInfo.text}
                            </Badge>
                        </div>
                    </div>
                    <div className="d-flex justify-content-between align-items-center mb-2">
                        <div style={{ width: '40%' }}>
                            <strong className="fs-6">Priority:</strong>
                        </div>
                        <div style={{ width: '60%' }}>
                            {priorityInfo && (
                                <Badge 
                                    bg={priorityInfo.variant}
                                    className="px-3 py-2 fs-6 w-100 text-center"
                                >
                                    {priorityInfo.text}
                                </Badge>
                            )}
                        </div>
                    </div>
                    <div className="d-flex justify-content-between align-items-center">
                        <div style={{ width: '40%' }}>
                            <strong className="fs-6">Work Orders:</strong>
                        </div>
                        <div style={{ width: '60%' }}>
                            <div className="d-flex flex-column gap-2">
                                {visit.work_orders.map((wo, index) => (
                                    <Badge 
                                        key={wo.id}
                                        bg={getWorkOrderTypeVariant(wo.type)}
                                        className="px-3 py-2 fs-6 w-100 text-center"
                                    >
                                        {wo.type}
                                    </Badge>
                                ))}
                            </div>
                        </div>
                    </div>

                    {/* Progress Badge - Show for all visits */}
                    <div className="d-flex justify-content-between align-items-center mt-2">
                        <div style={{ width: '40%' }}>
                            <strong className="fs-6">Progress:</strong>
                        </div>
                        <div style={{ width: '60%' }}>
                            {visit.stats && (
                                <Badge 
                                    bg="info"
                                    className="px-3 py-2 fs-6 w-100 text-center"
                                >
                                    {visit.stats.completed}/{visit.stats.total} Done
                                </Badge>
                            )}
                        </div>
                    </div>
                </div>

                {showClaimButton && (
                    <Button
                        variant="outline-primary"
                        onClick={(e) => {
                            e.stopPropagation();
                            onClaimVisit(visit.id);
                        }}
                        className="w-100"
                    >
                        <FontAwesomeIcon icon={faHandPaper} className="me-2" />
                        Claim Visit
                    </Button>
                )}
            </ListGroup.Item>
        );
    };

    return (
        <div className="container-fluid p-0">
            {/* Header */}
            <div className="px-3 pt-2">
                <div className="d-flex align-items-center justify-content-between mb-1">
                    <h4 className="mb-0">{title}</h4>
                    {headerRight}
                </div>
                <hr className="custom-separator"/>
            </div>

            {/* Error Alert */}
            {error && (
                <div className="px-3 mb-3">
                    <Alert variant="danger" dismissible onClose={onErrorClose}>
                        {error}
                    </Alert>
                </div>
            )}

            {/* Unclaimed Visits */}
            {unclaimedVisits.length > 0 && (
                <div className="mb-4">
                    {renderSectionHeader('Unclaimed Visits', unclaimedVisits.length, 'unclaimed')}
                    {!collapseState.unclaimed && (
                        <ListGroup variant="flush">
                            {unclaimedVisits.map(visit => renderVisitItem(visit))}
                        </ListGroup>
                    )}
                </div>
            )}

            {/* Outstanding Visits */}
            {outstandingVisits.length > 0 && (
                <div className="mb-4">
                    {renderSectionHeader('Your Outstanding Visits', outstandingVisits.length, 'outstanding')}
                    {!collapseState.outstanding && (
                        <ListGroup variant="flush">
                            {outstandingVisits.map(visit => renderVisitItem(visit))}
                        </ListGroup>
                    )}
                </div>
            )}

            {/* Completed Visits */}
            {completedVisits.length > 0 && (
                <div>
                    {renderSectionHeader('Your Completed Visits', completedVisits.length, 'completed')}
                    {!collapseState.completed && (
                        <ListGroup variant="flush">
                            {completedVisits.map(visit => renderVisitItem(visit))}
                        </ListGroup>
                    )}
                </div>
            )}

            {unclaimedVisits.length === 0 && outstandingVisits.length === 0 && completedVisits.length === 0 && !error && (
                <ListGroup.Item className="text-center py-4">
                    <p className="text-muted mb-0">No visits scheduled for your assigned zones</p>
                </ListGroup.Item>
            )}
        </div>
    );
}

export default VisitList; 