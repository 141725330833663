import React, { useState, useEffect, useMemo } from 'react';
import {
    Box,
    Button,
    Modal,
    TextField,
    Typography,
    Alert,
    MenuItem,
    FormControl,
    InputLabel,
    Select,
    Tooltip,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    Grid,
    IconButton,
    FormHelperText,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import api from '../Common/axiosSetup';
import { useToast } from '../../Contexts/ToastContext';
import { Close as CloseIcon, RestartAlt as RestartAltIcon } from '@mui/icons-material';
import { getUserTimeZone, toServerMidnightUTC, utcToServerMidnight, SERVER_TIMEZONE } from '../../Utility/Timezone';

const ScheduledWorkOrderDetails = ({ 
    open, 
    onClose, 
    scheduledWorkOrder, 
    metadata,
    onUpdate,
    hiddenFields = ['id', 'facility_id', 'insert_timestamp', 'update_timestamp']
}) => {
    const [formData, setFormData] = useState({});
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const { addToast } = useToast();
    const userTZ = useMemo(() => getUserTimeZone(), []);

    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '800px',
        height: '90vh',
        bgcolor: 'background.paper',
        boxShadow: 24,
        borderRadius: 1,
        display: 'flex',
        flexDirection: 'column'
    };

    useEffect(() => {
        if (scheduledWorkOrder) {
            setFormData({ ...scheduledWorkOrder });
        }
    }, [scheduledWorkOrder]);

    if (!scheduledWorkOrder) return null;

    const handleInputChange = (field, value) => {
        setFormData(prev => ({
            ...prev,
            [field]: value
        }));
    };

    const handleSubmit = async () => {
        try {
            setLoading(true);
            setError(null);

            // Get only the modified fields
            const modifiedFields = {};
            Object.keys(formData).forEach(key => {
                if (formData[key] !== scheduledWorkOrder[key]) {
                    // Convert dates to UTC for server
                    if (metadata[key]?.data_type === 'date' || metadata[key]?.data_type === 'date_time') {
                        modifiedFields[key] = formData[key] ? toServerMidnightUTC(formData[key], userTZ, SERVER_TIMEZONE)?.toISOString() : null;
                    } else {
                        modifiedFields[key] = formData[key];
                    }
                }
            });

            if (Object.keys(modifiedFields).length === 0) {
                onClose();
                return;
            }

            await onUpdate(scheduledWorkOrder.id, modifiedFields);
            onClose();

        } catch (error) {
            const errorMessage = error.response?.data?.message;
            const errorDetails = error.response?.data?.errors?.join('\n• ');
            const fullError = errorDetails 
                ? `${errorMessage}:\n• ${errorDetails}` 
                : (errorMessage || 'Error updating scheduled work order');
            
            setError(fullError);
        } finally {
            setLoading(false);
        }
    };

    // Helper functions
    const isFieldRequired = (fieldName) => {
        return metadata?.[fieldName]?.constraints?.required === true;
    };

    const getTooltip = (fieldName) => {
        return metadata?.[fieldName]?.ui_settings?.tooltip || metadata?.[fieldName]?.description || '';
    };

    const getFieldLabel = (fieldName) => {
        return metadata?.[fieldName]?.ui_settings?.label || fieldName;
    };

    const isFieldEditable = (field) => {
        return !hiddenFields.includes(field) && metadata?.[field]?.ui_settings?.editable !== false;
    };

    const formatDate = (dateString) => {
        if (!dateString) return null;
        return utcToServerMidnight(dateString, SERVER_TIMEZONE);
    };

    // Define field order groups
    const fieldGroups = [
        // Facility Info (hardcoded, always first)
        ['facility_name', 'zone'],
        // Core Fields
        ['type', null],
        ['interval_type', 'target_month'],
        ['interval_days', null],
        ['schedule_enable', null],
        // Dates
        ['schedule_start_timestamp', 'schedule_end_timestamp'],
        ['last_record_timestamp', 'last_completed_timestamp'],
        // Additional Fields
        ['visit_policy', null],
        // Notes (always last, full width)
        ['notes']
    ];

    // Don't flatten the groups - we want to maintain the pairs
    const renderFields = () => {
        return fieldGroups.map((group, groupIndex) => {
            // For each group, render the fields side by side
            return group.map((field, fieldIndex) => {
                if (field === null) return <Grid item xs={6} key={`empty-${groupIndex}-${fieldIndex}`} />;
                // Handle interval_days visibility based on interval_type
                if (field === 'interval_days' && formData.interval_type !== 'Custom') {
                    return <Grid item xs={6} key={`empty-${groupIndex}-${fieldIndex}`} />;
                }
                // Handle target_month visibility here where we handle layout
                if (field === 'target_month' && formData.interval_type !== 'Annually' && formData.interval_type !== 'Quarterly') {
                    return <Grid item xs={6} key={`empty-${groupIndex}-${fieldIndex}`} />;
                }
                return renderField(field, `${field}-${groupIndex}-${fieldIndex}`);
            });
        });
    };

    const getQuarterOptions = () => [
        { value: 1, label: 'Q1' },
        { value: 2, label: 'Q2' },
        { value: 3, label: 'Q3' },
        { value: 4, label: 'Q4' }
    ];

    const getMonthOptions = () => [
        { value: 1, label: 'January' },
        { value: 2, label: 'February' },
        { value: 3, label: 'March' },
        { value: 4, label: 'April' },
        { value: 5, label: 'May' },
        { value: 6, label: 'June' },
        { value: 7, label: 'July' },
        { value: 8, label: 'August' },
        { value: 9, label: 'September' },
        { value: 10, label: 'October' },
        { value: 11, label: 'November' },
        { value: 12, label: 'December' }
    ];

    const renderField = (field, key) => {
        if (hiddenFields.includes(field)) return null;

        // Special handling for facility_name and zone
        if (field === 'facility_name' || field === 'zone') {
            return (
                <Grid item xs={6} key={key}>
                    <TextField
                        fullWidth
                        label={field === 'facility_name' ? 'Facility' : 'Zone'}
                        value={scheduledWorkOrder[field] || ''}
                        disabled={true}
                        sx={{ 
                            mb: 2,
                            '& .MuiInputBase-root': {
                                height: '56px'
                            }
                        }}
                    />
                </Grid>
            );
        }

        const fieldMeta = metadata[field];
        if (!fieldMeta) return null;

        const value = formData[field];
        const isEditable = isFieldEditable(field);
        const isRequired = isFieldRequired(field);
        const hasError = isRequired && !value && value !== 0;
        const label = getFieldLabel(field);
        const tooltip = getTooltip(field);
        const isFullWidth = fieldMeta.ui_settings?.full_width || field === 'notes';

        // Special handling for target_month based on interval_type
        if (field === 'target_month' && formData.interval_type) {
            const isQuarterly = formData.interval_type === 'Quarterly';
            const isAnnually = formData.interval_type === 'Annually';
            
            if (isQuarterly || isAnnually) {
                const options = isQuarterly ? getQuarterOptions() : getMonthOptions();
                const label = isQuarterly ? 'Target Quarter' : 'Target Month';
                
                return (
                    <Grid item xs={6} key={key}>
                        <Tooltip title={tooltip}>
                            <FormControl
                                fullWidth
                                error={hasError}
                                sx={{ 
                                    mb: 2,
                                    '& .MuiInputBase-root': {
                                        height: '56px'
                                    }
                                }}
                            >
                                <InputLabel>{label}</InputLabel>
                                <Select
                                    value={value || ''}
                                    onChange={(e) => handleInputChange(field, e.target.value)}
                                    label={label}
                                    disabled={!isEditable}
                                >
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                    {options.map(option => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </Select>
                                {hasError && (
                                    <FormHelperText>This field is required</FormHelperText>
                                )}
                            </FormControl>
                        </Tooltip>
                    </Grid>
                );
            }
        }

        // Common props for all input components
        const commonProps = {
            fullWidth: true,
            required: isRequired,
            error: hasError,
            disabled: !isEditable,
            helperText: hasError ? "This field is required" : "",
            sx: { 
                mb: 2,
                '& .MuiInputBase-root': {
                    height: field === 'notes' ? 'auto' : '56px'
                },
                '& .MuiInputLabel-root': {
                    textTransform: 'none'
                },
                '& .MuiOutlinedInput-root': {
                    '&.Mui-error .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#d32f2f'
                    }
                },
                '& .MuiFormHelperText-root.Mui-error': {
                    color: '#d32f2f'
                }
            }
        };

        return (
            <Grid item xs={isFullWidth ? 12 : 6} key={key}>
                {(() => {
                    switch (fieldMeta.data_type) {
                        case 'date':
                        case 'date_time':
                            return (
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <Tooltip title={tooltip}>
                                        <DatePicker
                                            label={label}
                                            value={formatDate(value)}
                                            onChange={(date) => handleInputChange(field, date?.toISOString())}
                                            disabled={!isEditable}
                                            renderInput={(params) => (
                                                <TextField 
                                                    {...params}
                                                    {...commonProps}
                                                />
                                            )}
                                        />
                                    </Tooltip>
                                </LocalizationProvider>
                            );

                        case 'boolean':
                            return (
                                <Tooltip title={tooltip}>
                                    <FormControl {...commonProps}>
                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                            <Checkbox
                                                checked={!!value}
                                                onChange={(e) => handleInputChange(field, e.target.checked)}
                                                disabled={!isEditable}
                                            />
                                            <Typography>{label}</Typography>
                                        </Box>
                                    </FormControl>
                                </Tooltip>
                            );

                        case 'integer':
                            // Check if field has allowed values
                            if (fieldMeta.constraints?.allowed_values) {
                                return (
                                    <Tooltip title={tooltip}>
                                        <FormControl {...commonProps}>
                                            <InputLabel error={hasError}>{label}</InputLabel>
                                            <Select
                                                value={value ?? ''}
                                                label={label}
                                                onChange={(e) => handleInputChange(field, e.target.value)}
                                            >
                                                {Object.entries(fieldMeta.constraints.allowed_values).map(([key, label]) => (
                                                    <MenuItem key={key} value={Number(key)}>{label}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Tooltip>
                                );
                            }
                            return (
                                <Tooltip title={tooltip}>
                                    <TextField
                                        {...commonProps}
                                        type="number"
                                        label={label}
                                        value={value ?? ''}
                                        onChange={(e) => handleInputChange(field, Number(e.target.value))}
                                    />
                                </Tooltip>
                            );

                        case 'string':
                            // Special handling for notes - make it resizable
                            if (field === 'notes') {
                                return (
                                    <Tooltip title={tooltip}>
                                        <TextField
                                            {...commonProps}
                                            multiline
                                            minRows={3}
                                            maxRows={10}
                                            label={label}
                                            value={value || ''}
                                            onChange={(e) => handleInputChange(field, e.target.value)}
                                        />
                                    </Tooltip>
                                );
                            }

                            // Check if field has allowed values
                            if (fieldMeta.constraints?.allowed_values) {
                                return (
                                    <Tooltip title={tooltip}>
                                        <FormControl {...commonProps}>
                                            <InputLabel error={hasError}>{label}</InputLabel>
                                            <Select
                                                value={value ?? ''}
                                                label={label}
                                                onChange={(e) => handleInputChange(field, e.target.value)}
                                            >
                                                {fieldMeta.constraints.allowed_values.map(value => (
                                                    <MenuItem key={value} value={value}>{value}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Tooltip>
                                );
                            }

                            // Default text field
                            return (
                                <Tooltip title={tooltip}>
                                    <TextField
                                        {...commonProps}
                                        label={label}
                                        value={value || ''}
                                        onChange={(e) => handleInputChange(field, e.target.value)}
                                    />
                                </Tooltip>
                            );

                        default:
                            return null;
                    }
                })()}
            </Grid>
        );
    };

    const hasChanges = () => {
        return Object.keys(formData).some(key => formData[key] !== scheduledWorkOrder[key]);
    };

    return (
        <Modal 
            open={open} 
            onClose={onClose}
        >
            <Box sx={modalStyle}>
                {/* Fixed Header */}
                <Box sx={{ 
                    p: 3, 
                    borderBottom: 1, 
                    borderColor: 'divider',
                    bgcolor: 'background.paper',
                    flexShrink: 0
                }}>
                    <Box display="flex" alignItems="center" justifyContent="space-between">
                        <Typography variant="h5" component="h2" gutterBottom>
                            EDIT SCHEDULED WORK ORDER
                        </Typography>
                        <IconButton
                            onClick={onClose}
                            size="large"
                        >
                            <CloseIcon />
                        </IconButton>
                    </Box>
                    <Typography variant="body1" color="text.secondary" sx={{ mt: 1 }}>
                        Update scheduled work order details below. Fields marked with * are required.
                    </Typography>
                </Box>

                {/* Scrollable Content */}
                <Box sx={{ 
                    flex: 1,
                    overflowY: 'auto',
                    p: 3
                }}>
                    {error && (
                        <Alert 
                            severity="error" 
                            sx={{ 
                                mb: 2,
                                '& .MuiAlert-message': {
                                    whiteSpace: 'pre-line'
                                }
                            }}
                        >
                            {error}
                        </Alert>
                    )}

                    <Grid container spacing={2}>
                        {renderFields()}
                    </Grid>
                </Box>

                {/* Fixed Footer */}
                <Box sx={{ 
                    p: 3, 
                    borderTop: 1, 
                    borderColor: 'divider',
                    bgcolor: 'background.paper',
                    display: 'flex', 
                    justifyContent: 'flex-end', 
                    gap: 2,
                    mt: 'auto',
                    flexShrink: 0
                }}>
                    <Button 
                        onClick={() => {
                            setFormData({ ...scheduledWorkOrder });
                            setError(null);
                        }}
                        color="inherit"
                        startIcon={<RestartAltIcon />}
                        disabled={!hasChanges()}
                    >
                        Reset Changes
                    </Button>
                    <Button onClick={onClose} color="inherit">
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        onClick={handleSubmit}
                        disabled={loading || !hasChanges()}
                    >
                        Save Changes
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default ScheduledWorkOrderDetails; 