import React from 'react';

// Calculate distance between two points in kilometers
export const calculateDistance = (lat1, lon1, lat2, lon2) => {
    const R = 6371; // Earth's radius in km
    const dLat = (lat2 - lat1) * (Math.PI / 180);
    const dLon = (lon2 - lon1) * (Math.PI / 180);
    const a = 
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(lat1 * (Math.PI / 180)) * Math.cos(lat2 * (Math.PI / 180)) * 
        Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return Math.round(R * c); // Round to nearest kilometer
};

// Create universal maps link
export const getNavigationLink = (lat, lon) => {
    // Universal link that will open in native maps app
    return `https://www.google.com/maps/dir/?api=1&destination=${lat},${lon}`;
};

// Hook to get user's location
export const useUserLocation = () => {
    const [userLocation, setUserLocation] = React.useState(null);

    React.useEffect(() => {
        if ("geolocation" in navigator) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    setUserLocation({
                        lat: position.coords.latitude,
                        lon: position.coords.longitude
                    });
                },
                (error) => {
                    console.log("Error getting location:", error);
                }
            );
        }
    }, []);

    return userLocation;
}; 