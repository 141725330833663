import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { Routes, Route, useLocation } from 'react-router-dom';
import { onModalOpen, onChangeDarkMode, onChangeHighcontrast } from '../Redux/Actions/Actions';
import { Modal, Button, Alert } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
    faSync, 
    faRedoAlt, 
    faExclamationTriangle, 
    faCheck, 
    faTimes 
} from '@fortawesome/free-solid-svg-icons';
import { useConnectivity } from '../Contexts/ConnectivityContext';
import Header from '../Component/Common/Header';
import Sidebar from '../Component/Common/Sidebar';
import SettingsModal from '../Component/Common/SettingsModal';
import Profile from './UserProfile';
import FacilityManagement from './Mobile/FacilityManagement';
import WorkManagement from './Mobile/WorkManagement';
import Error404 from './Mobile/Error404';
import mobileMenu from '../Component/Data/Menu/MobileMenu.json';

function MobileIndex(props) {
    const { modalopen, darkMode, highcontrast } = props.Mainreducer;
    const { activekey, GotoChangeMenu } = props;
    const [menuData, setMenuData] = useState([...mobileMenu.menu]);
    const location = useLocation();
    const [refreshHandler, setRefreshHandler] = useState(null);
    const [lastFetchTime, setLastFetchTime] = useState(null);
    const [showRefreshConfirm, setShowRefreshConfirm] = useState(false);
    const { isOnline } = useConnectivity();

    const savedIsSidebarMini = localStorage.getItem("isSideBarMini");
    const initIsSidebarMini = !(savedIsSidebarMini === null) ? savedIsSidebarMini === "true" : false;

    // Intercept browser refresh attempts
    useEffect(() => {
        const handleKeyDown = (e) => {
            // Detect F5 or Ctrl/Cmd + R
            if (e.key === 'F5' || (e.key === 'r' && (e.ctrlKey || e.metaKey))) {
                e.preventDefault();
                e.stopPropagation();
                setShowRefreshConfirm(true);
                return false;
            }
        };

        const handleBeforeUnload = (e) => {
            // Only show browser's default warning if we're not showing our modal
            if (!showRefreshConfirm) {
                const message = 'Warning: Refreshing or leaving the page will disrupt your current work. The app automatically saves and syncs your work.';
            e.preventDefault();
                e.returnValue = message;
                return message;
            }
        };

        // Capture the refresh event at the highest level
        const captureRefresh = (e) => {
            if (e.target.location?.href?.includes('reload')) {
                e.preventDefault();
                e.stopPropagation();
                setShowRefreshConfirm(true);
                return false;
            }
        };

        // Add listeners with capture phase to intercept before browser handling
        window.addEventListener('keydown', handleKeyDown, { capture: true });
        window.addEventListener('beforeunload', handleBeforeUnload);
        window.addEventListener('click', captureRefresh, { capture: true });
        
        return () => {
            window.removeEventListener('keydown', handleKeyDown, { capture: true });
            window.removeEventListener('beforeunload', handleBeforeUnload);
            window.removeEventListener('click', captureRefresh, { capture: true });
        };
    }, [showRefreshConfirm]);

    // Close sidebar menu when location changes
    useEffect(() => {
        const sidebar = document.getElementById('mainsidemenu');
        if (sidebar && sidebar.classList.contains('open')) {
            sidebar.classList.remove('open');
        }
    }, [location]);

    // Handle refresh from any page
    const handleRefresh = useCallback(async () => {
        if (!refreshHandler) return;
        setShowRefreshConfirm(true);
    }, [refreshHandler]);

    const handleConfirmRefresh = async () => {
        setShowRefreshConfirm(false);
        if (refreshHandler) {
            await refreshHandler();
        }
    };

    const handleHardRefresh = () => {
        // Force a complete page reload, clearing the cache
        window.location.reload(true);
    };

    // Prevent refresh while modal is open
    useEffect(() => {
        if (showRefreshConfirm) {
            const preventRefresh = (e) => {
                e.preventDefault();
                return false;
            };
            window.addEventListener('beforeunload', preventRefresh, { capture: true });
            return () => window.removeEventListener('beforeunload', preventRefresh, { capture: true });
        }
    }, [showRefreshConfirm]);

    return (
        <>
            <Sidebar
                activekey={activekey}
                menuData={menuData}
                GotoChangeMenu={GotoChangeMenu}
                initIsSidebarMini={initIsSidebarMini}
            />
            <div className='main px-lg-4 px-md-4'>
                <Header
                    onModalOpen={(val) => { props.onModalOpen(true) }}
                    GotoChangeMenu={(val) => { GotoChangeMenu(val) }}
                    onRefresh={handleRefresh}
                    dataTimestamp={lastFetchTime}
                />
                <SettingsModal
                    show={modalopen}
                    highcontrast={highcontrast}
                    darkMode={darkMode}
                    onHide={(val) => { props.onModalOpen(false) }}
                    onChangeDarkMode={() => { props.onChangeDarkMode(darkMode === 'dark' ? 'light' : 'dark') }}
                    onChangeHighcontrast={() => { props.onChangeHighcontrast(highcontrast === 'high-contrast' ? 'light' : 'high-contrast') }}
                />

                {/* Refresh Confirmation Modal */}
                <Modal show={showRefreshConfirm} onHide={() => setShowRefreshConfirm(false)} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Refresh Options</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="d-flex flex-column gap-4">
                            {/* Soft Refresh Section */}
                            <div>
                                <h5 className="d-flex align-items-center">
                                    <FontAwesomeIcon icon={faSync} className="me-2 text-primary"/>
                                    Soft Refresh (Data Only)
                                </h5>
                                <p className="text-muted mb-2">
                                    Updates your data from the server while preserving the app state.
                                </p>
                                <ul className="list-unstyled mb-2">
                                    <li>
                                        <FontAwesomeIcon icon={faCheck} className="me-2 text-success"/>
                                        Refresh visit and work order data
                                    </li>
                                    <li>
                                        <FontAwesomeIcon icon={faCheck} className="me-2 text-success"/>
                                        Keep app running
                                    </li>
                                    <li>
                                        <FontAwesomeIcon icon={faCheck} className="me-2 text-success"/>
                                        Preserve navigation state
                                    </li>
                                </ul>
                                <Button variant="primary" onClick={handleConfirmRefresh} className="w-100">
                                    Refresh Data
                                </Button>
                            </div>

                            <hr/>

                            {/* Hard Refresh Section */}
                            <div>
                                <h5 className="d-flex align-items-center">
                                    <FontAwesomeIcon icon={faRedoAlt} className="me-2 text-danger"/>
                                    Hard Refresh (Full Page)
                                </h5>
                                <p className="text-muted mb-2">
                                    Completely reloads the app. Use this if you're experiencing technical issues.
                                </p>
                                <Alert variant="warning" className="d-flex align-items-center">
                                    <FontAwesomeIcon icon={faExclamationTriangle} className="me-2"/>
                                    Warning: This will:
                                </Alert>
                                <ul className="list-unstyled mb-2">
                                    <li>
                                        <FontAwesomeIcon icon={faTimes} className="me-2 text-danger"/>
                                        Reset the entire application
                                    </li>
                                    <li>
                                        <FontAwesomeIcon icon={faTimes} className="me-2 text-danger"/>
                                        Potentially clear cached data
                                    </li>
                                    <li>
                                        <FontAwesomeIcon icon={faTimes} className="me-2 text-danger"/>
                                        Potentially lose unsaved changes
                                    </li>
                                </ul>
                                <Button variant="outline-danger" onClick={handleHardRefresh} className="w-100">
                                    Hard Refresh Page
                                </Button>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setShowRefreshConfirm(false)}>
                            Cancel
                        </Button>
                    </Modal.Footer>
                </Modal>

                <div className="body d-flex py-3">
                    <Routes>
                        <Route 
                            path="/mobile/asset-management" 
                            element={
                                <FacilityManagement 
                                    setRefreshHandler={setRefreshHandler}
                                    setLastFetchTime={setLastFetchTime}
                                />
                            } 
                        />
                        <Route 
                            path="/mobile/work-management/*" 
                            element={
                                <WorkManagement 
                                    setRefreshHandler={setRefreshHandler}
                                    setLastFetchTime={setLastFetchTime}
                                />
                            } 
                        />
                        <Route path="/profile/*" element={<Profile />} />
                        <Route path="*" element={<Error404 />} />
                    </Routes>
                </div>
            </div>
        </>
    );
}

const mapStateToProps = ({ Mainreducer }) => ({
    Mainreducer
});

export default connect(mapStateToProps, {
    onModalOpen,
    onChangeDarkMode,
    onChangeHighcontrast
})(MobileIndex);