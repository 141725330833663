import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle, faUser } from '@fortawesome/free-solid-svg-icons';

function Error404() {
    return (
        <div className="container-fluid p-0">
            <div className="d-flex flex-column align-items-center justify-content-center min-vh-100 text-center px-3">
                <FontAwesomeIcon 
                    icon={faExclamationTriangle} 
                    className="text-warning mb-4"
                    style={{ fontSize: '4rem' }}
                />
                <h4 className="mb-3">Page Not Found</h4>
                <p className="text-muted mb-4">
                    The page you're looking for doesn't exist or has been moved.
                </p>
                <Link 
                    to="/profile/overview" 
                    className="btn btn-primary d-flex align-items-center gap-2"
                >
                    <FontAwesomeIcon icon={faUser} />
                    Back to Profile
                </Link>
            </div>
        </div>
    );
}

export default Error404; 