// ThemeControl.js
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

const ThemeControl = ({ onThemeChange }) => {
  const darkMode = useSelector((state) => state.Mainreducer.darkMode);
  const themeColor = useSelector((state) => state.Mainreducer.themeColor) || 'cyan';
  
  useEffect(() => {
    const storedTheme = localStorage.getItem('theme');
    const prefersDark = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
    const defaultDark = storedTheme === 'dark' || (storedTheme === null && prefersDark);
    const defaultHighContrast = storedTheme === 'high-contrast';

    if (defaultDark) {
      document.documentElement.setAttribute('data-theme', 'dark');
      onThemeChange('dark', themeColor);
    } else if (defaultHighContrast) {
      document.documentElement.setAttribute('data-theme', 'high-contrast');
      onThemeChange('high-contrast', themeColor);
    } else {
      onThemeChange('light', themeColor);
    }
  }, [onThemeChange, themeColor]);

  useEffect(() => {
    localStorage.setItem('theme', darkMode);
    localStorage.setItem('themeColor', themeColor);
    onThemeChange(darkMode, themeColor);
  }, [darkMode, themeColor, onThemeChange]);

  return null;
};

export default ThemeControl;