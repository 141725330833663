import React, { useState, useEffect, useCallback } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import api from '../../Component/Common/axiosSetup';
import { useToast } from '../../Contexts/ToastContext';
import { useAuth } from '../../Contexts/AuthContext';
import { useConnectivity } from '../../Contexts/ConnectivityContext';
import VisitList from './VisitList';
import VisitDetails from './VisitDetails';
import WorkOrderDetails from './WorkOrderDetails';
import { fetchAndStoreVisits, getVisitData, refreshVisitData, storeVisitData } from '../../Utility/visitStorage';
import { Button } from 'react-bootstrap';

// Helper component for the Sync Changes button
const SyncChangesButton = ({ isOnline, onSync }) => {
    const getPendingUpdatesCount = () => {
        const updates = JSON.parse(localStorage.getItem('pendingWorkOrderUpdates') || '[]');
        return updates.filter(update => {
            const userEmail = localStorage.getItem('userEmail');
            return update.userEmail === userEmail;
        }).length;
    };

    const pendingCount = getPendingUpdatesCount();
    const showButton = window.location.pathname.indexOf('/tomorrow') !== 0;

    if (!showButton) return null;

    return (
        <Button
            variant={isOnline && pendingCount > 0 ? "warning" : "secondary"}
            disabled={!isOnline || pendingCount === 0}
            onClick={onSync}
            className={`ms-auto ${isOnline && pendingCount > 0 ? 'text-white' : ''}`}
            style={{ minWidth: '120px' }}
        >
            Sync Changes {pendingCount > 0 ? `(${pendingCount})` : ''}
        </Button>
    );
};

function WorkManagement({ setRefreshHandler, setLastFetchTime }) {
    const [visits, setVisits] = useState([]);
    const [unclaimedVisits, setUnclaimedVisits] = useState([]);
    const [tomorrowVisits, setTomorrowVisits] = useState([]);
    const [error, setError] = useState('');
    const [metadata, setMetadata] = useState({});
    const navigate = useNavigate();
    const { addToast } = useToast();
    const { authenticatedEmail } = useAuth();
    const { isOnline } = useConnectivity();

    // Fetch metadata
    const fetchMetadata = async () => {
        try {
            const token = localStorage.getItem('token');
            const response = await api.get('/api/data-registry/metadata', {
                headers: { Authorization: `Bearer ${token}` },
                params: { table_name: 'work_order_active' }
            });
            setMetadata(response.data);
        } catch (error) {
            console.error('Error fetching metadata:', error);
        }
    };

    // Load visits data
    const loadVisits = async (forceRefresh = false, muteToasts = false) => {
        let visitData;
        let timestamp;
        
        try {
            setError('');

            // If offline, always try to use cached data first
            if (!isOnline) {
                const storedData = getVisitData();
                if (storedData) {
                    visitData = storedData.data;
                    timestamp = storedData.timestamp;
                    setMetadata(storedData.metadata);
                    setUnclaimedVisits(visitData.unclaimed);
                    setVisits(visitData.userVisits);
                    setTomorrowVisits(visitData.tomorrowVisits);
                    setLastFetchTime(timestamp);
                    if (!muteToasts) {
                        addToast('Using cached visit data (offline)', {
                            color: 'warning',
                            autoHide: true,
                            delay: 3000
                        });
                    }
                    return;
                } else {
                    setError('No cached data available while offline');
                    if (!muteToasts) {
                        addToast('No cached data available while offline', {
                            autoHide: true,
                            delay: 5000,
                            color: 'danger',
                            requireAck: true
                        });
                    }
                    return;
                }
            } else if (!forceRefresh) {
                const storedData = getVisitData();
                if (storedData && !storedData.isStale) {
                    visitData = storedData.data;
                    timestamp = storedData.timestamp;
                    setMetadata(storedData.metadata);
                } else {
                    visitData = await fetchAndStoreVisits(muteToasts ? () => {} : addToast, isOnline);
                    const freshData = getVisitData();
                    timestamp = freshData.timestamp;
                    setMetadata(visitData.metadata);
                }
            } else {
                visitData = await refreshVisitData(muteToasts ? () => {} : addToast, isOnline);
                const freshData = getVisitData();
                timestamp = freshData.timestamp;
                setMetadata(visitData.metadata);
            }

            setUnclaimedVisits(visitData.unclaimed);
            setVisits(visitData.userVisits);
            setTomorrowVisits(visitData.tomorrowVisits);

            // Update last fetch time with the timestamp from storage
            setLastFetchTime(timestamp);

            // Only show success toast if we're online and got fresh data and toasts aren't muted
            if (isOnline && forceRefresh && !muteToasts) {
                addToast('Data refreshed successfully', {
                    color: 'success',
                    autoHide: true,
                    delay: 3000
                });
            }
        } catch (error) {
            // If we're offline, try to use cached data instead of showing an error
            if (!isOnline) {
                const storedData = getVisitData();
                if (storedData) {
                    visitData = storedData.data;
                    timestamp = storedData.timestamp;
                    setMetadata(storedData.metadata);
                    setUnclaimedVisits(visitData.unclaimed);
                    setVisits(visitData.userVisits);
                    setTomorrowVisits(visitData.tomorrowVisits);
                    setLastFetchTime(timestamp);
                    if (!muteToasts) {
                        addToast('Using cached visit data (offline)', {
                            color: 'warning',
                            autoHide: true,
                            delay: 3000
                        });
                    }
                    return;
                } else {
                    setError('No cached data available while offline');
                    if (!muteToasts) {
                        addToast('No cached data available while offline', {
                            autoHide: true,
                            delay: 5000,
                            color: 'danger',
                            requireAck: true
                        });
                    }
                    return;
                }
            }

            // Only show error if we're online
            const errorMessage = 'Error fetching visits. Please try again later.';
            setError(errorMessage);
            if (!muteToasts) {
                addToast(errorMessage, {
                    autoHide: true,
                    delay: 5000,
                    color: 'danger',
                    requireAck: true
                });
            }
            console.error('Error fetching visits:', error);
            if (error.response?.status === 401) {
                navigate('/login');
            }
        }
    };

    // Handle claiming a visit
    const handleClaimVisit = async (visitId) => {
        try {
            const token = localStorage.getItem('token');
            await api.post(`/api/visits/${visitId}/claim`, {}, {
                headers: { Authorization: `Bearer ${token}` }
            });
            
            // First refresh data (makes API calls and stores in cache)
            await refreshVisitData(() => {});
            // Then load the fresh data into state
            await loadVisits(false, true);
            
            addToast('Visit claimed successfully', {
                autoHide: true,
                delay: 3000,
                color: 'success'
            });
        } catch (error) {
            addToast('Failed to claim visit. Please try again.', {
                autoHide: true,
                delay: 5000,
                color: 'danger',
                requireAck: true
            });
        }
    };

    // Handle unclaiming a visit
    const handleUnclaimVisit = async (visitId) => {
        try {
            const token = localStorage.getItem('token');
            await api.post(`/api/visits/${visitId}/unclaim`, {}, {
                headers: { Authorization: `Bearer ${token}` }
            });
            
            // First refresh data (makes API calls and stores in cache)
            await refreshVisitData(() => {});
            // Then load the fresh data into state
            await loadVisits(false, true);
            
            addToast('Visit unclaimed successfully', {
                autoHide: true,
                delay: 3000,
                color: 'success'
            });
        } catch (error) {
            const errorMessage = error.response?.data?.errors?.[0] || 'Failed to unclaim visit. Please try again.';
            addToast(errorMessage, {
                autoHide: true,
                delay: 5000,
                color: 'danger',
                requireAck: true
            });
        }
    };

    // Update work order status in memory
    const updateWorkOrderStatus = async (visitId, workOrderId, status, comments) => {
        // Update local state immediately
        setVisits(prevVisits => {
            const updatedVisits = prevVisits.map(visit => {
                if (visit.id === visitId) {
                    const updatedWorkOrders = visit.work_orders.map(wo => {
                        if (wo.id === workOrderId) {
                            return {
                                ...wo,
                                status: status === 'Completed' ? 10 : 20, // Convert to numeric for UI
                                comments: comments,
                                completed_timestamp: new Date().toISOString()
                            };
                        }
                        return wo;
                    });
                    return {
                        ...visit,
                        work_orders: updatedWorkOrders
                    };
                }
                return visit;
            });

            // Update the stored visit data with the new state
            const storedData = getVisitData();
            if (storedData) {
                const updatedData = {
                    ...storedData.data,
                    userVisits: updatedVisits
                };
                storeVisitData(updatedData);
            }

            return updatedVisits;
        });

        // Store the update in pending changes if offline
        if (!isOnline) {
            const userEmail = localStorage.getItem('userEmail');
            const pendingUpdates = JSON.parse(localStorage.getItem('pendingWorkOrderUpdates') || '[]');
            pendingUpdates.push({
                visitId,
                workOrderId,
                status, // Keep original string status for API
                comments,
                timestamp: Date.now(),
                userEmail
            });
            localStorage.setItem('pendingWorkOrderUpdates', JSON.stringify(pendingUpdates));
            
            addToast('Work order updated (offline). Changes will sync when online.', {
                color: 'warning',
                autoHide: true,
                delay: 3000
            });
            return;
        } else {
            // If online, try to sync immediately
            try {
                const token = localStorage.getItem('token');
                await api.post(`/api/work-orders/${workOrderId}/complete`, {
                    status, // Keep original string status for API
                    comments
                }, {
                    headers: { Authorization: `Bearer ${token}` }
                });
            } catch (error) {
                const errorMessage = error.response?.data?.message || error.message || 'Unknown error occurred';
                console.error('Error updating work order status:', errorMessage);
                // Store failed update for retry
                const pendingUpdates = JSON.parse(localStorage.getItem('pendingWorkOrderUpdates') || '[]');
                const userEmail = localStorage.getItem('userEmail');
                pendingUpdates.push({
                    visitId,
                    workOrderId,
                    status, // Keep original string status for API
                    comments,
                    timestamp: Date.now(),
                    userEmail
                });
                localStorage.setItem('pendingWorkOrderUpdates', JSON.stringify(pendingUpdates));
                
                addToast(`Failed to sync work order update: ${errorMessage}. Will retry when connection improves.`, {
                    color: 'warning',
                    autoHide: true,
                    delay: 5000
                });
            }
        }
    };

    // Function to sync pending updates when coming online
    const syncPendingUpdates = async () => {
        const allUpdates = JSON.parse(localStorage.getItem('pendingWorkOrderUpdates') || '[]');
        const currentUserEmail = localStorage.getItem('userEmail');
        
        // Filter updates for current user only
        const pendingUpdates = allUpdates.filter(update => update.userEmail === currentUserEmail);
        const otherUserUpdates = allUpdates.filter(update => update.userEmail !== currentUserEmail);
        
        console.log('Attempting to sync pending updates:', pendingUpdates);
        if (pendingUpdates.length === 0) return;

        const token = localStorage.getItem('token');
        const failedUpdates = [];
        let successCount = 0;
        const errors = new Set(); // Track unique error messages

        for (const update of pendingUpdates) {
            try {
                console.log('Syncing update:', update);
                // Convert any numeric status to string for API
                const status = typeof update.status === 'number' 
                    ? (update.status === 10 ? 'Completed' : 'Cancelled')
                    : update.status;
                    
                await api.post(`/api/work-orders/${update.workOrderId}/complete`, {
                    status,
                    comments: update.comments
                }, {
                    headers: { Authorization: `Bearer ${token}` }
                });
                console.log('Successfully synced update for work order:', update.workOrderId);
                successCount++;
            } catch (error) {
                const errorMessage = error.response?.data?.message || error.message || 'Unknown error occurred';
                console.error('Error syncing work order update:', errorMessage);
                errors.add(errorMessage); // Add to unique errors set
                failedUpdates.push(update);
            }
        }

        // Update pending updates with failed ones plus other users' updates
        const updatesToStore = [...failedUpdates, ...otherUserUpdates];
        if (updatesToStore.length > 0) {
            console.log('Some updates failed to sync:', failedUpdates);
            localStorage.setItem('pendingWorkOrderUpdates', JSON.stringify(updatesToStore));
            
            // Only show failure toast if some updates failed
            if (failedUpdates.length > 0) {
                const errorList = Array.from(errors).join('; ');
                const message = `Failed to sync ${failedUpdates.length} of ${pendingUpdates.length} updates: ${errorList}. Will retry when connection improves.`;
                addToast(message, {
                    color: 'danger',
                    autoHide: true,
                    delay: 7000
                });
            }
        } else {
            console.log('All updates synced successfully');
            localStorage.removeItem('pendingWorkOrderUpdates');
        }

        // If any updates were synced successfully, refresh the visit data
        if (successCount > 0) {
            await loadVisits(true, true);
            addToast(`Successfully synced ${successCount} updates`, {
                color: 'success',
                autoHide: true,
                delay: 3000
            });
        }
    };

    // Add effect to sync pending updates when coming online
    useEffect(() => {
        if (isOnline) {
            // Don't auto-sync on initial page load, only when connection state changes
            if (localStorage.getItem('hasInitialLoad') === 'true') {
                syncPendingUpdates();
            } else {
                localStorage.setItem('hasInitialLoad', 'true');
            }
        }
    }, [isOnline]);

    // Handle refresh
    const handleRefresh = async () => {
        try {
            await loadVisits(true);
        } catch (error) {
            console.error('Error during refresh:', error);
        }
    };

    // Register refresh handler
    useEffect(() => {
        setRefreshHandler(() => handleRefresh);
    }, [setRefreshHandler]);

    useEffect(() => {
        loadVisits();
    }, [authenticatedEmail]);

    // Process visits to separate completed and outstanding
    const processVisits = (visitsList) => {
        return visitsList.reduce((acc, visit) => {
            const totalWorkOrders = visit.work_orders.length;
            const completedWorkOrders = visit.work_orders.filter(
                wo => wo.status === 10 || wo.status === 20
            ).length;

            // Add completion stats to the visit
            const visitWithStats = {
                ...visit,
                stats: {
                    total: totalWorkOrders,
                    completed: completedWorkOrders,
                    isFullyComplete: totalWorkOrders === completedWorkOrders
                }
            };

            if (visitWithStats.stats.isFullyComplete) {
                acc.completed.push(visitWithStats);
            } else {
                acc.outstanding.push(visitWithStats);
            }
            return acc;
        }, { outstanding: [], completed: [] });
    };

    // Process all visit types
    const processedTodayVisits = processVisits(visits);
    const processedTomorrowVisits = processVisits(tomorrowVisits);
    const processedUnclaimedVisits = unclaimedVisits.map(visit => {
        const totalWorkOrders = visit.work_orders.length;
        const completedWorkOrders = visit.work_orders.filter(
            wo => wo.status === 10 || wo.status === 20
        ).length;

        return {
            ...visit,
            stats: {
                total: totalWorkOrders,
                completed: completedWorkOrders,
                isFullyComplete: totalWorkOrders === completedWorkOrders
            }
        };
    });

    return (
        <Routes>
            {/* Tomorrow's routes first */}
            <Route 
                path="/tomorrow" 
                element={
                    <VisitList 
                        unclaimedVisits={[]}
                        outstandingVisits={processedTomorrowVisits.outstanding}
                        completedVisits={processedTomorrowVisits.completed}
                        error={error} 
                        onErrorClose={() => setError('')}
                        metadata={metadata}
                        onClaimVisit={handleClaimVisit}
                        title="Tomorrow's Visits"
                    />
                } 
            />
            <Route 
                path="/tomorrow/:visitId/:workOrderId" 
                element={
                    <WorkOrderDetails 
                        visits={[...visits, ...unclaimedVisits, ...tomorrowVisits]} 
                        error={error} 
                        onWorkOrderUpdate={updateWorkOrderStatus}
                        metadata={metadata}
                    />
                } 
            />
            <Route 
                path="/tomorrow/:visitId" 
                element={
                    <VisitDetails 
                        visits={[...visits, ...unclaimedVisits, ...tomorrowVisits]} 
                        error={error} 
                        metadata={metadata}
                        onClaimVisit={handleClaimVisit}
                        onUnclaimVisit={handleUnclaimVisit}
                    />
                } 
            />

            {/* Today's routes */}
            <Route 
                path="/" 
                element={
                    <VisitList 
                        unclaimedVisits={processedUnclaimedVisits}
                        outstandingVisits={processedTodayVisits.outstanding}
                        completedVisits={processedTodayVisits.completed}
                        error={error} 
                        onErrorClose={() => setError('')}
                        metadata={metadata}
                        onClaimVisit={handleClaimVisit}
                        title="Today's Visits"
                        headerRight={<SyncChangesButton isOnline={isOnline} onSync={syncPendingUpdates} />}
                    />
                } 
            />
            <Route 
                path="/:visitId/:workOrderId" 
                element={
                    <WorkOrderDetails 
                        visits={[...visits, ...unclaimedVisits, ...tomorrowVisits]} 
                        error={error} 
                        onWorkOrderUpdate={updateWorkOrderStatus}
                        metadata={metadata}
                        headerRight={<SyncChangesButton isOnline={isOnline} onSync={syncPendingUpdates} />}
                    />
                } 
            />
            <Route 
                path="/:visitId" 
                element={
                    <VisitDetails 
                        visits={[...visits, ...unclaimedVisits, ...tomorrowVisits]} 
                        error={error} 
                        metadata={metadata}
                        onClaimVisit={handleClaimVisit}
                        onUnclaimVisit={handleUnclaimVisit}
                        headerRight={<SyncChangesButton isOnline={isOnline} onSync={syncPendingUpdates} />}
                    />
                } 
            />
        </Routes>
    );
}

export default WorkManagement; 