import React, { useState, useEffect, useRef } from 'react';
import FullCalendar from '@fullcalendar/react';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import interactionPlugin from '@fullcalendar/interaction';
import api from '../Common/axiosSetup';
import { useToast } from '../../Contexts/ToastContext';
import { Box, CircularProgress, Button, TextField, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import AddIcon from '@mui/icons-material/Add';
import EditUserSchedule from './EditUserSchedule';
import AddUserSchedule from './AddUserSchedule';
import useGlobalFilters from '../../Hooks/useGlobalFilters';
import { useTheme } from '@mui/material/styles';
import { getUserTimeZone, toServerMidnightUTC, utcToServerMidnight, SERVER_TIMEZONE } from '../../Utility/Timezone';

const getUserDisplayName = (user) => {
    const firstName = user?.first_name?.trim();
    const lastName = user?.last_name?.trim();
    
    if (firstName && lastName) {
        return `${firstName} ${lastName}`;
    }
    return user?.email || '';
};

const UserScheduleCalendar = () => {
    const [resources, setResources] = useState([]);
    const [events, setEvents] = useState([]);
    const [loading, setLoading] = useState(true);
    const [searchText, setSearchText] = useState(() => {
        return localStorage.getItem('userScheduleSearchText') || '';
    });
    const [metadata, setMetadata] = useState({});

    const [isAddModalOpen, setIsAddModalOpen] = useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [selectedSchedule, setSelectedSchedule] = useState(null);
    const [preSelectedUser, setPreSelectedUser] = useState(null);
    const [selectedDates, setSelectedDates] = useState(null);

    const calendarRef = useRef(null);
    const { addToast } = useToast();
    const theme = useTheme();

    const { filters, updateArea, updateRun, updateZone } = useGlobalFilters();
    const { area, run, zone } = filters;

    /** Fetch metadata */
    const fetchMetadata = async () => {
        try {
            const token = localStorage.getItem('token');

            // Get facility metadata for area/run/zone hierarchy
            const facilityResponse = await api.get('/api/data-registry/metadata', {
                headers: { Authorization: `Bearer ${token}` },
                params: { 
                    table_name: 'facility',
                    column_name: 'data'
                },
            });
            setMetadata(facilityResponse.data || {});
        } catch (error) {
            addToast('Error fetching metadata', {
                color: 'danger',
                autoHide: true,
                delay: 5000,
            });
        } finally {
            setLoading(false);
        }
    };

    // Fetch resources (users) and events (schedules)
    const fetchResourcesAndEvents = async () => {
        try {
            setLoading(true);
            const token = localStorage.getItem('token');

            const params = new URLSearchParams();
            if (area) params.append('area', area);
            if (run) params.append('run', run);
            if (zone) params.append('zone', zone);
            params.append('schedule_tracking', true);

            // Fetch users using the new filtered endpoint
            const resourceResponse = await api.get('/api/user/search/filtered', {
                headers: { Authorization: `Bearer ${token}` },
                params
            });
            const resourcesData = resourceResponse.data.map((user) => ({
                id: user.id,
                title: getUserDisplayName(user),
                extendedProps: {
                    email: user.email
                }
            }));
            setResources(resourcesData);

            // Fetch schedules
            const eventResponse = await api.get('/api/user_schedule', {
                headers: { Authorization: `Bearer ${token}` },
                params
            });
            const eventsData = eventResponse.data.map((schedule) => ({
                id: schedule.id,
                resourceId: schedule.user_id,
                title: schedule.schedule_type,
                start: schedule.start,
                end: schedule.end || schedule.start,
                allDay: schedule.all_day,
                backgroundColor: schedule.schedule_type === 'Days Off' ? '#e0e0e0' : theme.palette.info.main,
                textColor: schedule.schedule_type === 'Days Off' ? '#000000' : '#ffffff',
                extendedProps: {
                    description: schedule.description,
                    schedule_type: schedule.schedule_type
                }
            }));
            setEvents(eventsData);
        } catch (error) {
            console.error('Error fetching data:', error);
            const errorMessage = error.response?.data?.message;
            const errorDetails = error.response?.data?.errors?.join('\n• ');
            const fullError = errorDetails
                ? `${errorMessage}:\n• ${errorDetails}`
                : errorMessage || 'Error fetching schedule data';

            addToast(fullError, {
                color: 'danger',
                autoHide: true,
                delay: 5000
            });
        } finally {
            setLoading(false);
        }
    };

    // Initial fetch
    useEffect(() => {
        fetchMetadata();
        fetchResourcesAndEvents();
    }, [area, run, zone]);

    // Get available areas, runs, and zones from metadata
    const areas = metadata?.data__area?.constraints?.allowed_values || [];
    const runs = area
        ? metadata?.data__run?.constraints?.allowed_values?.[area] || []
        : [];
    const zones = run && metadata?.data__zone?.constraints?.allowed_values
        ? metadata.data__zone.constraints.allowed_values[run] || []
        : [];

    // Filter resources based on search text
    const filteredResources = resources.filter(resource => {
        const fullName = resource.title.toLowerCase();
        return !searchText || fullName.includes(searchText.toLowerCase());
    });

    // FullCalendar event handlers
    const handleDateSelect = (selectInfo) => {
        const userTZ = getUserTimeZone();
        const user = resources.find(r => r.id === selectInfo.resource.id);
        setPreSelectedUser(user);

        setSelectedDates({
            start: selectInfo.start,
            end: selectInfo.end
        });
        setIsAddModalOpen(true);
    };

    const handleEventClick = (clickInfo) => {
        const resource = clickInfo.event.getResources()[0];
        setSelectedSchedule({
            id: clickInfo.event.id,
            user_id: resource.id,
            schedule_type: clickInfo.event.extendedProps.schedule_type,
            start: clickInfo.event.start,
            end: clickInfo.event.end,
            all_day: clickInfo.event.allDay,
            description: clickInfo.event.extendedProps.description
        });
        setIsEditModalOpen(true);
    };

    const handleEventDrop = (dropInfo) => {
        const resource = dropInfo.event.getResources()[0];
        const updated = {
            id: dropInfo.event.id,
            user_id: resource.id,
            schedule_type: dropInfo.event.extendedProps.schedule_type,
            start: dropInfo.event.start,
            end: dropInfo.event.end,
            all_day: dropInfo.event.allDay,
            description: dropInfo.event.extendedProps.description
        };
        dropInfo.revert();
        setSelectedSchedule(updated);
        setIsEditModalOpen(true);
    };

    // Add & Edit success handlers
    const handleAddSuccess = (newSchedule) => {
        setEvents(prev => [...prev, {
            id: newSchedule.id,
            resourceId: newSchedule.user_id,
            title: newSchedule.schedule_type,
            start: newSchedule.start,
            end: newSchedule.end || newSchedule.start,
            allDay: newSchedule.all_day,
            backgroundColor: newSchedule.schedule_type === 'Days Off' ? '#e0e0e0' : theme.palette.info.main,
            textColor: newSchedule.schedule_type === 'Days Off' ? '#000000' : '#ffffff',
            extendedProps: {
                description: newSchedule.description,
                schedule_type: newSchedule.schedule_type
            }
        }]);
    };

    const handleEditSuccess = (updatedSchedule) => {
        if (!updatedSchedule) {
            // Schedule was deleted
            setEvents(prev => prev.filter(evt => evt.id !== selectedSchedule.id));
            return;
        }

        setEvents(prev => prev.map(evt => 
            evt.id === updatedSchedule.id
                ? {
                    id: updatedSchedule.id,
                    resourceId: updatedSchedule.user_id,
                    title: updatedSchedule.schedule_type,
                    start: updatedSchedule.start,
                    end: updatedSchedule.end || updatedSchedule.start,
                    allDay: updatedSchedule.all_day,
                    backgroundColor: updatedSchedule.schedule_type === 'Days Off' ? '#e0e0e0' : theme.palette.info.main,
                    textColor: updatedSchedule.schedule_type === 'Days Off' ? '#000000' : '#ffffff',
                    extendedProps: {
                        description: updatedSchedule.description,
                        schedule_type: updatedSchedule.schedule_type
                    }
                }
                : evt
        ));
    };

    const handleRefresh = async () => {
        await fetchResourcesAndEvents();
        addToast('Calendar refreshed', {
            color: 'success',
            autoHide: true,
            delay: 3000
        });
    };

    return (
        <Box
            sx={{
                height: 'calc(100vh - 280px)',
                width: '100%',
                padding: theme.spacing(2),
                backgroundColor: theme.palette.background.default,
                borderRadius: theme.shape.borderRadius,
                boxShadow: theme.shadows[1],
                overflow: 'hidden',
                display: 'flex',
                flexDirection: 'column',
                gap: theme.spacing(2),
                // Set CSS variables from theme
                '--fc-event-bg-color': theme.palette.primary.main,
                '--fc-event-text-color': theme.palette.primary.contrastText,
                '--fc-event-font': theme.typography.fontFamily
            }}
        >
            {/* Filters & Buttons */}
            <Box sx={{ display: 'flex', gap: 2 }}>
                <FormControl sx={{ minWidth: 120 }}>
                    <InputLabel>Area</InputLabel>
                    <Select value={area} label="Area" onChange={(e) => updateArea(e.target.value)}>
                        <MenuItem value="">All</MenuItem>
                        {areas?.map((a) => (
                            <MenuItem key={a} value={a}>
                                {a}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <FormControl sx={{ minWidth: 120 }}>
                    <InputLabel>Run</InputLabel>
                    <Select
                        value={run}
                        label="Run"
                        onChange={(e) => updateRun(e.target.value)}
                        disabled={!area}
                    >
                        <MenuItem value="">All</MenuItem>
                        {runs?.map((r) => (
                            <MenuItem key={r} value={r}>
                                {r}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <FormControl sx={{ minWidth: 120 }}>
                    <InputLabel>Zone</InputLabel>
                    <Select
                        value={zone}
                        label="Zone"
                        onChange={(e) => updateZone(e.target.value)}
                        disabled={!run}
                    >
                        <MenuItem value="">All</MenuItem>
                        {zones?.map((z) => (
                            <MenuItem key={z} value={z}>
                                {z}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <TextField
                    size="small"
                    label="Search Users"
                    variant="outlined"
                    value={searchText}
                    onChange={(e) => {
                        const value = e.target.value;
                        setSearchText(value);
                        localStorage.setItem('userScheduleSearchText', value);
                    }}
                    sx={{ 
                        width: 300,
                        '& .MuiOutlinedInput-root': {
                            height: '56px'
                        }
                    }}
                />

                <Box sx={{ marginLeft: 'auto', display: 'flex', gap: 1 }}>
                    <Button
                        variant="outlined"
                        startIcon={<RefreshIcon />}
                        onClick={handleRefresh}
                        sx={{ height: '40px', textTransform: 'none' }}
                    >
                        Refresh
                    </Button>
                    <Button
                        variant="contained"
                        startIcon={<AddIcon />}
                        onClick={() => setIsAddModalOpen(true)}
                        sx={{ height: '40px', textTransform: 'none' }}
                    >
                        Add Schedule
                    </Button>
                </Box>
            </Box>

            {/* Main Calendar */}
            {loading ? (
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100%',
                    }}
                >
                    <CircularProgress />
                </Box>
            ) : (
                <FullCalendar
                    ref={calendarRef}
                    plugins={[resourceTimelinePlugin, interactionPlugin]}
                    schedulerLicenseKey="CC-Attribution-NonCommercial-NoDerivatives"
                    height="100%"
                    initialView="resourceTimelineWeek"
                    timeZone="CST"
                    slotMinTime="00:00:00"
                    slotMaxTime="24:00:00"
                    eventDurationEditable={false}
                    eventStartEditable={false}
                    headerToolbar={{
                        left: 'prev next today',
                        center: 'title',
                        right: 'resourceTimelineThreeDay,resourceTimelineWeek,resourceTimelineMonth',
                    }}
                    views={{
                        resourceTimelineThreeDay: {
                            type: 'resourceTimeline',
                            duration: { days: 3 },
                            buttonText: 'Day',
                            expandRows: true
                        },
                        resourceTimelineWeek: {
                            type: 'resourceTimeline',
                            duration: { days: 7 },
                            buttonText: 'Week',
                            expandRows: true
                        },
                        resourceTimelineMonth: {
                            type: 'resourceTimeline',
                            duration: { months: 1 },
                            buttonText: 'Month',
                            expandRows: true
                        },
                    }}
                    buttonText={{
                        today: 'Today',
                        day: 'Day',
                        week: 'Week',
                        month: 'Month',
                    }}
                    resources={filteredResources}
                    events={events}
                    editable
                    selectable
                    select={handleDateSelect}
                    eventClick={handleEventClick}
                    eventDrop={handleEventDrop}
                    resourceAreaColumns={[
                        {
                            field: 'title',
                            headerContent: 'Users',
                            width: 400,
                        },
                    ]}
                    slotLabelInterval="24:00:00"
                    slotMinWidth={100}
                    resourceAreaWidth={400}
                    resourceLabelText="Users"
                    resourceOrder="title"
                    slotLabelFormat={{
                        day: 'numeric',
                        weekday: 'short',
                        month: 'numeric',
                        omitZeroMinute: true,
                    }}
                    dayHeaderFormat={{
                        weekday: 'short',
                        month: 'numeric',
                        day: 'numeric',
                        omitCommas: true,
                    }}
                    eventContent={(eventInfo) => {
                        const isDaysOff = eventInfo.event.extendedProps.schedule_type === 'Days Off';
                        const userTZ = getUserTimeZone();
                        
                        const formatDate = (date) => {
                            const localDate = utcToServerMidnight(date, userTZ);
                            localDate.setHours(localDate.getHours() + 24);
                            return localDate.toLocaleDateString('en-US', {
                                month: 'short',
                                day: 'numeric'
                            });
                        };
                        
                        const startDate = formatDate(eventInfo.event.start);
                        const endDate = eventInfo.event.end 
                            ? formatDate(new Date(eventInfo.event.end.getTime() - 24 * 60 * 60 * 1000))
                            : startDate;
                        
                        return {
                            html: `
                                <div style="
                                    height: 100%; 
                                    width: 100%;
                                    display: flex; 
                                    flex-direction: column; 
                                    justify-content: center; 
                                    align-items: center; 
                                    background: ${isDaysOff ? theme.palette.primary.main : theme.palette.secondary.main}; 
                                    color: ${isDaysOff ? theme.palette.primary.contrastText : theme.palette.secondary.contrastText}; 
                                    padding: 8px;
                                    border-radius: 4px;
                                    font-family: ${theme.typography.fontFamily};
                                    font-size: 14px;
                                    box-sizing: border-box;
                                ">
                                    <div style="font-weight: 600; margin-bottom: 4px; color: ${isDaysOff ? theme.palette.primary.contrastText : theme.palette.secondary.contrastText};">${eventInfo.event.extendedProps.schedule_type}</div>
                                    <div style="color: ${isDaysOff ? theme.palette.primary.contrastText : theme.palette.secondary.contrastText};">${startDate} through ${endDate}</div>
                                </div>
                            `
                        };
                    }}
                />
            )}

            <EditUserSchedule
                open={isEditModalOpen}
                onClose={() => {
                    setIsEditModalOpen(false);
                    setSelectedSchedule(null);
                }}
                onSuccess={handleEditSuccess}
                schedule={selectedSchedule}
            />

            <AddUserSchedule
                open={isAddModalOpen}
                onClose={() => {
                    setIsAddModalOpen(false);
                    setPreSelectedUser(null);
                    setSelectedDates(null);
                }}
                onSuccess={handleAddSuccess}
                preSelectedUser={preSelectedUser}
                initialDates={selectedDates}
            />

            {/* MUI-like FC button styles + Sticky Headers */}
            <style>{`
                /* 1) Sticky date/time headers */
                .fc .fc-timeline-header,
                .fc .fc-datagrid-header,
                .fc .fc-resource-timeline-divider {
                    position: sticky !important;
                    top: 0 !important;
                    z-index: 999 !important;
                    background-color: ${theme.palette.background.default} !important;
                }

                /* 2) Timeline body scrolling */
                .fc .fc-view-harness {
                    flex: 1 1 auto !important;
                    position: relative !important;
                    overflow: hidden !important; 
                }
                .fc .fc-timeline-body {
                    overflow-y: auto !important;
                }

                /* 3) Make events fill their slots */
                .fc .fc-timeline-event {
                    height: 100%!important;
                    margin: 2px 0 !important;
                    background: none !important;  /* Remove default background */
                    border: none !important;      /* Remove default border */
                }
                
                .fc-timeline-event .fc-event-main {
                    height: 100% !important;
                    padding: 0 !important;
                }
                
                .fc-timeline-event .fc-event-main-frame {
                    height: 100% !important;
                }

                /* Remove default event styling that might hide our content */
                .fc .fc-event-main-frame,
                .fc .fc-event-title-frame {
                    display: block !important;
                    height: 100% !important;
                }

                .fc .fc-event-title {
                    display: block !important;
                    height: 100% !important;
                    white-space: normal !important;
                }

                /* 4) MUI-like button styling */
                .fc .fc-button {
                    height: 40px !important;
                    padding: 8px 16px !important;
                    min-width: 64px !important;
                    font-family: inherit !important;
                    font-size: 1rem !important;
                    text-transform: none !important;
                    background-color: transparent !important;
                    border: 1px solid rgba(255, 255, 255, 0.23) !important;
                    border-radius: 4px !important;
                    color: rgba(255, 255, 255, 0.7) !important;
                    margin: 0 4px !important;
                }
                .fc .fc-button-group {
                    gap: 6px !important; /* spacing between Day/Week/Month in the group */
                }
                .fc .fc-today-button {
                    color: rgba(255, 255, 255, 0.7) !important;
                    font-weight: 400 !important;
                }
                .fc .fc-today-button:disabled {
                    background-color: rgba(255, 255, 255, 0.12) !important;
                    border-color: rgba(255, 255, 255, 0.12) !important;
                    color: rgba(255, 255, 255, 0.3) !important;
                    pointer-events: none !important;
                }
                .fc .fc-prev-button,
                .fc .fc-next-button {
                    color: rgba(255, 255, 255, 0.7) !important;
                    font-weight: 400 !important;
                }
                .fc .fc-toolbar-title {
                    font-size: 1.25rem;
                    font-weight: 500;
                    padding: 0 16px;
                }
            `}</style>
        </Box>
    );
};

// Custom event render function
const renderEventContent = (eventInfo) => {
    return (
        <Box sx={{ 
            p: 1, 
            textAlign: 'center',
            whiteSpace: 'normal',
            overflow: 'hidden'
        }}>
            <div>{eventInfo.event.extendedProps.schedule_type}</div>
            <div style={{ fontSize: '0.8em' }}>
                {new Date(eventInfo.event.start).toLocaleDateString()} - {new Date(eventInfo.event.end).toLocaleDateString()}
            </div>
            {eventInfo.event.extendedProps.description && (
                <div style={{ fontSize: '0.8em', fontStyle: 'italic' }}>
                    {eventInfo.event.extendedProps.description}
                </div>
            )}
        </Box>
    );
};

export default UserScheduleCalendar;