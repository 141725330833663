import React, { useMemo } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { ListGroup, Badge, Alert, Button } from 'react-bootstrap';
import { useTheme } from '@mui/material/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHandPaper, faArrowLeft, faLocationArrow } from '@fortawesome/free-solid-svg-icons';
import { useAuth } from '../../Contexts/AuthContext';
import { useToast } from '../../Contexts/ToastContext';
import { useConnectivity } from '../../Contexts/ConnectivityContext';
import { calculateDistance, getNavigationLink, useUserLocation } from '../../Utility/navigationUtils';
import { adjustDbDate, formatDbDate } from '../../Utility/dateUtils';

function VisitDetails({ visits, error, metadata, onClaimVisit, onUnclaimVisit, headerRight }) {
    const { visitId } = useParams();
    const navigate = useNavigate();
    const theme = useTheme();
    const { authenticatedEmail } = useAuth();
    const { addToast } = useToast();
    const { isOnline } = useConnectivity();
    const userLocation = useUserLocation();

    // Find the specific visit
    const visit = visits.find(v => v.id === visitId);
    const isClaimedByMe = visit?.claimed_by === authenticatedEmail;
    const isUnclaimed = !visit?.claimed_by;
    const allWorkOrdersCompleted = visit?.work_orders?.every(wo => wo.status === 10 || wo.status === 20) || false;

    // Check if this is a tomorrow's visit
    const isTomorrowVisit = useMemo(() => {
        if (!visit?.visit_date) {
            console.log('No visit date found');
            return false;
        }

        const visitDate = adjustDbDate(visit.visit_date);
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        const tomorrow = new Date(today);
        tomorrow.setDate(tomorrow.getDate() + 1);
        const dayAfterTomorrow = new Date(tomorrow);
        dayAfterTomorrow.setDate(dayAfterTomorrow.getDate() + 1);
        
        console.log('Visit date:', visitDate.toISOString());
        console.log('Tomorrow:', tomorrow.toISOString());
        console.log('Day after tomorrow:', dayAfterTomorrow.toISOString());
        console.log('Is tomorrow visit:', visitDate >= tomorrow && visitDate < dayAfterTomorrow);
        
        return visitDate >= tomorrow && visitDate < dayAfterTomorrow;
    }, [visit]);

    // Get priority text and badge variant
    const getPriorityInfo = (priority) => {
        const priorityValues = metadata?.priority?.constraints?.allowed_values || {
            "0": "Critical",
            "5": "High",
            "10": "Scheduled"
        };
        
        const text = priorityValues[priority] || 'Unknown';
        let variant;

        switch(text) {
            case 'Critical':
                variant = 'danger';
                break;
            case 'High':
                variant = 'warning';
                break;
            case 'Scheduled':
                variant = 'primary';
                break;
            default:
                variant = 'primary';
        }

        return { text, variant };
    };

    // Get highest priority from work orders
    const getHighestPriority = (workOrders) => {
        if (!workOrders || workOrders.length === 0) return null;
        return Math.min(...workOrders.map(wo => wo.priority));
    };

    const getStatusText = (status) => {
        if (status === 10) return 'Completed';
        if (status === 20) return 'Cancelled';
        return 'Not Complete';
    };

    const getWorkOrderTypeVariant = (type) => {
        switch(type) {
            case 'Maintenance':
                return 'warning';
            case 'Routine Maintenance':
                return 'primary';
            case 'Exception':
                return 'danger';
            default:
                return 'primary';
        }
    };

    // Get visit type info
    const getVisitTypeInfo = (visitType) => {
        const text = visitType === 1 ? "Exception" : "Routine";
        const variant = visitType === 1 ? 'danger' : 'primary';
        return { text, variant };
    };

    const handleNavigationClick = (e) => {
        e.stopPropagation();
        if (!visit.facility?.surface_latitude || !visit.facility?.surface_longitude) {
            addToast('Coordinates not available for this site. Please contact support to get this added.', {
                color: 'warning',
                autoHide: true,
                delay: 5000
            });
            return;
        }
        window.open(getNavigationLink(visit.facility.surface_latitude, visit.facility.surface_longitude), '_blank');
    };

    const handleClaimVisit = async () => {
        await onClaimVisit(visitId);
    };

    const handleUnclaimVisit = async () => {
        await onUnclaimVisit(visitId);
    };

    if (!visit) {
        return (
            <div className="container-fluid p-0">
                <div className="px-3 pt-2">
                    <Button 
                        variant="primary"
                        onClick={() => navigate(isTomorrowVisit ? '/mobile/work-management/tomorrow' : '/mobile/work-management')}
                        className="mb-3"
                    >
                        <FontAwesomeIcon icon={faArrowLeft} className="me-2" />
                        Back to Visits
                    </Button>
                    <Alert variant="danger">
                        Visit not found
                    </Alert>
                </div>
            </div>
        );
    }

    const highestPriority = getHighestPriority(visit.work_orders);
    const priorityInfo = highestPriority !== null ? getPriorityInfo(highestPriority) : null;
    const visitTypeInfo = getVisitTypeInfo(visit.visit_type);

    // Calculate distance if we have both user location and facility coordinates
    const distance = userLocation && visit.facility?.surface_latitude && visit.facility?.surface_longitude
        ? calculateDistance(
            userLocation.lat,
            userLocation.lon,
            parseFloat(visit.facility.surface_latitude),
            parseFloat(visit.facility.surface_longitude)
        )
        : null;

    const isWorkOrderLate = (workOrder) => {
        if (!workOrder.due_date || workOrder.status === 10 || workOrder.status === 20) return false;
        const dueDate = adjustDbDate(workOrder.due_date);
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        return dueDate < today;
    };

    return (
        <div className="container-fluid p-0">
            {/* Header */}
            <div className="px-3 pt-2">
                <div className="d-flex align-items-center justify-content-between mb-3">
                    <Button 
                        variant="primary"
                        onClick={() => navigate(isTomorrowVisit ? '/mobile/work-management/tomorrow' : '/mobile/work-management')}
                        style={{ minWidth: '120px' }}
                    >
                        <FontAwesomeIcon icon={faArrowLeft} className="me-2" />
                        Back to Visits
                    </Button>
                    {headerRight}
                </div>
                <hr className="custom-separator"/>
            </div>

            {/* Facility Info Table */}
            <div className="visit-details mb-3">
                <div className="d-flex justify-content-between align-items-center mb-2">
                    <div style={{ width: '40%' }}>
                        <strong className="fs-6">UWI:</strong>
                    </div>
                    <div style={{ width: '60%' }}>
                        <span className="fs-6">{visit.facility.uwi}</span>
                    </div>
                </div>
                <div className="d-flex justify-content-between align-items-center mb-2">
                    <div style={{ width: '40%' }}>
                        <strong className="fs-6">Zone:</strong>
                    </div>
                    <div style={{ width: '60%' }}>
                        <span className="fs-6">{visit.facility.zone}</span>
                    </div>
                </div>
                <div className="d-flex justify-content-between align-items-center mb-2">
                    <div style={{ width: '40%' }}>
                        <strong className="fs-6">Navigation:</strong>
                    </div>
                    <div style={{ width: '60%' }}>
                        <Button
                            variant="outline-secondary"
                            size="sm"
                            onClick={handleNavigationClick}
                            className="w-100"
                        >
                            <FontAwesomeIcon icon={faLocationArrow} className="me-2" />
                            Navigate {distance ? `(${distance}km)` : ''}
                        </Button>
                    </div>
                </div>
            </div>

            {/* Visit Info Table */}
            <div className="visit-info mb-3">
                <div className="d-flex justify-content-between align-items-center mb-2">
                    <div style={{ width: '40%' }}>
                        <strong className="fs-6">Visit Type:</strong>
                    </div>
                    <div style={{ width: '60%' }}>
                        <Badge 
                            bg={visitTypeInfo.variant}
                            className="px-3 py-2 fs-6 w-100 text-center"
                        >
                            {visitTypeInfo.text}
                        </Badge>
                    </div>
                </div>
                <div className="d-flex justify-content-between align-items-center mb-2">
                    <div style={{ width: '40%' }}>
                        <strong className="fs-6">Priority:</strong>
                    </div>
                    <div style={{ width: '60%' }}>
                        {priorityInfo && (
                            <Badge 
                                bg={priorityInfo.variant}
                                className="px-3 py-2 fs-6 w-100 text-center"
                            >
                                {priorityInfo.text}
                            </Badge>
                        )}
                    </div>
                </div>
                <div className="d-flex justify-content-between align-items-center mb-2">
                    <div style={{ width: '40%' }}>
                        <strong className="fs-6">Work Order Types:</strong>
                    </div>
                    <div style={{ width: '60%' }}>
                        <div className="d-flex flex-column gap-2">
                            {[...new Set(visit.work_orders.map(wo => wo.type))].map((type, index) => (
                                <Badge 
                                    key={index}
                                    bg={getWorkOrderTypeVariant(type)}
                                    className="px-3 py-2 fs-6 w-100 text-center"
                                >
                                    {type}
                                </Badge>
                            ))}
                        </div>
                    </div>
                </div>
                <div className="d-flex justify-content-between align-items-center mb-2">
                    <div style={{ width: '40%' }}>
                        <strong className="fs-6">Claim Status:</strong>
                    </div>
                    <div style={{ width: '60%' }}>
                        <Badge 
                            bg={isClaimedByMe ? 'info' : isUnclaimed ? 'warning' : 'secondary'}
                            className="px-3 py-2 fs-6 w-100 text-center"
                        >
                            {isClaimedByMe ? 'Claimed by you' : isUnclaimed ? 'Unclaimed' : `Claimed by ${visit.claimed_by}`}
                        </Badge>
                    </div>
                </div>
            </div>

            {/* Claim/Unclaim Buttons */}
            {isUnclaimed && (
                <Button
                    variant="outline-primary"
                    onClick={handleClaimVisit}
                    className="w-100 mb-3"
                    disabled={!isOnline}
                    title={!isOnline ? "You must be online to claim a visit" : ""}
                >
                    <FontAwesomeIcon icon={faHandPaper} className="me-2" />
                    Claim Visit
                </Button>
            )}
            {isClaimedByMe && (
                <Button
                    variant="outline-danger"
                    onClick={handleUnclaimVisit}
                    className="w-100 mb-3"
                    disabled={!isOnline || allWorkOrdersCompleted}
                    title={!isOnline ? "You must be online to unclaim a visit" : allWorkOrdersCompleted ? "Cannot unclaim a visit where all work orders are completed or cancelled" : ""}
                >
                    <FontAwesomeIcon icon={faHandPaper} className="me-2" />
                    Unclaim Visit
                </Button>
            )}

            <hr className="custom-separator"/>

            {/* Work Orders List */}
            <ListGroup variant="flush">
                {visit.work_orders.map(wo => {
                    const priorityInfo = getPriorityInfo(wo.priority);
                    const statusText = getStatusText(wo.status);
                    const statusVariant = wo.status === 10 ? 'success' : wo.status === 20 ? 'warning' : 'primary';
                    const isLate = isWorkOrderLate(wo);

                    return (
                        <ListGroup.Item 
                            key={wo.id} 
                            action
                            onClick={() => navigate(`/mobile/work-management${isTomorrowVisit ? '/tomorrow' : ''}/${visitId}/${wo.id}`)}
                            className="px-3 py-3"
                        >
                            <div className="d-flex flex-column gap-2">
                                <div className="d-flex justify-content-between align-items-center mb-2">
                                    <div style={{ width: '40%' }}>
                                        <strong className="fs-6">Work Order Type:</strong>
                                    </div>
                                    <div style={{ width: '60%' }}>
                                        <Badge 
                                            bg={getWorkOrderTypeVariant(wo.type)}
                                            className="px-3 py-2 fs-6 w-100 text-center"
                                        >
                                            {wo.type}
                                        </Badge>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-between align-items-center mb-2">
                                    <div style={{ width: '40%' }}>
                                        <strong className="fs-6">Priority:</strong>
                                    </div>
                                    <div style={{ width: '60%' }}>
                                        <Badge 
                                            bg={priorityInfo.variant}
                                            className="px-3 py-2 fs-6 w-100 text-center"
                                        >
                                            {priorityInfo.text}
                                        </Badge>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-between align-items-center mb-2">
                                    <div style={{ width: '40%' }}>
                                        <strong className="fs-6">Status:</strong>
                                    </div>
                                    <div style={{ width: '60%' }}>
                                        <Badge 
                                            bg={statusVariant}
                                            className="px-3 py-2 fs-6 w-100 text-center"
                                        >
                                            {statusText}
                                        </Badge>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-between align-items-center mb-2">
                                    <div style={{ width: '40%' }}>
                                        <strong className="fs-6">Due Date:</strong>
                                    </div>
                                    <div style={{ width: '60%' }} className="d-flex align-items-center gap-2">
                                        <span className="fs-6">{formatDbDate(wo.due_date)}</span>
                                        {isLate && (
                                            <Badge bg="warning">
                                                Late
                                            </Badge>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <hr className="my-3"/>
                            <div>
                                <strong className="text-muted fs-6">Work Order Notes:</strong>
                                <p className="mt-1 mb-0">{wo.notes || 'No notes available'}</p>
                            </div>
                        </ListGroup.Item>
                    );
                })}
            </ListGroup>
        </div>
    );
}

export default VisitDetails; 