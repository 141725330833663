import React, { useState, useEffect, useRef } from 'react';
import FullCalendar from '@fullcalendar/react';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import interactionPlugin from '@fullcalendar/interaction';
import api from '../Common/axiosSetup';
import { useToast } from '../../Contexts/ToastContext';
import { Box, CircularProgress, Button, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import AddIcon from '@mui/icons-material/Add';
import EditVisit from './EditVisit';
import AddVisit from './AddVisit';
import useGlobalFilters from '../../Hooks/useGlobalFilters';
import { useTheme } from '@mui/material/styles';

const VisitsTable = () => {
  const [resources, setResources] = useState([]);
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);

  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedVisit, setSelectedVisit] = useState(null);

  const [isOutOfSync, setIsOutOfSync] = useState(false);
  const [isChecking, setIsChecking] = useState(false);

  const [metadata, setMetadata] = useState({});
  const [facMetadata, setFacMetadata] = useState({});
  const [searchText, setSearchText] = useState(() => {
    return localStorage.getItem('visitsTableSearchText') || '';
  });

  const checkTimeoutRef = useRef(null);
  const calendarRef = useRef(null);

  const { addToast } = useToast();
  const theme = useTheme();

  const { filters, updateArea, updateRun, updateZone } = useGlobalFilters();
  const { area, run, zone } = filters;

  // Areas, Runs, Zones from facility metadata
  const areas = facMetadata?.data__area?.constraints?.allowed_values || [];
  const runs = area
    ? facMetadata?.data__run?.constraints?.allowed_values?.[area] || []
    : [];
  const zones =
    run && facMetadata?.data__zone?.constraints?.allowed_values
      ? facMetadata.data__zone.constraints.allowed_values[run] || []
      : [];

  /** Fetch resources & events */
  const fetchResourcesAndEvents = async () => {
    try {
      setLoading(true);
      const token = localStorage.getItem('token');

      const params = new URLSearchParams();
      if (area) params.append('area', area);
      if (run) params.append('run', run);
      if (zone) params.append('zone', zone);

      // Facilities
      const resourceResponse = await api.get('/api/facility/search/filtered', {
        headers: { Authorization: `Bearer ${token}` },
        params,
      });
      const resourcesData = resourceResponse.data.map((facility) => ({
        id: facility.id,
        title: facility.facility_name,
        area: facility.area,
        run: facility.run,
        zone: facility.zone,
      }));
      setResources(resourcesData);

      // Visits
      const eventResponse = await api.get('/api/visits', {
        headers: { Authorization: `Bearer ${token}` },
        params,
      });
      const eventsData = eventResponse.data.map((visit) => ({
        id: visit.id,
        title: `Visit ${visit.id}`,
        start: visit.scheduled_start_timestamp,
        end: visit.scheduled_end_timestamp,
        resourceId: visit.facility_id,
        extendedProps: {
          work_orders: visit.work_orders || [],
          visit_type: visit.visit_type,
          status: visit.status,
          notes: visit.notes,
          assignedTo: visit.assigned_to,
          updateTimestamp: visit.update_timestamp,
          onShiftUsers: visit.on_shift_users || [],
          claimed_by: visit.claimed_by,
          claimed_by_user: visit.claimed_by_user,
        },
      }));
      setEvents(eventsData);
    } catch (error) {
      console.error('Error fetching data:', error);
      const errorMessage = error.response?.data?.message;
      const errorDetails = error.response?.data?.errors?.join('\n• ');
      const fullError = errorDetails
        ? `${errorMessage}:\n• ${errorDetails}`
        : errorMessage || 'Error fetching visits data';

      addToast(fullError, {
        color: 'danger',
        autoHide: true,
        delay: 5000,
      });
    } finally {
      setLoading(false);
    }
  };

  /** Fetch metadata */
  const fetchMetadata = async () => {
    try {
      const token = localStorage.getItem('token');

      // Visit metadata
      const visitResponse = await api.get('/api/data-registry/metadata', {
        headers: { Authorization: `Bearer ${token}` },
        params: { table_name: 'visits' },
      });
      setMetadata(visitResponse.data || {});

      // Facility metadata
      const facilityResponse = await api.get('/api/data-registry/metadata', {
        headers: { Authorization: `Bearer ${token}` },
        params: { table_name: 'facility' },
      });
      setFacMetadata(facilityResponse.data || {});
    } catch (error) {
      addToast('Error fetching metadata', {
        color: 'danger',
        autoHide: true,
        delay: 5000,
      });
    } finally {
      setLoading(false);
    }
  };

  // FullCalendar interactions
  const handleDateSelect = (selectInfo) => {
    setSelectedVisit({
      start: selectInfo.startStr,
      end: selectInfo.endStr,
      resourceId: selectInfo.resource.id,
    });
    setIsAddModalOpen(true);
  };

  const handleEventClick = (clickInfo) => {
    const resource = clickInfo.event.getResources()[0];
    setSelectedVisit({
      id: clickInfo.event.id,
      title: clickInfo.event.title,
      start: clickInfo.event.startStr,
      end: clickInfo.event.endStr,
      resourceId: resource?.id,
      facility_name: resource?.title || '',
      facility_zone: resource?.extendedProps?.zone || '',
      visit_type: clickInfo.event.extendedProps.visit_type,
      work_orders: clickInfo.event.extendedProps.work_orders || [],
      ...clickInfo.event.extendedProps,
    });
    setIsEditModalOpen(true);
  };

  const handleEventDrop = (dropInfo) => {
    const resource = dropInfo.event.getResources()[0];
    const updated = {
      id: dropInfo.event.id,
      title: dropInfo.event.title,
      start: dropInfo.event.startStr,
      end: dropInfo.event.endStr,
      resourceId: resource?.id,
      facility_name: resource?.title || '',
      facility_zone: resource?.extendedProps?.zone || '',
      visit_type: dropInfo.event.extendedProps.visit_type,
      work_orders: dropInfo.event.extendedProps.work_orders || [],
      ...dropInfo.event.extendedProps,
    };
    dropInfo.revert();
    setSelectedVisit(updated);
    setIsEditModalOpen(true);
  };

  // Add & Edit success
  const handleAddSuccess = (newVisit) => {
    setEvents((prev) => [
        ...prev,
        {
            id: newVisit.id,
            title: `Visit ${newVisit.id}`,
            start: newVisit.scheduled_start_timestamp,
            end: newVisit.scheduled_end_timestamp,
            resourceId: newVisit.facility_id,
            extendedProps: {
                work_orders: newVisit.work_orders || [],
                visit_type: newVisit.visit_type,
                status: newVisit.status,
                notes: newVisit.notes,
                assignedTo: newVisit.assigned_to,
                updateTimestamp: newVisit.update_timestamp,
                onShiftUsers: newVisit.on_shift_users || [],
                claimed_by: newVisit.claimed_by,
                claimed_by_user: newVisit.claimed_by_user
            },
        },
    ]);
    addToast('Visit created successfully', {
        color: 'success',
        autoHide: true,
        delay: 5000
    });
  };

  const handleEditSuccess = (updatedVisit) => {
    // If updatedVisit is null, it means the visit was deleted
    if (!updatedVisit) {
        setEvents(prev => prev.filter(evt => evt.id !== selectedVisit.id));
        addToast('Visit deleted successfully', {
            color: 'success',
            autoHide: true,
            delay: 5000
        });
        return;
    }

    setEvents(prev =>
        prev.map((evt) =>
            evt.id === updatedVisit.id
                ? {
                    id: updatedVisit.id,
                    title: `Visit ${updatedVisit.id}`,
                    start: updatedVisit.scheduled_start_timestamp,
                    end: updatedVisit.scheduled_end_timestamp,
                    resourceId: updatedVisit.facility_id,
                    extendedProps: {
                        work_orders: updatedVisit.work_orders || [],
                        visit_type: updatedVisit.visit_type,
                        facility_name: updatedVisit.facility_name,
                        facility_zone: updatedVisit.facility_zone,
                        updateTimestamp: updatedVisit.update_timestamp,
                        onShiftUsers: updatedVisit.on_shift_users || [],
                        claimed_by: updatedVisit.claimed_by,
                        claimed_by_user: updatedVisit.claimed_by_user
                    },
                }
                : evt
        )
    );
    addToast('Visit updated successfully', {
        color: 'success',
        autoHide: true,
        delay: 5000
    });
  };

  // Auto-refresh
  const checkVersions = async () => {
    if (isChecking) return;
    try {
      setIsChecking(true);
      const token = localStorage.getItem('token');
      const params = new URLSearchParams();
      if (area) params.append('area', area);
      if (run) params.append('run', run);
      if (zone) params.append('zone', zone);

      const response = await api.get('/api/visits', {
        headers: { Authorization: `Bearer ${token}` },
        params,
      });
      const freshData = response.data;

      const hasChanges = freshData.some((freshVisit) => {
        const currentEvent = events.find((e) => e.id === freshVisit.id);
        return (
          !currentEvent ||
          new Date(currentEvent.extendedProps.updateTimestamp) <
            new Date(freshVisit.update_timestamp)
        );
      });

      setIsOutOfSync(hasChanges);
    } catch (error) {
      console.error('Error checking versions:', error);
    } finally {
      setIsChecking(false);
    }
  };

  const handleRefresh = async () => {
    await fetchResourcesAndEvents();
    setIsOutOfSync(false);
  };

  // Filter changes
  const handleAreaChange = (e) => updateArea(e.target.value);
  const handleRunChange = (e) => updateRun(e.target.value);
  const handleZoneChange = (e) => updateZone(e.target.value);

  // On mount => fetch metadata
  useEffect(() => {
    fetchMetadata();
  }, []);

  // After metadata => fetch resources + events
  useEffect(() => {
    if (facMetadata && Object.keys(facMetadata).length > 0) {
      fetchResourcesAndEvents();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [area, run, zone, facMetadata]);

  // Periodic checks (120s)
  useEffect(() => {
    const startChecking = () => {
      checkTimeoutRef.current = setTimeout(async () => {
        await checkVersions();
        startChecking();
      }, 120000);
    };
    startChecking();
    return () => {
      if (checkTimeoutRef.current) clearTimeout(checkTimeoutRef.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [events]);

  return (
    <Box
      sx={{
        height: 'calc(100vh - 280px)',
        width: '100%',
        padding: theme.spacing(2),
        backgroundColor: theme.palette.background.default,
        borderRadius: theme.shape.borderRadius,
        boxShadow: theme.shadows[1],
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(2),
        // Set CSS variables from theme
        '--fc-event-bg-color': theme.palette.primary.main,
        '--fc-event-text-color': theme.palette.primary.contrastText,
        '--fc-event-font': theme.typography.fontFamily
      }}
    >
      {/* Filters & Buttons */}
      <Box sx={{ display: 'flex', gap: 2 }}>
        <FormControl sx={{ minWidth: 120 }}>
          <InputLabel>Area</InputLabel>
          <Select value={area} label="Area" onChange={handleAreaChange}>
            <MenuItem value="">All</MenuItem>
            {areas.map((a) => (
              <MenuItem key={a} value={a}>
                {a}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl sx={{ minWidth: 120 }}>
          <InputLabel>Run</InputLabel>
          <Select
            value={run}
            label="Run"
            onChange={handleRunChange}
            disabled={!area}
          >
            <MenuItem value="">All</MenuItem>
            {runs.map((r) => (
              <MenuItem key={r} value={r}>
                {r}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl sx={{ minWidth: 120 }}>
          <InputLabel>Zone</InputLabel>
          <Select
            value={zone}
            label="Zone"
            onChange={handleZoneChange}
            disabled={!run}
          >
            <MenuItem value="">All</MenuItem>
            {zones.map((z) => (
              <MenuItem key={z} value={z}>
                {z}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <TextField
          size="small"
          label="Search Facilities"
          variant="outlined"
          value={searchText}
          onChange={(e) => {
            const value = e.target.value;
            setSearchText(value);
            localStorage.setItem('visitsTableSearchText', value);
          }}
          sx={{ 
            width: 300,
            '& .MuiOutlinedInput-root': {
              height: '56px'
            }
          }}
        />

        <Box sx={{ marginLeft: 'auto', display: 'flex', gap: 1 }}>
          <Button
            variant="outlined"
            startIcon={<RefreshIcon />}
            onClick={handleRefresh}
            color={isOutOfSync ? 'warning' : 'primary'}
            sx={{ height: '40px', textTransform: 'none' }}
          >
            {isOutOfSync ? 'Out of Sync' : 'Refresh'}
          </Button>
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => setIsAddModalOpen(true)}
            sx={{ height: '40px', textTransform: 'none' }}
          >
            Add Visit
          </Button>
        </Box>
      </Box>

      {/* Main Calendar */}
      {loading ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <FullCalendar
          ref={calendarRef}
          plugins={[resourceTimelinePlugin, interactionPlugin]}
          schedulerLicenseKey="CC-Attribution-NonCommercial-NoDerivatives"
          height="100%"
          initialView="resourceTimelineWeek"
          timeZone="UTC"
          slotMinTime="06:00:00"
          slotMaxTime="30:00:00"
          eventDurationEditable={false}
          eventStartEditable={false}
          validRange={{
            start: new Date(new Date().setHours(0, 0, 0, 0)).toISOString(),
          }}
          headerToolbar={{
            left: 'prev next today',
            center: 'title',
            right: 'resourceTimelineThreeDay,resourceTimelineWeek,resourceTimelineMonth',
          }}
          views={{
            resourceTimelineThreeDay: {
              type: 'resourceTimeline',
              duration: { days: 3 },
              buttonText: 'Day',
              expandRows: true
            },
            resourceTimelineWeek: {
              type: 'resourceTimeline',
              duration: { days: 7 },
              buttonText: 'Week',
              expandRows: true
            },
            resourceTimelineMonth: {
              type: 'resourceTimeline',
              duration: { months: 1 },
              buttonText: 'Month',
              expandRows: true
            },
          }}
          buttonText={{
            today: 'Today',
            day: 'Day',
            week: 'Week',
            month: 'Month',
          }}
          resources={resources.filter(resource => {
            const facilityName = resource.title.toLowerCase();
            return !searchText || facilityName.includes(searchText.toLowerCase());
          })}
          events={events}
          editable
          selectable
          select={handleDateSelect}
          eventClick={handleEventClick}
          eventDrop={handleEventDrop}
          resourceAreaColumns={[
            {
              field: 'title',
              headerContent: 'Facility',
              width: 400,
            },
          ]}
          slotLabelInterval="24:00:00"
          slotMinWidth={100}
          resourceAreaWidth={400}
          resourceLabelText="Facilities"
          resourceOrder="title"
          resourceGroupField="zone"
          slotLabelFormat={{
            day: 'numeric',
            weekday: 'short',
            month: 'numeric',
            omitZeroMinute: true,
          }}
          dayHeaderFormat={{
            weekday: 'short',
            month: 'numeric',
            day: 'numeric',
            omitCommas: true,
          }}
          eventContent={(eventInfo) => {
            const isException = eventInfo.event.extendedProps.visit_type === 1;
            const onShiftUsers = eventInfo.event.extendedProps.onShiftUsers || [];
            const claimedByUser = eventInfo.event.extendedProps.claimed_by_user;
            const claimedBy = eventInfo.event.extendedProps.claimed_by;
            const workOrders = eventInfo.event.extendedProps.work_orders || [];
            const completedOrCancelledOrders = workOrders.filter(wo => wo.status === 10 || wo.status === 20).length;
            const isAllDone = workOrders.length > 0 && completedOrCancelledOrders === workOrders.length;
            
            const onShiftText = onShiftUsers.length > 0 
                ? `On Shift: ${onShiftUsers.map(user => {
                    const firstName = user.first_name?.trim() || '';
                    const lastName = user.last_name?.trim() || '';
                    return firstName && lastName ? `${firstName} ${lastName[0]}.` : user.email;
                }).join(', ')}`
                : 'On Shift: No Staff Scheduled';

            let claimText = 'Unclaimed';
            if (claimedByUser) {
                const firstName = claimedByUser.first_name?.trim() || '';
                const lastName = claimedByUser.last_name?.trim() || '';
                claimText = `Claimed by: ${firstName && lastName ? `${firstName} ${lastName[0]}.` : claimedBy}`;
            }
            
            return {
                html: `
                    <div style="
                        height: 100%; 
                        width: 100%;
                        display: flex; 
                        flex-direction: column; 
                        justify-content: center; 
                        align-items: center; 
                        background: ${isException ? theme.palette.error.main : theme.palette.primary.main}; 
                        color: ${isException ? theme.palette.error.contrastText : theme.palette.primary.contrastText}; 
                        padding: 8px;
                        border-radius: 4px;
                        font-family: ${theme.typography.fontFamily};
                        font-size: 14px;
                        box-sizing: border-box;
                        opacity: ${isAllDone ? '0.5' : '1'};
                    ">
                        <div style="font-weight: 600; margin-bottom: 4px; color: ${isException ? theme.palette.error.contrastText : theme.palette.primary.contrastText};">${isException ? 'Exception Visit' : 'Routine Visit'}</div>
                        <hr style="width: 85%; border: 0; border-top: 1px solid rgba(255, 255, 255, 0.3); margin: 4px 0;" />
                        <div style="color: ${isException ? theme.palette.error.contrastText : theme.palette.primary.contrastText}; margin-bottom: 4px;">Work Orders: ${completedOrCancelledOrders}/${workOrders.length}</div>
                        <hr style="width: 85%; border: 0; border-top: 1px solid rgba(255, 255, 255, 0.3); margin: 4px 0;" />
                        <div style="color: ${isException ? theme.palette.error.contrastText : theme.palette.primary.contrastText}; margin-bottom: 4px;">${onShiftText}</div>
                        <hr style="width: 85%; border: 0; border-top: 1px solid rgba(255, 255, 255, 0.3); margin: 4px 0;" />
                        <div style="color: ${isException ? theme.palette.error.contrastText : theme.palette.primary.contrastText}; opacity: ${claimedByUser ? '1' : '0.7'};">${claimText}</div>
              </div>
                `
            };
          }}
        />
      )}

      {/* Edit Modal */}
      <EditVisit
        open={isEditModalOpen}
        onClose={() => {
          setIsEditModalOpen(false);
          setSelectedVisit(null);
        }}
        onSuccess={handleEditSuccess}
        visit={selectedVisit}
        metadata={metadata}
        facMetadata={facMetadata}
      />

      {/* Add Modal */}
      <AddVisit
        open={isAddModalOpen}
        onClose={() => setIsAddModalOpen(false)}
        onSuccess={handleAddSuccess}
        metadata={metadata}
        facMetadata={facMetadata}
      />

      {/* MUI-like FC button styles + Sticky Headers */}
      <style>{`
        /* 1) Sticky date/time headers */
        .fc .fc-timeline-header,
        .fc .fc-datagrid-header,
        .fc .fc-resource-timeline-divider {
          position: sticky !important;
          top: 0 !important;
          z-index: 999 !important;
          background-color: ${theme.palette.background.default} !important;
        }

        /* 2) Timeline body scrolling */
        .fc .fc-view-harness {
          flex: 1 1 auto !important;
          position: relative !important;
          overflow: hidden !important; 
        }
        .fc .fc-timeline-body {
          overflow-y: auto !important;
        }

        /* 3) Make events fill their slots */
        .fc .fc-timeline-event {
          height: 100%!important;
          margin: 2px 0 !important;
          background: none !important;  /* Remove default background */
          border: none !important;      /* Remove default border */
        }
        
        .fc-timeline-event .fc-event-main {
          height: 100% !important;
          padding: 0 !important;
        }
        
        .fc-timeline-event .fc-event-main-frame {
          height: 100% !important;
        }

        /* Remove default event styling that might hide our content */
        .fc .fc-event-main-frame,
        .fc .fc-event-title-frame {
          display: block !importSant;
          height: 100% !important;
        }

        .fc .fc-event-title {
          display: block !important;
          height: 100% !important;
          white-space: normal !important;
        }

        /* 4) MUI-like button styling */
        .fc .fc-button {
          height: 40px !important;
          padding: 8px 16px !important;
          min-width: 64px !important;
          font-family: inherit !important;
          font-size: 1rem !important;
          text-transform: none !important;
          background-color: transparent !important;
          border: 1px solid rgba(255, 255, 255, 0.23) !important;
          border-radius: 4px !important;
          color: rgba(255, 255, 255, 0.7) !important;
          margin: 0 4px !important;
        }
        .fc .fc-button-group {
          gap: 6px !important; /* spacing between Day/Week/Month in the group */
        }
        .fc .fc-today-button {
          color: rgba(255, 255, 255, 0.7) !important;
          font-weight: 400 !important;
        }
        .fc .fc-today-button:disabled {
          background-color: rgba(255, 255, 255, 0.12) !important;
          border-color: rgba(255, 255, 255, 0.12) !important;
          color: rgba(255, 255, 255, 0.3) !important;
          pointer-events: none !important;
        }
        .fc .fc-prev-button,
        .fc .fc-next-button {
          color: rgba(255, 255, 255, 0.7) !important;
          font-weight: 400 !important;
        }
        .fc .fc-toolbar-title {
          font-size: 1.25rem;
          font-weight: 500;
          padding: 0 16px;
        }
      `}</style>
    </Box>
  );
};

export default VisitsTable;
