import React, { useState, useEffect } from 'react';
import { Box, Tabs, Tab, CircularProgress } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { alpha } from '@mui/material/styles';
import WorkOrdersTable from '../Component/WorkOrders/WorkOrdersTable';
import ScheduledWorkOrders from '../Component/ScheduledWorkOrders/ScheduledWorkOrdersTable';
import Visits from '../Component/Visits/VisitsTable';

const WorkManagementScreen = () => {
    const [selectedTab, setSelectedTab] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const theme = useTheme();

    useEffect(() => {
        const savedTab = localStorage.getItem('workManagementTab');
        setSelectedTab(savedTab !== null ? parseInt(savedTab) : 0);
        setIsLoading(false);
    }, []);

    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue);
        localStorage.setItem('workManagementTab', newValue.toString());
    };

    if (isLoading || selectedTab === null) {
        return (
            <Box sx={{ 
                display: 'flex', 
                justifyContent: 'center', 
                alignItems: 'center', 
                height: '100vh' 
            }}>
                <CircularProgress />
            </Box>
        );
    }

    return (
        <div>
            <h1>Work Management</h1>
            <hr className="custom-separator"/>
            
            {/* Navigation Tabs */}
            <Box 
                sx={{ 
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    mb: 3,
                    mt: 2,
                    borderBottom: 1,
                    borderColor: 'divider'
                }}
            >
                <Tabs 
                    value={selectedTab} 
                    onChange={handleTabChange}
                    textColor="primary"
                    indicatorColor="primary"
                    sx={{
                        '& .MuiTab-root': {
                            fontSize: '1rem',
                            fontWeight: 500,
                            minWidth: 200,
                            textTransform: 'none',
                            px: 4,
                            '&.Mui-selected': {
                                color: theme.palette.primary.main,
                            }
                        },
                        '& .MuiTabs-indicator': {
                            height: 3,
                            borderRadius: '3px 3px 0 0'
                        }
                    }}
                >
                    <Tab 
                        label="Work Orders" 
                        sx={{
                            '&:hover': {
                                backgroundColor: alpha(theme.palette.primary.main, 0.04),
                            }
                        }}
                    />
                    <Tab 
                        label="Scheduled Work Orders"
                        sx={{
                            '&:hover': {
                                backgroundColor: alpha(theme.palette.primary.main, 0.04),
                            }
                        }}
                    />
                    <Tab 
                        label="Visits"
                        sx={{
                            '&:hover': {
                                backgroundColor: alpha(theme.palette.primary.main, 0.04),
                            }
                        }}
                    />
                </Tabs>
            </Box>

            {/* Content */}
            <Box sx={{ mt: 2 }}>
                {selectedTab === 0 ? (
                    <WorkOrdersTable />
                ) : selectedTab === 1 ? (
                    <ScheduledWorkOrders />
                ) : (
                    <Visits />
                )}
            </Box>
        </div>
    );
};

export default WorkManagementScreen; 