import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';

function SettingsModal(props) {

    const dispatch = useDispatch();
    const [tcolor, setTcolor] = useState(false);
    const Changetheme = (color) => {
        localStorage.setItem("themeColor", color);
        var theme = document.getElementById('cryptoon-layout');
        theme.className = `theme-${color}`
        setTcolor(!tcolor)
        
        dispatch({ type: 'THEME_COLOR', payload: color });
    }
    const Isactivetheme = (val) => {
        var theme = document.getElementById('cryptoon-layout');
        if (theme) {
            if (document.getElementById('cryptoon-layout').classList.contains('theme-' + val)) {
                return 'active'
            }
            return ""
        }
    }
    const { show,onHide,onChangeDarkMode,onChangeHighcontrast,highcontrast,darkMode} = props;
    return (
        <Modal className="modal fade right " id="Settingmodal" show={show} onHide={onHide} >
            <div className="modal-content">
                <Modal.Header className="modal-header" closeButton>
                    <h5 className="modal-title">Interface Settings</h5>
                </Modal.Header>
                <Modal.Body className="modal-body custom_setting">
                    <div className="setting-theme pb-3">
                        <h6 className="card-title mb-2 fs-6 d-flex align-items-center"><i className="icofont-color-bucket fs-4 me-2 text-primary"></i>Color Settings</h6>
                        <ul className="list-unstyled row row-cols-3 g-2 choose-skin mb-2 mt-2">
                            <li data-theme="indigo" className={Isactivetheme('indigo')}><div className="indigo" onClick={() => { Changetheme('indigo') }}></div></li>
                            <li data-theme="tradewind" className={Isactivetheme('tradewind')}><div className="tradewind" onClick={() => { Changetheme('tradewind') }}></div></li>
                            <li data-theme="monalisa" className={Isactivetheme('monalisa')}><div className="monalisa" onClick={() => { Changetheme('monalisa') }}></div></li>
                            <li data-theme="blue" className={Isactivetheme('blue')}><div className="blue" onClick={() => { Changetheme('blue') }}></div></li>
                            <li data-theme="cyan" className={Isactivetheme('cyan')}><div className="cyan" onClick={() => { Changetheme('cyan') }}></div></li>
                            <li data-theme="green" className={Isactivetheme('green')}><div className="green" onClick={() => { Changetheme('green') }}></div></li>
                            <li data-theme="orange" className={Isactivetheme('orange')}><div className="orange" onClick={() => { Changetheme('orange') }}></div></li>
                            <li data-theme="blush" className={Isactivetheme('blush')}><div className="blush" onClick={() => { Changetheme('blush') }}></div></li>
                            <li data-theme="red" className={Isactivetheme('red')}><div className="red" onClick={() => { Changetheme('red') }}></div></li>
                        </ul>
                    </div>
                    <div className="setting-mode py-3">
                        <h6 className="card-title mb-2 fs-6 d-flex align-items-center"><i className="icofont-layout fs-4 me-2 text-primary"></i>Contrast Layout</h6>
                        <ul className="list-group list-unstyled mb-0 mt-1">
                            <li className="list-group-item d-flex align-items-center py-1 px-2">
                                <div className="form-check form-switch theme-switch mb-0">
                                    <input className="form-check-input" type="checkbox" id="theme-switch" checked={darkMode === 'dark'} onChange={onChangeDarkMode}/>
                                    <label className="form-check-label" htmlFor="theme-switch" >Enable Dark Mode!</label>
                                </div>
                            </li>
                            <li className="list-group-item d-flex align-items-center py-1 px-2">
                                <div className="form-check form-switch theme-high-contrast mb-0">
                                    <input className="form-check-input" type="checkbox" id="theme-high-contrast" checked={highcontrast === 'high-contrast'} onChange={onChangeHighcontrast}/>
                                    <label className="form-check-label" htmlFor="theme-high-contrast">Enable High Contrast</label>
                                </div>
                            </li>
                        </ul>
                    </div>
                </Modal.Body>
            </div>

        </Modal>
    )
}

export default SettingsModal;