import React from 'react';
import FacilityDataTable from '../Component/FacilityManagement/FacilityDataTable';

const FacilityManagementScreen = () => {
    return (
        <div>
            <h1>Asset Management</h1>
            <hr className="custom-separator"/>
            <FacilityDataTable />
        </div>
    );
};

export default FacilityManagementScreen; 