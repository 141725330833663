import React, { useState, useEffect } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField,
    Box,
    IconButton,
    Typography,
    Paper,
    Alert,
    DialogContentText
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTheme } from '@mui/material/styles';

const EditMetadataModal = ({ open, onClose, metadata, onSave }) => {
    const theme = useTheme();
    const [editedMetadata, setEditedMetadata] = useState('');
    const [error, setError] = useState('');

    useEffect(() => {
        if (metadata) {
            try {
                const formatted = JSON.stringify(metadata, null, 2);
                setEditedMetadata(formatted);
                setError('');
            } catch (e) {
                setError('Invalid metadata format');
            }
        }
    }, [metadata]);

    const handleSave = () => {
        try {
            const parsed = JSON.parse(editedMetadata);
            onSave(parsed);
            setError('');
        } catch (e) {
            setError('Invalid JSON format');
        }
    };

    const validateJson = (value) => {
        try {
            if (value) {
                JSON.parse(value);
                setError('');
            }
        } catch (e) {
            setError('Invalid JSON format');
        }
    };

    return (
        <Dialog 
            open={open} 
            onClose={onClose}
            maxWidth="md"
            fullWidth
            PaperProps={{
                sx: {
                    minHeight: '60vh',
                    maxHeight: '90vh',
                    bgcolor: theme.palette.background.paper,
                    color: theme.palette.text.primary,
                    boxShadow: 24,
                    p: 4,
                    borderRadius: 1
                }
            }}
        >
            <DialogTitle sx={{ 
                m: 0, 
                p: 2, 
                display: 'flex', 
                justifyContent: 'space-between',
                alignItems: 'center'
            }}>
                <Typography variant="h6">Edit Metadata</Typography>
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>

            <DialogContent sx={{ p: 2 }}>
                <DialogContentText sx={{ mb: 3 }}>
                    Edit the metadata below. Please ensure it's in valid JSON format.
                    The editor will validate the JSON as you type.
                </DialogContentText>

                {error && (
                    <Alert severity="error" sx={{ mb: 2 }}>
                        {error}
                    </Alert>
                )}

                <TextField
                    fullWidth
                    multiline
                    rows={20}
                    value={editedMetadata}
                    onChange={(e) => {
                        setEditedMetadata(e.target.value);
                        validateJson(e.target.value);
                    }}
                    error={!!error}
                    helperText={error || "Enter valid JSON that defines how this data should be handled"}
                    sx={{
                        fontFamily: 'monospace',
                        '& .MuiInputBase-input': {
                            fontFamily: 'monospace',
                            fontSize: '0.875rem',
                        },
                    }}
                />
            </DialogContent>

            <DialogActions sx={{ p: 2, gap: 2 }}>
                <Button 
                    onClick={() => {
                        setEditedMetadata(JSON.stringify(metadata, null, 2));
                        setError('');
                    }} 
                    color="secondary"
                >
                    Reset
                </Button>
                <Box sx={{ flex: 1 }} />
                <Button onClick={onClose} color="inherit">
                    Cancel
                </Button>
                <Button 
                    onClick={handleSave} 
                    variant="contained"
                    disabled={!!error}
                >
                    Save Changes
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default EditMetadataModal; 