import React from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    FormGroup,
    FormControlLabel,
    Checkbox,
    Button
} from '@mui/material';

const ExportDialog = ({ 
    open, 
    onClose, 
    columns, 
    selectedColumns, 
    onColumnChange, 
    onExport, 
    title = 'Export Options' 
}) => {
    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <FormGroup>
                    {columns.map(col => 
                        col.field !== 'actions' && (
                            <FormControlLabel
                                key={col.field}
                                control={
                                    <Checkbox
                                        checked={selectedColumns[col.field]}
                                        onChange={(e) => onColumnChange(col.field, e.target.checked)}
                                    />
                                }
                                label={col.headerName}
                            />
                        )
                    )}
                </FormGroup>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button onClick={onExport} variant="contained">
                    Export
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ExportDialog;