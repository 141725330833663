/**
 * Adjusts a date from the DB by adding 12 hours to handle timezone differences.
 * @param {string} dbDate - The date string from the DB
 * @returns {Date} Adjusted date with 12 hours added
 */
export const adjustDbDate = (dbDate) => {
    if (!dbDate) return null;
    return new Date(new Date(dbDate).getTime() + (12 * 60 * 60 * 1000));
};

/**
 * Formats a date into a readable string, after adjusting for timezone.
 * @param {string} dbDate - The date string from the DB
 * @param {object} options - Date formatting options
 * @returns {string} Formatted date string or 'Not set' if no date provided
 */
export const formatDbDate = (dbDate, options = { 
    year: 'numeric',
    month: 'short',
    day: 'numeric'
}) => {
    if (!dbDate) return 'Not set';
    const adjustedDate = adjustDbDate(dbDate);
    return adjustedDate.toLocaleDateString('en-US', options);
}; 