import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

export const exportToExcel = (data, visibleColumns, filename = 'export') => {
    const filteredData = getFilteredData(data, visibleColumns);
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(filteredData);
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, `${filename}.xlsx`);
};

export const exportToPDF = (data, visibleColumns, filename = 'export') => {
    const doc = new jsPDF();
    const filteredData = getFilteredData(data, visibleColumns);

    doc.autoTable({
        head: [visibleColumns.map(col => col.headerName)],
        body: filteredData.map(row => 
            visibleColumns.map(col => row[col.headerName])
        ),
        styles: { fontSize: 8 },
        headStyles: { fillColor: [71, 117, 163] },
    });

    doc.save(`${filename}.pdf`);
};

export const printData = (data, visibleColumns, title = 'Print') => {
    const printContent = createPrintContent(data, visibleColumns);
    const printWindow = window.open('', '_blank');
    
    printWindow.document.write(`
        <html>
            <head>
                <title>${title}</title>
                <style>
                    @media print {
                        table { border-collapse: collapse; width: 100%; }
                        th, td { border: 1px solid black; padding: 8px; }
                        th { background-color: #f2f2f2; }
                    }
                </style>
            </head>
            <body>
                ${printContent}
            </body>
        </html>
    `);
    
    printWindow.document.close();
    printWindow.print();
};

// Helper functions
const getFilteredData = (data, visibleColumns) => {
    return data.map(row => {
        const filteredRow = {};
        visibleColumns.forEach(col => {
            filteredRow[col.headerName] = row[col.field];
        });
        return filteredRow;
    });
};

const createPrintContent = (data, visibleColumns) => {
    const table = `
        <table>
            <thead>
                <tr>
                    ${visibleColumns
                        .map(col => `<th>${col.headerName}</th>`)
                        .join('')}
                </tr>
            </thead>
            <tbody>
                ${getFilteredData(data, visibleColumns)
                    .map(row => `
                        <tr>
                            ${visibleColumns
                                .map(col => `<td>${row[col.headerName]}</td>`)
                                .join('')}
                        </tr>
                    `)
                    .join('')}
            </tbody>
        </table>
    `;
    
    return table;
};