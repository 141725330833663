import React, { useState, useEffect } from 'react';
import { Box, Button, IconButton, TextField, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import api from '../Common/axiosSetup';
import { useToast } from '../../Contexts/ToastContext';
import { useTheme } from '@mui/material/styles';
import DataTable from '../Common/DataTable';
import AddDataRegistryEntry from './AddDataRegistryEntry';
import EditNoteIcon from '@mui/icons-material/EditNote';
import EditMetadataModal from './EditMetadataModal';
import NotesModal from './NotesModal';

const DataRegistryTable = () => {
    const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isAddModalOpen, setIsAddModalOpen] = useState(false);
    const [editingMetadata, setEditingMetadata] = useState(null);
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [selectedMetadata, setSelectedMetadata] = useState(null);
    const [isNotesModalOpen, setIsNotesModalOpen] = useState(false);
    const [selectedNotes, setSelectedNotes] = useState(null);
    const { addToast } = useToast();
    const theme = useTheme();

    const formatMetadata = (metadata) => {
        try {
            const stringified = JSON.stringify(metadata);
            return stringified.length > 600 
                ? stringified.substring(0, 600) + '...' 
                : stringified;
        } catch (e) {
            return 'Invalid metadata format';
        }
    };

    const handleSaveMetadata = async (id, newMetadata) => {
        try {
            let parsedMetadata;
            try {
                parsedMetadata = JSON.parse(newMetadata);
            } catch (e) {
                addToast('Invalid JSON format', { color: 'danger', autoHide: true, delay: 15000, requireAck: false });
                return;
            }

            const token = localStorage.getItem('token');
            await api.put(`/api/data-registry/${id}`, { metadata: parsedMetadata }, {
                headers: { Authorization: `Bearer ${token}` },
            });

            setRows(rows.map(row => 
                row.id === id ? { ...row, metadata: parsedMetadata } : row
            ));
            setEditingMetadata(null);
            addToast('Metadata updated successfully', { color: 'success', requireAck: false, autoHide: true, delay: 15000 });
        } catch (error) {
            addToast('Failed to update metadata', { color: 'danger', requireAck: true, autoHide: true, delay: 15000 });
        }
    };

    const formatNotes = (notes) => {
        if (!notes || !notes.notes_list || !notes.notes_list.length) {
            return '';
        }
        
        const latestNote = notes.notes_list[notes.notes_list.length - 1];
        return (
            <Box sx={{ 
                p: 2,
                backgroundColor: 'rgba(0, 0, 0, 0.02)',
                borderRadius: 1,
                minHeight: '60px'
            }}>
                <Typography 
                    variant="body2" 
                    sx={{ 
                        fontStyle: 'italic',
                        mb: 1,
                        color: 'text.primary'
                    }}
                >
                    {latestNote.note_text}
                </Typography>
                <Typography 
                    variant="caption" 
                    sx={{ 
                        color: 'text.secondary',
                        display: 'block'
                    }}
                >
                    by {latestNote.author} on {new Date(latestNote.timestamp).toLocaleDateString()}
                </Typography>
            </Box>
        );
    };

    const columns = [
        { 
            field: 'table_name', 
            headerName: 'Table Name', 
            headerAlign: 'center',
            align: 'center',
            flex: 0.5,
            sortable: true,
            filterable: true 
        },
        { 
            field: 'column_name', 
            headerName: 'Column Name', 
            headerAlign: 'center',
            align: 'center',
            flex: 0.5,
            sortable: true,
            filterable: true 
        },
        { 
            field: 'key_detail', 
            headerName: 'Key Detail', 
            headerAlign: 'center',
            align: 'center',
            flex: 0.5,
            sortable: true,
            filterable: true 
        },
        { 
            field: 'metadata', 
            headerName: 'Metadata', 
            headerAlign: 'center',
            align: 'left',
            flex: 4,
            minWidth: 300,
            renderCell: (params) => {
                return (
                    <Box
                        sx={{
                            width: '100%',
                            height: '100%',
                            fontSize: '0.75rem',
                            fontFamily: 'monospace',
                            position: 'relative',
                            cursor: 'pointer',
                            p: 1,
                            overflow: 'hidden',
                            whiteSpace: 'normal',
                            wordBreak: 'break-word',
                            lineHeight: '1.2',
                            display: '-webkit-box',
                            WebkitLineClamp: 5,
                            WebkitBoxOrient: 'vertical',
                            '&:hover': {
                                backgroundColor: 'rgba(0, 0, 0, 0.04)',
                            }
                        }}
                        onClick={() => {
                            setSelectedMetadata({ id: params.row.id, value: params.value });
                            setEditModalOpen(true);
                        }}
                    >
                        {formatMetadata(params.value)}
                    </Box>
                );
            }
        },
        { 
            field: 'notes', 
            headerName: 'Notes', 
            flex: 1.5,
            minWidth: 250,
            sortable: false,
            renderCell: (params) => {
                const notesList = params.value?.notes_list || [];
                
                return (
                    <Box
                        sx={{ 
                            cursor: 'pointer',
                            '&:hover': { bgcolor: 'rgba(255, 255, 255, 0.08)' },
                            width: '100%',
                            height: '100%',
                            p: 1,
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center'
                        }}
                        onClick={() => {
                            setSelectedNotes({
                                id: params.row.id,
                                notes_list: params.value?.notes_list || []
                            });
                            setIsNotesModalOpen(true);
                        }}
                    >
                        {notesList.length > 0 ? (
                            <>
                                <Typography 
                                    variant="body2" 
                                    sx={{ 
                                        mb: 0.5,
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        display: '-webkit-box',
                                        WebkitLineClamp: 2,
                                        WebkitBoxOrient: 'vertical'
                                    }}
                                >
                                    {notesList[notesList.length - 1].note_text}
                                </Typography>
                                <Box 
                                    sx={{ 
                                        display: 'flex', 
                                        justifyContent: 'space-between',
                                        alignItems: 'center'
                                    }}
                                >
                                    <Typography 
                                        variant="caption" 
                                        color="text.secondary"
                                    >
                                        {new Date(notesList[notesList.length - 1].timestamp).toLocaleDateString()} - {notesList[notesList.length - 1].author}
                                    </Typography>
                                    <Typography 
                                        variant="caption" 
                                        color="text.secondary"
                                        sx={{ ml: 1 }}
                                    >
                                        {notesList.length} note{notesList.length !== 1 ? 's' : ''}
                                    </Typography>
                                </Box>
                            </>
                        ) : (
                            <Typography color="text.secondary">
                                No notes
                            </Typography>
                        )}
                    </Box>
                );
            }
        },
    ];

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const token = localStorage.getItem('token');
                const response = await api.get('/api/data-registry', {
                    headers: { Authorization: `Bearer ${token}` },
                });
                setRows(response.data);
            } catch (error) {
                addToast('Error fetching data registry entries', {
                    color: 'danger',
                    autoHide: true,
                    delay: 15000,
                    requireAck: true
                });
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [addToast]);

    const handleAddSuccess = (newEntry) => {
        setRows(prevRows => [...prevRows, newEntry]);
        addToast('Entry added successfully!', { color: 'success', delay: 15000, autoHide: true, requireAck: false });
    };

    const handleMetadataSave = async (newMetadata) => {
        try {
            const token = localStorage.getItem('token');
            await api.put(`/api/data-registry/${selectedMetadata.id}`, 
                { metadata: newMetadata },
                { headers: { Authorization: `Bearer ${token}` } }
            );

            setRows(rows.map(row => 
                row.id === selectedMetadata.id 
                    ? { ...row, metadata: newMetadata } 
                    : row
            ));
            setEditModalOpen(false);
            setSelectedMetadata(null);
            addToast('Metadata updated successfully', { color: 'success', delay: 15000, autoHide: true, requireAck: false });
        } catch (error) {
            addToast('Failed to update metadata', { color: 'danger', delay: 15000, autoHide: false, requireAck: true });
        }
    };

    const handleAddNote = async (text) => {
        try {
            const token = localStorage.getItem('token');
            
            // Now selectedNotes.id will be defined
            const response = await api.post(
                `/api/data-registry/${selectedNotes.id}/notes`, 
                { note_text: text },
                { headers: { Authorization: `Bearer ${token}` } }
            );

            // Update the local state with the response from server
            setRows(rows.map(row => 
                row.id === selectedNotes.id ? response.data : row
            ));

            setIsNotesModalOpen(false);
            addToast('Note added successfully', { 
                color: 'success', 
                delay: 15000, 
                autoHide: true, 
                requireAck: false 
            });
        } catch (error) {
            addToast('Failed to add note', { 
                color: 'danger', 
                delay: 15000, 
                autoHide: false, 
                requireAck: true 
            });
        }
    };

    return (
        <Box sx={{ 
            height: '100%', 
            width: '100%', 
            padding: theme.spacing(2), 
            backgroundColor: theme.palette.background.default,
            borderRadius: theme.shape.borderRadius,
            boxShadow: theme.shadows[1],
            display: 'flex',
            flexDirection: 'column',
            gap: theme.spacing(2),
            minHeight: '500px'
        }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Button 
                    variant="contained"
                    onClick={() => setIsAddModalOpen(true)}
                >
                    Add Entry
                </Button>
            </Box>
            <DataTable
                rows={rows}
                columns={columns}
                loading={loading}
                tableId="data_registry"
                sortable
                filterable
                disableSelectionOnClick
                autoHeight={false}
                rowHeight={120}
                sx={{
                    '& .MuiDataGrid-row': {
                        minHeight: '120px !important',
                    },
                    '& .MuiDataGrid-cell': {
                        padding: '12px',
                    },
                    '& .MuiDataGrid-footerContainer': {
                        borderTop: `1px solid ${theme.palette.divider}`,
                        '& .MuiTablePagination-root': {
                            display: 'flex',
                            alignItems: 'center',
                            '& .MuiTablePagination-selectLabel, & .MuiTablePagination-displayedRows': {
                                margin: 0,
                                display: 'flex',
                                alignItems: 'center',
                            },
                            '& .MuiInputBase-root': {
                                marginRight: 2,
                                marginLeft: 2,
                            },
                        },
                    },
                    '& .MuiDataGrid-toolbarContainer': {
                        padding: theme.spacing(2),
                        backgroundColor: theme.palette.background.paper,
                        borderBottom: `1px solid ${theme.palette.divider}`,
                    },
                    '& .MuiDataGrid-overlay': {
                        backgroundColor: 'transparent',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: '100%'
                    }
                }}
            />
            <AddDataRegistryEntry
                open={isAddModalOpen}
                onClose={() => setIsAddModalOpen(false)}
                onSuccess={handleAddSuccess}
            />
            <EditMetadataModal
                open={editModalOpen}
                onClose={() => {
                    setEditModalOpen(false);
                    setSelectedMetadata(null);
                }}
                metadata={selectedMetadata?.value}
                onSave={handleMetadataSave}
            />
            <NotesModal
                open={isNotesModalOpen}
                onClose={() => setIsNotesModalOpen(false)}
                notes={selectedNotes}
                onAddNote={handleAddNote}
            />
        </Box>
    );
};

export default DataRegistryTable;
