import api from '../Component/Common/axiosSetup';

const STORAGE_KEY = 'visitData';
const VERSION = '1.0';

export const storeVisitData = (data) => {
    const userEmail = localStorage.getItem('userEmail');
    const storageData = {
        timestamp: Date.now(),
        version: VERSION,
        userEmail,
        data: data
    };
    localStorage.setItem(STORAGE_KEY, JSON.stringify(storageData));
};

export const getVisitData = (maxAgeHours = 24) => {
    const stored = localStorage.getItem(STORAGE_KEY);
    if (!stored) return null;

    try {
        const { timestamp, version, userEmail, data } = JSON.parse(stored);
        const currentUserEmail = localStorage.getItem('userEmail');
        const ageHours = (Date.now() - timestamp) / (1000 * 60 * 60);
        
        // Version check for future schema changes
        if (version !== VERSION) {
            localStorage.removeItem(STORAGE_KEY);
            return null;
        }

        // Validate user email matches
        if (!userEmail || userEmail !== currentUserEmail) {
            localStorage.removeItem(STORAGE_KEY);
            return null;
        }

        return {
            data,
            timestamp,
            isStale: ageHours > maxAgeHours,
            age: {
                hours: Math.floor(ageHours),
                minutes: Math.round((ageHours % 1) * 60)
            }
        };
    } catch (error) {
        console.error('Error parsing stored visit data:', error);
        localStorage.removeItem(STORAGE_KEY);
        return null;
    }
};

export const fetchAndStoreVisits = async (addToast, isOnline) => {
    try {
        const token = localStorage.getItem('token');
        
        // If we're offline, try to use cached data regardless of age
        if (!isOnline) {
            const storedData = getVisitData();
            if (storedData) {
                addToast('Using cached visit data (offline)', {
                    color: 'warning',
                    autoHide: true,
                    delay: 3000
                });
                return storedData.data;
            }
        }

        // Fetch all required data in parallel
        const [unclaimedResponse, userVisitsResponse, tomorrowVisitsResponse, metadataResponse] = await Promise.all([
            api.get('/api/visits/unclaimed', { headers: { Authorization: `Bearer ${token}` } }),
            api.get('/api/visits/user/me', { headers: { Authorization: `Bearer ${token}` } }),
            api.get('/api/visits/tomorrow', { headers: { Authorization: `Bearer ${token}` } }),
            api.get('/api/data-registry/metadata', { 
                headers: { Authorization: `Bearer ${token}` },
                params: { table_name: 'work_order_active' }
            })
        ]);

        const visitData = {
            unclaimed: unclaimedResponse.data,
            userVisits: userVisitsResponse.data,
            tomorrowVisits: tomorrowVisitsResponse.data,
            metadata: metadataResponse.data
        };

        // Store the data with timestamp
        storeVisitData(visitData);

        return visitData;
    } catch (error) {
        console.error('Error fetching visits:', error);
        
        // If we have stored data, use it as fallback
        const storedData = getVisitData();
        if (storedData) {
            addToast('Using cached visit data (fetch failed)', {
                color: 'warning',
                autoHide: true,
                delay: 3000
            });
            return storedData.data;
        }
        throw error;
    }
};

export const invalidateVisitCache = () => {
    localStorage.removeItem(STORAGE_KEY);
};

export const refreshVisitData = async (addToast, isOnline = true) => {
    invalidateVisitCache();
    return fetchAndStoreVisits(addToast, isOnline);
}; 