import React, { useState, useEffect } from 'react';
import {
    Modal,
    Paper,
    DialogContentText,
    Button,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Box,
    Typography,
    CircularProgress,
    Alert,
    FormHelperText,
    Tooltip,
    Autocomplete,
    Chip
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import api from '../Common/axiosSetup';
import { useToast } from '../../Contexts/ToastContext';
import { RestartAlt as RestartAltIcon } from '@mui/icons-material';

const formatPhoneNumber = (value) => {
    if (!value) return value;
    // Remove all non-digits
    const digits = value.replace(/\D/g, '');
    // Add + if it starts with a country code
    if (digits.length > 10) {
        return `+${digits}`;
    }
    return digits;
};

const AddUser = ({ open, onClose, onAdd }) => {
    const [loading, setLoading] = useState(false);
    const [metadata, setMetadata] = useState({});
    const [formData, setFormData] = useState({});
    const [error, setError] = useState(null);
    const theme = useTheme();
    const { addToast } = useToast();

    useEffect(() => {
        const fetchMetadata = async () => {
            try {
                const token = localStorage.getItem('token');
                const response = await api.get('/api/data-registry/metadata', {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                    params: {
                        table_name: 'users'
                    }
                });
                setMetadata(response.data);
            } catch (error) {
                console.error('Error fetching metadata:', error);
                addToast('Error fetching user metadata', {
                    autoHide: true,
                    color: 'danger',
                    requireAck: true,
                    delay: 15000
                });
            }
        };

        if (open) {
            fetchMetadata();
        }
    }, [open, addToast]);

    const handleInputChange = (field, value) => {
        setFormData(prev => ({
            ...prev,
            [field]: value
        }));
    };

    const handleChange = (field, value) => {
        setFormData(prev => ({
            ...prev,
            [field]: value
        }));
    };

    const formatFieldLabel = (field) => {
        // Convert snake_case to Title Case
        return field
            .split('_')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
            .join(' ');
    };

    const renderField = (field) => {
        const meta = metadata[field];
        if (!meta) return null;

        const { ui_settings, constraints, data_type, description } = meta;
        const value = formData[field];
        const isRequired = constraints?.required;
        const hasError = isRequired && !value && value !== false;
        const fieldLabel = ui_settings?.label || formatFieldLabel(field);

        // Helper for common props
        const commonProps = {
            fullWidth: true,
            size: "small",
            required: isRequired,
            error: hasError,
            helperText: hasError ? "This field is required" : "",
            sx: { 
                mb: 2,
                '& .MuiInputBase-root': {
                    height: '56px'
                },
                '& .MuiInputLabel-root': {
                    textTransform: 'none'  // Remove uppercase transform since we're using custom labels
                },
                '& .MuiOutlinedInput-root': {
                    '&.Mui-error .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#d32f2f'
                    }
                },
                '& .MuiFormHelperText-root.Mui-error': {
                    color: '#d32f2f'
                }
            }
        };

        // Boolean dropdown
        if (data_type === 'boolean' && ui_settings?.control_type === 'dropdown') {
            const trueValue = ui_settings.allowed_values?.true || 'Yes';
            const falseValue = ui_settings.allowed_values?.false || 'No';
            
            return (
                <Tooltip title={ui_settings.tooltip || description} placement="top">
                    <FormControl {...commonProps}>
                        <InputLabel>{fieldLabel}</InputLabel>
                        <Select
                            value={value === undefined || value === null ? '' : (value ? trueValue : falseValue)}
                            onChange={(e) => {
                                if (e.target.value === '') {
                                    handleChange(field, null);
                                } else {
                                    handleChange(field, e.target.value === trueValue);
                                }
                            }}
                            label={fieldLabel}
                        >
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            <MenuItem value={trueValue}>{trueValue}</MenuItem>
                            <MenuItem value={falseValue}>{falseValue}</MenuItem>
                        </Select>
                        {hasError && (
                            <FormHelperText>{commonProps.helperText}</FormHelperText>
                        )}
                    </FormControl>
                </Tooltip>
            );
        }

        // Multi-select array
        if (data_type === 'array' && ui_settings?.control_type === 'multi_select') {
            return (
                <Tooltip title={ui_settings.tooltip || description} placement="top">
                    <FormControl {...commonProps}>
                        <Autocomplete
                            multiple
                            value={value || []}
                            onChange={(_, newValue) => handleChange(field, newValue)}
                            options={constraints.allowed_values || []}
                            renderTags={(tagValue, getTagProps) =>
                                tagValue.map((option, index) => (
                                    <Chip
                                        label={option}
                                        {...getTagProps({ index })}
                                        size="small"
                                    />
                                ))
                            }
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={fieldLabel}
                                    error={hasError}
                                    helperText={commonProps.helperText}
                                />
                            )}
                        />
                    </FormControl>
                </Tooltip>
            );
        }

        // Phone number with validation
        if (data_type === 'string' && constraints?.format?.includes('\\d{10}')) {
            return (
                <Tooltip title={ui_settings.tooltip || description} placement="top">
                    <TextField
                        {...commonProps}
                        label={fieldLabel}
                        value={value || ''}
                        onChange={(e) => {
                            const formattedValue = formatPhoneNumber(e.target.value);
                            handleChange(field, formattedValue);
                        }}
                        error={hasError || (value && !new RegExp(constraints.format).test(value))}
                        helperText={
                            hasError ? commonProps.helperText :
                            (value && !new RegExp(constraints.format).test(value)) ?
                            "Invalid phone number format" : ""
                        }
                        placeholder="+1234567890"
                        inputProps={{
                            maxLength: constraints.max_length || 15
                        }}
                    />
                </Tooltip>
            );
        }

        // Default text field
        return (
            <TextField
                {...commonProps}
                label={fieldLabel}
                value={value || ''}
                onChange={(e) => handleInputChange(field, e.target.value)}
            />
        );
    };

    const handleSubmit = async () => {
        try {
            setLoading(true);
            setError(null);

            const token = localStorage.getItem('token');
            const response = await api.post('/api/user', formData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            });

            onAdd(response.data);
            onClose();
            addToast('User added successfully', {
                color: 'success',
                autoHide: true,
                delay: 15000,
                requireAck: false
            });
        } catch (error) {
            const errorMessage = error.response?.data?.message;
            const errorDetails = error.response?.data?.errors?.join('\n• ');
            const fullError = errorDetails 
                ? `${errorMessage}:\n• ${errorDetails}` 
                : (errorMessage || 'Error adding user');
            
            addToast(fullError, {
                color: 'danger',
                autoHide: false,
                delay: 15000,
                requireAck: true
            });
        } finally {
            setLoading(false);
        }
    };

    const handleClearForm = () => {
        setFormData({});
    };

    const fields = Object.keys(metadata)
        .filter(key => key !== 'id')
        .sort((a, b) => {
            const orderA = metadata[a]?.display_order ?? 999;
            const orderB = metadata[b]?.display_order ?? 999;
            return orderA - orderB;
        });

    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '800px',
        height: '90vh',
        bgcolor: 'background.paper',
        boxShadow: 24,
        borderRadius: 1,
        display: 'flex',
        flexDirection: 'column'
    };

    return (
        <Modal open={open} onClose={onClose}>
            <Box sx={modalStyle}>
                {/* Fixed Header */}
                <Box sx={{ 
                    p: 3, 
                    borderBottom: 1, 
                    borderColor: 'divider',
                    bgcolor: 'background.paper',
                    flexShrink: 0
                }}>
                    <Typography variant="h5" component="h2" gutterBottom>
                        ADD NEW USER
                    </Typography>
                    <Typography variant="body1" color="text.secondary">
                        Please fill in the user details below. All fields marked with * are required.
                    </Typography>
                </Box>

                {/* Scrollable Content */}
                <Box sx={{ 
                    flex: 1,
                    overflowY: 'auto',
                    p: 3
                }}>
                    {error && (
                        <Alert 
                            severity="error" 
                            sx={{ 
                                mb: 2,
                                '& .MuiAlert-message': {
                                    whiteSpace: 'pre-line'
                                }
                            }}
                        >
                            {error}
                        </Alert>
                    )}

                    {loading ? (
                        <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
                            <CircularProgress />
                        </Box>
                    ) : (
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
                            {fields.map(field => renderField(field))}
                        </Box>
                    )}
                </Box>

                {/* Fixed Footer */}
                <Box sx={{ 
                    p: 3, 
                    borderTop: 1, 
                    borderColor: 'divider',
                    bgcolor: 'background.paper',
                    display: 'flex', 
                    justifyContent: 'flex-end', 
                    gap: 2,
                    mt: 'auto',
                    flexShrink: 0
                }}>
                    <Button 
                        onClick={handleClearForm} 
                        color="inherit"
                        startIcon={<RestartAltIcon />}
                    >
                        Clear Form
                    </Button>
                    <Button onClick={onClose} color="inherit">
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        onClick={handleSubmit}
                        disabled={loading}
                    >
                        Add User
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default AddUser; 