import React from 'react';

const EquipmentManagementScreen = () => {
    return (
        <div>
            <h1>Inventory Management</h1>
            <hr className="custom-separator"/>
        </div>
    );
};

export default EquipmentManagementScreen; 