import api from '../Component/Common/axiosSetup';

const STORAGE_KEY = 'facilityData';
const VERSION = '1.0';

export const storeFacilityData = (data) => {
    const userEmail = localStorage.getItem('userEmail');
    const storageData = {
        timestamp: Date.now(),
        version: VERSION,
        userEmail,
        data: data
    };
    localStorage.setItem(STORAGE_KEY, JSON.stringify(storageData));
};

export const getFacilityData = (maxAgeHours = 24) => {
    const stored = localStorage.getItem(STORAGE_KEY);
    if (!stored) return null;

    try {
        const { timestamp, version, userEmail, data } = JSON.parse(stored);
        const currentUserEmail = localStorage.getItem('userEmail');
        const ageHours = (Date.now() - timestamp) / (1000 * 60 * 60);
        
        // Version check for future schema changes
        if (version !== VERSION) {
            localStorage.removeItem(STORAGE_KEY);
            return null;
        }

        // Validate user email matches
        if (!userEmail || userEmail !== currentUserEmail) {
            localStorage.removeItem(STORAGE_KEY);
            return null;
        }

        return {
            data,
            timestamp,
            isStale: ageHours > maxAgeHours,
            age: {
                hours: Math.floor(ageHours),
                minutes: Math.round((ageHours % 1) * 60)
            }
        };
    } catch (error) {
        console.error('Error parsing stored facility data:', error);
        localStorage.removeItem(STORAGE_KEY);
        return null;
    }
};

export const fetchAndStoreFacilities = async (addToast, isOnline = true) => {
    try {
        // If offline, try to use stored data regardless of age
        if (!isOnline) {
            const stored = getFacilityData();
            if (stored) {
                const { hours, minutes } = stored.age;
                const ageText = hours > 0 ? `${hours} hours` : `${minutes} minutes`;
                
                addToast?.(`Using cached data from ${ageText} ago - No internet connection`, {
                    color: 'warning',
                    autoHide: true,
                    delay: 5000
                });
                return stored.data;
            }
        }

        const token = localStorage.getItem('token');
        
        // Fetch both facilities and metadata in parallel
        const [facilitiesResponse, metadataResponse] = await Promise.all([
            api.get('/api/facility/search/filtered', {
                headers: { Authorization: `Bearer ${token}` }
            }),
            api.get('/api/data-registry/metadata', {
                headers: { Authorization: `Bearer ${token}` },
                params: { table_name: 'facility' }
            })
        ]);

        // Process metadata
        const areas = new Set();
        const runs = new Set();
        const zones = new Set();

        // Process area values
        if (metadataResponse.data?.data__area?.constraints?.allowed_values) {
            const areaValues = metadataResponse.data.data__area.constraints.allowed_values;
            if (Array.isArray(areaValues)) {
                areaValues.forEach(area => areas.add(area));
            } else {
                Object.keys(areaValues).forEach(area => areas.add(area));
            }
        }

        // Process run values
        if (metadataResponse.data?.data__run?.constraints?.allowed_values) {
            const runValues = metadataResponse.data.data__run.constraints.allowed_values;
            Object.keys(runValues).forEach(area => {
                if (Array.isArray(runValues[area])) {
                    runValues[area].forEach(run => runs.add(run));
                }
            });
        }

        // Process zone values
        if (metadataResponse.data?.data__zone?.constraints?.allowed_values) {
            const zoneValues = metadataResponse.data.data__zone.constraints.allowed_values;
            Object.keys(zoneValues).forEach(run => {
                if (Array.isArray(zoneValues[run])) {
                    zoneValues[run].forEach(zone => zones.add(zone));
                }
            });
        }

        const processedMetadata = {
            areas: Array.from(areas).sort(),
            runs: Array.from(runs).sort(),
            zones: Array.from(zones).sort(),
            runsByArea: metadataResponse.data?.data__run?.constraints?.allowed_values || {},
            zonesByRun: metadataResponse.data?.data__zone?.constraints?.allowed_values || {}
        };

        const facilityData = {
            facilities: facilitiesResponse.data,
            metadata: processedMetadata
        };

        storeFacilityData(facilityData);
        return facilityData;
    } catch (error) {
        console.error('Error fetching facilities:', error);
        
        // Try to use stored data as fallback
        const stored = getFacilityData();
        if (stored) {
            const { hours, minutes } = stored.age;
            const ageText = hours > 0 ? `${hours} hours` : `${minutes} minutes`;
            
            addToast?.(`Using cached data from ${ageText} ago - Failed to fetch fresh data`, {
                color: 'warning',
                autoHide: true,
                delay: 5000
            });
            return stored.data;
        }
        
        throw error;
    }
};

export const invalidateFacilityCache = () => {
    localStorage.removeItem(STORAGE_KEY);
};

export const refreshFacilityData = async (addToast, isOnline = true) => {
    invalidateFacilityCache();
    return fetchAndStoreFacilities(addToast, isOnline);
}; 