import React, { useState, useEffect, useCallback, useMemo } from 'react';
import {
    Box,
    Button,
    Modal,
    TextField,
    Typography,
    Alert,
    MenuItem,
    Paper,
    DialogActions,
    DialogContent,
    DialogContentText,
    Dialog,
    FormControl,
    InputLabel,
    Select,
    Chip,
    List,
    ListItem,
    ListItemText,
    Divider,
    Tooltip,
    Checkbox,
    RadioGroup,
    FormControlLabel,
    Radio,
    FormLabel
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import api from '../Common/axiosSetup';
import useGlobalFilters from '../../Hooks/useGlobalFilters';
import { useToast } from '../../Contexts/ToastContext';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { getUserTimeZone, toServerMidnightUTC, utcToServerMidnight, SERVER_TIMEZONE } from '../../Utility/Timezone';

const AddWorkOrder = ({ 
    open, 
    onClose, 
    onSuccess, 
    metadata,
    facMetadata 
}) => {
    const theme = useTheme();
    const { addToast } = useToast();
    const [formData, setFormData] = useState({
        facility_ids: [],
        type: '',
        status: 0,
        priority: '',
        start_timestamp: new Date(),
        due_timestamp: new Date(),
        notes: '',
        visit_type: 'new'
    });
    const [error, setError] = useState('');
    const [facilities, setFacilities] = useState([]);
    const [confirmOpen, setConfirmOpen] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [selectedFacilitiesMap, setSelectedFacilitiesMap] = useState(new Map());
    const [facilityTypeFilter, setFacilityTypeFilter] = useState('');
    const [searchText, setSearchText] = useState('');

    // Use global filters
    const { filters, updateArea, updateRun, updateZone } = useGlobalFilters();
    const { area, run, zone } = filters;

    // Get areas from facility metadata constraints
    const areas = facMetadata?.data__area?.constraints?.allowed_values || [];
    const runs = area ? (facMetadata?.data__run?.constraints?.allowed_values?.[area] || []) : [];
    const zones = run && facMetadata?.data__zone?.constraints?.allowed_values
        ? (facMetadata.data__zone.constraints.allowed_values[run] || [])
        : [];

    // Get unique facility types from the facilities list
    const facilityTypes = useMemo(() => {
        const types = new Set(facilities.map(f => f.type).filter(Boolean));
        return Array.from(types).sort();
    }, [facilities]);

    // Filter facilities based on type and search text
    const filteredFacilities = useMemo(() => {
        return facilities.filter(facility => {
            const facilityName = facility.facility_name?.toLowerCase() || '';
            const matchesSearch = !searchText || facilityName.includes(searchText.toLowerCase());
            const matchesType = !facilityTypeFilter || facility.type === facilityTypeFilter;
            return matchesSearch && matchesType;
        });
    }, [facilities, searchText, facilityTypeFilter]);

    // Fetch facilities when filters change or modal opens
    useEffect(() => {
        if (open) {
            fetchFacilities();
        }
    }, [area, run, zone, open]);

    const fetchFacilities = async () => {
        try {
            const token = localStorage.getItem('token');
            const params = new URLSearchParams();
            if (area) params.append('area', area);
            if (run) params.append('run', run);
            if (zone) params.append('zone', zone);

            const response = await api.get('/api/facility/search/filtered', {
                headers: { Authorization: `Bearer ${token}` },
                params
            });

            setFacilities(response.data);
        } catch (error) {
            setError('Failed to fetch facilities');
        }
    };

    const handleAreaChange = (event) => {
        updateArea(event.target.value);
    };

    const handleRunChange = (event) => {
        updateRun(event.target.value);
    };

    const handleZoneChange = (event) => {
        updateZone(event.target.value);
    };

    const handleFacilityChange = (event) => {
        const selectedIds = event.target.value;
        setFormData(prev => ({ ...prev, facility_ids: selectedIds }));
        
        // Update selected facilities map
        const newSelectedFacilities = new Map();
        selectedIds.forEach(id => {
            // If facility is already in our map, keep it
            if (selectedFacilitiesMap.has(id)) {
                newSelectedFacilities.set(id, selectedFacilitiesMap.get(id));
            } else {
                // If it's a new selection, find it in the current facilities list
                const facility = facilities.find(f => f.id === id);
                if (facility) {
                    // Store complete facility data
                    newSelectedFacilities.set(id, { ...facility });
                }
            }
        });
        setSelectedFacilitiesMap(newSelectedFacilities);
    };

    // Get user's timezone once when component mounts
    const userTZ = useMemo(() => getUserTimeZone(), []);

    const handleSubmit = async () => {
        if (isSubmitting) return;
        
        try {
            setIsSubmitting(true);
            setConfirmOpen(false);
            
            // Convert dates to server midnight UTC using our utility
            const startUTC = toServerMidnightUTC(formData.start_timestamp, userTZ, SERVER_TIMEZONE);
            const dueUTC = toServerMidnightUTC(formData.due_timestamp, userTZ, SERVER_TIMEZONE);
            
            const workOrderData = {
                ...formData,
                start_timestamp: startUTC.toISOString(),
                due_timestamp: dueUTC.toISOString()
            };

            const token = localStorage.getItem('token');
            
            // Create the work order first
            const response = await api.post('/api/work-orders', workOrderData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            });

            // Add visit handling logic
            if (workOrderData.visit_type === 'new') {
                try {
                    // Convert today to server midnight UTC
                    const todayUTC = toServerMidnightUTC(new Date(), userTZ, SERVER_TIMEZONE);
                    
                    // Group work orders by facility_id
                    const workOrdersByFacility = response.data.reduce((acc, wo) => {
                        if (!acc[wo.facility_id]) {
                            acc[wo.facility_id] = [];
                        }
                        acc[wo.facility_id].push(wo);
                        return acc;
                    }, {});

                    // Create visits for each facility
                    const visitResults = {
                        successful: 0,
                        failed: 0,
                        errors: []
                    };

                    for (const [facility_id, facilityWorkOrders] of Object.entries(workOrdersByFacility)) {
                        try {
                            await api.post('/api/visits', {
                                facility_id,
                                visit_date: todayUTC.toISOString(),
                                work_orders: facilityWorkOrders
                            }, {
                                headers: { Authorization: `Bearer ${token}` }
                            });
                            visitResults.successful++;
                        } catch (error) {
                            visitResults.failed++;
                            const facilityName = facilityWorkOrders[0].facility_name;
                            const errorMessage = error.response?.data?.message;
                            const errorDetails = error.response?.data?.errors?.join('\n• ');
                            visitResults.errors.push(`${facilityName}: ${errorDetails || errorMessage || 'Unknown error'}`);
                        }
                    }

                    // Show summary toast
                    if (visitResults.failed === 0) {
                        addToast(`Successfully created ${visitResults.successful} visit${visitResults.successful > 1 ? 's' : ''}`, {
                            color: 'success',
                            autoHide: true,
                            delay: 15000
                        });
                    } else {
                        const errorMessage = [
                            `Created ${response.data.length} work order${response.data.length > 1 ? 's' : ''}`,
                            `Successfully created ${visitResults.successful} visit${visitResults.successful > 1 ? 's' : ''}`,
                            `Failed to create ${visitResults.failed} visit${visitResults.failed > 1 ? 's' : ''}`,
                            '',
                            'Failed visits:',
                            visitResults.errors.map(err => `• ${err}`).join('\n')
                        ].join('\n');

                        addToast(errorMessage, {
                            color: 'warning',
                            autoHide: false,
                            delay: 15000,
                            requireAck: true
                        });

                        if (visitResults.successful === 0) {
                            throw new Error('Failed to create any visits');
                        }
                    }
                } catch (error) {
                    if (!error.message?.includes('Failed to create any visits')) {
                        const errorMessage = error.response?.data?.message;
                        const errorDetails = error.response?.data?.errors?.join('\n• ');
                        const fullError = errorDetails 
                            ? `${errorMessage}:\n• ${errorDetails}` 
                            : (errorMessage || 'Error creating visits');
                        
                        addToast(fullError, {
                            color: 'danger',
                            autoHide: false,
                            delay: 15000,
                            requireAck: true
                        });
                    }
                    throw error; // Re-throw to prevent success handling
                }
            }
            // For 'next', we'll let the backend handle it

            onSuccess(response.data);
            setError('');
            onClose();
            clearForm();
        } catch (error) {
            const errorMessage = error.response?.data?.message;
            const errorDetails = error.response?.data?.errors?.join('\n• ');
            const fullError = errorDetails 
                ? `${errorMessage}:\n• ${errorDetails}` 
                : (errorMessage || 'Error creating work order');
            
            setError(fullError);
        } finally {
            setIsSubmitting(false);
        }
    };

    const clearForm = () => {
        setFormData({
            facility_ids: [],
            type: '',
            status: 0,
            priority: '',
            start_timestamp: new Date(),
            due_timestamp: new Date(),
            notes: '',
            visit_type: 'new'
        });
        setSelectedFacilitiesMap(new Map());
        setFacilityTypeFilter('');
        setSearchText('');
        setError('');
    };

    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '800px',
        height: '90vh',
        bgcolor: 'background.paper',
        boxShadow: 24,
        borderRadius: 1,
        display: 'flex',
        flexDirection: 'column'
    };

    // Convert selected facilities map to array for easier rendering
    const selectedFacilities = useMemo(() => 
        Array.from(selectedFacilitiesMap.values()), 
        [selectedFacilitiesMap]
    );

    // Group selected facilities by type for summary
    const facilityTypeGroups = useMemo(() => {
        return selectedFacilities.reduce((acc, facility) => {
            const type = facility.type || 'Unknown';
            acc[type] = (acc[type] || 0) + 1;
            return acc;
        }, {});
    }, [selectedFacilities]);

    // Helper function to check if a field is required
    const isFieldRequired = (fieldName) => {
        return metadata?.[fieldName]?.constraints?.required === true;
    };

    // Helper function to get tooltip text from metadata
    const getTooltip = (fieldName) => {
        return metadata?.[fieldName]?.ui_settings?.tooltip || metadata?.[fieldName]?.description || '';
    };

    const getFieldLabel = (fieldName) => {
        return metadata?.[fieldName]?.ui_settings?.label || fieldName;
    };

    const handleSelectAllFiltered = () => {
        const allFilteredIds = filteredFacilities.map(f => f.id);
        const newSelectedIds = new Set([...formData.facility_ids]);
        
        // Check if all filtered facilities are already selected
        const allSelected = allFilteredIds.every(id => newSelectedIds.has(id));
        
        if (allSelected) {
            // Deselect all filtered facilities
            allFilteredIds.forEach(id => newSelectedIds.delete(id));
        } else {
            // Select all filtered facilities
            allFilteredIds.forEach(id => newSelectedIds.add(id));
        }
        
        // Update form data with new selection
        const newIds = Array.from(newSelectedIds);
        setFormData(prev => ({
            ...prev,
            facility_ids: newIds
        }));
        
        // Update facilities map
        const newMap = new Map(selectedFacilitiesMap);
        if (!allSelected) {
            filteredFacilities.forEach(facility => {
                newMap.set(facility.id, facility);
            });
        } else {
            allFilteredIds.forEach(id => {
                newMap.delete(id);
            });
        }
        setSelectedFacilitiesMap(newMap);
    };

    const formatDate = (dateString) => {
        if (!dateString) return null;
        return utcToServerMidnight(dateString, SERVER_TIMEZONE);
    };

    return (
        <>
            <Modal open={open} onClose={onClose}>
                <Box sx={modalStyle}>
                    {/* Fixed Header */}
                    <Box sx={{ 
                        p: 3, 
                        borderBottom: 1, 
                        borderColor: 'divider',
                        bgcolor: 'background.paper',
                        flexShrink: 0
                    }}>
                        <Typography variant="h5" component="h2" gutterBottom>
                            CREATE WORK ORDERS
                        </Typography>
                        <Typography variant="body1" color="text.secondary" sx={{ mt: 1 }}>
                            Create one or multiple work orders by selecting facilities and defining work order details.
                        </Typography>
                        <Typography variant="body2" color="warning.main" sx={{ mt: 1 }}>
                            Note: If your facility isn't found in the list, ensure it has an assigned zone. 
                            Work orders can only be attached to facilities with a zone assigned.
                        </Typography>
                    </Box>

                    {/* Scrollable Content */}
                    <Box sx={{ 
                        flex: 1,
                        overflowY: 'auto',
                        p: 3
                    }}>
                        {error && (
                            <Alert 
                                severity="error" 
                                sx={{ 
                                    mb: 2,
                                    '& .MuiAlert-message': {
                                        whiteSpace: 'pre-line'
                                    }
                                }}
                            >
                                {error}
                            </Alert>
                        )}

                        {/* Rest of the form content */}
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
                            {/* Facility Selection Section */}
                            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                                <Typography variant="subtitle1" sx={{ fontWeight: 'medium' }}>
                                    Facility Selection
                                </Typography>
                                <Box sx={{ display: 'flex', gap: 2 }}>
                                    <FormControl sx={{ flex: 1 }}>
                                        <InputLabel>Area</InputLabel>
                                        <Select
                                            value={area}
                                            label="Area"
                                            onChange={handleAreaChange}
                                        >
                                            <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem>
                                            {areas.map((area) => (
                                                <MenuItem key={area} value={area}>
                                                    {area}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>

                                    <FormControl sx={{ flex: 1 }}>
                                        <InputLabel>Run</InputLabel>
                                        <Select
                                            value={run}
                                            label="Run"
                                            onChange={handleRunChange}
                                            disabled={!area}
                                        >
                                            <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem>
                                            {runs.map((run) => (
                                                <MenuItem key={run} value={run}>
                                                    {run}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>

                                    <FormControl sx={{ flex: 1 }}>
                                        <InputLabel>Zone</InputLabel>
                                        <Select
                                            value={zone}
                                            label="Zone"
                                            onChange={handleZoneChange}
                                            disabled={!run}
                                        >
                                            <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem>
                                            {zones.map((zone) => (
                                                <MenuItem key={zone} value={zone}>
                                                    {zone}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Box>

                                <Box sx={{ display: 'flex', gap: 2 }}>
                                    <FormControl sx={{ flex: 1 }}>
                                        <InputLabel>Facility Type</InputLabel>
                                        <Select
                                            value={facilityTypeFilter}
                                            label="Facility Type"
                                            onChange={(e) => setFacilityTypeFilter(e.target.value)}
                                        >
                                            <MenuItem value="">
                                                <em>All Types</em>
                                            </MenuItem>
                                            {facilityTypes.map((type) => (
                                                <MenuItem key={type} value={type}>
                                                    {type}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>

                                    <TextField
                                        sx={{ flex: 2 }}
                                        label="Search Facilities"
                                        value={searchText}
                                        onChange={(e) => setSearchText(e.target.value)}
                                        placeholder="Type to search facilities..."
                                        InputProps={{
                                            type: 'search',
                                        }}
                                    />
                                </Box>

                                <FormControl fullWidth required>
                                    <Tooltip title="Select one or more facilities to create work orders for">
                                        <InputLabel>Select Facilities</InputLabel>
                                    </Tooltip>
                                    <Select
                                        multiple
                                        value={formData.facility_ids}
                                        onChange={(e) => e.preventDefault()}
                                        label="Select Facilities"
                                        error={formData.facility_ids.length === 0}
                                        MenuProps={{
                                            PaperProps: {
                                                style: {
                                                    maxHeight: 400,
                                                    width: 'auto',
                                                    maxWidth: '100%'
                                                }
                                            },
                                            // Keep dropdown open after selection
                                            autoFocus: true,
                                            disableAutoFocusItem: true,
                                            anchorOrigin: {
                                                vertical: 'bottom',
                                                horizontal: 'left'
                                            },
                                            transformOrigin: {
                                                vertical: 'top',
                                                horizontal: 'left'
                                            }
                                        }}
                                        renderValue={(selected) => (
                                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                {selected.map((value) => {
                                                    const facility = selectedFacilitiesMap.get(value);
                                                    return (
                                                        <Chip 
                                                            key={value} 
                                                            label={facility?.facility_name || 'Unnamed Facility'}
                                                            size="small"
                                                            onDelete={() => {
                                                                const newIds = formData.facility_ids.filter(id => id !== value);
                                                                setFormData(prev => ({
                                                                    ...prev,
                                                                    facility_ids: newIds
                                                                }));
                                                                // Update the facilities map
                                                                const newMap = new Map(selectedFacilitiesMap);
                                                                newMap.delete(value);
                                                                setSelectedFacilitiesMap(newMap);
                                                            }}
                                                            onClick={(e) => e.stopPropagation()}
                                                            onMouseDown={(e) => e.stopPropagation()}
                                                        />
                                                    );
                                                })}
                                            </Box>
                                        )}
                                    >
                                        {/* Add "Select All Filtered" option */}
                                        {filteredFacilities.length > 0 && (
                                            <MenuItem
                                                dense
                                                sx={{
                                                    borderBottom: '1px solid',
                                                    borderColor: 'divider',
                                                    fontWeight: 'bold'
                                                }}
                                                onClick={(event) => {
                                                    event.preventDefault();
                                                    event.stopPropagation();
                                                    handleSelectAllFiltered();
                                                }}
                                            >
                                                <Checkbox
                                                    checked={
                                                        filteredFacilities.length > 0 &&
                                                        filteredFacilities.every(facility => 
                                                            formData.facility_ids.includes(facility.id)
                                                        )
                                                    }
                                                    indeterminate={
                                                        filteredFacilities.some(facility => 
                                                            formData.facility_ids.includes(facility.id)
                                                        ) &&
                                                        !filteredFacilities.every(facility => 
                                                            formData.facility_ids.includes(facility.id)
                                                        )
                                                    }
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        e.stopPropagation();
                                                        handleSelectAllFiltered();
                                                    }}
                                                />
                                                <Box sx={{ ml: 1 }}>
                                                    {`Select All Filtered (${filteredFacilities.length})`}
                                                </Box>
                                            </MenuItem>
                                        )}
                                        
                                        {filteredFacilities.map((facility) => {
                                            const isSelected = formData.facility_ids.includes(facility.id);
                                            const handleToggle = () => {
                                                const newIds = isSelected
                                                    ? formData.facility_ids.filter(id => id !== facility.id)
                                                    : [...formData.facility_ids, facility.id];
                                                
                                                setFormData(prev => ({
                                                    ...prev,
                                                    facility_ids: newIds
                                                }));
                                                
                                                // Update facilities map
                                                const newMap = new Map(selectedFacilitiesMap);
                                                if (!isSelected) {
                                                    newMap.set(facility.id, facility);
                                                } else {
                                                    newMap.delete(facility.id);
                                                }
                                                setSelectedFacilitiesMap(newMap);
                                            };

                                            return (
                                                <MenuItem 
                                                    key={facility.id} 
                                                    value={facility.id}
                                                    sx={{ 
                                                        whiteSpace: 'normal',
                                                        wordBreak: 'break-word'
                                                    }}
                                                    onClick={(event) => {
                                                        event.preventDefault();
                                                        handleToggle();
                                                    }}
                                                >
                                                    <Checkbox 
                                                        checked={isSelected}
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            e.stopPropagation();
                                                            handleToggle();
                                                        }}
                                                    />
                                                    <Box sx={{ ml: 1 }}>
                                                        <Typography variant="body1">
                                                            {facility.facility_name || `Unknown Facility - ${facility.id}`}
                                                        </Typography>
                                                        <Typography variant="caption" color="text.secondary">
                                                            {facility.type || 'Unknown Type'} | {facility.zone || 'No Zone'}
                                                        </Typography>
                                                    </Box>
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                </FormControl>

                                {selectedFacilities.length > 0 && (
                                    <Box sx={{ mt: 2, border: 1, borderColor: 'divider', borderRadius: 1, p: 2 }}>
                                        <Typography variant="subtitle2" gutterBottom>
                                            Selected Facilities Summary
                                        </Typography>
                                        <Typography variant="body2" color="text.secondary" gutterBottom>
                                            Work orders will be created for {selectedFacilities.length} facilities
                                        </Typography>
                                        
                                        {/* Type summary */}
                                        <Box sx={{ mb: 2 }}>
                                            <Typography variant="body2" color="text.secondary">
                                                Facility Types:
                                            </Typography>
                                            {Object.entries(facilityTypeGroups).map(([type, count]) => (
                                                <Typography key={type} variant="body2" sx={{ ml: 2 }}>
                                                    • {type}: {count}
                                                </Typography>
                                            ))}
                                        </Box>

                                        {/* Scrollable facility list */}
                                        <Box sx={{ maxHeight: '150px', overflow: 'auto', bgcolor: 'background.default', borderRadius: 1 }}>
                                            <List dense>
                                                {selectedFacilities.map((facility, index) => (
                                                    <React.Fragment key={facility.id}>
                                                        <ListItem>
                                                            <ListItemText
                                                                primary={`${facility.facility_name || 'Unnamed Facility'} - ${facility.type || 'Unknown Type'} - ${facility.zone || 'No Zone'}${facility.next_visit_date ? ` (Next Visit: ${formatDate(facility.next_visit_date).toLocaleDateString()})` : ''}`}
                                                            />
                                                        </ListItem>
                                                        {index < selectedFacilities.length - 1 && <Divider />}
                                                    </React.Fragment>
                                                ))}
                                            </List>
                                        </Box>
                                    </Box>
                                )}
                            </Box>

                            {/* Work Order Details Section */}
                            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                                <Typography variant="subtitle1" sx={{ fontWeight: 'medium' }}>
                                    Work Order Details
                                </Typography>
                                <Box sx={{ display: 'flex', gap: 2 }}>
                                    <FormControl sx={{ flex: 1 }} required={isFieldRequired('type')}>
                                        <Tooltip title={getTooltip('type')}>
                                            <InputLabel error={!formData.type && isFieldRequired('type')}>
                                                {getFieldLabel('type')}
                                            </InputLabel>
                                        </Tooltip>
                                        <Select
                                            value={formData.type}
                                            label={getFieldLabel('type')}
                                            onChange={(e) => setFormData(prev => ({ ...prev, type: e.target.value }))}
                                            error={!formData.type && isFieldRequired('type')}
                                        >
                                            {metadata?.type?.constraints?.allowed_values?.map(type => (
                                                <MenuItem key={type} value={type}>{type}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>

                                    <FormControl sx={{ flex: 1 }} required={isFieldRequired('priority')}>
                                        <Tooltip title={getTooltip('priority')}>
                                            <InputLabel error={!formData.priority && isFieldRequired('priority')}>
                                                {getFieldLabel('priority')}
                                            </InputLabel>
                                        </Tooltip>
                                        <Select
                                            value={formData.priority}
                                            label={getFieldLabel('priority')}
                                            onChange={(e) => setFormData(prev => ({ ...prev, priority: Number(e.target.value) }))}
                                            error={!formData.priority && isFieldRequired('priority')}
                                        >
                                            {Object.entries(metadata?.priority?.constraints?.allowed_values || {}).map(([value, label]) => (
                                                <MenuItem key={value} value={Number(value)}>{label}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Box>

                                <Box sx={{ display: 'flex', gap: 2 }}>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <Tooltip title={getTooltip('start_timestamp')}>
                                            <DatePicker
                                                label={
                                                    <Box component="span" sx={{ display: 'flex', alignItems: 'center' }}>
                                                        {getFieldLabel('start_timestamp')}
                                                        {isFieldRequired('start_timestamp') && (
                                                            <Box component="span" sx={{ color: 'error.main', ml: 0.5 }}>*</Box>
                                                        )}
                                                    </Box>
                                                }
                                                value={formData.start_timestamp}
                                                onChange={(date) => setFormData(prev => ({ ...prev, start_timestamp: date }))}
                                                renderInput={(params) => (
                                                    <TextField 
                                                        {...params} 
                                                        sx={{ flex: 1 }}
                                                        required={isFieldRequired('start_timestamp')}
                                                        error={!formData.start_timestamp && isFieldRequired('start_timestamp')}
                                                    />
                                                )}
                                                sx={{ flex: 1 }}
                                            />
                                        </Tooltip>
                                        <Tooltip title={getTooltip('due_timestamp')}>
                                            <DatePicker
                                                label={
                                                    <Box component="span" sx={{ display: 'flex', alignItems: 'center' }}>
                                                        {getFieldLabel('due_timestamp')}
                                                        {isFieldRequired('due_timestamp') && (
                                                            <Box component="span" sx={{ color: 'error.main', ml: 0.5 }}>*</Box>
                                                        )}
                                                    </Box>
                                                }
                                                value={formData.due_timestamp}
                                                onChange={(date) => setFormData(prev => ({ ...prev, due_timestamp: date }))}
                                                renderInput={(params) => (
                                                    <TextField 
                                                        {...params} 
                                                        sx={{ flex: 1 }}
                                                        required={isFieldRequired('due_timestamp')}
                                                        error={!formData.due_timestamp && isFieldRequired('due_timestamp')}
                                                    />
                                                )}
                                                sx={{ flex: 1 }}
                                            />
                                        </Tooltip>
                                    </LocalizationProvider>
                                </Box>

                                <Tooltip title={getTooltip('notes')}>
                                    <TextField
                                        fullWidth
                                        multiline
                                        rows={4}
                                        label={
                                            <Box component="span" sx={{ display: 'flex', alignItems: 'center' }}>
                                                {getFieldLabel('notes')}
                                                {isFieldRequired('notes') && (
                                                    <Box component="span" sx={{ color: 'error.main', ml: 0.5 }}>*</Box>
                                                )}
                                            </Box>
                                        }
                                        value={formData.notes}
                                        onChange={(e) => setFormData(prev => ({ ...prev, notes: e.target.value }))}
                                        required={isFieldRequired('notes')}
                                        error={!formData.notes && isFieldRequired('notes')}
                                    />
                                </Tooltip>
                            </Box>

                            <Box sx={{ p: 2 }}>
                                <FormControl component="fieldset" required>
                                    <FormLabel component="legend">Visit Assignment</FormLabel>
                                    <RadioGroup
                                        value={formData.visit_type}
                                        onChange={(e) => setFormData(prev => ({ ...prev, visit_type: e.target.value }))}
                                    >
                                        <FormControlLabel 
                                            value="new" 
                                            control={<Radio />} 
                                            label="New Exception Visit Today" 
                                        />
                                        <FormControlLabel 
                                            value="next" 
                                            control={<Radio />} 
                                            label="Add To Next Visit"
                                        />
                                    </RadioGroup>
                                </FormControl>
                            </Box>
                        </Box>
                    </Box>

                    {/* Fixed Footer */}
                    <Box sx={{ 
                        p: 3, 
                        borderTop: 1, 
                        borderColor: 'divider',
                        bgcolor: 'background.paper',
                        display: 'flex', 
                        justifyContent: 'flex-end', 
                        gap: 2,
                        mt: 'auto',  // Push to bottom
                        flexShrink: 0
                    }}>
                        <Button 
                            onClick={clearForm} 
                            color="inherit"
                            startIcon={<RestartAltIcon />}
                        >
                            Clear Form
                        </Button>
                        <Button onClick={onClose} color="inherit">
                            Cancel
                        </Button>
                        <Button
                            onClick={() => setConfirmOpen(true)}
                            variant="contained"
                            disabled={!formData.facility_ids.length || !formData.type}
                        >
                            Create Work Orders
                        </Button>
                    </Box>
                </Box>
            </Modal>

            <Dialog open={confirmOpen} onClose={() => setConfirmOpen(false)}>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to create {formData.facility_ids.length} work orders?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setConfirmOpen(false)} color="inherit" disabled={isSubmitting}>
                        Cancel
                    </Button>
                    <Button 
                        onClick={handleSubmit} 
                        variant="contained" 
                        color="primary"
                        disabled={isSubmitting}
                    >
                        {isSubmitting ? 'Creating...' : 'Confirm'}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default AddWorkOrder; 