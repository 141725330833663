import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Box, Button, FormControl, InputLabel, MenuItem, Select, Tooltip } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import api from '../Common/axiosSetup';
import { useToast } from '../../Contexts/ToastContext';
import DataTable from '../Common/DataTable';
import AddWorkOrder from './AddWorkOrder';
import WorkOrderDetails from './WorkOrderDetails';
import { GridToolbar } from '@mui/x-data-grid';
import useGlobalFilters from '../../Hooks/useGlobalFilters';
import InfoIcon from '@mui/icons-material/Info';
import IconButton from '@mui/material/IconButton';
import { getUserTimeZone, toServerMidnightUTC, utcToServerMidnight, SERVER_TIMEZONE } from '../../Utility/Timezone';

const WorkOrdersTable = () => {
    const [rows, setRows] = useState([]);
    const [selectedRowIds, setSelectedRowIds] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isAddModalOpen, setIsAddModalOpen] = useState(false);
    const [metadata, setMetadata] = useState({});
    const [facMetadata, setFacMetadata] = useState({});
    const [isOutOfSync, setIsOutOfSync] = useState(false);
    const [isChecking, setIsChecking] = useState(false);
    const [selectedWorkOrder, setSelectedWorkOrder] = useState(null);
    const [isDetailsOpen, setIsDetailsOpen] = useState(false);
    const checkTimeoutRef = useRef(null);
    
    const { addToast } = useToast();
    const theme = useTheme();

    const { filters, updateArea, updateRun, updateZone } = useGlobalFilters();
    const { area, run, zone } = filters;

    const userTZ = getUserTimeZone();

    const handleOpenDetails = (workOrder) => {
        setSelectedWorkOrder(workOrder);
        setIsDetailsOpen(true);
    };

    const handleCloseDetails = () => {
        setIsDetailsOpen(false);
        setSelectedWorkOrder(null);
    };

    const columns = [
        { 
            field: 'id', 
            headerName: 'ID', 
            headerAlign: 'center',
            align: 'center',
            flex: 0.5,
        },
        { 
            field: 'facility_name', 
            headerName: 'Facility', 
            headerAlign: 'center',
            align: 'left',
            flex: 1,
        },
        { 
            field: 'zone', 
            headerName: 'Zone', 
            headerAlign: 'center',
            align: 'center',
            flex: 0.3,
        },
        { 
            field: 'type', 
            headerName: 'Type', 
            headerAlign: 'center',
            align: 'center',
            flex: 0.8,
        },
        { 
            field: 'priority', 
            headerName: 'Priority', 
            headerAlign: 'center',
            align: 'center',
            flex: 0.3
        },
        {
            field: 'status',
            headerName: 'Status',
            headerAlign: 'center',
            align: 'center',
            flex: 0.3,
        },
        {
            field: 'notes',
            headerName: 'Notes',
            headerAlign: 'center',
            align: 'left',
            flex: 0.75,
            minWidth: 200,
            renderCell: (params) => {
                const firstLine = params.value?.split('\n')[0] || '';
                const hasMoreLines = params.value?.includes('\n');
                
                return (
                    <Tooltip title={params.value || ''} placement="top-start">
                        <div style={{ 
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            width: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            paddingLeft: '8px'
                        }}>
                            {firstLine}{hasMoreLines ? '...' : ''}
                        </div>
                    </Tooltip>
                );
            }
        },
        {
            field: 'start_timestamp',
            headerName: 'Start Date',
            headerAlign: 'center',
            align: 'center',
            flex: 0.7
        },
        {
            field: 'due_timestamp',
            headerName: 'Due Date',
            headerAlign: 'center',
            align: 'center',
            flex: 0.7
        },
        {
            field: 'next_visit_date',
            headerName: 'Next Visit',
            headerAlign: 'center',
            align: 'center',
            flex: 0.7,
            valueFormatter: (params) => {
                if (!params) return '';
                const date = utcToServerMidnight(params, SERVER_TIMEZONE);
                return date.toLocaleDateString();
            }
        },
        {
            field: 'visit_id',
            headerName: 'On Visit',
            headerAlign: 'center',
            align: 'center',
            flex: 0.4,
            renderCell: (params) => (
                <Box sx={{ 
                    display: 'flex', 
                    alignItems: 'center', 
                    justifyContent: 'center',
                    width: '100%'
                }}>
                    {params.value ? '✓' : '✗'}
                </Box>
            )
        },
        { 
            field: 'details',
            headerName: 'Details',
            flex: 0.4,
            sortable: false,
            filterable: false,
            align: 'center',
            headerAlign: 'center',
            renderCell: (params) => (
                <IconButton
                    onClick={(e) => {
                        e.stopPropagation();
                        handleOpenDetails(params.row);
                    }}
                    size="small"
                >
                    <InfoIcon />
                </IconButton>
            ),
        },
    ];

    // Get areas from facility metadata constraints
    const areas = facMetadata?.data__area?.constraints?.allowed_values || [];
    const runs = area ? (facMetadata?.data__run?.constraints?.allowed_values?.[area] || []) : [];
    const zones = run && facMetadata?.data__zone?.constraints?.allowed_values
        ? (facMetadata.data__zone.constraints.allowed_values[run] || [])
        : [];

    const fetchMetadata = async () => {
        try {
            const token = localStorage.getItem('token');
            
            // Fetch work order metadata
            const workOrderResponse = await api.get('/api/data-registry/metadata', {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                params: {
                    table_name: 'work_order_active'
                }
            });
            setMetadata(workOrderResponse.data || {});

            // Fetch facility metadata
            const facilityResponse = await api.get('/api/data-registry/metadata', {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                params: {
                    table_name: 'facility'
                }
            });
            setFacMetadata(facilityResponse.data || {});
        } catch (error) {
            addToast('Error fetching metadata', {
                color: 'danger',
                autoHide: true,
                delay: 5000
            });
        }
    };

    const fetchWorkOrders = async () => {
        try {
            setLoading(true);
            const token = localStorage.getItem('token');
            
            // Build query parameters
            const params = new URLSearchParams();
            if (area) params.append('area', area);
            if (run) params.append('run', run);
            if (zone) params.append('zone', zone);

            const response = await api.get('/api/work-orders', {
                headers: { Authorization: `Bearer ${token}` },
                params
            });
            setRows(response.data);
        } catch (error) {
            addToast('Error fetching work orders', {
                color: 'danger',
                autoHide: true,
                delay: 5000
            });
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchWorkOrders();
        fetchMetadata();
    }, []);

    const handleAreaChange = (event) => {
        updateArea(event.target.value);
    };

    const handleRunChange = (event) => {
        updateRun(event.target.value);
    };

    const handleZoneChange = (event) => {
        updateZone(event.target.value);
    };

    const handleAddSuccess = (newWorkOrders) => {
        // Ensure newWorkOrders is always treated as an array
        const workOrdersArray = Array.isArray(newWorkOrders) ? newWorkOrders : [newWorkOrders];
        setRows(prevRows => [...prevRows, ...workOrdersArray]);
        
        addToast(
            `Successfully created ${workOrdersArray.length} work order${workOrdersArray.length > 1 ? 's' : ''}!`, 
            { 
                color: 'success', 
                delay: 15000, 
                autoHide: true, 
                requireAck: false 
            }
        );
    };

    const handleRowUpdate = async (id, modifiedFields) => {
        try {
            const token = localStorage.getItem('token');
            
            // Convert date fields to UTC midnight in server timezone
            const processedFields = { ...modifiedFields };
            if (processedFields.start_timestamp) {
                processedFields.start_timestamp = toServerMidnightUTC(processedFields.start_timestamp, userTZ, SERVER_TIMEZONE).toISOString();
            }
            if (processedFields.due_timestamp) {
                processedFields.due_timestamp = toServerMidnightUTC(processedFields.due_timestamp, userTZ, SERVER_TIMEZONE).toISOString();
            }
            
            const workOrderData = {
                id: id,
                ...processedFields
            };
            
            const response = await api.put(`/api/work-orders/${id}`, workOrderData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            });

            // Update the row with all returned data to ensure we have the latest state
            setRows(prevRows => prevRows.map(row => 
                row.id === id ? response.data : row
            ));

            addToast('Work order updated successfully', {
                color: 'success',
                autoHide: true,
                delay: 15000,
                requireAck: false
            });

            return response.data;

        } catch (error) {
            const errorMessage = error.response?.data?.message;
            const errorDetails = error.response?.data?.errors?.join('\n• ');
            const fullError = errorDetails 
                ? `${errorMessage}:\n• ${errorDetails}` 
                : (errorMessage || 'Error updating work order');
                
            addToast(fullError, {
                color: 'danger',
                autoHide: false,
                delay: 15000,
                requireAck: true
            });
            throw error;
        }
    };

    const handleRowDelete = async (id) => {
        try {
            const token = localStorage.getItem('token');
            await api.delete(`/api/work-orders/${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            });

            setRows(prev => prev.filter(row => row.id !== id));

            addToast('Work order deleted successfully', {
                color: 'success',
                autoHide: true,
                delay: 15000,
                requireAck: false
            });
        } catch (error) {
            const errorMessage = error.response?.data?.message;
            const errorDetails = error.response?.data?.errors?.join('\n• ');
            const fullError = errorDetails 
                ? `${errorMessage}:\n• ${errorDetails}` 
                : (errorMessage || 'Error deleting work order');
                
            addToast(fullError, {
                color: 'danger',
                autoHide: false,
                delay: 15000,
                requireAck: true
            });
            throw error;
        }
    };

    const checkVersions = useCallback(async () => {
        if (isChecking || !rows.length) return;
        
        try {
            setIsChecking(true);
            const token = localStorage.getItem('token');

            // Build query parameters with the same filters
            const params = new URLSearchParams();
            if (area) params.append('area', area);
            if (run) params.append('run', run);
            if (zone) params.append('zone', zone);

            const response = await api.get('/api/work-orders', {
                headers: { Authorization: `Bearer ${token}` },
                params
            });

            const freshData = response.data;
            const hasChanges = freshData.some(freshRow => {
                const currentRow = rows.find(r => r.id === freshRow.id);
                return !currentRow || 
                    new Date(currentRow.update_timestamp) < new Date(freshRow.update_timestamp);
            });

            setIsOutOfSync(hasChanges);
        } catch (error) {
            console.error('Error checking versions:', error);
        } finally {
            setIsChecking(false);
        }
    }, [rows, isChecking, area, run, zone]);

    useEffect(() => {
        const startChecking = () => {
            checkTimeoutRef.current = setTimeout(async () => {
                await checkVersions();
                startChecking();
            }, 120000); // Check every 120 seconds
        };

        startChecking();

        return () => {
            if (checkTimeoutRef.current) {
                clearTimeout(checkTimeoutRef.current);
            }
        };
    }, [checkVersions]);

    const handleRefresh = async () => {
        await fetchWorkOrders();
        setIsOutOfSync(false);
    };

    // Fetch work orders when filters change
    useEffect(() => {
        fetchWorkOrders();
    }, [area, run, zone]);

    return (
        <Box sx={{ 
            height: 'calc(100vh - 280px)', 
            width: '100%', 
            padding: theme.spacing(2),
            backgroundColor: theme.palette.background.default,
            borderRadius: theme.shape.borderRadius,
            boxShadow: theme.shadows[1],
            display: 'flex',
            flexDirection: 'column',
            gap: theme.spacing(2),
            overflow: 'hidden'
        }}>
            <Box sx={{ 
                display: 'flex', 
                justifyContent: 'space-between',
                alignItems: 'center'
            }}>
                <Box sx={{ display: 'flex', gap: 2 }}>
                    <FormControl sx={{ minWidth: 120 }}>
                        <InputLabel>Area</InputLabel>
                        <Select
                            value={area}
                            label="Area"
                            onChange={handleAreaChange}
                        >
                            <MenuItem value="">All</MenuItem>
                            {areas.map(area => (
                                <MenuItem key={area} value={area}>{area}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <FormControl sx={{ minWidth: 120 }}>
                        <InputLabel>Run</InputLabel>
                        <Select
                            value={run}
                            label="Run"
                            onChange={handleRunChange}
                            disabled={!area}
                        >
                            <MenuItem value="">All</MenuItem>
                            {runs.map(run => (
                                <MenuItem key={run} value={run}>
                                    {run}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <FormControl sx={{ minWidth: 120 }}>
                        <InputLabel>Zone</InputLabel>
                        <Select
                            value={zone}
                            label="Zone"
                            onChange={handleZoneChange}
                            disabled={!run}
                        >
                            <MenuItem value="">All</MenuItem>
                            {zones.map(zone => (
                                <MenuItem key={zone} value={zone}>{zone}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>

                <Button 
                    variant="contained"
                    onClick={() => setIsAddModalOpen(true)}
                >
                    Add Work Order
                </Button>
            </Box>

            <Box sx={{ 
                flex: 1,
                minHeight: 0,
                overflow: 'hidden'
            }}>
                <DataTable
                    rows={rows}
                    columns={columns}
                    loading={loading}
                    metadata={metadata}
                    tableId="work_orders"
                    checkboxSelection={false}
                    onRowSelectionModelChange={(ids) => setSelectedRowIds(ids)}
                    rowSelectionModel={selectedRowIds}
                    slots={{ toolbar: GridToolbar }}
                    filterMode="client"
                    disableMultipleColumnsFiltering={false}
                    disableMultipleSelection={true}
                    enableInlineEdit={true}
                    onRowUpdate={handleRowUpdate}
                    onRowDelete={handleRowDelete}
                    nonEditableFields={['id', 'insert_timestamp', 'update_timestamp', 'facility_name', 'zone', 'details', 'next_visit_date', 'visit_id']}
                    enableVersionCheck={true}
                    isOutOfSync={isOutOfSync}
                    onRefresh={handleRefresh}
                    disableDelete={false}
                    getRowId={(row) => row.id}
                />
            </Box>

            <AddWorkOrder
                open={isAddModalOpen}
                onClose={() => setIsAddModalOpen(false)}
                onSuccess={handleAddSuccess}
                metadata={metadata}
                facMetadata={facMetadata}
            />

            <WorkOrderDetails
                open={isDetailsOpen}
                onClose={handleCloseDetails}
                workOrder={selectedWorkOrder}
                metadata={metadata}
                onUpdate={handleRowUpdate}
            />
        </Box>
    );
};

export default WorkOrdersTable;
