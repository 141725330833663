import React, { useState, useEffect, useMemo } from 'react';
import { 
    DataGridPro, 
    GridToolbar,
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    GridToolbarQuickFilter,
    useGridApiRef,
    gridFilteredRowCountSelector,
    GridActionsCellItem,
    GridRowEditStopReasons,
    GridRowModes,
} from '@mui/x-data-grid-pro';
import { 
    Box, 
    CircularProgress, 
    Button, 
    Tooltip, 
    TextField, 
    Select, 
    MenuItem, 
    Typography, 
    Dialog, 
    DialogTitle, 
    DialogContent, 
    DialogActions,
    Chip
} from '@mui/material';
import { useTheme, alpha } from '@mui/material/styles';
import { useAuth } from '../../Contexts/AuthContext';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import { useSelector } from 'react-redux';
import RefreshIcon from '@mui/icons-material/Refresh';
import { keyframes } from '@mui/system';
import {
    GridEditInputCell
} from '@mui/x-data-grid-pro';
import { getUserTimeZone, utcToServerMidnight, toServerMidnightUTC, SERVER_TIMEZONE } from '../../Utility/Timezone';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

// --------------------------------------------------------------------
// 1) HELPER: Build date filter operators in one place
// --------------------------------------------------------------------
function getDateFilterOperators() {
    const userTZ = getUserTimeZone();

    // Reusable input component for date filter
    const DateFilterInput = ({ item, applyValue, ...props }) => (
        <TextField
            {...props}
            type="date"
            size="small"
            value={item?.value || ''}
            onChange={(e) => {
                const newValue = e.target.value;
                applyValue({ ...item, value: newValue });
            }}
            InputLabelProps={{ shrink: true }}
            sx={{
                width: '160px',
                '& .MuiInputBase-root': {
                    height: '36px',
                    paddingRight: '8px'
                },
                '& .MuiOutlinedInput-input': {
                    padding: '8px 14px',
                    height: '20px',
                    lineHeight: '20px'
                },
                '& input[type="date"]::-webkit-calendar-picker-indicator': {
                    position: 'absolute',
                    right: '8px',
                    height: '20px',
                    width: '20px',
                    cursor: 'pointer'
                },
                '& .MuiOutlinedInput-root': {
                    alignItems: 'center'
                },
                mt: 2,
                mb: 0.5
            }}
        />
    );

    return [
        {
            label: 'Equals',
            value: 'equals',
            getApplyFilterFn: (filterItem) => {
                if (!filterItem?.value) return null;
                return (params) => {
                    if (!params) return false;
                    try {
                        // Convert both dates to server midnight for comparison
                        const filterDate = toServerMidnightUTC(new Date(filterItem.value), userTZ, SERVER_TIMEZONE);
                        const paramDate = utcToServerMidnight(params, userTZ);
                        return filterDate.getTime() === paramDate.getTime();
                    } catch (e) {
                        return false;
                    }
                };
            },
            InputComponent: DateFilterInput,
        },
        {
            label: 'After',
            value: 'after',
            getApplyFilterFn: (filterItem) => {
                if (!filterItem?.value) return null;
                return (params) => {
                    if (!params) return false;
                    try {
                        // Convert both dates to server midnight for comparison
                        const filterDate = toServerMidnightUTC(new Date(filterItem.value), userTZ, SERVER_TIMEZONE);
                        const paramDate = utcToServerMidnight(params, userTZ);
                        return paramDate.getTime() > filterDate.getTime();
                    } catch (e) {
                        return false;
                    }
                };
            },
            InputComponent: DateFilterInput,
        },
        {
            label: 'Before',
            value: 'before',
            getApplyFilterFn: (filterItem) => {
                if (!filterItem?.value) return null;
                return (params) => {
                    if (!params) return false;
                    try {
                        // Convert both dates to server midnight for comparison
                        const filterDate = toServerMidnightUTC(new Date(filterItem.value), userTZ, SERVER_TIMEZONE);
                        const paramDate = utcToServerMidnight(params, userTZ);
                        return paramDate.getTime() < filterDate.getTime();
                    } catch (e) {
                        return false;
                    }
                };
            },
            InputComponent: DateFilterInput,
        },
    ];
}

// --------------------------------------------------------------------
// 2) HELPER: Apply logic for fields that are UUID
// --------------------------------------------------------------------
function handleUUIDField(field, metadata, config) {
    const meta = metadata[field];
    if (!meta) return config;

    if (meta.data_type === 'uuid' || meta.constraints?.format === 'uuid') {
        return {
            ...config,
            valueFormatter: (params) => {
                if (!params) return '';
                try {
                    return `${params.slice(0, 4).toUpperCase()}-${params.slice(-4).toUpperCase()}`;
                } catch (e) {
                    return params;
                }
            }
        };
    }

    return config;
}

// --------------------------------------------------------------------
// 3) HELPER: Apply logic for dropdown fields (including cascading)
// --------------------------------------------------------------------
function handleDropdownField(field, metadata, config) {
    const meta = metadata[field];
    if (!meta?.constraints) return config;

    const { constraints, ui_settings, data_type } = meta;

    // Handle integer dropdown with display text mapping
    if (data_type === 'integer' && ui_settings?.control_type === 'dropdown') {
        const allowedValues = constraints.allowed_values || {};
        const options = Object.entries(allowedValues).map(([value, label]) => ({
            value: parseInt(value, 10),
            label
        }));

        return {
            ...config,
            type: 'singleSelect',
            valueOptions: options.map(opt => opt.value),
            valueFormatter: (params) => {
                if (params.value === undefined || params.value === null) return '';
                return allowedValues[params.value] || params.value;
            },
            renderCell: (params) => {
                if (params.value === undefined || params.value === null) return '';
                return allowedValues[params.value] || params.value;
            },
            filterOperators: [
                {
                    label: 'is',
                    value: 'is',
                    getApplyFilterFn: (filterItem) => {
                        if (!filterItem.value && filterItem.value !== 0) return null;
                        return (params) => {
                            return params === filterItem.value;
                        };
                    },
                    InputComponent: (props) => {
                        const { item, applyValue } = props;
                        return (
                            <Select
                                value={item.value !== undefined ? item.value : ''}
                                onChange={(e) => applyValue({ ...item, value: e.target.value })}
                                size="small"
                                sx={{ mt: 2.4, mb: 0.5, width: '190px' }}
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {options.map(({ value, label }) => (
                                    <MenuItem key={value} value={value}>
                                        {label}
                                    </MenuItem>
                                ))}
                            </Select>
                        );
                    }
                }
            ],
            renderEditCell: (params) => (
                <Select
                    value={params.value}
                    onChange={(e) => {
                        params.api.setEditCellValue({
                            id: params.id,
                            field: params.field,
                            value: e.target.value
                        });
                    }}
                    sx={{ width: '100%' }}
                >
                    {options.map(({ value, label }) => (
                        <MenuItem key={value} value={value}>
                            {label}
                        </MenuItem>
                    ))}
                </Select>
            )
        };
    }

    // Handle boolean dropdown with configurable values
    if (data_type === 'boolean' && ui_settings?.control_type === 'dropdown') {
        const trueValue = ui_settings.allowed_values?.true || 'Yes';
        const falseValue = ui_settings.allowed_values?.false || 'No';
        
        return {
            ...config,
            type: 'singleSelect',
            valueOptions: [trueValue, falseValue],
            valueFormatter: (params) => {
                const boolValue = params.value === true || params.value === 'true';
                return boolValue ? trueValue : falseValue;
            },
            valueParser: (value) => {
                console.log('ValueParser called with:', value);
                return value === true || value === 'true' || value === trueValue;
            },
            renderCell: (params) => {
                const boolValue = params.value === true || params.value === 'true';
                return boolValue ? trueValue : falseValue;
            },
            renderEditCell: (params) => {
                // Convert any truthy/falsy value to explicit boolean
                const boolValue = params.value === true || params.value === 'true';
                
                return (
                    <Select
                        value={boolValue ? trueValue : falseValue}
                        onChange={(e) => {
                            // Explicitly convert to boolean based on selected value
                            const newValue = e.target.value === trueValue;
                            console.log('Boolean edit:', { 
                                oldValue: params.value, 
                                selected: e.target.value, 
                                newValue: newValue 
                            });
                            params.api.setEditCellValue({
                                id: params.id,
                                field: params.field,
                                value: newValue
                            });
                        }}
                        sx={{ width: '100%' }}
                    >
                        <MenuItem value={trueValue}>{trueValue}</MenuItem>
                        <MenuItem value={falseValue}>{falseValue}</MenuItem>
                    </Select>
                );
            }
        };
    }

    // Handle multi-select fields
    if (data_type === 'array' && ui_settings?.control_type === 'multi_select') {
        return {
            ...config,
            type: 'singleSelect',
            multiple: true,
            valueOptions: constraints.allowed_values || [],
            filterOperators: [
                {
                    label: 'is any of',
                    value: 'isAnyOf',
                    getApplyFilterFn: (filterItem) => {
                        if (!filterItem.value || !filterItem.value.length) {
                            return null;
                        }
                        return (params) => {
                            // params will be the array of values in the cell
                            if (!params || !Array.isArray(params)) return false;
                            // Check if any of the cell's values are in the filter values
                            return params.some(value => filterItem.value.includes(value));
                        };
                    },
                    InputComponent: ({ item, applyValue }) => (
                        <Select
                            multiple
                            value={item.value || []}
                            onChange={(e) => applyValue({ ...item, value: e.target.value })}
                            size="small"
                            sx={{ mt: 2.4, mb: 0.5, width: '190px' }}
                        >
                            {(constraints.allowed_values || []).map((option) => (
                                <MenuItem key={option} value={option}>
                                    {option}
                                </MenuItem>
                            ))}
                        </Select>
                    )
                }
            ],
            renderCell: (params) => {
                const values = params.value || [];
                return (
                    <Box sx={{ 
                        display: 'flex', 
                        flexWrap: 'wrap', 
                        gap: 0.5 
                    }}>
                        {values.map((value) => (
                            <Chip
                                key={value}
                                label={value}
                                size="small"
                                sx={{ 
                                    maxWidth: '100px',
                                    '.MuiChip-label': {
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis'
                                    }
                                }}
                            />
                        ))}
                    </Box>
                );
            },
            renderEditCell: (params) => (
                <Select
                    multiple
                    value={params.value || []}
                    onChange={(e) => {
                        if (params.api && typeof params.api.setEditCellValue === 'function') {
                            params.api.setEditCellValue({ 
                                id: params.id, 
                                field: params.field, 
                                value: e.target.value 
                            });
                        } else {
                            // Handle the case for AddUser modal
                            params.onChange?.(e.target.value);
                        }
                    }}
                    sx={{ width: '100%' }}
                >
                    {(constraints.allowed_values || []).map((option) => (
                        <MenuItem key={option} value={option}>
                            {option}
                        </MenuItem>
                    ))}
                </Select>
            )
        };
    }

    // Handle cascading dropdown
    if (ui_settings?.control_type === 'cascading_dropdown') {
        const allowedValues = constraints.allowed_values || {};
        
        return {
            ...config,
            type: 'singleSelect',
            // Dynamic valueOptions based on parent field
            valueOptions: (params) => {
                const row = params.row;
                // Use cascading_parent_field instead of parent_field
                const parentField = ui_settings.cascading_parent_field;
                const parentValue = row[`data__${parentField}`] || row[parentField];

                // Get valid options based on parent value
                if (parentValue && allowedValues[parentValue]) {
                    return allowedValues[parentValue];
                }
                return Object.values(allowedValues).flat();
            }
        };
    }
    // Regular dropdown with allowed_values
    else if (Array.isArray(constraints.allowed_values)) {
        return {
            ...config,
            type: 'singleSelect',
            valueOptions: constraints.allowed_values,
        };
    }

    return config;
}

// --------------------------------------------------------------------
// 4) HELPER: Apply logic for numeric fields
// --------------------------------------------------------------------
function handleNumberField(field, metadata, config) {
    const meta = metadata[field];
    if (!meta?.ui_settings) return config;

    if (meta.ui_settings.control_type === 'number_input') {
        return {
            ...config,
            type: 'number',
            valueFormatter: (params) => {
                if (!params) return '';
                try {
                    const value = parseInt(params, 10);
                    return meta.units ? `${value} ${meta.units}` : value;
                } catch (e) {
                    return params;
                }
            }
        };
    }

    return config;
}

// --------------------------------------------------------------------
// 5) HELPER: Apply logic for date fields
// --------------------------------------------------------------------
function handleDateField(field, metadata, config) {
    const meta = metadata[field];
    if (!meta?.ui_settings) return config;

    if (meta.ui_settings.control_type === 'date_picker') {
        const userTZ = getUserTimeZone();
        return {
            ...config,
            type: 'date',
            valueFormatter: (params) => {
                if (!params) return '';
                try {
                    // Convert UTC date from DB to server timezone for display
                    const date = utcToServerMidnight(params, SERVER_TIMEZONE);
                    return date.toLocaleDateString();
                } catch (e) {
                    return params;
                }
            },
            valueGetter: (params) => {
                // For edit mode, convert UTC to server midnight
                if (!params) return null;
                return utcToServerMidnight(params, SERVER_TIMEZONE);
            },
            filterOperators: getDateFilterOperators(),
        };
    }

    return config;
}

// --------------------------------------------------------------------
// 6) HELPER: Apply logic for text fields with special formats
// --------------------------------------------------------------------
function handleTextField(field, metadata, config) {
    const meta = metadata[field];
    if (!meta?.constraints) return config;

    const { constraints, ui_settings } = meta;

    // Handle phone number format
    if (constraints.format && constraints.format.includes('\\d{10}')) {
        return {
            ...config,
            type: 'string',
            renderEditCell: (params) => (
                <GridEditInputCell
                    {...params}
                    inputProps={{
                        placeholder: '3064567890',
                        inputMode: 'tel'
                    }}
                />
            )
        };
    }

    return config;
}

// --------------------------------------------------------------------
// MASTER HELPER: Combine all the above into one “metadata-driven” config
// --------------------------------------------------------------------
function getMetadataColumnConfig(field, metadata, baseConfig) {
    let config = { ...baseConfig };

    config = handleUUIDField(field, metadata, config);
    config = handleDropdownField(field, metadata, config);
    config = handleTextField(field, metadata, config);
    config = handleNumberField(field, metadata, config);
    config = handleDateField(field, metadata, config);

    return config;
}

// --------------------------------------------------------------------
// 7) HELPER: Check if a field is editable for inline editing
// --------------------------------------------------------------------
function isFieldEditable(field, row, metadata, enableInlineEdit) {
    const meta = metadata[field];

    // If there’s no metadata about this field, fallback to table-level setting
    if (!meta) return enableInlineEdit;

    // If field is not editable at base and no conditional, not editable
    if (!meta.editable && !meta.conditional_editable) {
        return false;
    }

    // If field is explicitly editable (no conditions)
    if (meta.editable) {
        return true;
    }

    // If there's conditional editability
    if (meta.conditional_editable) {
        const { conditions, dependent_field } = meta.conditional_editable;

        // "data__..." prefix check
        const dependentValue = row[`data__${dependent_field}`] || row[dependent_field];

        // Find matching condition
        const matchingCondition = conditions.find(
            (condition) => condition.value === dependentValue
        );
        return matchingCondition?.editable ?? false;
    }

    // Fallback
    return enableInlineEdit;
}

// --------------------------------------------------------------------
// Custom toolbar component (unchanged)
// --------------------------------------------------------------------
function CustomToolbar({ onResetColumns, enableVersionCheck, isOutOfSync, onRefresh }) {
    return (
        <GridToolbarContainer sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Box sx={{ display: 'flex', gap: 0.5 }}>
                <GridToolbarColumnsButton />
                <GridToolbarFilterButton />
                <Tooltip title="Reset column widths">
                    <Button
                        size="small"
                        startIcon={<RestartAltIcon />}
                        onClick={onResetColumns}
                        sx={{
                            textTransform: 'none',
                            minWidth: 'auto'
                        }}
                    >
                        Reset Widths
                    </Button>
                </Tooltip>
                {enableVersionCheck && (
                    <Button
                        size="small"
                        startIcon={<RefreshIcon />}
                        onClick={onRefresh}
                        sx={{
                            textTransform: 'none',
                            minWidth: 'auto',
                            animation: isOutOfSync ? `${pulse} 2s infinite` : 'none',
                            color: isOutOfSync ? 'warning.main' : 'inherit'
                        }}
                    >
                        {isOutOfSync ? 'Refresh (Out of Sync)' : 'Refresh'}
                    </Button>
                )}
            </Box>
            <GridToolbarQuickFilter />
        </GridToolbarContainer>
    );
}

// --------------------------------------------------------------------
// We keep the getStoredTableState logic
// --------------------------------------------------------------------
const getStoredTableState = (tableId, email) => {
    const storageKey = `tableSettings_${email}_${tableId}`;
    const saved = localStorage.getItem(storageKey);
    const defaultState = {
        columnVisibility: {},
        columnWidths: {},
        sorting: [],
        filter: {
            items: [],
            logicOperator: 'and',
            quickFilterValues: []
        },
        pagination: {
            pageSize: 25,
            page: 0
        }
    };

    if (!saved) return defaultState;

    try {
        const parsedState = JSON.parse(saved);
        return {
            ...defaultState,
            ...parsedState,
            filter: {
                ...defaultState.filter,
                items: Array.isArray(parsedState.filter?.items) ? parsedState.filter.items : [],
                logicOperator: parsedState.filter?.logicOperator || 'and',
                quickFilterValues: Array.isArray(parsedState.filter?.quickFilterValues) ?
                    parsedState.filter.quickFilterValues : []
            }
        };
    } catch (e) {
        console.error('Error parsing stored table state:', e);
        return defaultState;
    }
};

const pulse = keyframes`
  0% { opacity: 1; }
  50% { opacity: 0.5; }
  100% { opacity: 1; }
`;

// --------------------------------------------------------------------
// MAIN COMPONENT
// --------------------------------------------------------------------
const DataTable = ({
    rows,
    columns,
    loading,
    onRowClick,
    pageSize = 10,
    checkboxSelection = false,
    components = {},
    componentsProps = {},
    onRowSelectionModelChange,
    rowSelectionModel = [],
    autoHeight = false,
    rowHeight = 52,
    sx = {},
    filterModel: externalFilterModel,
    onFilterModelChange,
    disableMultipleColumnsFiltering = false,
    disableMultipleSelection = false,
    filterMode = 'client',
    tableId = 'default',
    metadata = {},
    enableInlineEdit = false,
    onRowUpdate,
    onRowDelete,
    nonEditableFields = [],
    enableVersionCheck = false,
    isOutOfSync = false,
    onRefresh,
    disableDelete = false,
    ...otherProps
}) => {
    const theme = useTheme();
    const { authenticatedEmail, isAuthenticated, authCheckComplete } = useAuth();
    const themeColor = useSelector((state) => state.Mainreducer.themeColor) || 'cyan';

    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [rowToDelete, setRowToDelete] = useState(null);
    const [rowModesModel, setRowModesModel] = useState({});

    const [tableState, setTableState] = useState(() =>
        getStoredTableState(tableId, authenticatedEmail)
    );

    const apiRef = useGridApiRef();

    // ----------------- Inline Editing Handlers -------------------
    const handleRowEditStop = (params, event) => {
        if (params.reason === GridRowEditStopReasons.rowFocusOut) {
            event.defaultMuiPrevented = true;
        }
    };

    const handleEditClick = (id) => () => {
        setRowModesModel((prev) => ({
            ...prev,
            [id]: { mode: GridRowModes.Edit },
        }));
    };

    const handleSaveClick = (id) => () => {
        setRowModesModel((prev) => ({
            ...prev,
            [id]: { mode: GridRowModes.View },
        }));
    };

    const handleCancelClick = (id) => () => {
        setRowModesModel((prev) => ({
            ...prev,
            [id]: { mode: GridRowModes.View, ignoreModifications: true },
        }));
    };

    const handleDeleteClick = (id) => {
        const row = rows.find(r => r.id === id);
        setRowToDelete(row);
        setDeleteDialogOpen(true);
    };

    const handleDeleteConfirm = async () => {
        try {
            if (onRowDelete && rowToDelete) {
                await onRowDelete(rowToDelete.id);
                setDeleteDialogOpen(false);
                setRowToDelete(null);
            }
        } catch (error) {
            console.error('Error deleting row:', error);
        }
    };

    const processRowUpdate = async (newRow, oldRow) => {
        console.log('ProcessRowUpdate - Old Row:', oldRow);
        console.log('ProcessRowUpdate - New Row:', newRow);

        try {
            if (onRowUpdate) {
                // Get only the modified fields
                const modifiedFields = {};
                Object.keys(newRow).forEach(key => {
                    if (newRow[key] !== oldRow[key]) {
                        modifiedFields[key] = newRow[key];
                    }
                });

                console.log('ProcessRowUpdate - Modified Fields:', modifiedFields);

                if (Object.keys(modifiedFields).length > 0) {
                    const response = await onRowUpdate(newRow.id, modifiedFields);
                    console.log('ProcessRowUpdate - API Response:', response);
                    
                    // Just update the timestamp in newRow
                    newRow.update_timestamp = response.update_timestamp;
                    return newRow;
                }
            }
            return newRow;
        } catch (error) {
            throw error;
        }
    };

    // ----------------- Process columns in one place -------------------
    const processedColumns = useMemo(() => {
        // 1) Merge base config + metadata config
        let cols = columns.map((col) => {
            // Start with user-provided column definition
            const baseConfig = {
                ...col,
                // If we have stored widths, apply them:
                ...(tableState.columnWidths[col.field]
                    ? {
                          width: tableState.columnWidths[col.field],
                          flex: undefined
                      }
                    : {})
            };

            // Keep this as is - it handles both basic editable and conditional_editable
            baseConfig.editable = enableInlineEdit
                ? (params) => isFieldEditable(col.field, params.row, metadata, enableInlineEdit)
                : false;

            // Now apply all metadata-driven transformations
            return getMetadataColumnConfig(col.field, metadata, baseConfig);
        });

        // 2) If we have “cascading_dropdown,” we also need custom filter logic
        cols = cols.map((col) => {
            const meta = metadata[col.field];
            if (meta?.ui_settings?.control_type === 'cascading_dropdown') {
                const parentField = meta.ui_settings.cascading_parent_field;
                const allowedValues = meta.constraints?.allowed_values || {};

                // For filtering we define a single “equals” operator with a custom Input
                return {
                    ...col,
                    filterOperators: [
                        {
                            label: 'equals',
                            value: 'equals',
                            getApplyFilterFn: (filterItem) => {
                                if (!filterItem.value) return null;
                                return (params) => params === filterItem.value;
                            },
                            InputComponent: ({ item, applyValue }) => {
                                // The idea: get the “parent field”'s filter if any
                                const filterModel = apiRef.current?.state?.filter?.filterModel;
                                const parentValue = filterModel?.items?.find(
                                    (f) =>
                                        f.field === `data__${parentField}` ||
                                        f.field === parentField
                                )?.value;

                                // Build the options based on the parent's selected value
                                const options = parentValue
                                    ? allowedValues[parentValue] || []
                                    : // If no parent filter, flatten all unique child values
                                      Object.values(allowedValues)
                                        .flat()
                                        .filter((v, i, a) => a.indexOf(v) === i);

                                // If current filter is not in the allowed options, clear it:
                                React.useEffect(() => {
                                    if (item.value && !options.includes(item.value)) {
                                        applyValue({ ...item, value: '' });
                                    }
                                    // eslint-disable-next-line
                                }, [parentValue]);

                                return (
                                    <Select
                                        value={item.value || ''}
                                        onChange={(e) =>
                                            applyValue({ ...item, value: e.target.value })
                                        }
                                        size="small"
                                        sx={{ mt: 2.4, mb: 0.5, width: '190px' }}
                                    >
                                        <MenuItem value="">
                                            <em>None</em>
                                        </MenuItem>
                                        {options.map((option) => (
                                            <MenuItem key={option} value={option}>
                                                {option}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                );
                            },
                        },
                    ],
                };
            }
            return col;
        });

        // 3) If inline editing is enabled, we push an “actions” column
        if (enableInlineEdit) {
            cols.push({
                field: 'actions',
                type: 'actions',
                headerName: 'Actions',
                width: 100,
                getActions: ({ id, row }) => {
                    const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
                    const hasEditableFields = columns.some((col) =>
                        isFieldEditable(col.field, row, metadata, enableInlineEdit)
                    );

                    if (isInEditMode) {
                        return [
                            <GridActionsCellItem
                                key="save"
                                icon={<SaveIcon />}
                                label="Save"
                                onClick={handleSaveClick(id)}
                                showInMenu={false}
                            />,
                            <GridActionsCellItem
                                key="cancel"
                                icon={<CancelIcon />}
                                label="Cancel"
                                onClick={handleCancelClick(id)}
                                showInMenu={false}
                            />,
                        ];
                    }

                    const actions = [];
                    if (hasEditableFields) {
                        actions.push(
                            <GridActionsCellItem
                                key="edit"
                                icon={<EditIcon />}
                                label="Edit"
                                onClick={handleEditClick(id)}
                                showInMenu={false}
                            />
                        );
                    }

                    if (!disableDelete) {
                        actions.push(
                            <GridActionsCellItem
                                key="delete"
                                icon={<DeleteIcon />}
                                label="Delete"
                                onClick={() => handleDeleteClick(id)}
                                showInMenu={false}
                                sx={{ color: theme.palette.error.main }}
                            />
                        );
                    }

                    return actions;
                },
            });
        }

        return cols;
    }, [
        columns,
        metadata,
        tableState.columnWidths,
        enableInlineEdit,
        theme.palette.error.main,
        apiRef,
        rowModesModel,
        disableDelete
    ]);

    // On mount, if no external filter, load from localStorage
    useEffect(() => {
        if (!externalFilterModel) {
            const savedState = getStoredTableState(tableId, authenticatedEmail);
            setTableState(savedState);
        }
    }, [tableId, externalFilterModel, authenticatedEmail]);

    // Helper to save the state to localStorage
    const saveState = (newState) => {
        setTableState(newState);
        const storageKey = `tableSettings_${authenticatedEmail}_${tableId}`;
        localStorage.setItem(storageKey, JSON.stringify(newState));
    };

    // Reset column widths
    const handleResetColumns = () => {
        const newState = {
            ...tableState,
            columnWidths: {}
        };
        saveState(newState);
    };

    // If auth not complete or not signed in, just show loader or message
    if (!authCheckComplete) {
        return (
            <Box
                sx={{ 
                    height: '100%', 
                    width: '100%', 
                    display: 'flex', 
                    justifyContent: 'center', 
                    alignItems: 'center' 
                }}
            >
                <CircularProgress />
            </Box>
        );
    }

    if (!isAuthenticated) {
        return (
            <Box
                sx={{ 
                    height: '100%', 
                    width: '100%', 
                    display: 'flex', 
                    justifyContent: 'center', 
                    alignItems: 'center',
                    color: 'text.secondary'
                }}
            >
                Please sign in to view this content
            </Box>
        );
    }

    return (
        <Box
            sx={{ 
                display: 'flex',
                flexDirection: 'column',
                height: '100%',  // Take full height of parent
                width: '100%'
            }}
        >
            {loading ? (
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100%'
                    }}
                >
                    <CircularProgress />
                </Box>
            ) : (
                <>
                    <DataGridPro
                        {...otherProps}
                        rows={rows}
                        columns={processedColumns}
                        getRowId={(row) => row.id}
                        apiRef={apiRef}
                        loading={loading}
                        pagination
                        paginationMode="client"
                        paginationModel={tableState.pagination}
                        onPaginationModelChange={(model) => {
                            saveState({
                                ...tableState,
                                pagination: model
                            });
                        }}
                        editMode="row"
                        rowModesModel={rowModesModel}
                        onRowModesModelChange={(newRowModesModel) => {
                            setRowModesModel(newRowModesModel);
                        }}
                        onRowEditStop={handleRowEditStop}
                        processRowUpdate={processRowUpdate}
                        onProcessRowUpdateError={(error) => {
                            console.error('Error processing row update:', error);
                        }}
                        isCellEditable={(params) => {
                            if (nonEditableFields.includes(params.field)) {
                                return false;
                            }

                            // Get the column definition which has our metadata-based editable setting
                            const column = processedColumns.find(col => col.field === params.field);
                            if (!column) return false;

                            // Use the editable function we set in processedColumns
                            return typeof column.editable === 'function' 
                                ? column.editable(params)
                                : column.editable;
                        }}
                        pageSizeOptions={[10, 25, 50]}
                        checkboxSelection={checkboxSelection}
                        disableSelectionOnClick
                        onRowClick={onRowClick}
                        onRowSelectionModelChange={onRowSelectionModelChange}
                        columnVisibilityModel={tableState.columnVisibility}
                        onColumnVisibilityModelChange={(newModel) => {
                            saveState({
                                ...tableState,
                                columnVisibility: newModel
                            });
                        }}
                        sortModel={tableState.sorting}
                        onSortModelChange={(model) => {
                            saveState({
                                ...tableState,
                                sorting: model
                            });
                        }}
                        onColumnWidthChange={(params) => {
                            const newState = {
                                ...tableState,
                                columnWidths: {
                                    ...tableState.columnWidths,
                                    [params.colDef.field]: params.width
                                }
                            };
                            saveState(newState);
                        }}
                        filterModel={externalFilterModel || tableState.filter}
                        onFilterModelChange={(model) => {
                            // Immediately update the state for cascading filters
                            if (!externalFilterModel) {
                                const newState = {
                                    ...tableState,
                                    filter: {
                                        items: Array.isArray(model?.items) ? model.items : [],
                                        logicOperator: model?.logicOperator || 'and',
                                        quickFilterValues: Array.isArray(model?.quickFilterValues)
                                            ? model.quickFilterValues
                                            : []
                                    }
                                };
                                setTableState(newState);
                                saveState(newState);
                            }
                            if (onFilterModelChange) onFilterModelChange(model);
                        }}
                        slots={{
                            toolbar: CustomToolbar,
                            ...components,
                            pagination: () => {
                                const filteredCount = gridFilteredRowCountSelector(apiRef);
                                const totalPages = Math.max(1, Math.ceil(filteredCount / tableState.pagination.pageSize));
                                
                                return (
                                    <Box
                                        sx={{
                                            p: 1,
                                            display: 'flex',
                                            alignItems: 'center',
                                            width: '100%'
                                        }}
                                    >
                                        <Box sx={{ flex: 1 }} />
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                gap: 1,
                                                flex: 2,
                                                justifyContent: 'center'
                                            }}
                                        >
                                            <Button
                                                variant="outlined"
                                                size="small"
                                                onClick={() => {
                                                    saveState({
                                                        ...tableState,
                                                        pagination: {
                                                            ...tableState.pagination,
                                                            page: 0
                                                        }
                                                    });
                                                }}
                                                disabled={tableState.pagination.page === 0}
                                            >
                                                First
                                            </Button>
                                            <Button
                                                variant="outlined"
                                                size="small"
                                                onClick={() => {
                                                    saveState({
                                                        ...tableState,
                                                        pagination: {
                                                            ...tableState.pagination,
                                                            page: tableState.pagination.page - 1
                                                        }
                                                    });
                                                }}
                                                disabled={tableState.pagination.page === 0}
                                            >
                                                Previous
                                            </Button>
                                            <Typography>
                                                Page {tableState.pagination.page + 1} of {totalPages}
                                            </Typography>
                                            <Button
                                                variant="outlined"
                                                size="small"
                                                onClick={() => {
                                                    saveState({
                                                        ...tableState,
                                                        pagination: {
                                                            ...tableState.pagination,
                                                            page: tableState.pagination.page + 1
                                                        }
                                                    });
                                                }}
                                                disabled={tableState.pagination.page >= totalPages - 1}
                                            >
                                                Next
                                            </Button>
                                            <Button
                                                variant="outlined"
                                                size="small"
                                                onClick={() => {
                                                    saveState({
                                                        ...tableState,
                                                        pagination: {
                                                            ...tableState.pagination,
                                                            page: totalPages - 1
                                                        }
                                                    });
                                                }}
                                                disabled={tableState.pagination.page >= totalPages - 1}
                                            >
                                                Last
                                            </Button>
                                        </Box>
                                        <Box
                                            sx={{ 
                                                flex: 1,
                                                display: 'flex',
                                                justifyContent: 'flex-end'
                                            }}
                                        >
                                            <Select
                                                size="small"
                                                value={tableState.pagination.pageSize}
                                                onChange={(e) => {
                                                    saveState({
                                                        ...tableState,
                                                        pagination: {
                                                            ...tableState.pagination,
                                                            pageSize: e.target.value,
                                                            page: 0
                                                        }
                                                    });
                                                }}
                                            >
                                                {[10, 25, 50, 100].map((size) => (
                                                    <MenuItem key={size} value={size}>
                                                        {size} rows
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </Box>
                                    </Box>
                                );
                            }
                        }}
                        slotProps={{
                            toolbar: {
                                showQuickFilter: true,
                                quickFilterProps: { debounceMs: 500 },
                                printOptions: { disableToolbarButton: true },
                                csvOptions: { disableToolbarButton: true },
                                onResetColumns: handleResetColumns,
                                enableVersionCheck: enableVersionCheck,
                                isOutOfSync: isOutOfSync,
                                onRefresh: onRefresh
                            },
                            ...componentsProps
                        }}
                        autoHeight={autoHeight}
                        rowHeight={rowHeight}
                        disableColumnMenu={false}
                        disableColumnFilter={false}
                        disableColumnSelector={false}
                        disableDensitySelector={false}
                        filterMode={filterMode}
                        disableMultipleColumnsFiltering={disableMultipleColumnsFiltering}
                        disableMultipleSelection={disableMultipleSelection}
                        sx={{
                            border: 'none',
                            '& .MuiDataGrid-main': {
                                backgroundColor: theme.palette.background.paper,
                            },
                            // Only style editable cells when row is in edit mode
                            '& .MuiDataGrid-row--editing': {
                                '& .MuiDataGrid-cell.MuiDataGrid-cell--editable': {
                                    backgroundColor: 'rgba(0, 150, 136, 0.08)',  // Light teal for editable cells
                                    '&:hover': {
                                        backgroundColor: 'rgba(0, 150, 136, 0.12)',  // Slightly darker on hover
                                    }
                                },
                                '& .MuiDataGrid-cell--editing': {
                                    backgroundColor: 'rgba(0, 150, 136, 0.16) !important',  // Darker when being edited
                                    '&:focus-within': {
                                        backgroundColor: 'rgba(0, 150, 136, 0.24) !important',  // Even darker when focused
                                    }
                                },
                                '& .MuiDataGrid-cell:not(.MuiDataGrid-cell--editable)': {
                                    backgroundColor: 'rgba(0, 0, 0, 0.04)'  // Light gray for non-editable cells
                                }
                            },
                            '& .MuiDataGrid-selectedRowCount': {
                                position: 'absolute',
                                left: '16px',
                                width: '300px', // Fixed width to prevent wrapping
                                fontSize: '1rem', // Match pagination font size
                                fontFamily: theme.typography.fontFamily, // Match pagination font family
                            },
                            '& .MuiDataGrid-footerContainer': {
                                borderTop: `1px solid ${theme.palette.divider}`,
                                position: 'relative',
                                justifyContent: 'center', // Center pagination
                                minHeight: '52px',
                            },
                            '& .MuiTablePagination-root': {
                                flex: '0 1 auto', // Allow pagination to shrink but not grow
                            },
                            ...sx  // Keep any other custom styles
                        }}
                        rowSelectionModel={rowSelectionModel}
                    />
                    <Dialog
                        open={deleteDialogOpen}
                        onClose={() => setDeleteDialogOpen(false)}
                        aria-labelledby="delete-dialog-title"
                    >
                        <DialogTitle id="delete-dialog-title">
                            Confirm Delete
                        </DialogTitle>
                        <DialogContent>
                            <Typography>
                                Are you sure you want to delete this object?
                            </Typography>
                            {rowToDelete && (
                                <Box
                                    sx={{
                                        mt: 2,
                                        p: 2,
                                        bgcolor: 'background.default',
                                        borderRadius: 1
                                    }}
                                >
                                    {Object.entries(rowToDelete)
                                        .filter(([key]) => !key.startsWith('_') && key !== 'actions')
                                        .map(([key, value]) => (
                                            <Typography
                                                key={key}
                                                variant="body2"
                                                sx={{ mb: 0.5 }}
                                            >
                                                <strong>
                                                    {key
                                                        .replace('data__', '')
                                                        .replace(/_/g, ' ')}
                                                    :
                                                </strong>{' '}
                                                {value}
                                            </Typography>
                                        ))}
                                </Box>
                            )}
                            <Typography color="error" sx={{ mt: 2 }}>
                                This action cannot be undone.
                            </Typography>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                onClick={() => setDeleteDialogOpen(false)}
                                color="inherit"
                            >
                                Cancel
                            </Button>
                            <Button
                                onClick={handleDeleteConfirm}
                                color="error"
                                variant="contained"
                            >
                                Delete
                            </Button>
                        </DialogActions>
                    </Dialog>
                </>
            )}
        </Box>
    );
};

export default DataTable;