import React, { useState, useEffect } from 'react';
import {
    Modal,
    Paper,
    DialogContentText,
    Button,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Box,
    Typography,
    CircularProgress,
    Alert,
    FormHelperText,
    Tooltip,
    Autocomplete,
    Chip
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import api from '../Common/axiosSetup';
import { useToast } from '../../Contexts/ToastContext';
import { RestartAlt as RestartAltIcon } from '@mui/icons-material';

const AddNewFacility = ({ open, onClose, onAdd }) => {
    const [loading, setLoading] = useState(false);
    const [metadata, setMetadata] = useState({});
    const [formData, setFormData] = useState({});
    const [error, setError] = useState(null);
    const theme = useTheme();
    const { addToast } = useToast();

    useEffect(() => {
        const fetchMetadata = async () => {
            try {
                const token = localStorage.getItem('token');
                const response = await api.get('/api/data-registry/metadata', {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                    params: {
                        table_name: 'facility'
                    }
                });
                setMetadata(response.data);
            } catch (error) {
                console.error('Error fetching metadata:', error);
                addToast('Error fetching facility metadata', {
                    autoHide: true,
                    color: 'danger',
                    requireAck: true,
                    delay: 15000
                });
            }
        };

        if (open) {
            fetchMetadata();
            // Reset form data when opening
            setFormData({});
        }
    }, [open, addToast]);

    const handleInputChange = (field, value) => {
        setFormData(prev => ({
            ...prev,
            [field]: value
        }));

        // If type changes, reset all data fields since they might depend on type
        if (field === 'type') {
            const newFormData = { type: value };
            setFormData(newFormData);
            return;
        }

        // Clear dependent fields if parent field changes
        if (metadata[field]?.ui_settings?.control_type === 'cascading_dropdown') {
            const dependentFields = Object.entries(metadata)
                .filter(([_, meta]) => meta.ui_settings?.cascading_parent_field === field.replace('data__', ''))
                .map(([key]) => key);
            
            dependentFields.forEach(depField => {
                setFormData(prev => ({
                    ...prev,
                    [depField]: ''
                }));
            });
        }
    };

    const isFieldEditable = (fieldMeta, selectedType) => {
        // If field is not editable at base and has no conditional editable rules, it's never editable
        if (!fieldMeta.editable && !fieldMeta.conditional_editable) {
            return false;
        }

        // If field is editable at base, it's always editable
        if (fieldMeta.editable) {
            return true;
        }

        // Check conditional editability
        if (fieldMeta.conditional_editable) {
            const { conditions, dependent_field } = fieldMeta.conditional_editable;
            
            // If dependent field is facility type and we have a selected type
            if (dependent_field === 'type' && selectedType) {
                // Find matching condition
                const matchingCondition = conditions.find(condition => 
                    condition.value === selectedType
                );
                return matchingCondition?.editable ?? false;
            }
        }

        return false;
    };

    const renderField = (field) => {
        const meta = metadata[field];
        if (!meta) return null;

        const { ui_settings, constraints, data_type, description } = meta;
        const value = formData[field];
        const isRequired = constraints?.required;
        const hasError = isRequired && !value && value !== false;
        const fieldLabel = ui_settings?.label || field.replace('data__', '').split('_')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
            .join(' ');

        // Special handling for facility type field
        if (field === 'type') {
            return (
                <Tooltip title="Select the type of facility" placement="top">
                    <FormControl
                        fullWidth
                        size="small"
                        required
                        error={hasError}
                        sx={{ 
                            mb: 2,
                            '& .MuiInputBase-root': {
                                height: '56px'
                            },
                            '& .MuiInputLabel-root': {
                                textTransform: 'none'  // Remove uppercase transform since we're using custom labels
                            }
                        }}
                    >
                        <InputLabel>{ui_settings?.label || 'Facility Type'}</InputLabel>
                        <Select
                            value={value || ''}
                            onChange={(e) => handleInputChange('type', e.target.value)}
                            label={ui_settings?.label || 'Facility Type'}
                        >
                            {metadata.type?.constraints?.allowed_values?.map((option) => (
                                <MenuItem key={option} value={option}>
                                    {option}
                                </MenuItem>
                            ))}
                        </Select>
                        {hasError && (
                            <FormHelperText>This field is required</FormHelperText>
                        )}
                    </FormControl>
                </Tooltip>
            );
        }

        // Helper for common props
        const commonProps = {
            fullWidth: true,
            size: "small",
            required: isRequired,
            error: hasError,
            helperText: hasError ? "This field is required" : "",
            sx: { 
                mb: 2,
                '& .MuiInputBase-root': {
                    height: '56px'
                },
                '& .MuiInputLabel-root': {
                    textTransform: 'none'  // Remove uppercase transform since we're using custom labels
                },
                '& .MuiOutlinedInput-root': {
                    '&.Mui-error .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#d32f2f'  // Match Work Orders red shade
                    }
                },
                '& .MuiFormHelperText-root.Mui-error': {
                    color: '#d32f2f'  // Match Work Orders red shade}
                }
            }
        };

        // Handle number input
        if (meta.ui_settings?.control_type === 'number_input') {
            return (
                <Tooltip title={ui_settings.tooltip || description} placement="top">
                    <TextField
                        {...commonProps}
                        type="number"
                        label={fieldLabel}
                        value={value || ''}
                        onChange={(e) => handleInputChange(field, e.target.value)}
                        inputProps={{
                            min: meta.constraints?.min_value,
                            max: meta.constraints?.max_value
                        }}
                    />
                </Tooltip>
            );
        }

        // Handle date picker
        if (meta.ui_settings?.control_type === 'date_picker') {
            return (
                <Tooltip title={ui_settings.tooltip || description} placement="top">
                    <TextField
                        {...commonProps}
                        type="date"
                        label={fieldLabel}
                        value={value || ''}
                        onChange={(e) => handleInputChange(field, e.target.value)}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Tooltip>
            );
        }

        // Handle dropdown
        if (meta.ui_settings?.control_type === 'dropdown') {
            return (
                <Tooltip title={ui_settings.tooltip || description} placement="top">
                    <FormControl {...commonProps}>
                        <InputLabel>{fieldLabel}</InputLabel>
                        <Select
                            value={value || ''}
                            onChange={(e) => handleInputChange(field, e.target.value)}
                            label={fieldLabel}
                        >
                            {meta.constraints?.allowed_values?.map((option) => (
                                <MenuItem key={option} value={option}>
                                    {option}
                                </MenuItem>
                            ))}
                        </Select>
                        {hasError && (
                            <FormHelperText>{commonProps.helperText}</FormHelperText>
                        )}
                    </FormControl>
                </Tooltip>
            );
        }

        // Handle cascading dropdown
        if (meta.ui_settings?.control_type === 'cascading_dropdown') {
            const parentField = meta.ui_settings.cascading_parent_field;
            const parentValue = formData[`data__${parentField}`];
            const options = parentValue ? meta.constraints?.allowed_values[parentValue] || [] : [];
            
            return (
                <Tooltip title={ui_settings.tooltip || description} placement="top">
                    <FormControl {...commonProps}>
                        <InputLabel>{fieldLabel}</InputLabel>
                        <Select
                            value={value || ''}
                            onChange={(e) => handleInputChange(field, e.target.value)}
                            label={fieldLabel}
                            disabled={!parentValue}
                        >
                            {options.map((option) => (
                                <MenuItem key={option} value={option}>
                                    {option}
                                </MenuItem>
                            ))}
                        </Select>
                        {hasError && (
                            <FormHelperText>{commonProps.helperText}</FormHelperText>
                        )}
                    </FormControl>
                </Tooltip>
            );
        }

        // Handle multi-select
        if (meta.ui_settings?.control_type === 'multi_select') {
            return (
                <Tooltip title={ui_settings.tooltip || description} placement="top">
                    <FormControl {...commonProps}>
                        <Autocomplete
                            multiple
                            value={value || []}
                            onChange={(_, newValue) => handleInputChange(field, newValue)}
                            options={meta.constraints?.allowed_values || []}
                            renderTags={(tagValue, getTagProps) =>
                                tagValue.map((option, index) => (
                                    <Chip
                                        label={option}
                                        {...getTagProps({ index })}
                                        size="small"
                                    />
                                ))
                            }
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={fieldLabel}
                                    error={hasError}
                                    helperText={commonProps.helperText}
                                />
                            )}
                        />
                    </FormControl>
                </Tooltip>
            );
        }

        // Default text field
        return (
            <Tooltip title={ui_settings.tooltip || description} placement="top">
                <TextField
                    {...commonProps}
                    label={fieldLabel}
                    value={value || ''}
                    onChange={(e) => handleInputChange(field, e.target.value)}
                />
            </Tooltip>
        );
    };

    const handleSubmit = async () => {
        try {
            setLoading(true);
            setError(null);

            const token = localStorage.getItem('token');
            const response = await api.post('/api/facility', formData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            });

            onAdd(response.data);
            onClose();
            addToast('Facility added successfully', {
                color: 'success',
                autoHide: true,
                delay: 15000,
                requireAck: false
            });
        } catch (error) {
            const errorMessage = error.response?.data?.message;
            const errorDetails = error.response?.data?.errors?.join('\n• ');
            const fullError = errorDetails 
                ? `${errorMessage}:\n• ${errorDetails}` 
                : (errorMessage || 'Error adding facility');
            
            addToast(fullError, {
                color: 'danger',
                autoHide: false,
                delay: 15000,
                requireAck: true
            });
        } finally {
            setLoading(false);
        }
    };

    const handleClearForm = () => {
        setFormData({});
    };

    const fields = Object.keys(metadata)
        .filter(key => key.startsWith('data__'))
        .sort((a, b) => {
            // Get display_order from metadata root, default to 999 if not specified
            const orderA = metadata[a]?.display_order ?? 999;
            const orderB = metadata[b]?.display_order ?? 999;
            return orderA - orderB;
        });

    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '800px',
        height: '90vh',
        bgcolor: 'background.paper',
        boxShadow: 24,
        borderRadius: 1,
        display: 'flex',
        flexDirection: 'column'
    };

    return (
        <Modal open={open} onClose={onClose}>
            <Box sx={modalStyle}>
                {/* Fixed Header */}
                <Box sx={{ 
                    p: 3, 
                    borderBottom: 1, 
                    borderColor: 'divider',
                    bgcolor: 'background.paper',
                    flexShrink: 0
                }}>
                    <Typography variant="h5" component="h2" gutterBottom>
                        ADD NEW FACILITY
                    </Typography>
                    <Typography variant="body1" color="text.secondary">
                        Please fill in the details below to add a new facility. All fields marked with * are required.
                    </Typography>
                </Box>

                {/* Scrollable Content */}
                <Box sx={{ 
                    flex: 1,
                    overflowY: 'auto',
                    p: 3
                }}>
                    {error && (
                        <Alert 
                            severity="error" 
                            sx={{ 
                                mb: 2,
                                '& .MuiAlert-message': {
                                    whiteSpace: 'pre-line'
                                }
                            }}
                        >
                            {error}
                        </Alert>
                    )}

                    {loading ? (
                        <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
                            <CircularProgress />
                        </Box>
                    ) : (
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
                            {/* Render facility type first */}
                            {renderField('type')}
                            {/* Then render all data fields */}
                            {fields.map(field => renderField(field))}
                        </Box>
                    )}
                </Box>

                {/* Fixed Footer */}
                <Box sx={{ 
                    p: 3, 
                    borderTop: 1, 
                    borderColor: 'divider',
                    bgcolor: 'background.paper',
                    display: 'flex', 
                    justifyContent: 'flex-end', 
                    gap: 2,
                    mt: 'auto',
                    flexShrink: 0
                }}>
                    <Button 
                        onClick={handleClearForm} 
                        color="inherit"
                        startIcon={<RestartAltIcon />}
                    >
                        Clear Form
                    </Button>
                    <Button onClick={onClose} color="inherit">
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        onClick={handleSubmit}
                        disabled={loading}
                    >
                        Add Facility
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default AddNewFacility;
