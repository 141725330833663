import React, { useState, useEffect, useMemo } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Box,
    Typography,
    Switch,
    FormControlLabel,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    List,
    ListItem,
    ListItemText,
    Collapse,
    IconButton,
    Popover,
    Chip,
    Alert,
    Tooltip,
    Checkbox,
} from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InfoIcon from '@mui/icons-material/Info';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { getUserTimeZone, toServerMidnightUTC, utcToServerMidnight, SERVER_TIMEZONE } from '../../Utility/Timezone';

const ScheduledWorkOrderMultiEdit = ({ open, onClose, selectedRows, metadata, onSubmit }) => {
    const [formData, setFormData] = useState({});
    const [enabledFields, setEnabledFields] = useState({});
    const [setNullFields, setSetNullFields] = useState({});
    const [expandedValues, setExpandedValues] = useState({});
    const [anchorEl, setAnchorEl] = useState(null);
    const [activeField, setActiveField] = useState(null);
    const [error, setError] = useState(null);
    const userTZ = useMemo(() => getUserTimeZone(), []);

    useEffect(() => {
        if (open) {
            setFormData({});
            setEnabledFields({});
            setSetNullFields({});
            setExpandedValues({});
            setAnchorEl(null);
            setActiveField(null);
            setError(null);
        }
    }, [open]);

    const handleClose = () => {
        onClose();
    };

    const handleSubmit = async (submitType) => {
        try {
            const updates = {};
            const setNull = {};

            // Process each enabled field
            Object.entries(enabledFields).forEach(([field, enabled]) => {
                if (enabled) {
                    if (setNullFields[field]) {
                        setNull[field] = true;
                    } else if (field in formData) {
                        // Convert dates to UTC for server
                        if (metadata[field]?.data_type === 'date' || metadata[field]?.data_type === 'date_time') {
                            updates[field] = formData[field] ? toServerMidnightUTC(formData[field], userTZ, SERVER_TIMEZONE)?.toISOString() : null;
                        } else {
                            updates[field] = formData[field];
                        }
                    }
                }
            });

            await onSubmit({
                ids: selectedRows.map(row => row.id),
                updates,
                setNull
            }, submitType);

            onClose();
        } catch (err) {
            setError(err.message || 'Failed to update scheduled work orders');
        }
    };

    const handleInputChange = (field, value) => {
        if (field === 'interval_type') {
            // Reset interval_days when changing away from Custom
            if (value !== 'Custom') {
                setFormData(prev => ({
                    ...prev,
                    [field]: value,
                    interval_days: null
                }));
                // Also disable interval_days field
                setEnabledFields(prev => ({
                    ...prev,
                    interval_days: false
                }));
                setSetNullFields(prev => ({
                    ...prev,
                    interval_days: false
                }));
            } else {
                setFormData(prev => ({
                    ...prev,
                    [field]: value
                }));
            }
        } else {
            setFormData(prev => ({
                ...prev,
                [field]: value
            }));
        }
    };

    const handleSetNullChange = (field, checked) => {
        setSetNullFields(prev => ({
            ...prev,
            [field]: checked
        }));
        // Clear the field value when set to null
        if (checked) {
            setFormData(prev => {
                const newData = { ...prev };
                delete newData[field];
                return newData;
            });
        }
    };

    const toggleField = (field) => {
        setEnabledFields(prev => ({
            ...prev,
            [field]: !prev[field]
        }));
        // Reset the field's data when disabled
        if (enabledFields[field]) {
            setFormData(prev => {
                const newData = { ...prev };
                delete newData[field];
                return newData;
            });
            setSetNullFields(prev => {
                const newData = { ...prev };
                delete newData[field];
                return newData;
            });
        }
    };

    const toggleSetNull = (field) => {
        setSetNullFields(prev => ({
            ...prev,
            [field]: !prev[field]
        }));
        // Clear the field value when set to null
        if (!setNullFields[field]) {
            setFormData(prev => {
                const newData = { ...prev };
                delete newData[field];
                return newData;
            });
        }
    };

    const toggleExpand = (value) => {
        setExpandedValues(prev => ({
            ...prev,
            [value]: !prev[value]
        }));
    };

    const handleValueSummaryClick = (event, field) => {
        event.stopPropagation();
        setActiveField(field);
        setAnchorEl(event.currentTarget);
    };

    const renderValueSummary = (field) => {
        const uniqueValues = getUniqueValues(field);
        const count = Object.keys(uniqueValues).length;

        return (
            <Tooltip title="Click to see value distribution">
                <Chip
                    label={`${count} Different Value${count !== 1 ? 's' : ''}`}
                    onClick={(e) => handleValueSummaryClick(e, field)}
                    size="small"
                    variant="outlined"
                    sx={{ ml: 1 }}
                />
            </Tooltip>
        );
    };

    // Helper function to check if a field is required
    const isFieldRequired = (fieldName) => {
        return metadata?.[fieldName]?.constraints?.required === true;
    };

    // Helper function to get tooltip text from metadata
    const getTooltip = (fieldName) => {
        return metadata?.[fieldName]?.ui_settings?.tooltip || metadata?.[fieldName]?.description || '';
    };

    const getFieldLabel = (fieldName) => {
        return metadata?.[fieldName]?.ui_settings?.label || fieldName;
    };

    // Group selected work orders by current values
    const currentValueSummary = selectedRows.reduce((acc, wo) => {
        if (!wo) return acc;
        Object.keys(metadata || {}).forEach(key => {
            if (!acc[key]) acc[key] = new Set();
            if (wo[key] !== undefined && wo[key] !== null) {
                acc[key].add(wo[key]);
            }
        });
        return acc;
    }, {});

    const handlePopoverClose = () => {
        setAnchorEl(null);
        setActiveField(null);
    };

    const renderValuePopover = () => {
        if (!activeField) return null;

        const uniqueValues = getUniqueValues(activeField);
        const totalWorkOrders = selectedRows.length;
        const sortedEntries = Object.entries(uniqueValues).sort((a, b) => {
            const countDiff = b[1].length - a[1].length;
            if (countDiff !== 0) return countDiff;
            return a[0].localeCompare(b[0]);
        });

        return (
            <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handlePopoverClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                PaperProps={{
                    sx: { maxWidth: '80vw', maxHeight: '80vh' }
                }}
                disableRestoreFocus
            >
                <Box sx={{ p: 2, maxWidth: 600, maxHeight: 600, overflow: 'auto' }}>
                    <Typography variant="h6" gutterBottom>
                        Values for {activeField}
                    </Typography>
                    <Typography variant="caption" color="text.secondary">
                        Total work orders: {totalWorkOrders}
                    </Typography>

                    <List>
                        {sortedEntries.map(([value, items]) => (
                            <Box 
                                key={value} 
                                sx={{ 
                                    mb: 2,
                                    backgroundColor: 'background.paper',
                                    borderRadius: 1,
                                    overflow: 'hidden'
                                }}
                            >
                                <Box 
                                    sx={{ 
                                        p: 1, 
                                        backgroundColor: 'primary.main',
                                        color: 'primary.contrastText',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between'
                                    }}
                                >
                                    <Typography variant="subtitle2">
                                        {value} ({items.length} work orders - {((items.length/totalWorkOrders)*100).toFixed(1)}%)
                                    </Typography>
                                    <IconButton 
                                        size="small" 
                                        onClick={() => toggleExpand(value)}
                                        sx={{ 
                                            color: 'inherit',
                                            transform: expandedValues[value] ? 'rotate(180deg)' : 'rotate(0deg)',
                                            transition: 'transform 0.2s'
                                        }}
                                    >
                                        <ExpandMoreIcon />
                                    </IconButton>
                                </Box>

                                <Collapse in={expandedValues[value]}>
                                    <Box sx={{ p: 1 }}>
                                        {items.map((item) => (
                                            <Box 
                                                key={item.id} 
                                                sx={{ 
                                                    p: 1,
                                                    borderBottom: 1,
                                                    borderColor: 'divider'
                                                }}
                                            >
                                                <Typography variant="body2">
                                                    {item.name || item.id}
                                                </Typography>
                                                <Typography variant="caption" color="text.secondary">
                                                    Type: {item.type} | ID: {item.id.slice(0, 8)}...
                                                </Typography>
                                            </Box>
                                        ))}
                                    </Box>
                                </Collapse>
                            </Box>
                        ))}
                    </List>
                </Box>
            </Popover>
        );
    };

    // Add isFieldEditable function
    const isFieldEditable = (fieldMeta) => {
        // If field is not editable at base and has no conditional rules, it's never editable
        if (!fieldMeta?.editable && !fieldMeta?.conditional_editable) {
            return false;
        }

        // If field is editable at base, it's always editable
        if (fieldMeta?.editable) {
            return true;
        }

        return false;
    };

    const renderField = (field) => {
        const fieldMeta = metadata[field];
        if (!fieldMeta || !isFieldEditable(fieldMeta)) return null;

        const fieldName = fieldMeta?.ui_settings?.label || field.replace(/_/g, ' ').toUpperCase();
        
        // Special handling for target_month - only show if interval_type is Quarterly or Annually
        if (field === 'target_month') {
            const intervalType = formData.interval_type || selectedRows[0]?.interval_type;
            if (intervalType !== 'Quarterly' && intervalType !== 'Annually') {
                return null;
            }
        }

        return (
            <>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 2 }}>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={enabledFields[field]}
                                onChange={() => toggleField(field)}
                            />
                        }
                        label={fieldName}
                    />
                    {selectedRows?.length > 0 && renderValueSummary(field)}
                </Box>

                {enabledFields[field] && (
                    <Box sx={{ ml: 4 }}>
                        {renderFieldInput(field, fieldMeta, formData[field] || '', !fieldMeta?.required)}
                    </Box>
                )}
            </>
        );
    };

    const getQuarterOptions = () => [
        { value: 1, label: 'Q1' },
        { value: 2, label: 'Q2' },
        { value: 3, label: 'Q3' },
        { value: 4, label: 'Q4' }
    ];

    const getMonthOptions = () => [
        { value: 1, label: 'January' },
        { value: 2, label: 'February' },
        { value: 3, label: 'March' },
        { value: 4, label: 'April' },
        { value: 5, label: 'May' },
        { value: 6, label: 'June' },
        { value: 7, label: 'July' },
        { value: 8, label: 'August' },
        { value: 9, label: 'September' },
        { value: 10, label: 'October' },
        { value: 11, label: 'November' },
        { value: 12, label: 'December' }
    ];

    const renderFieldInput = (field, meta, value, showSetNull) => {
        // Special handling for interval_days based on interval_type
        if (field === 'interval_days') {
            // Only show if interval_type is Custom
            if (!formData.interval_type || formData.interval_type !== 'Custom') {
                return null;
            }
            return (
                <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                    <FormControl fullWidth>
                        <TextField
                            label={getFieldLabel(field)}
                            type="number"
                            value={value || ''}
                            onChange={(e) => handleInputChange(field, parseInt(e.target.value) || null)}
                            disabled={!enabledFields[field] || setNullFields[field]}
                            error={enabledFields[field] && !setNullFields[field] && !value}
                            helperText={enabledFields[field] && !setNullFields[field] && !value ? 'Required for Custom interval' : ''}
                        />
                    </FormControl>
                    {showSetNull && (
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={setNullFields[field] || false}
                                    onChange={() => toggleSetNull(field)}
                                    color="warning"
                                    disabled={!enabledFields[field]}
                                />
                            }
                            label="Set NULL"
                        />
                    )}
                </Box>
            );
        }

        // Special handling for target_month based on interval_type
        if (field === 'target_month' && formData.interval_type) {
            const isQuarterly = formData.interval_type === 'Quarterly';
            const isAnnually = formData.interval_type === 'Annually';
            
            if (isQuarterly || isAnnually) {
                const options = isQuarterly ? getQuarterOptions() : getMonthOptions();
                const label = isQuarterly ? 'Target Quarter' : 'Target Month';
                
                return (
                    <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                        <FormControl fullWidth>
                            <InputLabel>{label}</InputLabel>
                            <Select
                                value={value || ''}
                                label={label}
                                onChange={(e) => handleInputChange(field, e.target.value)}
                                disabled={!enabledFields[field] || setNullFields[field]}
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {options.map(option => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        {showSetNull && (
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={setNullFields[field] || false}
                                        onChange={() => toggleSetNull(field)}
                                        color="warning"
                                        disabled={!enabledFields[field]}
                                    />
                                }
                                label="Set NULL"
                            />
                        )}
                    </Box>
                );
            }
        }

        if (meta.data_type === 'date' || meta.data_type === 'date_time') {
            return (
                <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                            label={meta.ui_settings?.label || field}
                            value={value ? new Date(value) : null}
                            onChange={(date) => handleInputChange(field, date?.toISOString())}
                            disabled={!enabledFields[field] || setNullFields[field]}
                            renderInput={(params) => (
                                <TextField 
                                    {...params}
                                    fullWidth
                                    error={false}
                                />
                            )}
                        />
                    </LocalizationProvider>
                    {showSetNull && (
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={setNullFields[field] || false}
                                    onChange={() => toggleSetNull(field)}
                                    color="warning"
                                    disabled={!enabledFields[field]}
                                />
                            }
                            label="Set NULL"
                        />
                    )}
                </Box>
            );
        }

        if (meta.data_type === 'boolean') {
            return (
                <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                    <FormControl fullWidth>
                        <InputLabel>{meta.ui_settings?.label || field}</InputLabel>
                        <Select
                            value={value === null ? '' : value}
                            label={meta.ui_settings?.label || field}
                            onChange={(e) => handleInputChange(field, e.target.value === '' ? null : e.target.value === 'true')}
                            disabled={!enabledFields[field] || setNullFields[field]}
                        >
                            <MenuItem value="true">Yes</MenuItem>
                            <MenuItem value="false">No</MenuItem>
                        </Select>
                    </FormControl>
                    {showSetNull && (
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={setNullFields[field] || false}
                                    onChange={() => toggleSetNull(field)}
                                    color="warning"
                                    disabled={!enabledFields[field]}
                                />
                            }
                            label="Set NULL"
                        />
                    )}
                </Box>
            );
        }

        if (Array.isArray(meta.constraints?.allowed_values)) {
            return (
                <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                    <FormControl fullWidth>
                        <InputLabel>{meta.ui_settings?.label || field}</InputLabel>
                        <Select
                            value={value || ''}
                            label={meta.ui_settings?.label || field}
                            onChange={(e) => handleInputChange(field, e.target.value)}
                            disabled={!enabledFields[field] || setNullFields[field]}
                        >
                            {meta.constraints.allowed_values.map((option) => (
                                <MenuItem key={option} value={option}>
                                    {option}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    {showSetNull && (
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={setNullFields[field] || false}
                                    onChange={() => toggleSetNull(field)}
                                    color="warning"
                                    disabled={!enabledFields[field]}
                                />
                            }
                            label="Set NULL"
                        />
                    )}
                </Box>
            );
        }

        // Default text input
        return (
            <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                <TextField
                    fullWidth
                    label={meta.ui_settings?.label || field}
                    value={value || ''}
                    onChange={(e) => handleInputChange(field, e.target.value)}
                    disabled={!enabledFields[field] || setNullFields[field]}
                    multiline={field === 'notes'}
                    rows={field === 'notes' ? 4 : 1}
                />
                {showSetNull && (
                    <FormControlLabel
                        control={
                            <Switch
                                checked={setNullFields[field] || false}
                                onChange={() => toggleSetNull(field)}
                                color="warning"
                                disabled={!enabledFields[field]}
                            />
                        }
                        label="Set NULL"
                    />
                )}
            </Box>
        );
    };

    // Function to get unique values for a field
    const getUniqueValues = (field) => {
        const values = selectedRows.map(row => ({
            id: row.id,
            type: row.type,
            value: row[field]
        }));

        // Group by value
        const grouped = values.reduce((acc, curr) => {
            let key;
            if (curr.value === null || curr.value === undefined) {
                key = 'NULL';
            } else if (curr.value === '') {
                key = '(empty string)';
            } else {
                key = String(curr.value);
            }

            if (!acc[key]) {
                acc[key] = [];
            }
            acc[key].push(curr);
            return acc;
        }, {});

        return grouped;
    };

    // Add hasChanges function
    const hasChanges = () => {
        // Check if any fields are enabled
        const enabledFieldsList = Object.entries(enabledFields).filter(([_, enabled]) => enabled);
        if (enabledFieldsList.length === 0) return false;

        // Check if any enabled fields have values or are set to null
        return enabledFieldsList.some(([field]) => 
            field in formData || setNullFields[field]
        );
    };

    const resetFields = () => {
        setFormData({});
        setEnabledFields({});
        setSetNullFields({});
        setExpandedValues({});
        setAnchorEl(null);
        setActiveField(null);
        setError(null);
    };

    return (
        <Dialog 
            open={open} 
            onClose={onClose}
            maxWidth="md"
            fullWidth
            PaperProps={{
                sx: {
                    height: '90vh',
                    maxHeight: '90vh',
                    bgcolor: 'background.paper',
                    display: 'flex',
                    flexDirection: 'column'
                }
            }}
        >
            {/* Fixed Header */}
            <Box sx={{ 
                p: 3, 
                borderBottom: 1, 
                borderColor: 'divider',
                bgcolor: 'background.paper',
                flexShrink: 0
            }}>
                <Typography variant="h5" component="h2" gutterBottom>
                    MULTI-EDIT SCHEDULED WORK ORDERS
                </Typography>
                <Typography variant="body1" color="text.secondary">
                    Edit multiple scheduled work orders at once by enabling fields and setting new values.
                </Typography>
            </Box>

            <DialogContent sx={{ 
                flex: 1,
                overflowY: 'auto',
                p: 3,
                display: 'flex',
                flexDirection: 'column',
                gap: 3
            }}>
                {/* Instructions Box */}
                <Box sx={{ 
                    p: 2, 
                    bgcolor: 'action.hover',
                    borderRadius: 1,
                    border: '1px solid',
                    borderColor: 'divider'
                }}>
                    <Typography variant="subtitle2" color="primary" gutterBottom>
                        Instructions:
                    </Typography>
                    <Typography variant="body2" color="text.primary" sx={{ mb: 1 }}>
                        1. Enable the switch for each field you want to update
                    </Typography>
                    <Typography variant="body2" color="text.primary" sx={{ mb: 1 }}>
                        2. Enter new values for enabled fields
                    </Typography>
                    <Typography variant="body2" color="text.primary" sx={{ mb: 1 }}>
                        3. Use "Set NULL" switches to clear values where applicable
                    </Typography>
                    <Typography variant="body2" color="text.primary">
                        4. Choose "Safe Submit" to ensure all updates succeed, or "Force Submit" to apply partial updates
                    </Typography>
                </Box>

                {/* Summary Section */}
                <Box sx={{ 
                    p: 2,
                    bgcolor: 'action.hover',
                    borderRadius: 1,
                    border: '1px solid',
                    borderColor: 'divider'
                }}>
                    <Typography variant="subtitle1" gutterBottom>
                        Selection Summary
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Total scheduled work orders selected: {selectedRows?.length || 0}
                    </Typography>
                </Box>

                {/* Edit Fields */}
                <Typography variant="subtitle1" gutterBottom>
                    Edit Fields
                </Typography>
                <Typography variant="body2" color="text.secondary" gutterBottom>
                    Enable switches for fields you want to update
                </Typography>

                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                    {Object.keys(metadata || {})
                        .sort((a, b) => {
                            const orderA = metadata[a]?.display_order ?? Number.MAX_SAFE_INTEGER;
                            const orderB = metadata[b]?.display_order ?? Number.MAX_SAFE_INTEGER;
                            return orderA - orderB;
                        })
                        .map(field => {
                            const renderedField = renderField(field);
                            return renderedField ? (
                                <Box 
                                    key={field}
                                    sx={{
                                        p: 2,
                                        borderRadius: 1,
                                        border: '1px solid',
                                        borderColor: enabledFields[field] ? 'primary.main' : 'divider',
                                        bgcolor: enabledFields[field] ? 'action.hover' : 'background.paper',
                                        transition: 'all 0.2s'
                                    }}
                                >
                                    {renderedField}
                                </Box>
                            ) : null;
                        })}
                </Box>
            </DialogContent>

            {/* Fixed Footer */}
            <Box sx={{ 
                p: 3, 
                borderTop: 1, 
                borderColor: 'divider',
                bgcolor: 'background.paper',
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                mt: 'auto',
                flexShrink: 0
            }}>
                {/* Submission Options Box */}
                <Box sx={{ 
                    p: 2,
                    bgcolor: 'action.hover',
                    borderRadius: 1,
                    border: '1px solid',
                    borderColor: 'divider'
                }}>
                    <Typography variant="subtitle2" gutterBottom color="primary">
                        Submission Options:
                    </Typography>
                    <Box sx={{ display: 'flex', gap: 3, mt: 1 }}>
                        <Box>
                            <Typography variant="body2" fontWeight="medium" gutterBottom>
                                Safe Submit
                            </Typography>
                            <Typography variant="caption" color="text.secondary">
                                If any work order update fails, the entire operation is cancelled. 
                                No changes will be made.
                            </Typography>
                        </Box>
                        <Box>
                            <Typography variant="body2" fontWeight="medium" gutterBottom>
                                Force Submit
                            </Typography>
                            <Typography variant="caption" color="text.secondary">
                                Updates are processed independently. Successful updates will be applied 
                                even if others fail.
                            </Typography>
                        </Box>
                    </Box>
                </Box>

                {/* Action Buttons */}
                <Box sx={{ display: 'flex', gap: 1, justifyContent: 'flex-end' }}>
                    <Button 
                        onClick={resetFields} 
                        color="inherit"
                        startIcon={<RestartAltIcon />}
                    >
                        Reset All
                    </Button>
                    <Button onClick={onClose} color="inherit">
                        Cancel
                    </Button>
                    <Button 
                        onClick={() => handleSubmit('safe')} 
                        variant="contained"
                        color="primary"
                        disabled={!hasChanges()}
                    >
                        Safe Submit
                    </Button>
                    <Button 
                        onClick={() => handleSubmit('force')} 
                        variant="contained"
                        color="warning"
                        disabled={!hasChanges()}
                    >
                        Force Submit
                    </Button>
                </Box>
            </Box>
            {renderValuePopover()}
        </Dialog>
    );
};

export default ScheduledWorkOrderMultiEdit; 