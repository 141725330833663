import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import api from '../Component/Common/axiosSetup';
import { useToast } from './ToastContext';

const ConnectivityContext = createContext();

export const useConnectivity = () => useContext(ConnectivityContext);

export const ConnectivityProvider = ({ children }) => {
    const [isOnline, setIsOnline] = useState(true);
    const [lastPingTime, setLastPingTime] = useState(null);
    const [isRefreshing, setIsRefreshing] = useState(false);
    const [refreshError, setRefreshError] = useState(null);
    const [refreshAttempts, setRefreshAttempts] = useState(0);
    const { addToast } = useToast();

    // Check internet connectivity with retry logic
    const checkInternet = useCallback(async (retryCount = 3) => {
        for (let i = 0; i < retryCount; i++) {
            try {
                const controller = new AbortController();
                const timeoutId = setTimeout(() => controller.abort(), 5000);

                try {
                    await fetch('https://www.google.com/favicon.ico', {
                        mode: 'no-cors',
                        signal: controller.signal,
                        cache: 'no-store'
                    });
                    return true;
                } catch (error) {
                    if (i === retryCount - 1) return false;
                    await new Promise(resolve => setTimeout(resolve, 1000 * (i + 1))); // Exponential backoff
                } finally {
                    clearTimeout(timeoutId);
                }
            } catch (error) {
                if (i === retryCount - 1) return false;
            }
        }
        return false;
    }, []);

    // Check backend connectivity with retry logic
    const checkConnectivity = useCallback(async (retryCount = 3) => {
        setIsRefreshing(true);
        setRefreshError(null);

        for (let i = 0; i < retryCount; i++) {
            try {
                const token = localStorage.getItem('token');
                await api.get('/', {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                setIsOnline(true);
                setLastPingTime(Date.now());
                setRefreshAttempts(0);
                setIsRefreshing(false);
                return true;
            } catch (error) {
                if (i === retryCount - 1) {
                    setIsOnline(false);
                    setRefreshError(error.message);
                    setRefreshAttempts(prev => prev + 1);
                    
                    setIsRefreshing(false);
                    return false;
                }
                await new Promise(resolve => setTimeout(resolve, 1000 * (i + 1))); // Exponential backoff
            }
        }
        setIsRefreshing(false);
        return false;
    }, [addToast, refreshAttempts]);

    // Store data in local storage with timestamp and version
    const storeData = useCallback((key, data) => {
        const storageData = {
            timestamp: Date.now(),
            version: process.env.REACT_APP_VERSION || '1.0.0',
            data: data
        };
        localStorage.setItem(key, JSON.stringify(storageData));
    }, []);

    // Get data from local storage with version check
    const getData = useCallback((key, maxAgeHours = 24) => {
        const stored = localStorage.getItem(key);
        if (!stored) return null;

        const { timestamp, version, data } = JSON.parse(stored);
        const ageHours = (Date.now() - timestamp) / (1000 * 60 * 60);
        const currentVersion = process.env.REACT_APP_VERSION || '1.0.0';
        
        return {
            data,
            timestamp,
            isStale: ageHours > maxAgeHours || version !== currentVersion
        };
    }, []);

    // Fetch and store data with improved error handling
    const fetchAndStore = useCallback(async (endpoint, storageKey, options = {}) => {
        const { retryCount = 3, maxAgeHours = 24 } = options;
        setIsRefreshing(true);
        setRefreshError(null);

        for (let i = 0; i < retryCount; i++) {
            try {
                const token = localStorage.getItem('token');
                const response = await api.get(endpoint, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                storeData(storageKey, response.data);
                setLastPingTime(Date.now());
                setRefreshAttempts(0);
                setIsRefreshing(false);
                return response.data;
            } catch (error) {
                if (i === retryCount - 1) {
                    setRefreshError(error.message);
                    setRefreshAttempts(prev => prev + 1);

                    // Try to get stored data if available
                    const stored = getData(storageKey, maxAgeHours);
                    if (stored && !stored.isStale) {
                        setIsRefreshing(false);
                        return stored.data;
                    }

                    // Show toast for data fetch failure
                    addToast(
                        <span>Failed to fetch updated data. {stored ? 'Using cached data.' : ''}</span>,
                        {
                            color: 'warning',
                            autoHide: true,
                            delay: 5000
                        }
                    );
                    setIsRefreshing(false);
                    throw error;
                }
                await new Promise(resolve => setTimeout(resolve, 1000 * (i + 1))); // Exponential backoff
            }
        }
        setIsRefreshing(false);
        return null;
    }, [storeData, getData, addToast]);

    // Initial connectivity check and periodic pinging
    useEffect(() => {
        const initialCheck = async () => {
            const internetAvailable = await checkInternet();
            if (internetAvailable) {
                await checkConnectivity();
            } else {
                setIsOnline(false);
            }
        };

        initialCheck();
        const connectivityInterval = setInterval(checkConnectivity, 30000);
        const internetInterval = setInterval(checkInternet, 300000);

        return () => {
            clearInterval(connectivityInterval);
            clearInterval(internetInterval);
        };
    }, [checkConnectivity, checkInternet]);

    // Listen for browser online/offline events
    useEffect(() => {
        const handleOnline = async () => {
            const internetAvailable = await checkInternet();
            if (internetAvailable) {
                await checkConnectivity();
            }
        };
        
        const handleOffline = () => {
            setIsOnline(false);
            addToast(
                <span>Network connection lost. Some features may be unavailable.</span>,
                {
                    color: 'warning',
                    autoHide: true,
                    delay: 5000
                }
            );
        };

        window.addEventListener('online', handleOnline);
        window.addEventListener('offline', handleOffline);

        return () => {
            window.removeEventListener('online', handleOnline);
            window.removeEventListener('offline', handleOffline);
        };
    }, [checkConnectivity, checkInternet, addToast]);

    return (
        <ConnectivityContext.Provider value={{
            isOnline,
            lastPingTime,
            isRefreshing,
            refreshError,
            refreshAttempts,
            checkConnectivity,
            storeData,
            getData,
            fetchAndStore
        }}>
            {children}
        </ConnectivityContext.Provider>
    );
} 