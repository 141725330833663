import React, { useState, useEffect, useRef } from 'react';
import api from '../Common/axiosSetup';
import { useToast } from '../../Contexts/ToastContext';
import { Card, Form, Badge } from 'react-bootstrap';

const UserProfileOverview = () => {
    const [userId, setUserId] = useState('****');
    const [userEmail, setUserEmail] = useState('****');
    const [createdAt, setCreatedAt] = useState('****');
    const [assignedZones, setAssignedZones] = useState([]);
    const { addToast } = useToast();
    const isMounted = useRef(false);

    // Fetch user data from the backend API
    useEffect(() => {
        if (isMounted.current) return;
        isMounted.current = true;

        const fetchUserData = async () => {
            try {
                const token = localStorage.getItem('token');

                // Add the Authorization header with the Bearer token
                const response = await api.get('/api/user/me', {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });

                const { id, email, createdAt, assigned_zones } = response.data;

                setUserId(id);
                setUserEmail(email);
                setCreatedAt(new Date(createdAt).toLocaleString());
                setAssignedZones(assigned_zones || []);
            } catch (error) {
                addToast('Error fetching user data. Please try again later.', {
                    autoHide: true,
                    delay: 15000,
                    color: 'danger',
                    requireAck: true
                });
                console.error('Error fetching user data:', error);
            }
        };

        fetchUserData();
    }, [addToast]);

    // Group zones by area
    const groupedZones = assignedZones.reduce((acc, zone) => {
        const area = zone.split('-')[0]; // Get the area prefix (e.g., "AF1", "AF2")
        if (!acc[area]) {
            acc[area] = [];
        }
        acc[area].push(zone);
        return acc;
    }, {});

    return (
        <Card className="w-100">
            <Card.Body>
                <Card.Title className="mb-4">User Profile Overview</Card.Title>
                <Form>
                    <Form.Group className="mb-3" controlId="formUserId">
                        <Form.Label>User ID</Form.Label>
                        <Form.Control type="text" value={userId} readOnly />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formUserEmail">
                        <Form.Label>Email Address</Form.Label>
                        <Form.Control type="email" value={userEmail} readOnly />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formCreatedAt">
                        <Form.Label>Account Created</Form.Label>
                        <Form.Control type="text" value={createdAt} readOnly />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formAssignedZones">
                        <Form.Label>Assigned Zones</Form.Label>
                        <div className="border rounded p-3">
                            {Object.entries(groupedZones).length > 0 ? (
                                Object.entries(groupedZones).map(([area, zones]) => (
                                    <div key={area} className="mb-3">
                                        <h6 className="text-muted mb-2">{area}</h6>
                                        <div className="d-flex flex-wrap gap-2">
                                            {zones.sort().map(zone => (
                                                <Badge 
                                                    key={zone} 
                                                    bg="primary" 
                                                    className="py-2 px-3"
                                                >
                                                    {zone}
                                                </Badge>
                                            ))}
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <p className="text-muted mb-0">No zones assigned</p>
                            )}
                        </div>
                    </Form.Group>
                </Form>
            </Card.Body>
        </Card>
    );
};

export default UserProfileOverview;
