import React from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useAuth } from '../../Contexts/AuthContext';
import { useToast } from '../../Contexts/ToastContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import api from '../Common/axiosSetup';

const SignOut = () => {
    const navigate = useNavigate();
    const { addToast } = useToast();
    const { setIsAuthenticated, setJustLoggedOut } = useAuth();

    const handleSignOut = async () => {
        try {
            if (process.env.REACT_APP_ENV === 'development') {
                console.log('Signing out...');
            }

            const token = localStorage.getItem('token');

            // Always clear token first
            localStorage.removeItem('token');
            localStorage.removeItem('userEmail');
            setIsAuthenticated(false);
            setJustLoggedOut(true);

            if (!token) {
                addToast('No valid session token found. You are already signed out.', {
                    autoHide: true,
                    delay: 15000,
                    color: 'warning'
                });
                navigate('/');
                return;
            }

            // Try to call the backend logout route, but don't wait for it
            try {
                await api.post(
                    '/api/auth/logout',
                    {}, // Empty body
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );
            } catch (error) {
                // Log the error but don't block the sign out process
                if (process.env.REACT_APP_ENV === 'development') {
                    console.error('Backend logout failed:', error);
                }
            }

            // Show success message
            addToast('You have been successfully signed out.', {
                autoHide: true,
                delay: 15000,
                color: 'success'
            });

            // Navigate to home
            navigate('/');
        } catch (error) {
            // Even if something goes wrong, ensure we're logged out locally
            localStorage.removeItem('token');
            localStorage.removeItem('userEmail');
            setIsAuthenticated(false);
            setJustLoggedOut(true);
            
            addToast('Error during sign out, but you have been logged out locally.', {
                autoHide: true,
                delay: 15000,
                color: 'warning'
            });
            
            if (process.env.REACT_APP_ENV === 'development') {
                console.error('Error signing out:', error);
            }
            
            navigate('/');
        }
    };

    return (
        <li>
            <Link to="#" className="m-link" onClick={handleSignOut}>
                <FontAwesomeIcon icon={faSignOutAlt} className="me-2" />
                <span>Sign Out</span>
            </Link>
        </li>
    );
};

export default SignOut;