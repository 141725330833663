import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Box, Button, FormControl, InputLabel, MenuItem, Select, Tooltip } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import api from '../Common/axiosSetup';
import { useToast } from '../../Contexts/ToastContext';
import DataTable from '../Common/DataTable';
import { GridToolbar } from '@mui/x-data-grid';
import useGlobalFilters from '../../Hooks/useGlobalFilters';
import InfoIcon from '@mui/icons-material/Info';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import AddScheduledWorkOrder from './AddScheduledWorkOrder';
import ScheduledWorkOrderDetails from './ScheduledWorkOrderDetails';
import ScheduledWorkOrderMultiEdit from './ScheduledWorkOrderMultiEdit';

const ScheduledWorkOrdersTable = () => {
    const [rows, setRows] = useState([]);
    const [selectedRowIds, setSelectedRowIds] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isAddModalOpen, setIsAddModalOpen] = useState(false);
    const [isMultiEditOpen, setIsMultiEditOpen] = useState(false);
    const [metadata, setMetadata] = useState({});
    const [facMetadata, setFacMetadata] = useState({});
    const [isOutOfSync, setIsOutOfSync] = useState(false);
    const [isChecking, setIsChecking] = useState(false);
    const [selectedScheduledWorkOrder, setSelectedScheduledWorkOrder] = useState(null);
    const [isDetailsOpen, setIsDetailsOpen] = useState(false);
    const checkTimeoutRef = useRef(null);
    
    const { addToast } = useToast();
    const theme = useTheme();

    const { filters, updateArea, updateRun, updateZone } = useGlobalFilters();
    const { area, run, zone } = filters;

    const handleOpenDetails = (scheduledWorkOrder) => {
        setSelectedScheduledWorkOrder(scheduledWorkOrder);
        setIsDetailsOpen(true);
    };

    const handleCloseDetails = () => {
        setIsDetailsOpen(false);
        setSelectedScheduledWorkOrder(null);
    };

    const columns = [
        { 
            field: 'id', 
            headerName: 'ID', 
            headerAlign: 'center',
            align: 'center',
            flex: 0.5,
        },
        { 
            field: 'facility_name', 
            headerName: 'Facility', 
            headerAlign: 'center',
            align: 'left',
            flex: 1,
        },
        { 
            field: 'zone', 
            headerName: 'Zone', 
            headerAlign: 'center',
            align: 'center',
            flex: 0.3,
        },
        { 
            field: 'type', 
            headerName: 'Type', 
            headerAlign: 'center',
            align: 'center',
            flex: 0.8,
        },
        {
            field: 'notes',
            headerName: 'Notes',
            headerAlign: 'center',
            align: 'left',
            flex: 0.75,
            minWidth: 200,
            renderCell: (params) => {
                const firstLine = params.value?.split('\n')[0] || '';
                const hasMoreLines = params.value?.includes('\n');
                
                return (
                    <Tooltip title={params.value || ''} placement="top-start">
                        <div style={{ 
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            width: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            paddingLeft: '8px'
                        }}>
                            {firstLine}{hasMoreLines ? '...' : ''}
                        </div>
                    </Tooltip>
                );
            }
        },
        { 
            field: 'interval_type', 
            headerName: 'Interval', 
            headerAlign: 'center',
            align: 'center',
            flex: 0.3,
        },
        {
            field: 'target_month',
            headerName: 'Create Target',
            headerAlign: 'center',
            align: 'center',
            flex: 0.5,
        },
        {
            field: 'schedule_start_timestamp',
            headerName: 'Start Date',
            headerAlign: 'center',
            align: 'center',
            flex: 0.7,
        },
        {
            field: 'schedule_enable',
            headerName: 'Enabled',
            headerAlign: 'center',
            align: 'center',
            flex: 0.3,
            type: 'boolean'
        },
        { 
            field: 'details',
            headerName: 'Details',
            flex: 0.4,
            sortable: false,
            filterable: false,
            align: 'center',
            headerAlign: 'center',
            renderCell: (params) => (
                <IconButton
                    onClick={(e) => {
                        e.stopPropagation();
                        handleOpenDetails(params.row);
                    }}
                    size="small"
                >
                    <InfoIcon />
                </IconButton>
            ),
        },
    ];

    // Get areas from facility metadata constraints
    const areas = facMetadata?.data__area?.constraints?.allowed_values || [];
    const runs = area ? (facMetadata?.data__run?.constraints?.allowed_values?.[area] || []) : [];
    const zones = run && facMetadata?.data__zone?.constraints?.allowed_values
        ? (facMetadata.data__zone.constraints.allowed_values[run] || [])
        : [];

    const fetchMetadata = async () => {
        try {
            const token = localStorage.getItem('token');
            
            // Fetch scheduled work order metadata
            const workOrderResponse = await api.get('/api/data-registry/metadata', {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                params: {
                    table_name: 'scheduled_work_order'
                }
            });
            setMetadata(workOrderResponse.data || {});

            // Fetch facility metadata
            const facilityResponse = await api.get('/api/data-registry/metadata', {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                params: {
                    table_name: 'facility'
                }
            });
            setFacMetadata(facilityResponse.data || {});
        } catch (error) {
            addToast('Error fetching metadata', {
                color: 'danger',
                autoHide: true,
                delay: 5000
            });
        }
    };

    const fetchScheduledWorkOrders = async () => {
        try {
            setLoading(true);
            const token = localStorage.getItem('token');
            
            // Build query parameters
            const params = new URLSearchParams();
            if (area) params.append('area', area);
            if (run) params.append('run', run);
            if (zone) params.append('zone', zone);

            const response = await api.get('/api/scheduled-work-orders', {
                headers: { Authorization: `Bearer ${token}` },
                params
            });
            setRows(response.data);
        } catch (error) {
            addToast('Error fetching scheduled work orders', {
                color: 'danger',
                autoHide: true,
                delay: 5000
            });
        } finally {
            setLoading(false);
        }
    };

    // Combined effect for initial load and filter changes
    useEffect(() => {
        const initializeData = async () => {
            try {
                setLoading(true);
                await Promise.all([
                    fetchScheduledWorkOrders(),
                    fetchMetadata()
                ]);
            } finally {
                setLoading(false);
            }
        };

        initializeData();
    }, [area, run, zone]); // This will handle both initial load and filter changes

    const handleAreaChange = (event) => {
        updateArea(event.target.value);
    };

    const handleRunChange = (event) => {
        updateRun(event.target.value);
    };

    const handleZoneChange = (event) => {
        updateZone(event.target.value);
    };

    const handleAddSuccess = (newScheduledWorkOrders) => {
        // Ensure newScheduledWorkOrders is always treated as an array
        const workOrdersArray = Array.isArray(newScheduledWorkOrders) ? newScheduledWorkOrders : [newScheduledWorkOrders];
        setRows(prevRows => [...prevRows, ...workOrdersArray]);
        
        addToast(
            `Successfully created ${workOrdersArray.length} scheduled work order${workOrdersArray.length > 1 ? 's' : ''}!`, 
            { 
                color: 'success', 
                delay: 15000, 
                autoHide: true, 
                requireAck: false 
            }
        );
    };

    const handleRowUpdate = async (id, modifiedFields) => {
        try {
            const token = localStorage.getItem('token');
            
            const workOrderData = {
                id: id,
                ...modifiedFields
            };
            
            const response = await api.put(`/api/scheduled-work-orders/${id}`, workOrderData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            });

            setRows(prevRows => prevRows.map(row => 
                row.id === id ? { ...row, ...modifiedFields } : row
            ));

            addToast('Scheduled work order updated successfully', {
                color: 'success',
                autoHide: true,
                delay: 15000,
                requireAck: false
            });

            return response.data;

        } catch (error) {
            const errorMessage = error.response?.data?.message;
            const errorDetails = error.response?.data?.errors?.join('\n• ');
            const fullError = errorDetails 
                ? `${errorMessage}:\n• ${errorDetails}` 
                : (errorMessage || 'Error updating scheduled work order');
                
            addToast(fullError, {
                color: 'danger',
                autoHide: false,
                delay: 15000,
                requireAck: true
            });
            throw error;
        }
    };

    const handleRowDelete = async (id) => {
        try {
            const token = localStorage.getItem('token');
            await api.delete(`/api/scheduled-work-orders/${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            });

            setRows(prev => prev.filter(row => row.id !== id));

            addToast('Scheduled work order deleted successfully', {
                color: 'success',
                autoHide: true,
                delay: 15000,
                requireAck: false
            });
        } catch (error) {
            const errorMessage = error.response?.data?.message;
            const errorDetails = error.response?.data?.errors?.join('\n• ');
            const fullError = errorDetails 
                ? `${errorMessage}:\n• ${errorDetails}` 
                : (errorMessage || 'Error deleting scheduled work order');
                
            addToast(fullError, {
                color: 'danger',
                autoHide: false,
                delay: 15000,
                requireAck: true
            });
            throw error;
        }
    };

    const handleMultiEditSuccess = () => {
        fetchScheduledWorkOrders();
        setSelectedRowIds([]);
        setIsMultiEditOpen(false);
    };

    const handleMultiEdit = () => {
        setIsMultiEditOpen(true);
    };

    const handleMultiEditSubmit = async (updateData, type) => {
        try {
            const token = localStorage.getItem('token');
            const response = await api.post('/api/scheduled-work-orders/multi-edit', {
                ...updateData,
                submitType: type
            }, {
                headers: { Authorization: `Bearer ${token}` }
            });

            // Update rows with successful changes
            if (response.data.success.length > 0) {
                setRows(prevRows => {
                    const updatedRows = [...prevRows];
                    response.data.success.forEach(updatedWorkOrder => {
                        const index = updatedRows.findIndex(row => row.id === updatedWorkOrder.id);
                        if (index !== -1) {
                            updatedRows[index] = {
                                ...updatedWorkOrder,
                                facility_name: updatedWorkOrder.facility_name,
                                zone: updatedWorkOrder.zone
                            };
                        }
                    });
                    return updatedRows;
                });
            }

            // Show success/error toast with summary
            const { summary } = response.data;
            if (summary.failed_changes === 0) {
                addToast(`Successfully updated ${summary.successful_changes} changes across ${response.data.success.length} work orders`, {
                    color: 'success',
                    autoHide: true,
                    delay: 15000
                });
            } else {
                // Format errors with bullet points and new lines
                const errorMessages = response.data.errors.map(err => 
                    `• ${err.facility_name}: ${err.error}`
                ).join('\n');

                const errorMessage = [
                    `${summary.successful_changes} changes succeeded`,
                    `${summary.failed_changes} changes failed`,
                    '',
                    'Failed updates:',
                    errorMessages
                ].join('\n');

                addToast(errorMessage, {
                    color: 'warning',
                    autoHide: false,
                    delay: 15000,
                    requireAck: true
                });
            }

            setIsMultiEditOpen(false);
            setSelectedRowIds([]);

        } catch (error) {
            const errorData = error.response?.data;
            if (errorData?.errors) {
                // Format errors with bullet points and new lines
                const errorMessages = Array.isArray(errorData.errors) 
                    ? errorData.errors.map(err => `• ${err}`).join('\n')
                    : `• ${errorData.errors}`;

                const errorMessage = [
                    'Failed updates:',
                    errorMessages
                ].join('\n');

                addToast(errorMessage, {
                    color: 'danger',
                    autoHide: false,
                    delay: 15000,
                    requireAck: true
                });
            } else {
                addToast(errorData?.message || 'Error updating work orders', {
                    color: 'danger',
                    autoHide: false,
                    delay: 15000,
                    requireAck: true
                });
            }
        }
    };

    return (
        <Box sx={{ 
            height: 'calc(100vh - 280px)', 
            width: '100%', 
            padding: theme.spacing(2),
            backgroundColor: theme.palette.background.default,
            borderRadius: theme.shape.borderRadius,
            boxShadow: theme.shadows[1],
            display: 'flex',
            flexDirection: 'column',
            gap: theme.spacing(2),
            overflow: 'hidden'
        }}>
            <Box sx={{ 
                display: 'flex', 
                justifyContent: 'space-between',
                alignItems: 'center'
            }}>
                <Box sx={{ display: 'flex', gap: 2 }}>
                    <FormControl sx={{ minWidth: 120 }}>
                        <InputLabel>Area</InputLabel>
                        <Select
                            value={area}
                            label="Area"
                            onChange={handleAreaChange}
                        >
                            <MenuItem value="">All</MenuItem>
                            {areas.map(area => (
                                <MenuItem key={area} value={area}>{area}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <FormControl sx={{ minWidth: 120 }}>
                        <InputLabel>Run</InputLabel>
                        <Select
                            value={run}
                            label="Run"
                            onChange={handleRunChange}
                            disabled={!area}
                        >
                            <MenuItem value="">All</MenuItem>
                            {runs.map(run => (
                                <MenuItem key={run} value={run}>
                                    {run}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <FormControl sx={{ minWidth: 120 }}>
                        <InputLabel>Zone</InputLabel>
                        <Select
                            value={zone}
                            label="Zone"
                            onChange={handleZoneChange}
                            disabled={!run}
                        >
                            <MenuItem value="">All</MenuItem>
                            {zones.map(zone => (
                                <MenuItem key={zone} value={zone}>{zone}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>

                <Box sx={{ display: 'flex', gap: 2 }}>
                    <Button
                        variant="contained"
                        onClick={handleMultiEdit}
                        disabled={selectedRowIds.length < 2}
                        sx={{
                            opacity: selectedRowIds.length < 2 ? 0.6 : 1,
                            '&:disabled': {
                                backgroundColor: theme.palette.action.disabledBackground,
                                color: theme.palette.action.disabled
                            }
                        }}
                    >
                        Multi Edit ({selectedRowIds.length} selected)
                    </Button>
                    <Button 
                        variant="contained"
                        onClick={() => setIsAddModalOpen(true)}
                    >
                        Add Scheduled Work Order
                    </Button>
                </Box>
            </Box>

            <Box sx={{ 
                flex: 1,
                minHeight: 0,
                overflow: 'hidden'
            }}>
                <DataTable
                    rows={rows}
                    columns={columns}
                    loading={loading}
                    metadata={metadata}
                    tableId="scheduled_work_orders"
                    checkboxSelection={true}
                    onRowSelectionModelChange={(ids) => setSelectedRowIds(ids)}
                    rowSelectionModel={selectedRowIds}
                    slots={{ toolbar: GridToolbar }}
                    filterMode="client"
                    disableMultipleColumnsFiltering={false}
                    disableMultipleSelection={false}
                    enableInlineEdit={true}
                    onRowUpdate={handleRowUpdate}
                    onRowDelete={handleRowDelete}
                    nonEditableFields={['id', 'insert_timestamp', 'update_timestamp', 'facility_name', 'interval_type', 'target_month', 'schedule_start_timestamp', 'zone', 'details', 'actions']}
                    enableVersionCheck={true}
                    isOutOfSync={isOutOfSync}
                    onRefresh={fetchScheduledWorkOrders}
                    disableDelete={false}
                    getRowId={(row) => row.id}
                />
            </Box>

            <AddScheduledWorkOrder
                open={isAddModalOpen}
                onClose={() => setIsAddModalOpen(false)}
                onSuccess={handleAddSuccess}
                metadata={metadata}
                facMetadata={facMetadata}
            />

            <ScheduledWorkOrderDetails
                open={isDetailsOpen}
                onClose={handleCloseDetails}
                scheduledWorkOrder={selectedScheduledWorkOrder}
                metadata={metadata}
                onUpdate={handleRowUpdate}
            />

            <ScheduledWorkOrderMultiEdit
                open={isMultiEditOpen}
                onClose={() => setIsMultiEditOpen(false)}
                selectedRows={rows.filter(row => selectedRowIds.includes(row.id))}
                metadata={metadata}
                onSubmit={handleMultiEditSubmit}
            />
        </Box>
    );
};

export default ScheduledWorkOrdersTable; 