import React, { useState, useEffect } from 'react';
import {
    Box,
    Button,
    Modal,
    TextField,
    Typography,
    Alert,
    MenuItem,
    Paper,
    DialogActions,
    DialogContent,
    DialogContentText,
    Dialog,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import api from '../Common/axiosSetup';

const AddDataRegistryEntry = ({ open, onClose, onSuccess }) => {
    const theme = useTheme();
    const [formData, setFormData] = useState({
        table_name: '',
        column_name: '',
        key_detail: '',
        metadata: '',
        notes: '',
    });
    const [error, setError] = useState('');
    const [tables, setTables] = useState([]);
    const [columns, setColumns] = useState([]);
    const [confirmOpen, setConfirmOpen] = useState(false);
    const [tableData, setTableData] = useState({});

    useEffect(() => {
        fetchTableData();
    }, []);

    const fetchTableData = async () => {
        try {
            const token = localStorage.getItem('token');

            const response = await api.get('/api/data-registry/table-data', {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            setTableData(response.data);
            // Extract table names for the dropdown
            setTables(Object.keys(response.data));
        } catch (error) {
            setError('Failed to fetch table data');
        }
    };

    const handleTableChange = (event) => {
        const tableName = event.target.value;
        setFormData(prev => ({ 
            ...prev, 
            table_name: tableName, 
            column_name: '',
            key_detail: ''
        }));
        // The table data is directly an array of column objects
        setColumns(tableData[tableName] || []);
    };


    const handleSubmit = async () => {
        try {
            // Format notes as JSONB if there's text
            let formattedData = { ...formData };
            
            // Handle notes formatting
            if (formData.notes && typeof formData.notes === 'string' && formData.notes.trim()) {
                formattedData.notes = [{
                    author: localStorage.getItem('username') || 'unknown',
                    timestamp: new Date().toISOString(),
                    note_text: formData.notes.trim()
                }];
            }    
            
            // Validate metadata JSON
            if (formData.metadata) {
                try {
                    // If metadata is a string, parse it to ensure it's valid JSON
                    if (typeof formData.metadata === 'string') {
                        formattedData.metadata = JSON.parse(formData.metadata);
                    }
                } catch (e) {
                    setError('Invalid JSON format in metadata');
                    return;
                }
            }

            const token = localStorage.getItem('token');

            const response = await api.post('/api/data-registry', formData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            onSuccess(response.data);
            setError('');
            setConfirmOpen(false);
            // Don't clear the form to allow for easy multiple entries
        } catch (error) {
            setError(error.response?.data?.message || 'Failed to add entry');
        }
    };

    const handleClearForm = () => {
        setFormData({
            table_name: '',
            column_name: '',
            key_detail: '',
            metadata: '',
            notes: '',
        });
        setError('');
    };

    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '600px',
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
        borderRadius: 1,
    };

    return (
        <>
            <Modal open={open} onClose={onClose}>
                <Paper sx={modalStyle}>
                    <Typography variant="h6" component="h2" gutterBottom>
                        Add New Data Registry Entry
                    </Typography>

                    <DialogContentText sx={{ mb: 3 }}>
                        Please fill in the details below to add a new data registry entry.
                        All fields marked with * are required.
                    </DialogContentText>

                    {error && (
                        <Alert severity="error" sx={{ mb: 2 }}>
                            {error}
                        </Alert>
                    )}

                    <Box component="form" sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                        <TextField
                            select
                            required
                            label="Table Name"
                            value={formData.table_name}
                            onChange={handleTableChange}
                        >
                            {tables.map((table) => (
                                <MenuItem key={table} value={table}>
                                    {table}
                                </MenuItem>
                            ))}
                        </TextField>

                        <TextField
                            select
                            required
                            label="Column Name"
                            value={formData.column_name}
                            onChange={(e) => setFormData(prev => ({ 
                                ...prev, 
                                column_name: e.target.value,
                                key_detail: ''
                            }))}
                            disabled={!formData.table_name}
                        >
                            {/* Map directly over the array of column objects */}
                            {columns.map((column) => (
                                <MenuItem key={column.name} value={column.name}>
                                    {`${column.name} (${column.data_type})`}
                                </MenuItem>
                            ))}
                        </TextField>

                        {formData.column_name && columns.find(col => col.name === formData.column_name)?.data_type === 'jsonb' && (
                            <TextField
                                label="Key Detail"
                                value={formData.key_detail}
                                onChange={(e) => setFormData(prev => ({ ...prev, key_detail: e.target.value }))}
                                placeholder="Enter the JSON key this rule applies to"
                                helperText="Specify the JSON key these rules should apply to"
                            />
                        )}

                        <TextField
                            required
                            label="Metadata*"
                            multiline
                            rows={10}
                            value={formData.metadata}
                            onChange={(e) => {
                                setFormData(prev => ({ ...prev, metadata: e.target.value }));
                                // Optional: Add real-time JSON validation
                                try {
                                    if (e.target.value) {
                                        JSON.parse(e.target.value);
                                    }
                                    setError('');
                                } catch (err) {
                                    setError('Invalid JSON format in metadata');
                                }
                            }}
                            placeholder="Enter data element metadata in JSON format"
                            error={error.includes('JSON')}
                            helperText={error.includes('JSON') ? error : "Enter valid JSON that defines how this data should be handled"}
                        />

                        <TextField
                            label="Notes"
                            multiline
                            rows={5}
                            value={formData.notes}
                            onChange={(e) => setFormData(prev => ({ ...prev, notes: e.target.value }))}
                        />

                        <Box sx={{ display: 'flex', gap: 2, justifyContent: 'flex-end', mt: 2 }}>
                            <Button onClick={handleClearForm} color="secondary">
                                Clear Form
                            </Button>
                            <Button onClick={onClose} color="inherit">
                                Cancel
                            </Button>
                            <Button
                                variant="contained"
                                onClick={() => setConfirmOpen(true)}
                                disabled={!formData.table_name || !formData.column_name || !formData.metadata}
                            >
                                Add Entry
                            </Button>
                        </Box>
                    </Box>
                </Paper>
            </Modal>

            <Dialog open={confirmOpen} onClose={() => setConfirmOpen(false)}>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to add this entry to the data registry?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setConfirmOpen(false)} color="inherit">
                        Cancel
                    </Button>
                    <Button onClick={handleSubmit} variant="contained" autoFocus>
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default AddDataRegistryEntry; 