import React, { useState, useEffect, useMemo } from 'react';
import {
    Box,
    Button,
    Modal,
    TextField,
    Typography,
    Alert,
    MenuItem,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    FormControl,
    InputLabel,
    Select,
    Chip,
    Tooltip,
    Checkbox,
    FormHelperText
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import api from '../Common/axiosSetup';
import useGlobalFilters from '../../Hooks/useGlobalFilters';
import { useToast } from '../../Contexts/ToastContext';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { getUserTimeZone, toServerMidnightUTC, utcToServerMidnight, SERVER_TIMEZONE } from '../../Utility/Timezone';

const AddScheduledWorkOrder = ({ 
    open, 
    onClose, 
    onSuccess, 
    metadata,
    facMetadata 
}) => {
    const userTZ = useMemo(() => getUserTimeZone(), []);
    const [formData, setFormData] = useState({
        facility_ids: [],
        type: '',
        interval_type: '',
        target_month: '',
        schedule_start_timestamp: null,
        schedule_end_timestamp: null,
        visit_policy: 0,
        notes: '',
    });
    const [error, setError] = useState(null);
    const [facilities, setFacilities] = useState([]);
    const [confirmOpen, setConfirmOpen] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [selectedFacilitiesMap, setSelectedFacilitiesMap] = useState(new Map());
    const [facilityTypeFilter, setFacilityTypeFilter] = useState('');
    const [searchText, setSearchText] = useState('');

    const { addToast } = useToast();
    const theme = useTheme();

    // Use global filters
    const { filters, updateArea, updateRun, updateZone } = useGlobalFilters();
    const { area, run, zone } = filters;

    // Get areas from facility metadata constraints
    const areas = facMetadata?.data__area?.constraints?.allowed_values || [];
    const runs = area ? (facMetadata?.data__run?.constraints?.allowed_values?.[area] || []) : [];
    const zones = run && facMetadata?.data__zone?.constraints?.allowed_values
        ? (facMetadata.data__zone.constraints.allowed_values[run] || [])
        : [];

    // Get unique facility types from the facilities list
    const facilityTypes = useMemo(() => {
        const types = new Set(facilities.map(f => f.type).filter(Boolean));
        return Array.from(types).sort();
    }, [facilities]);

    // Filter facilities based on type and search text
    const filteredFacilities = useMemo(() => {
        return facilities.filter(facility => {
            const facilityName = facility.facility_name?.toLowerCase() || '';
            const matchesSearch = !searchText || facilityName.includes(searchText.toLowerCase());
            const matchesType = !facilityTypeFilter || facility.type === facilityTypeFilter;
            return matchesSearch && matchesType;
        });
    }, [facilities, searchText, facilityTypeFilter]);

    // Fetch facilities when filters change or modal opens
    useEffect(() => {
        if (open) {
            fetchFacilities();
        }
    }, [area, run, zone, open]);

    const fetchFacilities = async () => {
        try {
            const token = localStorage.getItem('token');
            const params = new URLSearchParams();
            if (area) params.append('area', area);
            if (run) params.append('run', run);
            if (zone) params.append('zone', zone);

            const response = await api.get('/api/facility/search/filtered', {
                headers: { Authorization: `Bearer ${token}` },
                params
            });
            setFacilities(response.data);
        } catch (error) {
            setError('Failed to fetch facilities');
        }
    };

    const handleAreaChange = (event) => {
        updateArea(event.target.value);
    };

    const handleRunChange = (event) => {
        updateRun(event.target.value);
    };

    const handleZoneChange = (event) => {
        updateZone(event.target.value);
    };

    const handleSelectAllFiltered = () => {
        const allFilteredIds = filteredFacilities.map(f => f.id);
        const newSelectedIds = new Set([...formData.facility_ids]);
        
        // Check if all filtered facilities are already selected
        const allSelected = allFilteredIds.every(id => newSelectedIds.has(id));
        
        if (allSelected) {
            // Deselect all filtered facilities
            allFilteredIds.forEach(id => newSelectedIds.delete(id));
        } else {
            // Select all filtered facilities
            allFilteredIds.forEach(id => newSelectedIds.add(id));
        }
        
        // Update form data with new selection
        const newIds = Array.from(newSelectedIds);
        setFormData(prev => ({
            ...prev,
            facility_ids: newIds
        }));
        
        // Update facilities map
        const newMap = new Map(selectedFacilitiesMap);
        if (!allSelected) {
            filteredFacilities.forEach(facility => {
                newMap.set(facility.id, facility);
            });
        } else {
            allFilteredIds.forEach(id => {
                newMap.delete(id);
            });
        }
        setSelectedFacilitiesMap(newMap);
    };

    const handleSubmit = async () => {
        if (isSubmitting) return;

        try {
            setIsSubmitting(true);
            setConfirmOpen(false);
            
            const scheduledWorkOrderData = {
                ...formData,
                target_month: formData.target_month ? parseInt(formData.target_month, 10) : null,
                schedule_start_timestamp: toServerMidnightUTC(formData.schedule_start_timestamp, userTZ, SERVER_TIMEZONE)?.toISOString(),
                schedule_end_timestamp: toServerMidnightUTC(formData.schedule_end_timestamp, userTZ, SERVER_TIMEZONE)?.toISOString()
            };
            
            const token = localStorage.getItem('token');
            const response = await api.post('/api/scheduled-work-orders', scheduledWorkOrderData, {
                headers: { Authorization: `Bearer ${token}` },
            });
            
            onSuccess(response.data);
            setError('');
            onClose();
        } catch (error) {           
            const errorMessage = error.response?.data?.message;
            const errorDetails = error.response?.data?.errors;
            
            let fullError = errorMessage || 'Failed to create scheduled work orders';
            if (errorDetails && errorDetails.length > 0) {
                fullError += ':\n• ' + errorDetails.join('\n• ');
            }

            setError(fullError);
            addToast(fullError, {
                color: 'danger',
                autoHide: false,
                delay: 15000,
                requireAck: true
            });
        } finally {
            setIsSubmitting(false);
        }
    };

    const clearForm = () => {
        setFormData({
            facility_ids: [],
            type: '',
            interval_type: '',
            target_month: '',
            schedule_start_timestamp: null,
            schedule_end_timestamp: null,
            visit_policy: 0,
            notes: '',
        });
        setSelectedFacilitiesMap(new Map());
        setFacilityTypeFilter('');
        setSearchText('');
        setError('');
    };

    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '800px',
        height: '90vh',
        bgcolor: 'background.paper',
        boxShadow: 24,
        borderRadius: 1,
        display: 'flex',
        flexDirection: 'column'
    };

    // Convert selected facilities map to array for easier rendering
    const selectedFacilities = useMemo(() => 
        Array.from(selectedFacilitiesMap.values()), 
        [selectedFacilitiesMap]
    );

    // Group selected facilities by type for summary
    const facilityTypeGroups = useMemo(() => {
        return selectedFacilities.reduce((acc, facility) => {
            const type = facility.type || 'Unknown';
            acc[type] = (acc[type] || 0) + 1;
            return acc;
        }, {});
    }, [selectedFacilities]);

    // Helper function to check if a field is required
    const isFieldRequired = (fieldName) => {
        return metadata?.[fieldName]?.constraints?.required === true;
    };

    // Helper function to get tooltip text from metadata
    const getTooltip = (fieldName) => {
        return metadata?.[fieldName]?.ui_settings?.tooltip || metadata?.[fieldName]?.description || '';
    };

    const getFieldLabel = (fieldName) => {
        return metadata?.[fieldName]?.ui_settings?.label || fieldName;
    };

    // Helper function to determine if target month should be shown
    const shouldShowTargetMonth = () => {
        return formData.interval_type === 'Quarterly' || formData.interval_type === 'Annually';
    };

    // Helper function to get quarter options
    const getQuarterOptions = () => [
        { value: 1, label: 'Q1' },
        { value: 2, label: 'Q2' },
        { value: 3, label: 'Q3' },
        { value: 4, label: 'Q4' }
    ];

    // Helper function to get month options
    const getMonthOptions = () => [
        { value: 1, label: 'January' },
        { value: 2, label: 'February' },
        { value: 3, label: 'March' },
        { value: 4, label: 'April' },
        { value: 5, label: 'May' },
        { value: 6, label: 'June' },
        { value: 7, label: 'July' },
        { value: 8, label: 'August' },
        { value: 9, label: 'September' },
        { value: 10, label: 'October' },
        { value: 11, label: 'November' },
        { value: 12, label: 'December' }
    ];

    // Helper function to get target month options based on interval type
    const getTargetMonthOptions = () => {
        if (formData.interval_type === 'Quarterly') {
            return getQuarterOptions();
        } else if (formData.interval_type === 'Annually') {
            return getMonthOptions();
        }
        return [];
    };

    return (
        <>
            <Modal open={open} onClose={onClose}>
                <Box sx={modalStyle}>
                    {/* Fixed Header */}
                    <Box sx={{ 
                        p: 3, 
                        borderBottom: 1, 
                        borderColor: 'divider',
                        bgcolor: 'background.paper',
                        flexShrink: 0
                    }}>
                        <Typography variant="h5" component="h2" gutterBottom>
                            CREATE SCHEDULED WORK ORDERS
                        </Typography>
                        <Typography variant="body1" color="text.secondary" sx={{ mt: 1 }}>
                            Create one or multiple scheduled work orders by selecting facilities and defining work order details.
                        </Typography>
                        <Typography variant="body2" color="warning.main" sx={{ mt: 1 }}>
                            Note: If your facility isn't found in the list, ensure it has an assigned zone. 
                            Work orders can only be attached to facilities with a zone assigned.
                        </Typography>
                    </Box>

                    {/* Scrollable Content */}
                    <Box sx={{ 
                        flex: 1,
                        overflowY: 'auto',
                        p: 3
                    }}>
                        {error && (
                            <Alert 
                                severity="error" 
                                sx={{ 
                                    mb: 2,
                                    '& .MuiAlert-message': {
                                        whiteSpace: 'pre-line'
                                    }
                                }}
                            >
                                {error}
                            </Alert>
                        )}

                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
                            {/* Facility Selection Section */}
                            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                                <Typography variant="subtitle1" sx={{ fontWeight: 'medium' }}>
                                    Facility Selection
                                </Typography>
                                <Box sx={{ display: 'flex', gap: 2 }}>
                                    <FormControl sx={{ flex: 1 }}>
                                        <InputLabel>Area</InputLabel>
                                        <Select
                                            value={area}
                                            label="Area"
                                            onChange={handleAreaChange}
                                        >
                                            <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem>
                                            {areas.map((area) => (
                                                <MenuItem key={area} value={area}>
                                                    {area}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>

                                    <FormControl sx={{ flex: 1 }}>
                                        <InputLabel>Run</InputLabel>
                                        <Select
                                            value={run}
                                            label="Run"
                                            onChange={handleRunChange}
                                            disabled={!area}
                                        >
                                            <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem>
                                            {runs.map((run) => (
                                                <MenuItem key={run} value={run}>
                                                    {run}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>

                                    <FormControl sx={{ flex: 1 }}>
                                        <InputLabel>Zone</InputLabel>
                                        <Select
                                            value={zone}
                                            label="Zone"
                                            onChange={handleZoneChange}
                                            disabled={!run}
                                        >
                                            <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem>
                                            {zones.map((zone) => (
                                                <MenuItem key={zone} value={zone}>
                                                    {zone}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Box>

                                <Box sx={{ display: 'flex', gap: 2 }}>
                                    <FormControl sx={{ flex: 1 }}>
                                        <InputLabel>Facility Type</InputLabel>
                                        <Select
                                            value={facilityTypeFilter}
                                            label="Facility Type"
                                            onChange={(e) => setFacilityTypeFilter(e.target.value)}
                                        >
                                            <MenuItem value="">
                                                <em>All Types</em>
                                            </MenuItem>
                                            {facilityTypes.map((type) => (
                                                <MenuItem key={type} value={type}>
                                                    {type}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>

                                    <TextField
                                        sx={{ flex: 2 }}
                                        label="Search Facilities"
                                        value={searchText}
                                        onChange={(e) => setSearchText(e.target.value)}
                                        placeholder="Type to search facilities..."
                                        InputProps={{
                                            type: 'search',
                                        }}
                                    />
                                </Box>

                                <FormControl fullWidth required>
                                    <Tooltip title="Select one or more facilities to create scheduled work orders for">
                                        <InputLabel>Select Facilities</InputLabel>
                                    </Tooltip>
                                    <Select
                                        multiple
                                        value={formData.facility_ids}
                                        onChange={(e) => e.preventDefault()}
                                        label="Select Facilities"
                                        error={formData.facility_ids.length === 0}
                                        MenuProps={{
                                            PaperProps: {
                                                style: {
                                                    maxHeight: 400,
                                                    width: 'auto',
                                                    maxWidth: '100%'
                                                }
                                            },
                                            // Keep dropdown open after selection
                                            autoFocus: true,
                                            disableAutoFocusItem: true,
                                            anchorOrigin: {
                                                vertical: 'bottom',
                                                horizontal: 'left'
                                            },
                                            transformOrigin: {
                                                vertical: 'top',
                                                horizontal: 'left'
                                            }
                                        }}
                                        renderValue={(selected) => (
                                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                {selected.map((value) => {
                                                    const facility = selectedFacilitiesMap.get(value);
                                                    return (
                                                        <Chip 
                                                            key={value} 
                                                            label={facility?.facility_name || 'Unnamed Facility'}
                                                            size="small"
                                                            onDelete={() => {
                                                                const newIds = formData.facility_ids.filter(id => id !== value);
                                                                setFormData(prev => ({
                                                                    ...prev,
                                                                    facility_ids: newIds
                                                                }));
                                                                // Update the facilities map
                                                                const newMap = new Map(selectedFacilitiesMap);
                                                                newMap.delete(value);
                                                                setSelectedFacilitiesMap(newMap);
                                                            }}
                                                            onClick={(e) => e.stopPropagation()}
                                                            onMouseDown={(e) => e.stopPropagation()}
                                                        />
                                                    );
                                                })}
                                            </Box>
                                        )}
                                    >
                                        {/* Add "Select All Filtered" option */}
                                        {filteredFacilities.length > 0 && (
                                            <MenuItem
                                                dense
                                                sx={{
                                                    borderBottom: '1px solid',
                                                    borderColor: 'divider',
                                                    fontWeight: 'bold'
                                                }}
                                                onClick={(event) => {
                                                    event.preventDefault();
                                                    event.stopPropagation();
                                                    handleSelectAllFiltered();
                                                }}
                                            >
                                                <Checkbox
                                                    checked={
                                                        filteredFacilities.length > 0 &&
                                                        filteredFacilities.every(facility => 
                                                            formData.facility_ids.includes(facility.id)
                                                        )
                                                    }
                                                    indeterminate={
                                                        filteredFacilities.some(facility => 
                                                            formData.facility_ids.includes(facility.id)
                                                        ) &&
                                                        !filteredFacilities.every(facility => 
                                                            formData.facility_ids.includes(facility.id)
                                                        )
                                                    }
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        e.stopPropagation();
                                                        handleSelectAllFiltered();
                                                    }}
                                                />
                                                <Box sx={{ ml: 1 }}>
                                                    {`Select All Filtered (${filteredFacilities.length})`}
                                                </Box>
                                            </MenuItem>
                                        )}
                                        
                                        {filteredFacilities.map((facility) => {
                                            const isSelected = formData.facility_ids.includes(facility.id);
                                            const handleToggle = () => {
                                                const newIds = isSelected
                                                    ? formData.facility_ids.filter(id => id !== facility.id)
                                                    : [...formData.facility_ids, facility.id];
                                                
                                                setFormData(prev => ({
                                                    ...prev,
                                                    facility_ids: newIds
                                                }));
                                                
                                                // Update facilities map
                                                const newMap = new Map(selectedFacilitiesMap);
                                                if (!isSelected) {
                                                    newMap.set(facility.id, facility);
                                                } else {
                                                    newMap.delete(facility.id);
                                                }
                                                setSelectedFacilitiesMap(newMap);
                                            };

                                            return (
                                                <MenuItem 
                                                    key={facility.id} 
                                                    value={facility.id}
                                                    sx={{ 
                                                        whiteSpace: 'normal',
                                                        wordBreak: 'break-word'
                                                    }}
                                                    onClick={(event) => {
                                                        event.preventDefault();
                                                        handleToggle();
                                                    }}
                                                >
                                                    <Checkbox 
                                                        checked={isSelected}
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            e.stopPropagation();
                                                            handleToggle();
                                                        }}
                                                    />
                                                    <Box sx={{ ml: 1 }}>
                                                        <Typography variant="body1">
                                                            {facility.facility_name || `Unknown Facility - ${facility.id}`}
                                                        </Typography>
                                                        <Typography variant="caption" color="text.secondary">
                                                            {facility.type || 'Unknown Type'} | {facility.zone || 'No Zone'}
                                                        </Typography>
                                                    </Box>
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                </FormControl>

                                {selectedFacilities.length > 0 && (
                                    <Box sx={{ mt: 2, border: 1, borderColor: 'divider', borderRadius: 1, p: 2 }}>
                                        <Typography variant="subtitle2" gutterBottom>
                                            Selected Facilities Summary
                                        </Typography>
                                        <Typography variant="body2" color="text.secondary" gutterBottom>
                                            Work orders will be created for {selectedFacilities.length} facilities
                                        </Typography>
                                        
                                        {/* Type summary */}
                                        <Box sx={{ mb: 2 }}>
                                            <Typography variant="body2" color="text.secondary">
                                                Facility Types:
                                            </Typography>
                                            {Object.entries(facilityTypeGroups).map(([type, count]) => (
                                                <Typography key={type} variant="body2" sx={{ ml: 2 }}>
                                                    • {type}: {count}
                                                </Typography>
                                            ))}
                                        </Box>
                                    </Box>
                                )}
                            </Box>

                            {/* Scheduled Work Order Details Section */}
                            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                                <Typography variant="subtitle1" sx={{ fontWeight: 'medium' }}>
                                    Scheduled Work Order Details
                                </Typography>
                                <Box sx={{ display: 'flex', gap: 2 }}>
                                    <FormControl sx={{ flex: 1 }} required={isFieldRequired('type')}>
                                        <Tooltip title={getTooltip('type')}>
                                            <InputLabel error={!formData.type && isFieldRequired('type')}>
                                                {getFieldLabel('type')}
                                            </InputLabel>
                                        </Tooltip>
                                        <Select
                                            value={formData.type}
                                            label={getFieldLabel('type')}
                                            onChange={(e) => setFormData(prev => ({ ...prev, type: e.target.value }))}
                                            error={!formData.type && isFieldRequired('type')}
                                        >
                                            {metadata?.type?.constraints?.allowed_values?.map(type => (
                                                <MenuItem key={type} value={type}>{type}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Box>

                                <Box sx={{ display: 'flex', gap: 2 }}>
                                    <FormControl sx={{ flex: 1 }} required={isFieldRequired('interval_type')}>
                                        <Tooltip title={getTooltip('interval_type')}>
                                            <InputLabel error={!formData.interval_type && isFieldRequired('interval_type')}>
                                                {getFieldLabel('interval_type')}
                                            </InputLabel>
                                        </Tooltip>
                                        <Select
                                            value={formData.interval_type}
                                            label={getFieldLabel('interval_type')}
                                            onChange={(e) => setFormData(prev => ({ 
                                                ...prev, 
                                                interval_type: e.target.value,
                                                target_month: '', // Reset target_month when interval_type changes
                                                interval_days: e.target.value === 'Custom' ? prev.interval_days : null // Keep interval_days only for Custom
                                            }))}
                                            error={!formData.interval_type && isFieldRequired('interval_type')}
                                        >
                                            {metadata?.interval_type?.constraints?.allowed_values?.map(interval => (
                                                <MenuItem key={interval} value={interval}>{interval}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>

                                    {shouldShowTargetMonth() && (
                                        <FormControl sx={{ flex: 1 }} required>
                                            <InputLabel error={!formData.target_month}>
                                                {formData.interval_type === 'Quarterly' ? 'Target Quarter' : 'Target Month'}
                                            </InputLabel>
                                            <Select
                                                value={formData.target_month || ''}
                                                label={formData.interval_type === 'Quarterly' ? 'Target Quarter' : 'Target Month'}
                                                onChange={(e) => setFormData(prev => ({ ...prev, target_month: e.target.value }))}
                                                error={!formData.target_month}
                                            >
                                                {getTargetMonthOptions().map(option => (
                                                    <MenuItem key={option.value} value={option.value}>
                                                        {option.label}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    )}
                                </Box>

                                {formData.interval_type === 'Custom' && (
                                    <Box sx={{ display: 'flex', gap: 2 }}>
                                        <FormControl sx={{ flex: 1 }} required>
                                            <Tooltip title={getTooltip('interval_days')}>
                                                <TextField
                                                    label={getFieldLabel('interval_days')}
                                                    type="number"
                                                    value={formData.interval_days || ''}
                                                    onChange={(e) => setFormData(prev => ({ 
                                                        ...prev, 
                                                        interval_days: parseInt(e.target.value) || null 
                                                    }))}
                                                    error={formData.interval_type === 'Custom' && !formData.interval_days}
                                                    helperText={formData.interval_type === 'Custom' && !formData.interval_days ? 'Required for Custom interval' : ''}
                                                />
                                            </Tooltip>
                                        </FormControl>
                                    </Box>
                                )}

                                <Box sx={{ display: 'flex', gap: 2 }}>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <Tooltip title={getTooltip('schedule_start_timestamp')}>
                                            <DatePicker
                                                label={
                                                    <Box component="span" sx={{ display: 'flex', alignItems: 'center' }}>
                                                        Schedule Start Date
                                                        <Box component="span" sx={{ color: 'error.main', ml: 0.5 }}>*</Box>
                                                    </Box>
                                                }
                                                value={formData.schedule_start_timestamp}
                                                onChange={(date) => setFormData(prev => ({ ...prev, schedule_start_timestamp: date }))}
                                                renderInput={(params) => (
                                                    <TextField 
                                                        {...params} 
                                                        sx={{ flex: 1 }}
                                                        required
                                                        error={!formData.schedule_start_timestamp}
                                                    />
                                                )}
                                                sx={{ flex: 1 }}
                                            />
                                        </Tooltip>

                                        <Tooltip title={getTooltip('schedule_end_timestamp')}>
                                            <DatePicker
                                                label="Schedule End Date"
                                                value={formData.schedule_end_timestamp}
                                                onChange={(date) => setFormData(prev => ({ ...prev, schedule_end_timestamp: date }))}
                                                renderInput={(params) => (
                                                    <TextField 
                                                        {...params} 
                                                        sx={{ flex: 1 }}
                                                    />
                                                )}
                                                sx={{ flex: 1 }}
                                            />
                                        </Tooltip>
                                    </LocalizationProvider>
                                </Box>

                                <FormControl sx={{ flex: 1 }} required>
                                    <Tooltip title={getTooltip('visit_policy')}>
                                        <InputLabel error={formData.visit_policy === undefined}>Visit Policy</InputLabel>
                                    </Tooltip>
                                    <Select
                                        value={formData.visit_policy}
                                        label="Visit Policy"
                                        onChange={(e) => setFormData(prev => ({ ...prev, visit_policy: e.target.value }))}
                                        error={formData.visit_policy === undefined}
                                    >
                                        {metadata?.visit_policy?.constraints?.allowed_values && 
                                            Object.entries(metadata.visit_policy.constraints.allowed_values).map(([value, label]) => (
                                                <MenuItem key={value} value={Number(value)}>{label}</MenuItem>
                                            ))
                                        }
                                    </Select>
                                </FormControl>

                                <Tooltip title={getTooltip('notes')}>
                                    <TextField
                                        fullWidth
                                        multiline
                                        rows={4}
                                        label="Notes"
                                        value={formData.notes}
                                        onChange={(e) => setFormData(prev => ({ ...prev, notes: e.target.value }))}
                                    />
                                </Tooltip>
                            </Box>
                        </Box>
                    </Box>

                    {/* Fixed Footer */}
                    <Box sx={{ 
                        p: 3, 
                        borderTop: 1, 
                        borderColor: 'divider',
                        bgcolor: 'background.paper',
                        display: 'flex', 
                        justifyContent: 'flex-end', 
                        gap: 2,
                        mt: 'auto',
                        flexShrink: 0
                    }}>
                        <Button 
                            onClick={clearForm} 
                            color="inherit"
                            startIcon={<RestartAltIcon />}
                        >
                            Clear Form
                        </Button>
                        <Button onClick={onClose} color="inherit">
                            Cancel
                        </Button>
                        <Button
                            onClick={() => setConfirmOpen(true)}
                            variant="contained"
                            disabled={!formData.facility_ids.length || !formData.type}
                        >
                            Create Scheduled Work Orders
                        </Button>
                    </Box>
                </Box>
            </Modal>

            <Dialog 
                open={confirmOpen} 
                onClose={() => setConfirmOpen(false)}
                disableRestoreFocus
                keepMounted={false}
            >
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to create {formData.facility_ids.length} scheduled work orders?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setConfirmOpen(false)} color="inherit" disabled={isSubmitting}>
                        Cancel
                    </Button>
                    <Button 
                        onClick={handleSubmit}
                        variant="contained" 
                        color="primary"
                        disabled={isSubmitting}
                    >
                        {isSubmitting ? 'Creating...' : 'Confirm'}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default AddScheduledWorkOrder; 