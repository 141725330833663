import React, { useState, useEffect, useRef } from "react";
import { useAuth } from '../../Contexts/AuthContext';
import { useNavigate, useLocation } from "react-router-dom";  // Add useLocation to capture query params
import api from '../Common/axiosSetup';
import { Form } from 'react-bootstrap';

function Login({ setIsMobileView }) {
    const navigate = useNavigate();
    const location = useLocation();  // To capture URL parameters
    const { isAuthenticated, authenticatedEmail, checkAuthState, setIsAuthenticated } = useAuth();
    const [email, setEmail] = useState('');
    const [otp, setOtp] = useState(Array(6).fill(''));
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [loading, setLoading] = useState(false);
    const [currentStep, setCurrentStep] = useState('EMAIL'); // Steps: EMAIL, OTP
    const [authCheckComplete, setAuthCheckComplete] = useState(false);
    const [isMobile, setIsMobile] = useState(() => {
        const saved = localStorage.getItem("isMobileView");
        return saved ? JSON.parse(saved) : window.innerWidth <= 768;
    });
    const inputRefs = useRef([]);

    // Update mobile view preference
    useEffect(() => {
        setIsMobileView(isMobile);
    }, [isMobile, setIsMobileView]);

    // Helper function to get URL query parameters
    const getQueryParams = (query) => {
        return query
            .substring(1)
            .split("&")
            .reduce((params, param) => {
                const [key, value] = param.split("=");
                params[key] = decodeURIComponent(value);
                return params;
            }, {});
    };

    // Check if there's an OTP and email in the URL
    useEffect(() => {
        const queryParams = getQueryParams(location.search);
        if (queryParams.otp && queryParams.email) {
            setEmail(queryParams.email);
            setOtp(queryParams.otp.split(''));  // Split the OTP into array format
            setCurrentStep('OTP');

            // Automatically submit the OTP after the OTP is set
            handleAutoOtpSubmit(queryParams.otp, queryParams.email);
        }
    }, [location.search]);

    // Check auth state when component mounts
    useEffect(() => {
        const validateAndClearSession = async () => {
            try {
                const token = localStorage.getItem('token');
                if (token) {
                    try {
                        await api.post('/api/auth/check-session', {}, {
                            headers: { Authorization: `Bearer ${token}` }
                        });
                    } catch (error) {
                        // If session check fails, clear token
                        localStorage.removeItem('token');
                        setIsAuthenticated(false);
                        setError('Your session has expired. Please sign in again.');
                    }
                }
            } catch (error) {
                // If anything goes wrong, clear token to be safe
                localStorage.removeItem('token');
                setIsAuthenticated(false);
            } finally {
                setAuthCheckComplete(true);
            }
        };

        validateAndClearSession();
    }, [setIsAuthenticated]);

    useEffect(() => {
        inputRefs.current = inputRefs.current.slice(0, otp.length);
    }, [otp]);

    useEffect(() => {
        if (inputRefs.current[0]) {
            inputRefs.current[0].focus();
        }
    }, []);

    const validateEmail = (email) => {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const allowedDomains = ['woodlanddc.com', 'flaresoft.solutions', 'djw.email'];
        const domain = email.split('@')[1];
        return emailPattern.test(email) && allowedDomains.includes(domain);
    };

    // Add mobile input styles
    const mobileInputStyle = {
        fontSize: isMobile ? '16px' : undefined,  // Better default size for mobile
        height: isMobile ? '40px' : undefined,    // Slightly smaller height on mobile
        padding: isMobile ? '8px' : undefined     // Adjusted padding for mobile
    };

    const handleEmailSubmit = async (event) => {
        event.preventDefault();
        setError('');    // Clear error messages
        setSuccess('');  // Clear success messages
        setLoading(true);

        if (!validateEmail(email)) {
            setError('Please enter a valid email address.');
            setLoading(false);
            return;
        }

        try {
            await api.post(`/api/auth/request`, { email });

            setCurrentStep('OTP');
            setSuccess('A one-time login request has been sent successfully. Please check your email for your OTP or magic link. If you do not receive it within a few minutes, please check your spam folder. This one-time password is valid for 15 minutes.');
        } catch (error) {
            setError('An error occurred during your login request. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    // Automatically submit the OTP and email when magic link is used
    const handleAutoOtpSubmit = async (otp, email) => {
        setLoading(true);

        try {
            const response = await api.post('/api/auth/verify', { email, otp });

            if (response.data.token) {
                // Store the JWT token and user email
                localStorage.setItem('token', response.data.token);
                localStorage.setItem('userEmail', email);  // Store email for mobile mode
                setIsAuthenticated(true);
                navigate('/profile/overview');  // Redirect to dashboard after successful login
            } else {
                setError(
                    <>
                        {error.response.data.message || 'Invalid OTP or server error.'}
                        <br/><br/>
                        You can try again or <a href="#" onClick={handleGoBack}>Go back</a> and request a new code. Contact support if this issue persists.
                    </>
                );
            }
        } catch (error) {
            setError(
                <>
                    {error.response.data.message || 'Invalid OTP or server error.'}
                    <br/><br/>
                    You can try again or <a href="#" onClick={handleGoBack}>Go back</a> and request a new code. Contact support if this issue persists.
                </>
            );
        } finally {
            setLoading(false);
        }
    };

    const handleOtpSubmit = async (event) => {
        event.preventDefault();
        setError('');    // Clear error messages
        setSuccess('');  // Clear success messages
        setLoading(true);

        try {
            const response = await api.post('/api/auth/verify', { email, otp: otp.join('') });

            if (response.data.token) {
                // Store the JWT token and user email
                localStorage.setItem('token', response.data.token);
                localStorage.setItem('userEmail', email);  // Store email for mobile mode
                setIsAuthenticated(true);
                navigate('/profile/overview');
            } else {
                setError(
                    <>
                        An unexpected error occurred.
                        <br/><br/>
                        You can try again or <a href="#" onClick={handleGoBack}>Go back</a> and request a new code. Contact support if this issue persists.
                    </>
                );
            }
        } catch (error) {
            if (error.response) {
                setError(
                    <>
                        {error.response.data.message || 'Invalid OTP or server error.'}
                        <br/><br/>
                        You can try again or <a href="#" onClick={handleGoBack}>Go back</a> and request a new code. Contact support if this issue persists.
                    </>
                );
            } else {
                setError(
                    <>
                        An unexpected error occurred.
                        <br/><br/>
                        You can try again or <a href="#" onClick={handleGoBack}>Go back</a> and request a new code. Contact support if this issue persists.
                    </>
                );
            }
        } finally {
            setLoading(false);
        }
    };

    // Handle resetting form and going back to the email input
    const handleGoBack = () => {
        setEmail(''); // Clear email
        setOtp(Array(6).fill('')); // Reset OTP input
        setError(''); // Clear any existing error messages
        setCurrentStep('EMAIL'); // Go back to email step
    };

    const handleInputChange = (index, event) => {
        const newOtp = [...otp];
        newOtp[index] = event.target.value.slice(0, 1);  // Enforce single character
        setOtp(newOtp);

        // Move to next input if the input is filled and not the last one
        if (event.target.value && index < 5) {
            inputRefs.current[index + 1].focus();
        }
    };

    const handleKeyDown = (index, event) => {
        if (event.key === "Backspace" && otp[index] === '') {
            // Focus previous input if current is empty and backspace is pressed
            if (index > 0) {
                inputRefs.current[index - 1].focus();
            }
        }
    };

    const handlePaste = (event) => {
        const paste = event.clipboardData.getData('text').slice(0, 6); // Get first 6 characters
        const newOtp = paste.split('');
        setOtp(newOtp);

        const nextInputIndex = newOtp.length < 6 ? newOtp.length : 5;
        if (inputRefs.current[nextInputIndex]) {
            inputRefs.current[nextInputIndex].focus();
        }

        event.preventDefault(); // Stop default paste action
    };

    return (
        <>
            <div className="body d-flex p-0 p-xl-0">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-6">
                            <div className="d-flex flex-column align-items-start">
                                <h1 className="mb-4">Woodland OT - OT Hub</h1>
                                <h5>Access your Woodland OT services below.</h5>
                                <span className="text-muted">
                                    Enter your email address, and we'll send you a secure one-time password (OTP) or magic link to access the system.
                                </span>
                                <div className="card mt-4 w-100">
                                    <div className="card-body p-4">
                                        {error && <div className="alert alert-danger">{error}</div>}
                                        {success && <div className="alert alert-success">{success}</div>}
                                        {authCheckComplete ? (
                                            isAuthenticated ? (
                                                <div>
                                                    <p>You are already signed in as {authenticatedEmail}. Click below to
                                                        continue to your dashboard.</p>
                                                    <button onClick={() => navigate('/profile/overview')}
                                                            className="btn btn-primary text-uppercase py-2 fs-5 w-100 mt-2">
                                                        Continue
                                                    </button>
                                                </div>
                                            ) : (
                                                <>
                                                    <Form.Group className="mb-4">
                                                        <Form.Label className="d-flex justify-content-between align-items-center">
                                                            <span>View Preference</span>
                                                            <Form.Check
                                                                type="switch"
                                                                id="view-mode-switch"
                                                                label={isMobile ? "Mobile View" : "Desktop View"}
                                                                checked={isMobile}
                                                                onChange={(e) => setIsMobile(e.target.checked)}
                                                                className="mb-0"
                                                            />
                                                        </Form.Label>
                                                    </Form.Group>
                                                    {currentStep === 'EMAIL' && (
                                                        <form onSubmit={handleEmailSubmit}>
                                                            <div className="mb-3">
                                                                <label className="form-label fs-6">Email Address</label>
                                                                <input type="email" 
                                                                       className="form-control"
                                                                       value={email}
                                                                       onChange={e => setEmail(e.target.value)}
                                                                       required/>
                                                            </div>
                                                            <button type="submit"
                                                                    className="btn btn-primary text-uppercase py-2 fs-5 w-100 mt-2"
                                                                    disabled={loading}>
                                                                {loading ? "Loading..." : "Request Access"}
                                                            </button>
                                                        </form>
                                                    )}

                                                    {currentStep === 'OTP' && (
                                                        <form onSubmit={handleOtpSubmit}>
                                                            <div className="mb-3">
                                                                <label className="form-label fs-6">Enter OTP</label>
                                                                <div className="d-flex justify-content-between mb-4">
                                                                    {otp.map((value, index) => (
                                                                        <input
                                                                            key={index}
                                                                            ref={(el) => inputRefs.current[index] = el}
                                                                            type="text"
                                                                            className="form-control form-control-lg text-center"
                                                                            style={{
                                                                                width: "12%",
                                                                                ...(isMobile && {
                                                                                    fontSize: '20px',
                                                                                    padding: '2px',
                                                                                    lineHeight: '1.2'
                                                                                })
                                                                            }}
                                                                            maxLength="1"
                                                                            value={value}
                                                                            onChange={(e) => handleInputChange(index, e)}
                                                                            onKeyDown={(e) => handleKeyDown(index, e)}
                                                                            onPaste={handlePaste}
                                                                            required
                                                                        />
                                                                    ))}
                                                                </div>
                                                            </div>
                                                            <button type="submit"
                                                                    className="btn btn-primary text-uppercase py-2 fs-5 w-100 mt-2"
                                                                    disabled={loading}>
                                                                {loading ? "Loading..." : "Log In"}
                                                            </button>
                                                        </form>
                                                    )}
                                                </>
                                            )
                                        ) : (
                                            <div className="d-flex justify-content-center mt-4">
                                                <div className="spinner-border" role="status">
                                                    <span className="visually-hidden">Loading...</span>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Login;