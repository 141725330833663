import React, { createContext, useContext, useState, useEffect } from 'react';

const GlobalFiltersContext = createContext();

export const useGlobalFilters = () => useContext(GlobalFiltersContext);

export const GlobalFiltersProvider = ({ children }) => {
    const [filters, setFilters] = useState(() => {
        const savedFilters = localStorage.getItem('globalFilters');
        return savedFilters ? JSON.parse(savedFilters) : {
            area: '',
            run: '',
            zone: ''
        };
    });

    // Save filters to localStorage whenever they change
    useEffect(() => {
        localStorage.setItem('globalFilters', JSON.stringify(filters));
    }, [filters]);

    const updateArea = (area) => {
        setFilters(prev => ({
            ...prev,
            area,
            run: '', // Reset dependent filters
            zone: ''
        }));
    };

    const updateRun = (run) => {
        setFilters(prev => ({
            ...prev,
            run,
            zone: '' // Reset dependent filter
        }));
    };

    const updateZone = (zone) => {
        setFilters(prev => ({
            ...prev,
            zone
        }));
    };

    const clearFilters = () => {
        setFilters({
            area: '',
            run: '',
            zone: ''
        });
    };

    return (
        <GlobalFiltersContext.Provider value={{
            filters,
            updateArea,
            updateRun,
            updateZone,
            clearFilters
        }}>
            {children}
        </GlobalFiltersContext.Provider>
    );
}; 