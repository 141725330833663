import React from 'react';
import { useConnectivity } from '../../Contexts/ConnectivityContext';
import { Button, OverlayTrigger, Popover, Badge } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
    faSync, 
    faCircle, 
    faExclamationTriangle, 
    faClock, 
    faCloudUpload,
    faWifi,
    faServer
} from '@fortawesome/free-solid-svg-icons';
import { formatDistanceToNow } from 'date-fns';

const ConnectivityStatus = ({ onRefresh, dataTimestamp }) => {
    const { 
        isOnline, 
        isRefreshing, 
        refreshError, 
        refreshAttempts,
        checkConnectivity 
    } = useConnectivity();

    // Get pending updates from localStorage
    const getPendingUpdates = () => {
        const updates = JSON.parse(localStorage.getItem('pendingWorkOrderUpdates') || '[]');
        return updates.filter(update => {
            const userEmail = localStorage.getItem('userEmail');
            return update.userEmail === userEmail;
        });
    };

    const pendingUpdates = getPendingUpdates();

    const handleRefresh = async () => {
        if (onRefresh) {
            await onRefresh();
        }
        await checkConnectivity();
    };

    const getDataAge = () => {
        if (!dataTimestamp) return null;
        const ageHours = (Date.now() - dataTimestamp) / (1000 * 60 * 60);
        if (ageHours < 1) {
            return `${Math.round(ageHours * 60)}m`;
        }
        return `${Math.round(ageHours)}h`;
    };

    const getTooltipText = () => {
        if (!dataTimestamp) return 'No data loaded yet';
        try {
            return `Last updated ${formatDistanceToNow(dataTimestamp, { addSuffix: true })}`;
        } catch (error) {
            return 'Invalid timestamp';
        }
    };

    return (
        <div className="d-flex justify-content-between align-items-center w-100 connectivity-bar">
            {/* Connection Status */}
            <div className="status-group">
                <OverlayTrigger
                    trigger={['hover', 'focus']}
                    placement="bottom"
                    overlay={
                        <Popover>
                            <Popover.Header>Server Connection</Popover.Header>
                            <Popover.Body>
                                {isOnline ? (
                                    <>
                                        <span className="text-success">● Connected to server</span>
                                        <p>All features are available and working normally</p>
                                    </>
                                ) : (
                                    <>
                                        <span className="text-warning">● Working offline</span>
                                        <p>Some features may be limited until connection is restored</p>
                                    </>
                                )}
                            </Popover.Body>
                        </Popover>
                    }
                >
                    <div className="d-flex align-items-center gap-2">
                        <FontAwesomeIcon 
                            icon={faServer}
                            className={isOnline ? 'text-success' : 'text-warning'}
                            style={{ fontSize: '28px', width: '28px', height: '28px' }}
                        />
                    </div>
                </OverlayTrigger>
            </div>

            {/* Network Status */}
            <div className="status-group">
                <OverlayTrigger
                    trigger={['hover', 'focus']}
                    placement="bottom"
                    overlay={
                        <Popover>
                            <Popover.Header>Network Status</Popover.Header>
                            <Popover.Body>
                                {isOnline ? (
                                    <>
                                        <span className="text-success">● Internet connection active</span>
                                        <p>Your device is connected to the internet</p>
                                    </>
                                ) : (
                                    <>
                                        <span className="text-warning">● No internet connection</span>
                                        <p>Check your network settings or contact your administrator</p>
                                    </>
                                )}
                            </Popover.Body>
                        </Popover>
                    }
                >
                    <div className="d-flex align-items-center gap-2">
                        <FontAwesomeIcon 
                            icon={faWifi}
                            className={isOnline ? 'text-success' : 'text-warning'}
                            style={{ fontSize: '28px', width: '28px', height: '28px' }}
                        />
                    </div>
                </OverlayTrigger>
            </div>

            {/* Refresh Button */}
            <div className="status-group">
                <OverlayTrigger
                    trigger={['hover', 'focus']}
                    placement="bottom"
                    overlay={
                        <Popover>
                            <Popover.Header>Refresh Data</Popover.Header>
                            <Popover.Body>
                                {isRefreshing ? (
                                    'Refreshing data...'
                                ) : refreshAttempts > 2 ? (
                                    <>
                                        <span className="text-warning">● Connection issues detected</span>
                                        <p>Multiple refresh attempts have failed. Check your connection.</p>
                                    </>
                                ) : (
                                    <>
                                        <span className="text-success">● Ready to refresh</span>
                                        <p>Click to refresh data and check connection status</p>
                                    </>
                                )}
                            </Popover.Body>
                        </Popover>
                    }
                >
                    <Button 
                        variant="link"
                        className="p-0 refresh-button"
                        onClick={handleRefresh}
                        disabled={isRefreshing}
                    >
                        <FontAwesomeIcon 
                            icon={faSync} 
                            spin={isRefreshing}
                            className={refreshAttempts > 2 ? 'text-warning' : ''}
                            style={{ fontSize: '28px', width: '28px', height: '28px' }}
                        />
                    </Button>
                </OverlayTrigger>
            </div>

            {/* Pending Updates */}
            <div className="status-group">
                <OverlayTrigger
                    trigger={['hover', 'focus']}
                    placement="bottom"
                    overlay={
                        <Popover>
                            <Popover.Header>Pending Updates</Popover.Header>
                            <Popover.Body>
                                {pendingUpdates.length > 0 ? (
                                    <>
                                        <span className="text-warning">● Updates pending</span>
                                        <p>{pendingUpdates.length} update{pendingUpdates.length > 1 ? 's' : ''} waiting to sync</p>
                                        <small className="text-muted">Updates will sync automatically when connection is restored</small>
                                    </>
                                ) : (
                                    <>
                                        <span className="text-success">● Fully synchronized</span>
                                        <p>All updates have been sent to the server</p>
                                    </>
                                )}
                            </Popover.Body>
                        </Popover>
                    }
                >
                    <div className="position-relative d-flex align-items-center">
                        <FontAwesomeIcon 
                            icon={faCloudUpload}
                            className={pendingUpdates.length > 0 ? 'text-warning' : 'text-success'}
                            style={{ fontSize: '28px', width: '28px', height: '28px' }}
                        />
                        {pendingUpdates.length > 0 && (
                            <Badge 
                                bg="warning" 
                                pill 
                                className="status-badge"
                            >
                                {pendingUpdates.length}
                            </Badge>
                        )}
                    </div>
                </OverlayTrigger>
            </div>

            {/* Last Update Time */}
            <div className="status-group">
                <OverlayTrigger
                    trigger={['hover', 'focus']}
                    placement="bottom"
                    overlay={
                        <Popover>
                            <Popover.Header>Last Update</Popover.Header>
                            <Popover.Body>
                                {getTooltipText()}
                            </Popover.Body>
                        </Popover>
                    }
                >
                    <div className="d-flex align-items-center gap-2">
                        <FontAwesomeIcon 
                            icon={faClock} 
                            style={{ fontSize: '28px', width: '28px', height: '28px' }}
                        />
                        <span className="status-text">{getDataAge()}</span>
                    </div>
                </OverlayTrigger>
            </div>
        </div>
    );
};

export default ConnectivityStatus; 