import React, {useState, useEffect} from 'react';
import {useAuth} from '../../Contexts/AuthContext';
import SignOut from '../Auth/SignOut';
import {useToast} from '../../Contexts/ToastContext';
import {Link, useNavigate} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faUser, faSignInAlt, faHomeAlt, faSignOutAlt, faOilWell, faDatabase, faWarehouse, faClipboardList, faUsers, faCalendarDay } from '@fortawesome/free-solid-svg-icons';
import WoodlandLogoBlack from "../../assets/images/woodland-logo-1024x155.png";
import WoodlandLogoWhite from "../../assets/images/woodland-logo-1024x155.png";
import WoodlandSmallLogoBlack from "../../assets/images/woodland-w.png";
import WoodlandSmallLogoWhite from "../../assets/images/woodland-w.png";

// Create a map of icons
const icons = {
    "faUser": faUser,
    "faSignInAlt": faSignInAlt,
    "faHomeAlt": faHomeAlt,
    "faSignOutAlt": faSignOutAlt,
    "faOilWell": faOilWell,
    "faDatabase": faDatabase,
    "faWarehouse": faWarehouse,
    "faClipboardList": faClipboardList,
    "faUsers": faUsers,
    "faCalendarDay": faCalendarDay,
};

function Sidebar(props) {
    const {activekey, menuData, GotoChangeMenu, initIsSidebarMini} = props;
    const {isAuthenticated, authCheckComplete, authenticatedEmail} = useAuth();
    const {addToast} = useToast();
    const [isSidebarMini, setIsSidebarMini] = useState(initIsSidebarMini);

    const ChangeSideBar = (setVal) => {
        localStorage.setItem("isSideBarMini", !setVal);
        setIsSidebarMini(!setVal)
    }

    const navigate = useNavigate();
    const baseUrl = process.env.PUBLIC_URL;

    const getLogo = () => {
        const mode = document.documentElement.getAttribute("data-theme");
        if (isSidebarMini) {
            return mode === 'light' ? WoodlandSmallLogoBlack : WoodlandSmallLogoWhite;
        } else {
            return mode === 'light' ? WoodlandLogoBlack : WoodlandLogoWhite;
        }
    }

    const filteredMenuData = menuData.filter(item =>
        item.authenticated === null ||
        item.authenticated === isAuthenticated
    );

    return (<div id="mainsidemenu"
                 className={`sidebar py-2 py-md-2 me-0 border-end ${isSidebarMini === true ? "sidebar-mini" : ""}`}>
            <div className="d-flex flex-column h-100">
                <Link to="/" className="mb-0 brand-icon">
                <span className="logo-icon">
                    <img className="logo-image" src={getLogo()} alt=""/>
                </span>
                </Link>
                {isAuthenticated && !isSidebarMini && (
                    <div className="user-info px-3 mt-3 d-flex align-items-center">
                        <FontAwesomeIcon icon={faUser} className="me-2" />
                        <span className="small">{authenticatedEmail}</span>
                    </div>
                )}
                <hr className="custom-separator my-2" />

                <ul className={`menu-list flex-grow-1 ${isSidebarMini ? 'mt-2' : 'mt-1'} px-1`}>
                    {authCheckComplete && filteredMenuData.map((item, i) => (
                        <li key={i}>
                            <Link to={"/" + item.routerLink[0]}
                                  className={`m-link ${("/" + item.routerLink[0] === activekey) ? "active" : ""}`}>
                                <FontAwesomeIcon icon={icons[item.icon]} className="me-2"/>
                                <span>{item.name}</span>
                            </Link>
                        </li>
                    ))}
                    {isAuthenticated && <SignOut />}
                </ul>
                <button type="button" className="btn btn-link sidebar-mini-btn text-muted" onClick={() => {
                    ChangeSideBar(isSidebarMini)
                }}>
                    <span className="ms-2"><i className="icofont-bubble-right"></i></span>
                </button>
            </div>
        </div>
    )
}

export default Sidebar;