import React, { useState, useEffect } from 'react';
import { ListGroup, Alert, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faLocationArrow } from '@fortawesome/free-solid-svg-icons';
import { FormControl, InputLabel, Select, MenuItem, TextField, Box } from '@mui/material';
import api from '../../Component/Common/axiosSetup';
import { useGlobalFilters } from '../../Contexts/GlobalFiltersContext';
import { useToast } from '../../Contexts/ToastContext';
import { useNavigate } from 'react-router-dom';
import { useConnectivity } from '../../Contexts/ConnectivityContext';
import ConnectivityStatus from '../../Component/Common/ConnectivityStatus';
import { calculateDistance, getNavigationLink, useUserLocation } from '../../Utility/navigationUtils';
import { fetchAndStoreFacilities, getFacilityData, refreshFacilityData } from '../../Utility/facilityStorage';

function FacilityManagement({ setRefreshHandler, setLastFetchTime }) {
    const [facilities, setFacilities] = useState([]);
    const [filteredFacilities, setFilteredFacilities] = useState([]);
    const [metadata, setMetadata] = useState({
        areas: [],
        runs: [],
        zones: [],
        runsByArea: {},
        zonesByRun: {}
    });
    const [error, setError] = useState('');
    const { filters, updateArea, updateRun, updateZone } = useGlobalFilters();
    const [searchQuery, setSearchQuery] = useState(localStorage.getItem('facilitySearchQuery') || '');
    const { addToast } = useToast();
    const navigate = useNavigate();
    const { isOnline } = useConnectivity();
    const userLocation = useUserLocation();

    // Save search query to local storage when it changes
    useEffect(() => {
        localStorage.setItem('facilitySearchQuery', searchQuery);
    }, [searchQuery]);

    // Handle refresh
    const handleRefresh = async () => {
        try {
            await loadFacilities(true);
        } catch (error) {
            console.error('Error during refresh:', error);
        }
    };

    // Load facilities based on filters
    const loadFacilities = async (forceRefresh = false) => {
        try {
            setError('');
            
            let facilityData;
            let timestamp;

            if (!forceRefresh) {
                const storedData = getFacilityData();
                if (storedData && !storedData.isStale) {
                    facilityData = storedData.data;
                    timestamp = storedData.timestamp;
                } else {
                    facilityData = await fetchAndStoreFacilities(addToast, isOnline);
                    const freshData = getFacilityData();
                    timestamp = freshData.timestamp;
                }
            } else {
                facilityData = await refreshFacilityData(addToast, isOnline);
                const freshData = getFacilityData();
                timestamp = freshData.timestamp;
            }

            if (facilityData?.facilities) {
                setFacilities(facilityData.facilities);
                setFilteredFacilities(facilityData.facilities);
            }

            if (facilityData?.metadata) {
                setMetadata(facilityData.metadata);
            }

            // Update last fetch time with the timestamp from storage
            setLastFetchTime(timestamp);

            // Only show success toast if we're online and got fresh data
            if (isOnline && forceRefresh) {
                addToast('Data refreshed successfully', {
                    color: 'success',
                    autoHide: true,
                    delay: 3000
                });
            }
        } catch (error) {
            const errorMessage = 'Error fetching facilities. Please try again later.';
            setError(errorMessage);
            addToast(errorMessage, {
                autoHide: true,
                delay: 5000,
                color: 'danger',
                requireAck: true
            });
            console.error('Error fetching facilities:', error);
            if (error.response?.status === 401) {
                navigate('/login');
            }
        }
    };

    // Apply filters and search
    useEffect(() => {
        let filtered = [...facilities];

        // Apply area filter
        if (filters.area) {
            filtered = filtered.filter(f => f.data?.area === filters.area);
        }

        // Apply run filter
        if (filters.run) {
            filtered = filtered.filter(f => f.data?.run === filters.run);
        }

        // Apply zone filter
        if (filters.zone) {
            filtered = filtered.filter(f => f.data?.zone === filters.zone);
        }

        // Apply search query
        if (searchQuery) {
            const query = searchQuery.toLowerCase();
            filtered = filtered.filter(f => 
                f.data?.facility_name?.toLowerCase().includes(query) ||
                f.data?.uwi?.toLowerCase().includes(query)
            );
        }

        setFilteredFacilities(filtered);
    }, [searchQuery, facilities, filters]);

    // Initial data load
    useEffect(() => {
        loadFacilities();
    }, []);

    // Register refresh handler
    useEffect(() => {
        setRefreshHandler(() => handleRefresh);
    }, [setRefreshHandler]);

    // Format UUID to show first 4 and last 4 chars
    const formatUUID = (uuid) => {
        if (!uuid) return '';
        return `${uuid.slice(0, 4)}-${uuid.slice(-4)}`;
    };

    const handleNavigationClick = (e, facility) => {
        e.stopPropagation();
        if (!facility.data?.surface_latitude || !facility.data?.surface_longitude) {
            addToast('Coordinates not available for this site. Please contact support to get this added.', {
                color: 'warning',
                autoHide: true,
                delay: 5000
            });
            return;
        }
        window.open(getNavigationLink(facility.data.surface_latitude, facility.data.surface_longitude), '_blank');
    };

    return (
        <div className="container-fluid p-0">
            {/* Header */}
            <div className="px-3 pt-2">
                <h4 className="mb-1">Asset Management</h4>
                <hr className="custom-separator"/>
            </div>

            {/* Error Alert */}
            {error && (
                <div className="px-3 mb-3">
                    <Alert variant="danger" onClose={() => setError('')} dismissible>
                        {error}
                    </Alert>
                </div>
            )}

            {/* Filters */}
            <div className="px-3">
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                    {/* Dropdowns row */}
                    <Box sx={{ display: 'flex', gap: 2 }}>
                        <FormControl sx={{ flex: 1 }}>
                            <InputLabel>Area</InputLabel>
                            <Select
                                value={filters.area || ''}
                                label="Area"
                                onChange={(e) => {
                                    updateArea(e.target.value);
                                }}
                                sx={{
                                    '.MuiSelect-select': {
                                        fontSize: '0.875rem'  // Makes selected text smaller
                                    }
                                }}
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {metadata.areas.map((area) => (
                                    <MenuItem key={area} value={area}>
                                        {area}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <FormControl sx={{ flex: 1 }}>
                            <InputLabel>Run</InputLabel>
                            <Select
                                value={filters.run || ''}
                                label="Run"
                                onChange={(e) => {
                                    updateRun(e.target.value);
                                }}
                                disabled={!filters.area}
                                sx={{
                                    '.MuiSelect-select': {
                                        fontSize: '0.875rem'  // Makes selected text smaller
                                    }
                                }}
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {filters.area && metadata.runsByArea[filters.area]?.map((run) => (
                                    <MenuItem key={run} value={run}>
                                        {run}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <FormControl sx={{ flex: 1 }}>
                            <InputLabel>Zone</InputLabel>
                            <Select
                                value={filters.zone || ''}
                                label="Zone"
                                onChange={(e) => {
                                    updateZone(e.target.value);
                                }}
                                disabled={!filters.run}
                                sx={{
                                    '.MuiSelect-select': {
                                        fontSize: '0.875rem'  // Makes selected text smaller
                                    }
                                }}
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {filters.run && metadata.zonesByRun[filters.run]?.map((zone) => (
                                    <MenuItem key={zone} value={zone}>
                                        {zone}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>

                    {/* Search bar row */}
                    <TextField
                        fullWidth
                        size="small"
                        label="Search by name or UWI"
                        variant="outlined"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        sx={{ 
                            '& .MuiOutlinedInput-root': {
                                height: '56px'
                            }
                        }}
                    />
                </Box>
            </div>

            {/* Facility List */}
            <div className="mt-3">
                <ListGroup variant="flush">
                    {filteredFacilities.map(facility => {
                        const distance = userLocation && facility.data?.surface_latitude && facility.data?.surface_longitude
                            ? calculateDistance(
                                userLocation.lat,
                                userLocation.lon,
                                parseFloat(facility.data.surface_latitude),
                                parseFloat(facility.data.surface_longitude)
                            )
                            : null;

                        return (
                            <ListGroup.Item key={facility.id} className="facility-item px-3">
                                <div className="d-flex justify-content-between align-items-start mb-2">
                                    <h6 className="mb-0">{facility.data?.facility_name}</h6>
                                    <small className="text-muted">{formatUUID(facility.id)}</small>
                                </div>
                                <div className="facility-details">
                                    <div className="mb-1">
                                        <small className="text-muted">
                                            {facility.data?.area || '-'} | {facility.data?.subarea || '-'} | {facility.data?.run || '-'} | {facility.data?.zone || '-'}
                                        </small>
                                    </div>
                                    <div className="mb-1">
                                        <strong>UWI:</strong> {facility.data?.uwi || '-'}
                                    </div>
                                    <div className="mb-1">
                                        <Button
                                            variant="outline-secondary"
                                            size="sm"
                                            onClick={(e) => handleNavigationClick(e, facility)}
                                        >
                                            <FontAwesomeIcon icon={faLocationArrow} className="me-2" />
                                            Navigate {distance ? `(${distance}km)` : ''}
                                        </Button>
                                    </div>
                                    <div className="mb-1">
                                        <strong>Type:</strong> {facility.type || '-'}
                                    </div>
                                    <div className="mb-1">
                                        <strong>Cost Center:</strong> {facility.data?.cost_center || '-'}
                                    </div>
                                    <div className="mb-1">
                                        <strong>WDC WI:</strong> {facility.data?.wdc_working_interest ? `${facility.data.wdc_working_interest}%` : '-'}
                                    </div>
                                    <div className="mb-1">
                                        <strong>Non Op:</strong> {facility.data?.joa_op_non_op || '-'}
                                    </div>
                                    <div className="mb-1">
                                        <strong>Maint. Profile:</strong> {facility.data?.maintenance_profile_days ? `${facility.data.maintenance_profile_days} days` : '-'}
                                    </div>
                                    <div>
                                        <strong>Maint. Base Date:</strong> {facility.data?.maintenance_base_date || '-'}
                                    </div>
                                </div>
                            </ListGroup.Item>
                        );
                    })}
                    {filteredFacilities.length === 0 && !error && (
                        <ListGroup.Item className="text-center py-4">
                            <p className="text-muted mb-0">No facilities found</p>
                        </ListGroup.Item>
                    )}
                </ListGroup>
            </div>
        </div>
    );
}

export default FacilityManagement; 