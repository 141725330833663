import { useState, useEffect } from 'react';
import { useAuth } from '../Contexts/AuthContext';

// Shared state between hook instances
const subscribers = new Set();
let globalFilters = null;

const useGlobalFilters = () => {
    const { authenticatedEmail } = useAuth();
    
    // Initialize state from localStorage first, then fallback to global state
    const getStoredFilters = () => {
        if (!authenticatedEmail) return { area: '', run: '', zone: '' };
        
        // Always check localStorage first
        const stored = localStorage.getItem(`global_filterObject_${authenticatedEmail}`);
        if (stored) {
            const filters = JSON.parse(stored);
            globalFilters = filters; // Update global state from localStorage
            return filters;
        }
        
        // Fallback to global state or default
        return globalFilters || { area: '', run: '', zone: '' };
    };

    const [filters, setFilters] = useState(getStoredFilters);

    // Subscribe to changes
    useEffect(() => {
        const updateState = (newFilters) => {
            setFilters(newFilters);
        };
        subscribers.add(updateState);
        return () => subscribers.delete(updateState);
    }, []);

    // Update localStorage and notify subscribers whenever filters change
    const updateFilters = (newFilters) => {
        if (authenticatedEmail) {
            console.log('Updating filters to:', newFilters);
            localStorage.setItem(`global_filterObject_${authenticatedEmail}`, JSON.stringify(newFilters));
            globalFilters = newFilters;
            subscribers.forEach(subscriber => subscriber(newFilters));
        }
    };

    const updateArea = (area) => {
        console.log('updateArea called with:', area);
        const newFilters = { area, run: '', zone: '' };
        updateFilters(newFilters);
    };

    const updateRun = (run) => {
        console.log('updateRun called with:', run, 'current filters:', filters);
        const newFilters = { area: filters.area, run, zone: '' };
        updateFilters(newFilters);
    };

    const updateZone = (zone) => {
        console.log('updateZone called with:', zone, 'current filters:', filters);
        const newFilters = { area: filters.area, run: filters.run, zone };
        updateFilters(newFilters);
    };

    const clearFilters = () => {
        const newFilters = { area: '', run: '', zone: '' };
        updateFilters(newFilters);
    };

    // Re-initialize when email changes
    useEffect(() => {
        const newFilters = getStoredFilters();
        setFilters(newFilters);
        globalFilters = newFilters;
    }, [authenticatedEmail]);

    return {
        filters,
        updateArea,
        updateRun,
        updateZone,
        clearFilters
    };
};

export default useGlobalFilters; 