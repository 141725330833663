import axios from 'axios';

// Create an Axios instance if you don't already have one
const api = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_API_URL,
    headers: {
        'x-env': process.env.REACT_APP_ENV
    }
});

if (process.env.REACT_APP_ENV === 'development' && process.env.REACT_APP_LOG_AXIOS || 'false' === 'true') {
// Request interceptor
    api.interceptors.request.use(request => {
        console.log('Starting Request', JSON.stringify(request, null, 2));
        return request;
    }, error => {
        console.log('Request Error:', error);
        return Promise.reject(error);
    });

// Response interceptor
    api.interceptors.response.use(
        (response) => response,
        (error) => {
            // Let AuthContext handle 401 responses
            if (error.response?.status === 401) {
                // Keep localStorage items (like pendingWorkOrderUpdates) but clear the token
                localStorage.removeItem('token');
                localStorage.removeItem('userEmail');
                
                // Let AuthContext handle the redirect and messaging
                if (window.handleSessionTimeout) {
                    window.handleSessionTimeout();
                }
            }
            return Promise.reject(error);
        }
    );
}

export default api;
