import React, { useState } from 'react';
import { useAuth } from '../../Contexts/AuthContext';
import { Link, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog } from '@fortawesome/free-solid-svg-icons';
import { Dropdown } from 'react-bootstrap';
import ConnectivityStatus from './ConnectivityStatus';
import ConnectionInfoModal from './ConnectionInfoModal';
import WoodlandLogoBlack from "../../assets/images/woodland-logo-1024x155.png";
import WoodlandLogoWhite from "../../assets/images/woodland-logo-1024x155.png";

function Header(props) {
    const { onModalOpen, GotoChangeMenu, onRefresh, dataTimestamp } = props;
    const { isAuthenticated, authCheckComplete, authenticatedEmail } = useAuth();
    const isMobileView = localStorage.getItem("isMobileView") === "true";
    const location = useLocation();
    const [showConnectionInfo, setShowConnectionInfo] = useState(false);

    const getLogo = () => {
        const mode = document.documentElement.getAttribute("data-theme");
        return mode === 'light' ? WoodlandLogoBlack : WoodlandLogoWhite;
    };

    return (
        <div className="header">
            <nav className="navbar py-4">
                <div className="container-xxl">
                    {/* Left side - Connectivity Status */}
                    <div className="order-1 d-flex align-items-center flex-grow-1">
                        {/* Mobile View */}
                        {isMobileView && (
                            <div className="d-md-none w-100">
                                <div className="d-flex justify-content-between align-items-center mb-2">
                                    <Dropdown>
                                        <Dropdown.Toggle as="button" className="btn btn-link p-0 border-0 menu-toggle" style={{ fontSize: '1.75rem' }}>
                                            <FontAwesomeIcon icon={faCog} />
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu className="dropdown-menu-end shadow">
                                            <Dropdown.Item onClick={() => onModalOpen(true)}>
                                                Settings
                                            </Dropdown.Item>
                                            <Dropdown.Item onClick={() => setShowConnectionInfo(true)}>
                                                Connection Info
                                            </Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>

                                    <img 
                                        src={getLogo()} 
                                        alt="Woodland Logo" 
                                        style={{ height: '32px', width: '180px', objectFit: 'contain', marginTop: '-4px' }} 
                                    />

                                    <button 
                                        className="navbar-toggler p-0 border-0 menu-toggle" 
                                        type="button" 
                                        onClick={() => {
                                            var sidebar = document.getElementById('mainsidemenu')
                                            if (sidebar) {
                                                sidebar.classList.toggle('open')
                                            }
                                        }}
                                        style={{ fontSize: '1.75rem' }}
                                    >
                                        <span className="fa fa-bars"></span>
                                    </button>
                                </div>
                                <div className="mobile-connectivity" style={{
                                    background: 'rgba(0, 77, 64, 0.5)',
                                    padding: '1rem',
                                    borderRadius: '8px',
                                    marginTop: '0.75rem',
                                    border: '1px solid rgba(0, 77, 64, 0.3)',
                                    backdropFilter: 'blur(8px)'
                                }}>
                                    <ConnectivityStatus 
                                        onRefresh={onRefresh}
                                        dataTimestamp={dataTimestamp}
                                    />
                                </div>
                            </div>
                        )}
                    </div>

                    {/* Right side - Settings (Desktop only) */}
                    {!isMobileView && (
                        <div className="order-2 d-flex align-items-center justify-content-end">
                            <Dropdown>
                            <Dropdown.Toggle as="button" className="btn btn-link p-0 border-0" style={{ fontSize: '1.75rem' }}>
                                    <FontAwesomeIcon icon={faCog} />
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="dropdown-menu-end shadow">
                                    <Dropdown.Item onClick={() => onModalOpen(true)}>
                                        Settings
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    )}
                </div>
            </nav>

            {/* Connection Info Modal */}
            <ConnectionInfoModal 
                show={showConnectionInfo}
                onHide={() => setShowConnectionInfo(false)}
            />
        </div>
    );
}

export default Header;