import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Box, Button } from '@mui/material';
import api from '../Common/axiosSetup';
import { useToast } from '../../Contexts/ToastContext';
import { useTheme } from '@mui/material/styles';
import DataTable from '../Common/DataTable';
import AddUser from './AddUser';
import { GridToolbar } from '@mui/x-data-grid';

const UserDataTable = () => {
    const [rows, setRows] = useState([]);
    const [selectedRowIds, setSelectedRowIds] = useState([]);
    const [openAdd, setOpenAdd] = useState(false);
    const [loading, setLoading] = useState(true);
    const [metadata, setMetadata] = useState({});
    const [isOutOfSync, setIsOutOfSync] = useState(false);
    const [isChecking, setIsChecking] = useState(false);
    const checkTimeoutRef = useRef(null);

    const { addToast } = useToast();
    const theme = useTheme();

    const fetchMetadata = async () => {
        try {
            const token = localStorage.getItem('token');
            const response = await api.get('/api/data-registry/metadata', {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                params: {
                    table_name: 'users'
                }
            });
            setMetadata(response.data);
        } catch (error) {
            console.error('Error fetching metadata:', error);
            addToast('Error fetching metadata', {
                color: 'danger',
                autoHide: true,
                delay: 5000
            });
        }
    };

    const fetchUsers = async () => {
        try {
            setLoading(true);
            const token = localStorage.getItem('token');
            const response = await api.get('/api/user', {
                headers: { Authorization: `Bearer ${token}` }
            });
            setRows(response.data);
        } catch (error) {
            console.error('Error fetching users:', error);
            addToast('Error refreshing users data', {
                color: 'danger',
                autoHide: true,
                delay: 5000
            });
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchUsers();
        fetchMetadata();
    }, []);

    const columns = [
        {
            field: 'id',
            headerName: 'ID',
            headerAlign: 'center',
            align: 'center',
            flex: 1
        },
        {
            field: 'first_name',
            headerName: 'First Name',
            headerAlign: 'center',
            align: 'left',
            flex: 1.5,
        },
        {
            field: 'last_name',
            headerName: 'Last Name',
            headerAlign: 'center',
            align: 'left',
            flex: 1.5,
        },
        {
            field: 'email',
            headerName: 'Email',
            headerAlign: 'center',
            align: 'left',
            flex: 2,
        },
        {
            field: 'phone_number',
            headerName: 'Phone Number',
            headerAlign: 'center',
            align: 'left',
            flex: 1.5,
        },
        {
            field: 'assigned_zones',
            headerName: 'Assigned Zones',
            headerAlign: 'center',
            align: 'left',
            flex: 2
        },
        {
            field: 'schedule_tracking',
            headerName: 'Schedule Tracking',
            headerAlign: 'center',
            align: 'center',
            flex: 1,
        }
    ];    

    const handleAddUser = async (newUser) => {
        try {
            setRows(prev => [...prev, newUser]);
        } catch (error) {
            console.error('Error processing new user:', error);
            addToast('Error processing new user data', {
                color: 'danger',
                autoHide: false,
                delay: 15000,
                requireAck: true
            });
        }
    };

    const handleRowUpdate = async (id, modifiedFields) => {
        try {
            const token = localStorage.getItem('token');
            
            const userData = {
                id: id,
                ...modifiedFields
            };
            
            const response = await api.put(`/api/user/${id}`, userData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            });

            setRows(prevRows => prevRows.map(row => 
                row.id === id ? { ...row, ...modifiedFields, update_timestamp: response.data.update_timestamp } : row
            ));

            addToast('User updated successfully', {
                color: 'success',
                autoHide: true,
                delay: 15000,
                requireAck: false
            });

            return { ...modifiedFields, update_timestamp: response.data.update_timestamp };

        } catch (error) {
            const errorMessage = error.response?.data?.message;
            const errorDetails = error.response?.data?.errors?.join('\n• ');
            const fullError = errorDetails 
                ? `${errorMessage}:\n• ${errorDetails}` 
                : (errorMessage || 'Error updating user');
                
            addToast(fullError, {
                color: 'danger',
                autoHide: false,
                delay: 15000,
                requireAck: true
            });
            throw error;
        }
    };

    const handleRowDelete = async (id) => {
        try {
            const token = localStorage.getItem('token');
            await api.delete(`/api/user/${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            });

            setRows(prev => prev.filter(row => row.id !== id));

            addToast('User deleted successfully', {
                color: 'success',
                autoHide: true,
                delay: 15000,
                requireAck: false
            });
        } catch (error) {
            const errorMessage = error.response?.data?.message;
            const errorDetails = error.response?.data?.errors?.join('\n• ');
            const fullError = errorDetails 
                ? `${errorMessage}:\n• ${errorDetails}` 
                : (errorMessage || 'Error deleting user');
                
            addToast(fullError, {
                color: 'danger',
                autoHide: false,
                delay: 15000,
                requireAck: true
            });
            throw error;
        }
    };

    const checkVersions = useCallback(async () => {
        if (isChecking || !rows.length) return;
        
        try {
            setIsChecking(true);
            const token = localStorage.getItem('token');
            const response = await api.get('/api/user', {
                headers: { Authorization: `Bearer ${token}` }
            });

            const freshData = response.data;
            const hasChanges = freshData.some(freshRow => {
                const currentRow = rows.find(r => r.id === freshRow.id);
                return !currentRow || 
                    new Date(currentRow.update_timestamp) < new Date(freshRow.update_timestamp);
            });

            setIsOutOfSync(hasChanges);
        } catch (error) {
            console.error('Error checking versions:', error);
        } finally {
            setIsChecking(false);
        }
    }, [rows, isChecking]);

    useEffect(() => {
        const startChecking = () => {
            checkTimeoutRef.current = setTimeout(async () => {
                await checkVersions();
                startChecking();
            }, 120000); // Check every 120 seconds
        };

        startChecking();

        return () => {
            if (checkTimeoutRef.current) {
                clearTimeout(checkTimeoutRef.current);
            }
        };
    }, [checkVersions]);

    const handleRefresh = async () => {
        await fetchUsers();
        setIsOutOfSync(false);
    };

    return (
        <Box sx={{ 
            height: 'calc(100vh - 280px)', 
            width: '100%', 
            padding: theme.spacing(2),
            backgroundColor: theme.palette.background.default,
            borderRadius: theme.shape.borderRadius,
            boxShadow: theme.shadows[1],
            display: 'flex',
            flexDirection: 'column',
            gap: theme.spacing(2),
            overflow: 'hidden'
        }}>
            <Box sx={{ 
                display: 'flex', 
                justifyContent: 'space-between', 
                gap: theme.spacing(2)
            }}>
                <Box sx={{ display: 'flex', gap: theme.spacing(2) }}>
                    <Button variant="contained" onClick={() => setOpenAdd(true)}>Add User</Button>
                </Box>
            </Box>
            <Box sx={{ 
                flex: 1,
                minHeight: 0,
                overflow: 'hidden'
            }}>
                <DataTable
                    rows={rows}
                    columns={columns}
                    loading={loading}
                    metadata={metadata}
                    tableId="user_management"
                    checkboxSelection={false}
                    onRowSelectionModelChange={(ids) => setSelectedRowIds(ids)}
                    rowSelectionModel={selectedRowIds}
                    slots={{ toolbar: GridToolbar }}
                    filterMode="client"
                    disableMultipleColumnsFiltering={false}
                    disableMultipleSelection={true}
                    enableInlineEdit={true}
                    onRowUpdate={handleRowUpdate}
                    onRowDelete={handleRowDelete}
                    nonEditableFields={['id', 'email', 'insert_timestamp', 'update_timestamp']}
                    enableVersionCheck={true}
                    isOutOfSync={isOutOfSync}
                    onRefresh={handleRefresh}
                    disableDelete={true}
                />
            </Box>
            <AddUser 
                open={openAdd} 
                onClose={() => setOpenAdd(false)} 
                onAdd={handleAddUser} 
            />
        </Box>
    );
};

export default UserDataTable; 