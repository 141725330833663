import React, { useState, useEffect } from 'react';
import { Modal, Button, ListGroup } from 'react-bootstrap';
import { useConnectivity } from '../../Contexts/ConnectivityContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle, faSync } from '@fortawesome/free-solid-svg-icons';

const ConnectionInfoModal = ({ show, onHide }) => {
    const { isOnline, lastPingTime, checkConnectivity } = useConnectivity();
    const [googleOnline, setGoogleOnline] = useState(false);
    const [lastDataAge, setLastDataAge] = useState('');
    const [isChecking, setIsChecking] = useState(false);
    const [locationInfo, setLocationInfo] = useState(null);
    const [networkInfo, setNetworkInfo] = useState(null);
    const [locationError, setLocationError] = useState(null);

    // Separate network info handling
    useEffect(() => {
        if ('connection' in navigator) {
            const connection = navigator.connection;
            const updateConnectionInfo = () => {
                setNetworkInfo({
                    type: connection?.type || 'unknown',
                    effectiveType: connection?.effectiveType || 'unknown',
                    downlink: connection?.downlink || 'unknown',
                    rtt: connection?.rtt || 'unknown',
                    signalStrength: connection?.signalStrength || 'N/A',
                    quality: calculateConnectionQuality(connection)
                });
            };

            updateConnectionInfo();
            connection?.addEventListener('change', updateConnectionInfo);
            return () => connection?.removeEventListener('change', updateConnectionInfo);
        }
    }, []);

    // Separate location handling with improved error handling and caching
    useEffect(() => {
        let locationUpdateInterval;

        const getCachedLocation = () => {
            try {
                const cachedLocation = localStorage.getItem('lastKnownLocation');
                if (cachedLocation) {
                    const location = JSON.parse(cachedLocation);
                    if (Date.now() - location.timestamp < 24 * 60 * 60 * 1000) {
                        return location;
                    }
                }
            } catch (error) {
                console.error('Error reading cached location:', error);
            }
            return null;
        };

        const updateLocation = async () => {
            if (!('geolocation' in navigator)) {
                setLocationError('Geolocation is not supported by your browser');
                const cached = getCachedLocation();
                if (cached) setLocationInfo(cached);
                return;
            }

            try {
                const position = await new Promise((resolve, reject) => {
                    navigator.geolocation.getCurrentPosition(
                        resolve,
                        reject,
                        {
                            enableHighAccuracy: true,
                            timeout: 10000,
                            maximumAge: 300000 // 5 minutes
                        }
                    );
                });

                const newLocation = {
                    latitude: position.coords.latitude,
                    longitude: position.coords.longitude,
                    accuracy: position.coords.accuracy,
                    timestamp: Date.now()
                };

                setLocationInfo(newLocation);
                setLocationError(null);
                localStorage.setItem('lastKnownLocation', JSON.stringify(newLocation));
            } catch (error) {
                console.warn('Error getting current location:', error);
                setLocationError(error.message);
                
                // Try to use cached location
                const cached = getCachedLocation();
                if (cached) {
                    setLocationInfo(cached);
                } else {
                    setLocationInfo(null);
                }
            }
        };

        // Initial update
        if (show) {
            updateLocation();
            // Update every 5 minutes while modal is open
            locationUpdateInterval = setInterval(updateLocation, 5 * 60 * 1000);
        }

        return () => {
            if (locationUpdateInterval) {
                clearInterval(locationUpdateInterval);
            }
        };
    }, [show]);

    // Calculate connection quality based on available metrics
    const calculateConnectionQuality = (connection) => {
        if (!connection) return 'Unknown';

        // If we have RTT and downlink, use them to estimate quality
        if (connection.rtt && connection.downlink) {
            if (connection.rtt < 50 && connection.downlink >= 10) return 'Excellent';
            if (connection.rtt < 100 && connection.downlink >= 5) return 'Good';
            if (connection.rtt < 200 && connection.downlink >= 2) return 'Fair';
            return 'Poor';
        }

        // Fallback to effectiveType if available
        if (connection.effectiveType) {
            switch (connection.effectiveType) {
                case '4g': return 'Good';
                case '3g': return 'Fair';
                case '2g': return 'Poor';
                case 'slow-2g': return 'Very Poor';
                default: return 'Unknown';
            }
        }

        return 'Unknown';
    };

    // Check if Google is reachable
    const checkInternet = async () => {
        try {
            // First check navigator.onLine
            if (!navigator.onLine) {
                setGoogleOnline(false);
                return;
            }

            // Try to fetch with a timeout
            const controller = new AbortController();
            const timeoutId = setTimeout(() => controller.abort(), 5000);

            try {
                // Try multiple endpoints in case one is blocked
                const endpoints = [
                    'https://www.google.com/favicon.ico',
                    'https://www.cloudflare.com/cdn-cgi/trace',
                    'https://www.apple.com/favicon.ico'
                ];

                for (const endpoint of endpoints) {
                    try {
                        const response = await fetch(endpoint, {
                            mode: 'no-cors', // This is needed for cross-origin requests
                            signal: controller.signal,
                            cache: 'no-store'
                        });
                        // With no-cors, we can't check response.ok, but if we get here without throwing, we're online
                        setGoogleOnline(true);
                        return;
                    } catch (e) {
                        console.log(`Failed to fetch ${endpoint}:`, e);
                        continue;
                    }
                }
                
                // If we get here, all endpoints failed
                setGoogleOnline(false);
            } catch (error) {
                console.log('Internet check failed:', error);
                setGoogleOnline(false);
            } finally {
                clearTimeout(timeoutId);
            }
        } catch (error) {
            console.log('Internet check error:', error);
            setGoogleOnline(false);
        }
    };

    // Check both connections
    const checkConnections = async () => {
        setIsChecking(true);
        await checkInternet();
        await checkConnectivity();
        setIsChecking(false);
    };

    // Check connections when modal opens and periodically
    useEffect(() => {
        if (show) {
            checkConnections();
            const interval = setInterval(checkConnections, 10000); // Check every 10 seconds
            return () => clearInterval(interval);
        }
    }, [show]);

    // Format last ping time
    useEffect(() => {
        if (!lastPingTime) return;

        const updateAge = () => {
            const ageMs = Date.now() - lastPingTime;
            let age;

            if (ageMs < 60000) { // Less than a minute
                age = `${Math.floor(ageMs / 1000)} seconds ago`;
            } else if (ageMs < 3600000) { // Less than an hour
                age = `${Math.floor(ageMs / 60000)} minutes ago`;
            } else if (ageMs < 86400000) { // Less than a day
                age = `${Math.floor(ageMs / 3600000)} hours ago`;
            } else {
                age = `${Math.floor(ageMs / 86400000)} days ago`;
            }
            setLastDataAge(age);
        };

        updateAge();
        const interval = setInterval(updateAge, 1000);
        return () => clearInterval(interval);
    }, [lastPingTime]);

    // Generate error information for email
    const getErrorInfo = () => {
        // Try to get cached location if current location is not available
        let locationToUse = locationInfo;
        if (!locationToUse) {
            const cachedLocation = localStorage.getItem('lastKnownLocation');
            if (cachedLocation) {
                const cached = JSON.parse(cachedLocation);
                // Only use cached location if it's less than 24 hours old
                if (Date.now() - cached.timestamp < 24 * 60 * 60 * 1000) {
                    locationToUse = cached;
                }
            }
        }

        const info = [
            'Please provide a brief description of the issues you are experiencing:',
            '',
            '',
            '----------------------------------------',
            'Connection Information',
            '----------------------------------------',
            '',
            `App Status: ${isOnline ? 'Online' : 'Offline'}`,
            `Internet Status: ${googleOnline ? 'Online' : 'Offline'}`,
            `Last Server Contact: ${lastDataAge}`,
            '',
            'Network Information:',
            `Connection Type: ${networkInfo?.type || 'N/A'}`,
            `Effective Type: ${networkInfo?.effectiveType || 'N/A'}`,
            `Downlink Speed: ${networkInfo?.downlink || 'N/A'} Mbps`,
            `Round Trip Time: ${networkInfo?.rtt || 'N/A'} ms`,
            '',
            'Location Information:',
            `Latitude: ${locationToUse?.latitude?.toFixed(6) || 'N/A'}`,
            `Longitude: ${locationToUse?.longitude?.toFixed(6) || 'N/A'}`,
            `Accuracy: ${locationToUse?.accuracy ? `${locationToUse.accuracy} meters` : 'N/A'}`,
            locationToUse?.timestamp ? 
                `Location Age: ${Math.round((Date.now() - locationToUse.timestamp) / 60000)} minutes${locationToUse !== locationInfo ? ' (cached)' : ''}` : 
                'Location Age: N/A',
            '',
            'Device Information:',
            `User Agent: ${navigator.userAgent}`,
            `Platform: ${navigator.platform}`,
            `Screen Resolution: ${window.screen.width}x${window.screen.height}`,
            `Viewport Size: ${window.innerWidth}x${window.innerHeight}`,
            `Date/Time: ${new Date().toISOString()}`
        ].join('%0D%0A');

        return `mailto:woodland.support@flaresoft.solutions?subject=Woodland OT Mobile Connection Issue&body=${info}`;
    };

    return (
        <Modal show={show} onHide={onHide} centered>
            <Modal.Header closeButton>
                <Modal.Title>Connection Information</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <ListGroup variant="flush">
                    <ListGroup.Item className="d-flex justify-content-between align-items-center">
                        <span>App Server Status</span>
                        <div>
                            <FontAwesomeIcon 
                                icon={faCircle} 
                                className={`me-2 ${isOnline ? 'text-success' : 'text-danger'}`}
                            />
                            {isOnline ? 'Online' : 'Offline'}
                        </div>
                    </ListGroup.Item>
                    <ListGroup.Item className="d-flex justify-content-between align-items-center">
                        <span>Internet Connection</span>
                        <div>
                            <FontAwesomeIcon 
                                icon={faCircle} 
                                className={`me-2 ${googleOnline ? 'text-success' : 'text-danger'}`}
                            />
                            {googleOnline ? 'Online' : 'Offline'}
                        </div>
                    </ListGroup.Item>
                    <ListGroup.Item className="d-flex justify-content-between align-items-center">
                        <span>Last Server Contact</span>
                        <span>{lastDataAge || 'Never'}</span>
                    </ListGroup.Item>
                    {networkInfo && (
                        <>
                            <ListGroup.Item className="d-flex justify-content-between align-items-center">
                                <span>Network Type</span>
                                <span>{networkInfo.type.toUpperCase()}</span>
                            </ListGroup.Item>
                            <ListGroup.Item className="d-flex justify-content-between align-items-center">
                                <span>Connection Quality</span>
                                <span>{networkInfo.quality}</span>
                            </ListGroup.Item>
                            <ListGroup.Item className="d-flex justify-content-between align-items-center">
                                <span>Download Speed</span>
                                <span>{networkInfo.downlink} Mbps</span>
                            </ListGroup.Item>
                            <ListGroup.Item className="d-flex justify-content-between align-items-center">
                                <span>Response Time</span>
                                <span>{networkInfo.rtt === 'unknown' ? 'N/A' : `${networkInfo.rtt}ms`}</span>
                            </ListGroup.Item>
                        </>
                    )}
                    <ListGroup.Item className="d-flex flex-column">
                        <div className="d-flex justify-content-between w-100">
                            <span>Location Status</span>
                            <span>
                                {locationInfo ? 
                                    (locationInfo === JSON.parse(localStorage.getItem('lastKnownLocation')) ? 'Cached' : 'Available') : 
                                    'Not Available'}
                            </span>
                        </div>
                        {locationError && (
                            <small className="text-danger mt-1">
                                Error: {locationError}
                            </small>
                        )}
                    </ListGroup.Item>

                    {locationInfo && (
                        <>
                            <ListGroup.Item className="d-flex flex-column">
                                <div className="d-flex justify-content-between w-100">
                                    <span>Coordinates</span>
                                    <span>
                                        {locationInfo.latitude.toFixed(6)}, {locationInfo.longitude.toFixed(6)}
                                    </span>
                                </div>
                                <small className="text-muted mt-1">
                                    {locationInfo === JSON.parse(localStorage.getItem('lastKnownLocation')) ? 
                                        'Using cached location' : 
                                        'Using current location'}
                                </small>
                            </ListGroup.Item>
                            <ListGroup.Item className="d-flex justify-content-between align-items-center">
                                <span>Location Age</span>
                                <span>
                                    {Math.round((Date.now() - locationInfo.timestamp) / 60000)} minutes
                                    {locationInfo === JSON.parse(localStorage.getItem('lastKnownLocation')) ? ' (cached)' : ''}
                                </span>
                            </ListGroup.Item>
                            {locationInfo.accuracy && (
                                <ListGroup.Item className="d-flex justify-content-between align-items-center">
                                    <span>Accuracy</span>
                                    <span>{Math.round(locationInfo.accuracy)} meters</span>
                                </ListGroup.Item>
                            )}
                        </>
                    )}
                </ListGroup>

                <div className="mt-4 d-flex flex-column gap-3">
                    <Button 
                        variant="outline-primary"
                        onClick={checkConnections}
                        disabled={isChecking}
                    >
                        <FontAwesomeIcon 
                            icon={faSync} 
                            className={`me-2 ${isChecking ? 'fa-spin' : ''}`}
                        />
                        {isChecking ? 'Checking Connections...' : 'Check Connections'}
                    </Button>

                    <div>
                        <p className="text-muted mb-2">
                            Having connection issues? Contact our support team for assistance. Please provide a brief description of the issues you are experiencing.
                        </p>
                        <a 
                            href={getErrorInfo()} 
                            className="btn btn-outline-primary w-100"
                        >
                            Email Support
                        </a>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default ConnectionInfoModal; 