import React, { useState } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField,
    Box,
    IconButton,
    Typography,
    List,
    ListItem,
    ListItemText,
    Divider,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTheme } from '@mui/material/styles';

const NotesModal = ({ open, onClose, notes = [], onAddNote }) => {
    const theme = useTheme();
    const [newNote, setNewNote] = useState('');

    const handleAddNote = () => {
        if (newNote.trim()) {
            onAddNote(newNote.trim());
            setNewNote('');
        }
    };

    return (
        <Dialog 
            open={open} 
            onClose={onClose}
            maxWidth="sm"
            fullWidth
            PaperProps={{
                sx: {
                    minHeight: '40vh',
                    maxHeight: '80vh',
                    bgcolor: 'background.paper',
                    color: theme.palette.text.primary,
                    boxShadow: 24,
                    p: 4,
                    borderRadius: 1
                }
            }}
        >
            <DialogTitle sx={{ 
                m: 0, 
                p: 2, 
                display: 'flex', 
                justifyContent: 'space-between',
                alignItems: 'center'
            }}>
                <Typography variant="h6">Notes History</Typography>
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: 'white'
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>

            <DialogContent sx={{ p: 2 }}>
                <List 
                    sx={{ 
                        mb: 3, 
                        maxHeight: '50vh',
                        overflowY: 'auto'
                    }}
                >
                    {(notes?.notes_list || []).slice().reverse().map((note, index) => (
                        <React.Fragment key={index}>
                            <ListItem 
                                alignItems="flex-start"
                                sx={{ 
                                    flexDirection: 'column',
                                    gap: 1,
                                    py: 2 
                                }}
                            >
                                <Typography
                                    sx={{ 
                                        width: '100%',
                                        whiteSpace: 'pre-wrap',
                                        wordBreak: 'break-word'
                                    }}
                                >
                                    {note.note_text}
                                </Typography>
                                <Typography
                                    variant="caption"
                                    color="text.secondary"
                                    sx={{ 
                                        alignSelf: 'flex-end',
                                        fontStyle: 'italic'
                                    }}
                                >
                                    {new Date(note.timestamp).toLocaleString()} - {note.author}
                                </Typography>
                            </ListItem>
                            {index < (notes?.notes_list?.length || 0) - 1 && (
                                <Divider 
                                    component="li" 
                                    sx={{ 
                                        borderColor: 'rgba(255, 255, 255, 0.12)',
                                        borderStyle: 'dashed',
                                        my: 1
                                    }} 
                                />
                            )}
                        </React.Fragment>
                    ))}
                </List>

                <TextField
                    fullWidth
                    multiline
                    rows={3}
                    value={newNote}
                    onChange={(e) => setNewNote(e.target.value)}
                    placeholder="Add a new note..."
                />
            </DialogContent>

            <DialogActions sx={{ p: 2, gap: 2 }}>
                <Box sx={{ flex: 1 }} />
                <Button onClick={onClose} color="inherit">
                    Cancel
                </Button>
                <Button 
                    onClick={handleAddNote} 
                    variant="contained"
                    disabled={!newNote.trim()}
                >
                    Add Note
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default NotesModal; 