import React, { useState, useEffect, useMemo } from 'react';
import {
    Modal,
    Box,
    Typography,
    IconButton,
    Button,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Grid,
    Alert,
    Tooltip,
    CircularProgress,
    Autocomplete,
    Divider
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import CloseIcon from '@mui/icons-material/Close';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { useTheme } from '@mui/material/styles';
import { useToast } from '../../Contexts/ToastContext';
import api from '../Common/axiosSetup';
import { format } from 'date-fns';
import useGlobalFilters from '../../Hooks/useGlobalFilters';
import { getUserTimeZone, toServerMidnightUTC, utcToServerMidnight, SERVER_TIMEZONE } from '../../Utility/Timezone';

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '800px',
    height: '90vh',
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: 1,
    display: 'flex',
    flexDirection: 'column'
};

const AddVisit = ({ open, onClose, onSuccess, metadata, facMetadata }) => {
    const theme = useTheme();
    const { addToast } = useToast();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    
    // Facility selection state
    const [facilities, setFacilities] = useState([]);
    const [facilityTypeFilter, setFacilityTypeFilter] = useState('');
    const [searchText, setSearchText] = useState('');

    // Work order state
    const [availableWorkOrders, setAvailableWorkOrders] = useState([]);
    const [workOrderMetadata, setWorkOrderMetadata] = useState({});

    const [formData, setFormData] = useState({
        facility_id: '',
        visit_date: null,
        work_orders: []
    });

    // Use global filters
    const { filters, updateArea, updateRun, updateZone } = useGlobalFilters();
    const { area, run, zone } = filters;

    // Get areas from facility metadata constraints
    const areas = facMetadata?.data__area?.constraints?.allowed_values || [];
    const runs = area ? (facMetadata?.data__run?.constraints?.allowed_values?.[area] || []) : [];
    const zones = run && facMetadata?.data__zone?.constraints?.allowed_values
        ? (facMetadata.data__zone.constraints.allowed_values[run] || [])
        : [];

    // Get unique facility types from the facilities list
    const facilityTypes = useMemo(() => {
        const types = new Set(facilities.map(f => f.type).filter(Boolean));
        return Array.from(types).sort();
    }, [facilities]);

    // Filter facilities based on type and search text
    const filteredFacilities = useMemo(() => {
        return facilities.filter(facility => {
            const facilityName = facility.facility_name?.toLowerCase() || '';
            const matchesSearch = !searchText || facilityName.includes(searchText.toLowerCase());
            const matchesType = !facilityTypeFilter || facility.type === facilityTypeFilter;
            return matchesSearch && matchesType;
        });
    }, [facilities, searchText, facilityTypeFilter]);

    // Fetch facilities when filters change or modal opens
    useEffect(() => {
        if (open) {
            fetchFacilities();
            fetchWorkOrderMetadata();
        }
    }, [area, run, zone, open]);

    // Fetch work orders when facility changes
    useEffect(() => {
        if (formData.facility_id) {
            fetchAvailableWorkOrders(formData.facility_id);
        }
    }, [formData.facility_id]);

    // Format helpers
    const formatWorkOrderId = (id) => {
        if (!id) return '';
        const idStr = id.toString();
        if (idStr.length <= 8) return idStr;
        return `${idStr.slice(0, 4)}-${idStr.slice(-4)}`;
    };

    const formatDate = (dateString) => {
        if (!dateString) return null;
        return utcToServerMidnight(dateString, SERVER_TIMEZONE);
    };

    const fetchFacilities = async () => {
        try {
            const token = localStorage.getItem('token');
            const response = await api.get('/api/facility/search/filtered', {
                headers: { Authorization: `Bearer ${token}` }
            });
            setFacilities(response.data);
        } catch (error) {
            setError('Failed to fetch facilities');
        }
    };

    const fetchWorkOrderMetadata = async () => {
        try {
            const token = localStorage.getItem('token');
            const response = await api.get('/api/data-registry/metadata', {
                headers: { Authorization: `Bearer ${token}` },
                params: { table_name: 'work_order_active' }
            });
            setWorkOrderMetadata(response.data || {});
        } catch (error) {
            console.error('Error fetching work order metadata:', error);
        }
    };

    const fetchAvailableWorkOrders = async (facilityId) => {
        if (!facilityId) return;
        
        try {
            const token = localStorage.getItem('token');
            const response = await api.get(`/api/work-orders/available/${facilityId}`, {
                headers: { Authorization: `Bearer ${token}` }
            });
            setAvailableWorkOrders(response.data);
        } catch (error) {
            console.error('Error fetching available work orders:', error);
            addToast('Error fetching available work orders', {
                color: 'danger',
                autoHide: true,
                delay: 5000
            });
        }
    };

    const getMetadataText = (field, value) => {
        const fieldMeta = workOrderMetadata[field];
        if (!fieldMeta?.constraints?.allowed_values) return value;
        return fieldMeta.constraints.allowed_values[value] || value;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!formData.facility_id || !formData.visit_date || formData.work_orders.length === 0) {
            setError('Please fill in all required fields and add at least one work order');
            return;
        }

        setLoading(true);
        try {
            const token = localStorage.getItem('token');
            const visitDate = toServerMidnightUTC(formData.visit_date, userTZ, SERVER_TIMEZONE);

            const response = await api.post('/api/visits', {
                facility_id: formData.facility_id,
                visit_date: visitDate.toISOString(),
                work_orders: formData.work_orders
            }, {
                headers: { Authorization: `Bearer ${token}` }
            });

            onSuccess(response.data);
            onClose();
            clearForm();
        } catch (error) {
            const errorMessage = error.response?.data?.message;
            const errorDetails = error.response?.data?.errors?.join('\n• ');
            const fullError = errorDetails 
                ? `${errorMessage}:\n• ${errorDetails}` 
                : (errorMessage || 'Error creating visit');
            
            setError(fullError);
        } finally {
            setLoading(false);
        }
    };

    const clearForm = () => {
        setFormData({
            facility_id: '',
            visit_date: null,
            work_orders: []
        });
        setFacilityTypeFilter('');
        setSearchText('');
        setError(null);
    };

    const handleAreaChange = (event) => {
        updateArea(event.target.value);
    };

    const handleRunChange = (event) => {
        updateRun(event.target.value);
    };

    const handleZoneChange = (event) => {
        updateZone(event.target.value);
    };

    const userTZ = useMemo(() => getUserTimeZone(), []);

    return (
        <Modal 
            open={open} 
            onClose={onClose}
        >
            <Box sx={modalStyle}>
                {/* Fixed Header */}
                <Box sx={{ 
                    p: 3, 
                    borderBottom: 1, 
                    borderColor: 'divider',
                    bgcolor: 'background.paper',
                    flexShrink: 0
                }}>
                    <Box display="flex" alignItems="center" justifyContent="space-between">
                        <Typography variant="h5" component="h2" gutterBottom>
                            ADD VISIT
                        </Typography>
                        <IconButton
                            onClick={onClose}
                            size="large"
                        >
                            <CloseIcon />
                        </IconButton>
                    </Box>
                    <Typography variant="body1" color="text.secondary" sx={{ mt: 1 }}>
                        Create a new visit by selecting a facility, date, and work orders. Fields marked with * are required.
                    </Typography>
                </Box>

                {/* Scrollable Content */}
                <Box sx={{ 
                    flex: 1,
                    overflowY: 'auto',
                    p: 3
                }}>
                    {error && (
                        <Alert 
                            severity="error" 
                            sx={{ 
                                mb: 2,
                                '& .MuiAlert-message': {
                                    whiteSpace: 'pre-line'
                                }
                            }}
                        >
                            {error}
                        </Alert>
                    )}

                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
                        {/* Facility Selection Section */}
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                            <Typography variant="subtitle1" sx={{ fontWeight: 'medium' }}>
                                Facility Selection
                            </Typography>

                            {/* Global Filters */}
                            <Box sx={{ display: 'flex', gap: 2 }}>
                                <FormControl sx={{ flex: 1 }}>
                                    <InputLabel>Area</InputLabel>
                                    <Select
                                        value={area}
                                        label="Area"
                                        onChange={handleAreaChange}
                                    >
                                        <MenuItem value="">
                                            <em>None</em>
                                        </MenuItem>
                                        {areas.map((area) => (
                                            <MenuItem key={area} value={area}>
                                                {area}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>

                                <FormControl sx={{ flex: 1 }}>
                                    <InputLabel>Run</InputLabel>
                                    <Select
                                        value={run}
                                        label="Run"
                                        onChange={handleRunChange}
                                        disabled={!area}
                                    >
                                        <MenuItem value="">
                                            <em>None</em>
                                        </MenuItem>
                                        {runs.map((run) => (
                                            <MenuItem key={run} value={run}>
                                                {run}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>

                                <FormControl sx={{ flex: 1 }}>
                                    <InputLabel>Zone</InputLabel>
                                    <Select
                                        value={zone}
                                        label="Zone"
                                        onChange={handleZoneChange}
                                        disabled={!run}
                                    >
                                        <MenuItem value="">
                                            <em>None</em>
                                        </MenuItem>
                                        {zones.map((zone) => (
                                            <MenuItem key={zone} value={zone}>
                                                {zone}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Box>

                            {/* Facility Type Filter and Search */}
                            <Box sx={{ display: 'flex', gap: 2 }}>
                                <FormControl sx={{ flex: 1 }}>
                                    <InputLabel>Facility Type</InputLabel>
                                    <Select
                                        value={facilityTypeFilter}
                                        label="Facility Type"
                                        onChange={(e) => setFacilityTypeFilter(e.target.value)}
                                    >
                                        <MenuItem value="">
                                            <em>All Types</em>
                                        </MenuItem>
                                        {facilityTypes.map((type) => (
                                            <MenuItem key={type} value={type}>
                                                {type}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>

                                <TextField
                                    sx={{ flex: 2 }}
                                    label="Search Facilities"
                                    value={searchText}
                                    onChange={(e) => setSearchText(e.target.value)}
                                    placeholder="Type to search facilities..."
                                    InputProps={{
                                        type: 'search',
                                    }}
                                />
                            </Box>

                            {/* Facility Selection Dropdown */}
                            <FormControl fullWidth required>
                                <InputLabel>Select Facility</InputLabel>
                                <Select
                                    value={formData.facility_id}
                                    label="Select Facility"
                                    onChange={(e) => setFormData(prev => ({
                                        ...prev,
                                        facility_id: e.target.value
                                    }))}
                                    MenuProps={{
                                        PaperProps: {
                                            style: {
                                                maxHeight: 400,
                                                width: 'auto',
                                                maxWidth: '100%'
                                            }
                                        }
                                    }}
                                >
                                    {filteredFacilities.map((facility) => (
                                        <MenuItem 
                                            key={facility.id} 
                                            value={facility.id}
                                            sx={{ 
                                                whiteSpace: 'normal',
                                                wordBreak: 'break-word'
                                            }}
                                        >
                                            <Box>
                                                <Typography variant="body1">
                                                    {facility.facility_name || `Unknown Facility - ${facility.id}`}
                                                </Typography>
                                                <Typography variant="caption" color="text.secondary">
                                                    {facility.type || 'Unknown Type'} | {facility.zone || 'No Zone'}
                                                </Typography>
                                            </Box>
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box>

                        {/* Visit Details Section */}
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                            <Typography variant="subtitle1" sx={{ fontWeight: 'medium' }}>
                                Visit Details
                            </Typography>

                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    label="Visit Date"
                                    value={formData.visit_date}
                                    onChange={(date) => setFormData(prev => ({
                                        ...prev,
                                        visit_date: date
                                    }))}
                                    slotProps={{
                                        textField: {
                                            required: true,
                                            error: !formData.visit_date,
                                            helperText: !formData.visit_date ? "Visit date is required" : ""
                                        }
                                    }}
                                />
                            </LocalizationProvider>
                        </Box>

                        {/* Work Orders Section */}
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                            <Typography variant="subtitle1" sx={{ fontWeight: 'medium' }}>
                                Work Orders
                            </Typography>

                            {/* Selected Work Orders */}
                            {formData.work_orders?.map((workOrder) => (
                                <Box key={workOrder.id} sx={{ 
                                    p: 2, 
                                    border: 1, 
                                    borderColor: 'divider',
                                    borderRadius: 1,
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'flex-start'
                                }}>
                                    <Box sx={{ flex: 1 }}>
                                        <Typography variant="body1">
                                            {formatWorkOrderId(workOrder.id)} ({workOrder.type})
                                        </Typography>
                                        <Typography variant="body2" color="textSecondary">
                                            Priority: {getMetadataText('priority', workOrder.priority)} | Due: {format(formatDate(workOrder.due_timestamp), 'yyyy-MM-dd')} | Status: {getMetadataText('status', workOrder.status)}
                                        </Typography>
                                        <Typography variant="body2" color="textSecondary" sx={{ 
                                            mt: 0.5,
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            maxWidth: '600px'
                                        }}>
                                            Notes: {workOrder.notes || 'No notes'}
                                        </Typography>
                                    </Box>
                                    <IconButton 
                                        size="small" 
                                        onClick={() => {
                                            setAvailableWorkOrders(prev => [...prev, workOrder]);
                                            setFormData(prev => ({
                                                ...prev,
                                                work_orders: prev.work_orders.filter(wo => wo.id !== workOrder.id)
                                            }));
                                        }}
                                        sx={{ color: 'error.main', ml: 1 }}
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                </Box>
                            ))}

                            {/* Work Order Selection */}
                            <Autocomplete
                                multiple
                                options={availableWorkOrders}
                                value={[]}  // Always empty since we handle selection manually
                                onChange={(event, newValue) => {
                                    setFormData(prev => ({
                                        ...prev,
                                        work_orders: [...prev.work_orders, ...newValue]
                                    }));
                                    setAvailableWorkOrders(prev => 
                                        prev.filter(wo => !newValue.find(selected => selected.id === wo.id))
                                    );
                                }}
                                getOptionLabel={(option) => `${formatWorkOrderId(option.id)} (${option.type})`}
                                renderOption={(props, option) => (
                                    <li {...props}>
                                        <Box sx={{ width: '100%' }}>
                                            <Typography variant="subtitle2">
                                                {formatWorkOrderId(option.id)} ({option.type})
                                            </Typography>
                                            <Typography variant="body2" color="text.secondary">
                                                Priority: {getMetadataText('priority', option.priority)} | Due: {format(formatDate(option.due_timestamp), 'yyyy-MM-dd')} | Status: {getMetadataText('status', option.status)}
                                            </Typography>
                                            <Typography variant="body2" color="text.secondary" sx={{ 
                                                mt: 0.5,
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis'
                                            }}>
                                                Notes: {option.notes || 'No notes'}
                                            </Typography>
                                        </Box>
                                    </li>
                                )}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        placeholder="Select work orders to add"
                                        disabled={!formData.facility_id}
                                    />
                                )}
                            />
                        </Box>
                    </Box>
                </Box>

                {/* Fixed Footer */}
                <Box sx={{ 
                    p: 3, 
                    borderTop: 1, 
                    borderColor: 'divider',
                    bgcolor: 'background.paper',
                    display: 'flex', 
                    justifyContent: 'flex-end', 
                    gap: 2,
                    mt: 'auto',
                    flexShrink: 0
                }}>
                    <Button 
                        onClick={clearForm}
                        color="inherit"
                        startIcon={<RestartAltIcon />}
                    >
                        Reset Form
                    </Button>
                    <Button onClick={onClose} color="inherit">
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        onClick={handleSubmit}
                        disabled={loading}
                    >
                        {loading ? <CircularProgress size={24} /> : 'Create Visit'}
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default AddVisit; 