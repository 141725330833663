// theme.js
import { createTheme as createMuiTheme } from '@mui/material/styles';
import { alpha } from '@mui/material/styles';

const createTheme = (mode, themeColor = 'cyan') => {
  console.log('Theme.js - Creating theme with:', { mode, themeColor });
  // Theme color sets
  const themes = {
    cyan: {
      primary: '#2A9D8F',
      secondary: '#E9C46A',
      dark: {
        background: '#162726',
        paper: '#1D3330',
        border: '#2A4745',
        text: '#E0F2F1',
      },
      light: {
        background: '#E0F7FA',
        paper: '#FFFFFF',
        border: '#B2EBF2',
        text: '#00695C',
      }
    },
    indigo: {
      primary: '#4B6BFB',
      secondary: '#DEB841',
      dark: {
        background: '#151B32',
        paper: '#1A2340',
        border: '#2D3654',
        text: '#E8EAF6',
      },
      light: {
        background: '#EEF2FF',
        paper: '#FFFFFF',
        border: '#C7D2FE',
        text: '#1E3A8A',
      }
    },
    blue: {
      primary: '#3B82F6',
      secondary: '#F59E0B',
      dark: {
        background: '#0F172A',
        paper: '#1E293B',
        border: '#334155',
        text: '#E2E8F0',
      },
      light: {
        background: '#F0F7FF',
        paper: '#FFFFFF',
        border: '#BFDBFE',
        text: '#1E40AF',
      }
    },
    orange: {
      primary: '#EA580C',
      secondary: '#FCD34D',
      dark: {
        background: '#27150D',
        paper: '#331B0F',
        border: '#4B2818',
        text: '#FFF3E0',
      },
      light: {
        background: '#FFF7ED',
        paper: '#FFFFFF',
        border: '#FFEDD5',
        text: '#9A3412',
      }
    },
    blush: {
      primary: '#BE185D',
      secondary: '#F4B8CC',
      dark: {
        background: '#1F1022',
        paper: '#2D1630',
        border: '#4A2448',
        text: '#FCE7F3',
      },
      light: {
        background: '#FDF2F8',
        paper: '#FFFFFF',
        border: '#FBCFE8',
        text: '#831843',
      }
    },
    red: {
      primary: '#B91C1C',
      secondary: '#C1A02D',
      dark: {
        background: '#1A0F0F',
        paper: '#2C1515',
        border: '#3D1F1F',
        text: '#FEE2E2',
      },
      light: {
        background: '#FEF2F2',
        paper: '#FFFFFF',
        border: '#FEE2E2',
        text: '#991B1B',
      }
    },
    tradewind: {
      primary: '#6AAB9C',
      secondary: '#D4AF37',
      dark: {
        background: '#162321',
        paper: '#1D2E2B',
        border: '#2A4541',
        text: '#E0F2F1',
      },
      light: {
        background: '#F0F7F6',
        paper: '#FFFFFF',
        border: '#B2D8D0',
        text: '#2A4541',
      }
    },
    monalisa: {
      primary: '#FF7B89',
      secondary: '#FFD700',
      dark: {
        background: '#2A1F21',
        paper: '#382A2D',
        border: '#4D3B3E',
        text: '#FFE4E6',
      },
      light: {
        background: '#FFF5F6',
        paper: '#FFFFFF',
        border: '#FFD6DA',
        text: '#943D47',
      }
    },
    green: {
      primary: '#7EB93F',
      secondary: '#FFC107',
      dark: {
        background: '#1A2416',
        paper: '#243219',
        border: '#374D2A',
        text: '#E8F5E9',
      },
      light: {
        background: '#F3F8EF',
        paper: '#FFFFFF',
        border: '#C5E1A5',
        text: '#33691E',
      }
    }
  };

  const colors = themes[themeColor] || themes.cyan;
  
  // Add high contrast colors
  const highContrastColors = {
    background: '#000000',
    paper: '#121212',
    border: '#FFFFFF',
    text: '#FFFFFF',
    tableHeader: '#000000',
    tableHeaderText: '#FFFFFF',
    tableRow: '#121212',
    tableRowHover: '#1F1F1F',
    tableRowSelected: '#2C2C2C',
    tableText: '#FFFFFF',
  };

  // Choose the appropriate color set based on mode
  const currentTheme = mode === 'high-contrast' 
    ? highContrastColors 
    : colors[mode === 'dark' ? 'dark' : 'light'];

  console.log('Theme Debug:', {
    mode,
    themeColor,
    colors,
    currentTheme,
    selectedTheme: themes[themeColor],
    background: currentTheme.background,
    paper: currentTheme.paper,
    border: currentTheme.border
  });

  return createMuiTheme({
    palette: {
      mode: mode === 'dark' || mode === 'high-contrast' ? 'dark' : 'light',
      primary: {
        main: colors.primary,
        light: alpha(colors.primary, 0.8),
        dark: alpha(colors.primary, 1.2),
      },
      secondary: {
        main: colors.secondary,
        light: mode === 'dark' ? '#8D6E63' : '#A1887F',
        dark: mode === 'dark' ? '#5D4037' : '#6D4C41',
      },
      background: {
        default: currentTheme.background,
        paper: currentTheme.paper,
      },
      text: {
        primary: currentTheme.text,
        secondary: mode === 'dark' ? '#B0BEC5' : '#546E7A',
      },
      divider: currentTheme.border,
    },
    typography: {
      fontFamily: 'Roboto, sans-serif',
      h1: {
        fontWeight: 600,
        color: mode === 'dark' ? '#E8F5E9' : '#1B5E20',
      },
      h2: {
        fontWeight: 600,
        color: mode === 'dark' ? '#E8F5E9' : '#1B5E20',
      },
      h3: {
        fontWeight: 600,
        color: mode === 'dark' ? '#E8F5E9' : '#1B5E20',
      },
      h4: {
        fontWeight: 600,
        color: mode === 'dark' ? '#E8F5E9' : '#1B5E20',
      },
      h5: {
        fontWeight: 600,
        color: mode === 'dark' ? '#E8F5E9' : '#1B5E20',
      },
      h6: {
        fontWeight: 600,
        color: mode === 'dark' ? '#E8F5E9' : '#1B5E20',
      },
    },
    components: {
      MuiDataGrid: {
        styleOverrides: {
          root: {
            '&.MuiDataGrid-root': {
              backgroundColor: currentTheme.paper + ' !important',
            },
            '& .MuiDataGrid-main': {
              backgroundColor: currentTheme.paper + ' !important',
            },
            '& .MuiDataGrid-virtualScroller': {
              backgroundColor: currentTheme.paper + ' !important',
            },
            '& .MuiDataGrid-row': {
              backgroundColor: currentTheme.paper + ' !important',
            },
            '& .MuiDataGrid-columnHeaders': {
              backgroundColor: colors.primary,
              '& .MuiDataGrid-columnHeaderTitle': {
                color: mode === 'dark' || mode === 'high-contrast' 
                  ? '#FFFFFF' 
                  : alpha(currentTheme.text, 0.87),
                fontWeight: 600,
              },
              '& .MuiIconButton-root': {
                color: mode === 'dark' || mode === 'high-contrast' 
                  ? '#FFFFFF' 
                  : alpha(currentTheme.text, 0.87),
              },
              fontWeight: 600,
              borderBottom: `2px solid ${currentTheme.border}`,
            },
            '& .MuiDataGrid-toolbarContainer': {
              backgroundColor: currentTheme.paper,
              borderBottom: `1px solid ${currentTheme.border}`,
              '& .MuiTextField-root': {
                backgroundColor: currentTheme.paper,
                borderRadius: 4,
              }
            },
            '& .MuiDataGrid-row': {
              backgroundColor: currentTheme.paper,
              borderBottom: `1px solid ${currentTheme.border}`,
              '&:hover': {
                backgroundColor: alpha(colors.primary, 0.04),
              },
              '&.Mui-selected': {
                backgroundColor: alpha(colors.primary, 0.08),
                '&:hover': {
                  backgroundColor: alpha(colors.primary, 0.12),
                },
              },
            },
            '& .MuiDataGrid-cell': {
              borderBottom: `1px solid ${currentTheme.border}`,
              color: currentTheme.text,
            },
            '& .MuiDataGrid-footerContainer': {
              backgroundColor: currentTheme.paper,
              borderTop: `1px solid ${currentTheme.border}`,
            },
            '& .MuiIconButton-root': {
              color: alpha(colors.primary, 0.7),
              '&:hover': {
                backgroundColor: alpha(colors.primary, 0.04),
              }
            },
            '& .MuiButton-root': {
              color: colors.primary,
              '&:hover': {
                backgroundColor: alpha(colors.primary, 0.04),
              }
            }
          }
        }
      },
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: 4,
            textTransform: 'none',
            fontWeight: 500,
            backgroundColor: colors.primary,
            color: '#FFFFFF',
            '&:hover': {
              backgroundColor: alpha(colors.primary, 0.8),
            },
            '&.MuiButton-contained': {
              backgroundColor: colors.primary,
              color: '#FFFFFF',
              '&:hover': {
                backgroundColor: alpha(colors.primary, 0.8),
              }
            },
            '&.MuiButton-outlined': {
              backgroundColor: 'transparent',
              borderColor: colors.primary,
              color: colors.primary,
              '&:hover': {
                backgroundColor: alpha(colors.primary, 0.1),
                borderColor: colors.primary,
              }
            },
            '&.MuiButton-text': {
              backgroundColor: 'transparent',
              color: colors.primary,
              '&:hover': {
                backgroundColor: alpha(colors.primary, 0.1),
              }
            }
          },
        },
      },
      MuiCard: {
        styleOverrides: {
          root: {
            borderRadius: 8,
            boxShadow: mode === 'dark' 
              ? '0 4px 6px rgba(0, 0, 0, 0.2)'
              : '0 4px 6px rgba(0, 0, 0, 0.1)',
            backgroundColor: mode === 'dark' ? '#243238' : '#FFFFFF',
          },
        },
      },
    },
  });
};

export default createTheme;