import React from 'react';
import { Nav } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

const UserProfileMenu = () => {
    const theme = useTheme();
    
    return (
        <Box sx={{
            '& .nav-pills': {
                '& .nav-link': {
                    color: theme.palette.text.primary,
                    '&:hover': {
                        backgroundColor: theme.palette.action.hover,
                    },
                    '&.active': {
                        backgroundColor: theme.palette.primary.main,
                        color: '#FFFFFF',
                        '&:hover': {
                            backgroundColor: theme.palette.primary.dark,
                        }
                    }
                }
            }
        }}>
            <Nav className="flex-column nav-pills">
                <Nav.Item>
                    <Nav.Link 
                        as={NavLink} 
                        to="/profile/overview" 
                        className={({ isActive }) => isActive ? "active" : ""}
                    >
                        Profile Overview
                    </Nav.Link>
                </Nav.Item>
            </Nav>
        </Box>
    );
};

export default UserProfileMenu;
