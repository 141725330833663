import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Box, Button, Tooltip, Select, MenuItem } from '@mui/material';
import api from '../Common/axiosSetup';
import { useToast } from '../../Contexts/ToastContext';
import { useTheme } from '@mui/material/styles';
import DataTable from '../Common/DataTable';
import AddNewFacility from './AddNewFacility';
import FacilityMultiEdit from './FacilityMultiEdit';
import ExportDialog from '../Common/ExportDialog';
import { exportToExcel, exportToPDF, printData } from '../Common/ExportHelpers';
import { GridToolbar } from '@mui/x-data-grid';

const FacilityDataTable = () => {
    const [rows, setRows] = useState([]);
    const [selectedRowIds, setSelectedRowIds] = useState([]);
    const [openAdd, setOpenAdd] = useState(false);
    const [openModify, setOpenModify] = useState(false);
    const [openMultiEdit, setOpenMultiEdit] = useState(false);
    const [currentFacility, setCurrentFacility] = useState(null);
    const [loading, setLoading] = useState(true);
    const [openExport, setOpenExport] = useState(false);
    const [exportType, setExportType] = useState(null);
    const [selectedColumns, setSelectedColumns] = useState({});
    const [metadata, setMetadata] = useState({});
    const [isOutOfSync, setIsOutOfSync] = useState(false);
    const [isChecking, setIsChecking] = useState(false);
    const checkTimeoutRef = useRef(null);

    const { addToast } = useToast();
    const theme = useTheme();

    const fetchMetadata = async () => {
        try {
            const token = localStorage.getItem('token');
            const response = await api.get('/api/data-registry/metadata', {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                params: {
                    table_name: 'facility'
                }
            });
            setMetadata(response.data);
        } catch (error) {
            console.error('Error fetching metadata:', error);
            addToast('Error fetching metadata', {
                color: 'danger',
                autoHide: true,
                delay: 5000
            });
        }
    };

    const fetchFacilities = async () => {
        try {
            setLoading(true);
            const token = localStorage.getItem('token');
            const response = await api.get('/api/facility', {
                headers: { Authorization: `Bearer ${token}` }
            });
            const flattenedData = flattenRows(response.data);
            setRows(flattenedData);
        } catch (error) {
            console.error('Error fetching facilities:', error);
            addToast('Error refreshing facilities data', {
                color: 'danger',
                autoHide: true,
                delay: 5000
            });
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchFacilities();
        fetchMetadata();
    }, []); // Empty dependency array for initial load

    useEffect(() => {
        const initialSelectedColumns = {};
        columns.forEach(col => {
            if (col.field !== 'actions') {
                initialSelectedColumns[col.field] = true;
            }
        });
        setSelectedColumns(initialSelectedColumns);
    }, []);

    const CustomFilter = ({ column, onFilterChange }) => {
        const options = metadata[column.field]?.constraints?.allowed_values || [];
        return (
            <Select
                onChange={(e) => onFilterChange(e.target.value)}
                displayEmpty
            >
                <MenuItem value="">All</MenuItem>
                {options.map((option) => (
                    <MenuItem key={option} value={option}>
                        {option}
                    </MenuItem>
                ))}
            </Select>
        );
    };

    const columns = [
        {
            field: 'id',
            headerName: 'ID',
            headerAlign: 'center',
            align: 'center',
            flex: 1
        },
        {
            field: 'data__facility_name',
            headerName: 'Facility Name',
            headerAlign: 'center',
            align: 'left',
            flex: 2,
        },
        {
            field: 'data__uwi',
            headerName: 'UWI',
            headerAlign: 'center',
            align: 'center',
            flex: 2,
        },
        {
            field: 'type',
            headerName: 'Facility Type',
            headerAlign: 'center',
            align: 'center',
            flex: 1,
        },
        {
            field: 'data__cost_center',
            headerName: 'Cost Center',
            headerAlign: 'center',
            align: 'center',
            flex: 1,
        },
        {
            field: 'data__area',
            headerName: 'Area',
            headerAlign: 'center',
            align: 'center',
            flex: 1,
        },
        {
            field: 'data__subarea',
            headerName: 'Sub Area',
            headerAlign: 'center',
            align: 'center',
            flex: 1,
        },
        {
            field: 'data__run',
            headerName: 'Run',
            headerAlign: 'center',
            align: 'center',
            flex: 1,
        },
        {
            field: 'data__zone',
            headerName: 'Zone',
            headerAlign: 'center',
            align: 'center',
            flex: 1,
        },
        {
            field: 'data__maintenance_profile',
            headerName: 'Maint. Profile',
            headerAlign: 'center',
            align: 'center',
            flex: 2,
        },
        {
            field: 'data__maintenance_base_date',
            headerName: 'Maint. Base Date',
            headerAlign: 'center',
            align: 'center',
            flex: 1.5,
            type: 'date'
        },
    ];    

    const flattenRows = (rows) => {
        return rows.map(row => {
            const newRow = { ...row };
            if (row.data) {
                Object.keys(row.data).forEach(key => {
                    newRow[`data__${key}`] = row.data[key];
                });
                delete newRow.data;
            }
            return newRow;
        });
    };

    const handleAddFacility = async (newFacility) => {
        try {
            const flattenedData = {
                ...newFacility,
                ...Object.entries(newFacility.data).reduce((acc, [key, value]) => {
                    acc[`data__${key}`] = value;
                    return acc;
                }, {})
            };
            delete flattenedData.data;
            setRows(prev => [...prev, flattenedData]);
        } catch (error) {
            console.error('Error processing new facility:', error);
            addToast('Error processing new facility data', {
                color: 'danger',
                autoHide: false,
                delay: 15000,
                requireAck: true
            });
        }
    };

    const handleModify = (facility) => {
        setCurrentFacility(facility);
        setOpenModify(true);
    };

    const handleMultiEdit = () => {
        setOpenMultiEdit(true);
    };

    const handleExportClick = (type) => {
        setExportType(type);
        setOpenExport(true);
    };

    const handleExport = () => {
        const visibleColumns = columns.filter(col => 
            col.field !== 'actions' && selectedColumns[col.field]
        );

        switch (exportType) {
            case 'excel':
                exportToExcel(rows, visibleColumns, 'facilities');
                break;
            case 'pdf':
                exportToPDF(rows, visibleColumns, 'facilities');
                break;
            case 'print':
                printData(rows, visibleColumns, 'Facilities');
                break;
            default:
                break;
        }
        setOpenExport(false);
    };

    const handleRowUpdate = async (id, modifiedFields) => {
        try {
            const token = localStorage.getItem('token');
            
            // Convert the flat data structure back to nested
            const facilityData = {
                id: id,
                data: {}
            };

            // Move all data__ fields to the data object
            Object.keys(modifiedFields).forEach(key => {
                if (key.startsWith('data__')) {
                    facilityData.data[key.replace('data__', '')] = modifiedFields[key];
                } else if (key === 'type') {
                    facilityData.type = modifiedFields[key];
                }
            });

            console.log('HandleRowUpdate - Request Data:', facilityData);
            
            const response = await api.put(`/api/facility/${id}`, facilityData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            });

            console.log('HandleRowUpdate - Response:', response.data);

            // Update the local rows state with the new data
            setRows(prevRows => prevRows.map(row => 
                row.id === id ? { ...row, ...modifiedFields, update_timestamp: response.data.update_timestamp } : row
            ));

            addToast('Facility updated successfully', {
                color: 'success',
                autoHide: true,
                delay: 15000,
                requireAck: false
            });

            // Make sure we're returning the complete updated row
            const updatedRow = { ...modifiedFields, update_timestamp: response.data.update_timestamp };
            console.log('HandleRowUpdate - Returning:', updatedRow);
            return updatedRow;

        } catch (error) {
            const errorMessage = error.response?.data?.message;
            const errorDetails = error.response?.data?.errors?.join('\n• ');
            const fullError = errorDetails 
                ? `${errorMessage}:\n• ${errorDetails}` 
                : (errorMessage || 'Error updating facility');
                
            addToast(fullError, {
                color: 'danger',
                autoHide: false,
                delay: 15000,
                requireAck: true
            });
            throw error;
        }
    };

    const handleRowDelete = async (id) => {
        try {
            const token = localStorage.getItem('token');
            await api.delete(`/api/facility/${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            });

            // Update rows state after successful deletion
            setRows(prev => prev.filter(row => row.id !== id));

            addToast('Facility deleted successfully', {
                color: 'success',
                autoHide: true,
                delay: 15000,
                requireAck: false
            });
        } catch (error) {
            const errorMessage = error.response?.data?.message;
            const errorDetails = error.response?.data?.errors?.join('\n• ');
            const fullError = errorDetails 
                ? `${errorMessage}:\n• ${errorDetails}` 
                : (errorMessage || 'Error deleting facility');
                
            addToast(fullError, {
                color: 'danger',
                autoHide: false,
                delay: 15000,
                requireAck: true
            });
            throw error;
        }
    };

    const checkVersions = useCallback(async () => {
        if (isChecking || !rows.length) return;
        
        try {
            setIsChecking(true);
            const token = localStorage.getItem('token');
            const response = await api.get('/api/facility', {
                headers: { Authorization: `Bearer ${token}` }
            });

            // Compare current rows with fresh data
            const freshData = response.data;
            const hasChanges = freshData.some(freshRow => {
                const currentRow = rows.find(r => r.id === freshRow.id);
                return !currentRow || 
                    new Date(currentRow.update_timestamp) < new Date(freshRow.update_timestamp);
            });

            setIsOutOfSync(hasChanges);
        } catch (error) {
            console.error('Error checking versions:', error);
        } finally {
            setIsChecking(false);
        }
    }, [rows, isChecking]);

    useEffect(() => {
        const startChecking = () => {
            checkTimeoutRef.current = setTimeout(async () => {
                await checkVersions();
                startChecking();
            }, 120000); // Check every 120 seconds
        };

        startChecking();

        return () => {
            if (checkTimeoutRef.current) {
                clearTimeout(checkTimeoutRef.current);
            }
        };
    }, [checkVersions]);

    const handleRefresh = async () => {
        await fetchFacilities();
        setIsOutOfSync(false);
    };

    const handleMultiEditSubmit = async (updateData, type) => {
        try {
            const token = localStorage.getItem('token');
            const response = await api.post('/api/facility/multi-edit', {
                ...updateData,
                submitType: type
            }, {
                headers: { Authorization: `Bearer ${token}` }
            });

            // Update rows with successful changes
            if (response.data.success.length > 0) {
                setRows(prevRows => {
                    const updatedRows = [...prevRows];
                    response.data.success.forEach(updatedFacility => {
                        const index = updatedRows.findIndex(row => row.id === updatedFacility.id);
                        if (index !== -1) {
                            const flattenedFacility = {
                                ...updatedFacility,
                                ...Object.entries(updatedFacility.data).reduce((acc, [key, value]) => {
                                    acc[`data__${key}`] = value;
                                    return acc;
                                }, {})
                            };
                            delete flattenedFacility.data;
                            updatedRows[index] = flattenedFacility;
                        }
                    });
                    return updatedRows;
                });
            }

            // Show success/error toast with summary
            const { summary } = response.data;
            if (summary.failed_changes === 0) {
                addToast(`Successfully updated ${summary.successful_changes} changes across ${response.data.success.length} facilities`, {
                    color: 'success',
                    autoHide: true,
                    delay: 15000
                });
            } else {
                // Format errors with bullet points and new lines
                const errorMessages = response.data.errors.map(err => 
                    `• ${err.facility_name}: ${err.error}`
                ).join('\n');

                const errorMessage = [
                    `${summary.successful_changes} changes succeeded`,
                    `${summary.failed_changes} changes failed`,
                    '',
                    'Failed updates:',
                    errorMessages
                ].join('\n');

                addToast(errorMessage, {
                    color: 'warning',
                    autoHide: false,
                    delay: 15000,
                    requireAck: true
                });
            }

            return response.data;
        } catch (error) {
            // Fix error handling here
            const errorData = error.response?.data;
            if (errorData?.errors) {
                // Format errors with bullet points and new lines
                const errorMessages = errorData.errors.map(err => 
                    `• ${err.facility_name}: ${err.error}`
                ).join('\n');

                const errorMessage = [
                    'Failed updates:',
                    errorMessages
                ].join('\n');

                addToast(errorMessage, {
                    color: 'danger',
                    autoHide: false,
                    delay: 15000,
                    requireAck: true
                });
            } else {
                // Fallback for unexpected errors
                addToast(errorData?.message || 'Error updating facilities', {
                    color: 'danger',
                    autoHide: false,
                    delay: 15000,
                    requireAck: true
                });
            }
            throw error;
        }
    };

    return (
        <Box sx={{ 
            height: 'calc(100vh - 210px)', 
            width: '100%', 
            padding: theme.spacing(2),
            backgroundColor: theme.palette.background.default,
            borderRadius: theme.shape.borderRadius,
            boxShadow: theme.shadows[1],
            display: 'flex',
            flexDirection: 'column',
            gap: theme.spacing(2),
            overflow: 'hidden'
        }}>
            <Box sx={{ 
                display: 'flex', 
                justifyContent: 'space-between', 
                gap: theme.spacing(2)
            }}>
                <Box sx={{ display: 'flex', gap: theme.spacing(2) }}>
                    <Button variant="contained" onClick={() => setOpenAdd(true)}>Add Facility</Button>
                    <Button 
                        variant="contained" 
                        onClick={handleMultiEdit} 
                        disabled={selectedRowIds.length <= 1}
                        sx={{
                            opacity: selectedRowIds.length <= 1 ? 0.6 : 1,
                            '&:disabled': {
                                backgroundColor: theme.palette.action.disabledBackground,
                                color: theme.palette.action.disabled
                            }
                        }}
                    >
                        Multi Edit ({selectedRowIds.length} selected)
                    </Button>
                </Box>
                
                <Box sx={{ display: 'flex', gap: theme.spacing(2) }}>
                    <Button variant="contained" onClick={() => handleExportClick('excel')}>
                        Export Excel
                    </Button>
                    <Button variant="contained" onClick={() => handleExportClick('pdf')}>
                        Export PDF
                    </Button>
                    <Button variant="contained" onClick={() => handleExportClick('print')}>
                        Print
                    </Button>
                </Box>
            </Box>
            <Box sx={{ 
                flex: 1,
                minHeight: 0,
                overflow: 'hidden'
            }}>
                <DataTable
                    rows={rows}
                    columns={columns}
                    loading={loading}
                    metadata={metadata}
                    tableId="facility_management"
                    checkboxSelection={true}
                    onRowSelectionModelChange={(ids) => setSelectedRowIds(ids)}
                    rowSelectionModel={selectedRowIds}
                    slots={{ toolbar: GridToolbar }}
                    filterMode="client"
                    disableMultipleColumnsFiltering={false}
                    disableMultipleSelection={false}
                    enableInlineEdit={true}
                    onRowUpdate={handleRowUpdate}
                    onRowDelete={handleRowDelete}
                    nonEditableFields={['id', 'type', 'insert_timestamp', 'update_timestamp', 'changed_by', 'change_timestamp']}
                    enableVersionCheck={true}
                    isOutOfSync={isOutOfSync}
                    onRefresh={handleRefresh}
                />
            </Box>
            <AddNewFacility open={openAdd} onClose={() => setOpenAdd(false)} onAdd={handleAddFacility} />
            <FacilityMultiEdit 
                open={openMultiEdit} 
                onClose={() => setOpenMultiEdit(false)} 
                selectedRows={rows.filter(row => selectedRowIds.includes(row.id))}
                metadata={metadata}
                onSubmit={handleMultiEditSubmit}
            />
            <ExportDialog
                open={openExport}
                onClose={() => setOpenExport(false)}
                columns={columns}
                selectedColumns={selectedColumns}
                onColumnChange={(field, checked) => 
                    setSelectedColumns(prev => ({ ...prev, [field]: checked }))
                }
                onExport={handleExport}
                title={`Select columns to ${exportType || 'export'}`}
            />
        </Box>
    );
};

export default FacilityDataTable;