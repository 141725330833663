import React, { createContext, useContext, useState, useEffect, useCallback, useRef } from 'react';
import api from '../Component/Common/axiosSetup';
import { useToast } from './ToastContext';
import { useNavigate } from 'react-router-dom';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [authenticatedEmail, setAuthenticatedEmail] = useState('');
    const [authCheckComplete, setAuthCheckComplete] = useState(false);
    const [sessionTimedOut, setSessionTimedOut] = useState(false); // Track session timeout
    const [justLoggedOut, setJustLoggedOut] = useState(false); // Track logout status
    const { addToast } = useToast();
    const prevIsAuthenticated = useRef(isAuthenticated);
    const navigate = useNavigate();

    const handleSessionTimeout = useCallback(() => {
        if (!sessionTimedOut && !justLoggedOut) { // Only show the toast once if not just logged out
            addToast(
                <span>Your session has timed out. Please sign in again.</span>,
                {
                    requireAck: true,
                    autoHide: false,
                    color: 'warning',
                }
            );
            setSessionTimedOut(true); // Set flag to prevent further toasts
            navigate('/');
        }
    }, [addToast, navigate, sessionTimedOut, justLoggedOut]);

    // Expose handleSessionTimeout to window object
    useEffect(() => {
        window.handleSessionTimeout = handleSessionTimeout;
        return () => {
            delete window.handleSessionTimeout;
        };
    }, [handleSessionTimeout]);

    const checkAuthState = useCallback(async () => {
        // Skip session check if just logged out
        if (justLoggedOut) {
            return;
        }

        // Check if in mobile view
        const isMobileView = localStorage.getItem("isMobileView");
        const isMobile = isMobileView ? JSON.parse(isMobileView) : false;

        try {
            if (process.env.REACT_APP_ENV === 'development') {
                console.log('Checking auth state...');
            }
            const token = localStorage.getItem('token');
            if (token) {
                // If in mobile view, just validate token exists and set authenticated
                if (isMobile) {
                    setAuthenticatedEmail(localStorage.getItem('userEmail') || '');
                    setIsAuthenticated(true);
                    setSessionTimedOut(false);
                    if (process.env.REACT_APP_ENV === 'development') {
                        console.log('Mobile mode: Skipping session check');
                    }
                } else {
                    // Regular session check for desktop mode
                    const response = await api.post(
                        '/api/auth/check-session',
                        {}, // Empty body
                        {
                            headers: {
                                Authorization: `Bearer ${token}`,
                            },
                        }
                    );
                    if (response.data.valid) {
                        setAuthenticatedEmail(response.data.user.email);
                        setIsAuthenticated(true);
                        setSessionTimedOut(false); // Reset the flag if session is valid

                        if (process.env.REACT_APP_ENV === 'development') {
                            console.log('Session is valid:', response.data);
                        }
                    } else {
                        throw new Error('Session invalid or expired');
                    }
                }
            } else {
                throw new Error('No token found');
            }
        } catch (error) {
            localStorage.removeItem('token');
            if (prevIsAuthenticated.current && !isMobile) { // Only show timeout for desktop mode
                handleSessionTimeout();
            }
            setIsAuthenticated(false);
            setAuthenticatedEmail('');

            if (process.env.REACT_APP_ENV === 'development') {
                console.error('Authentication error:', error);
            }
        } finally {
            setAuthCheckComplete(true);
            if (process.env.REACT_APP_ENV === 'development') {
                console.log('Auth check complete.');
            }
        }
    }, [handleSessionTimeout, justLoggedOut]);

    useEffect(() => {
        checkAuthState(); // Initial auth state check
    }, [checkAuthState]);

    useEffect(() => {
        // Check if in mobile view
        const isMobileView = localStorage.getItem("isMobileView");
        const isMobile = isMobileView ? JSON.parse(isMobileView) : false;

        // Only set up interval for desktop mode
        if (isAuthenticated && !isMobile) {
            const interval = setInterval(() => {
                checkAuthState();
            }, 600000); // Check every 600 seconds

            return () => clearInterval(interval);
        }
    }, [isAuthenticated, checkAuthState]);

    useEffect(() => {
        prevIsAuthenticated.current = isAuthenticated;
    }, [isAuthenticated]);

    return (
        <AuthContext.Provider value={{
            isAuthenticated,
            authenticatedEmail,
            authCheckComplete,
            checkAuthState,
            setIsAuthenticated,
            justLoggedOut,
            setJustLoggedOut
        }}>
            {children}
        </AuthContext.Provider>
    );
};
