import React, { useState, useEffect } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Box,
    Typography,
    Alert,
    Chip,
    Divider,
    Switch,
    FormControlLabel,
    Paper,
    CircularProgress,
    Tooltip,
    Popover,
    List,
    ListItem,
    ListItemText,
    Collapse,
    IconButton,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useToast } from '../../Contexts/ToastContext';
import { RestartAlt as RestartAltIcon, ExpandMore as ExpandMoreIcon } from '@mui/icons-material';

const FacilityMultiEdit = ({ open, onClose, selectedRows, metadata, onSubmit }) => {
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({});
    const [enabledFields, setEnabledFields] = useState({});
    const [setNullFields, setSetNullFields] = useState({});
    const [error, setError] = useState(null);
    const [submitType, setSubmitType] = useState(null);
    const [expandedValues, setExpandedValues] = useState({});
    const theme = useTheme();
    const { addToast } = useToast();
    const [anchorEl, setAnchorEl] = useState(null);
    const [activeField, setActiveField] = useState(null);
    const [selectedType, setSelectedType] = useState('');

    useEffect(() => {
        if (selectedRows?.length > 0) {
            // Get the type from the first selected row
            setSelectedType(selectedRows[0].type);
        }
    }, [selectedRows]);

    // Initialize expanded state when activeField changes
    useEffect(() => {
        if (activeField) {
            const uniqueValues = getUniqueValues(activeField);
            const initialExpanded = Object.keys(uniqueValues).reduce((acc, value) => ({
                ...acc,
                [value]: true  // Start all sections expanded
            }), {});
            setExpandedValues(initialExpanded);
        }
    }, [activeField]);  // Only run when activeField changes

    // Calculate summary statistics
    const summary = {
        total: selectedRows.length,
        byType: selectedRows.reduce((acc, row) => {
            acc[row.type] = (acc[row.type] || 0) + 1;
            return acc;
        }, {})
    };

    const handleInputChange = (field, value) => {
        if (!setNullFields[field]) {
            setFormData(prev => ({
                ...prev,
                [field]: value
            }));
        }
    };

    const toggleField = (field) => {
        setEnabledFields(prev => ({
            ...prev,
            [field]: !prev[field]
        }));

        // Clear value and setNull when disabling a field
        if (enabledFields[field]) {
            setFormData(prev => {
                const newData = { ...prev };
                delete newData[field];
                return newData;
            });
            setSetNullFields(prev => {
                const newSetNull = { ...prev };
                delete newSetNull[field];
                return newSetNull;
            });
        }
    };

    const toggleSetNull = (field) => {
        setSetNullFields(prev => {
            const newSetNull = {
                ...prev,
                [field]: !prev[field]
            };
            
            if (newSetNull[field]) {
                setFormData(prev => {
                    const newData = { ...prev };
                    delete newData[field];
                    return newData;
                });
            }
            
            return newSetNull;
        });
    };

    const resetFields = () => {
        setFormData({});
        setSetNullFields({});
        setEnabledFields({});
    };

    const handleValueClick = (event, field) => {
        setActiveField(field);
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setActiveField(null);
    };

    const toggleExpand = (value) => {
        setExpandedValues(prev => ({
            ...prev,
            [value]: !prev[value]
        }));
    };

    const renderValueSummary = (field) => {
        const uniqueValues = getUniqueValues(field);
        console.log('Unique Values for', field, ':', uniqueValues); // Debug log
        
        // Count the actual number of different values
        const count = Object.keys(uniqueValues).length;
        console.log('Count:', count); // Debug log

        return (
            <Tooltip title="Click to see value distribution">
                <Chip
                    label={`${count} Different Value${count !== 1 ? 's' : ''}`}
                    onClick={(e) => handleValueClick(e, field)}
                    size="small"
                    variant="outlined"
                    sx={{ ml: 1 }}
                />
            </Tooltip>
        );
    };

    const renderValuePopover = () => {
        if (!activeField) return null;

        const uniqueValues = getUniqueValues(activeField);
        const totalFacilities = selectedRows.length;
        const sortedEntries = Object.entries(uniqueValues).sort((a, b) => {
            const countDiff = b[1].length - a[1].length;
            if (countDiff !== 0) return countDiff;
            return a[0].localeCompare(b[0]);
        });

        return (
            <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                PaperProps={{
                    sx: { maxWidth: '80vw', maxHeight: '80vh' }
                }}
            >
                <Box sx={{ p: 2, maxWidth: 600, maxHeight: 600, overflow: 'auto' }}>
                    <Typography variant="h6" gutterBottom>
                        Values for {activeField?.replace('data__', '')}
                    </Typography>
                    <Typography variant="caption" color="text.secondary">
                        Total facilities: {totalFacilities}
                    </Typography>

                    <List>
                        {sortedEntries.map(([value, items]) => (
                            <Box 
                                key={value} 
                                sx={{ 
                                    mb: 2,
                                    backgroundColor: 'background.paper',
                                    borderRadius: 1,
                                    overflow: 'hidden'
                                }}
                            >
                                <Box 
                                    sx={{ 
                                        p: 1, 
                                        backgroundColor: 'primary.main',
                                        color: 'primary.contrastText',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between'
                                    }}
                                >
                                    <Typography variant="subtitle2">
                                        {value} ({items.length} facilities - {((items.length/totalFacilities)*100).toFixed(1)}%)
                                    </Typography>
                                    <IconButton 
                                        size="small" 
                                        onClick={() => toggleExpand(value)}
                                        sx={{ 
                                            color: 'inherit',
                                            transform: expandedValues[value] ? 'rotate(180deg)' : 'rotate(0deg)',
                                            transition: 'transform 0.2s'
                                        }}
                                    >
                                        <ExpandMoreIcon />
                                    </IconButton>
                                </Box>
                                
                                <Collapse in={expandedValues[value]}>
                                    <Box sx={{ p: 1 }}>
                                        {items.map((item) => (
                                            <Box 
                                                key={item.id} 
                                                sx={{ 
                                                    p: 1,
                                                    borderBottom: 1,
                                                    borderColor: 'divider'
                                                }}
                                            >
                                                <Typography variant="body2">
                                                    {item.facility_name}
                                                </Typography>
                                                <Typography variant="caption" color="text.secondary">
                                                    Type: {item.type} | ID: {item.id.slice(0, 8)}...
                                                </Typography>
                                            </Box>
                                        ))}
                                    </Box>
                                </Collapse>
                            </Box>
                        ))}
                    </List>
                </Box>
            </Popover>
        );
    };

    const renderField = (field) => {
        const fieldMeta = metadata[field];
        const fieldName = fieldMeta?.ui_settings?.label || field.replace('data__', '').replace(/_/g, ' ').toUpperCase();
        
        return (
            <Box>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 2 }}>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={enabledFields[field]}
                                onChange={(e) => toggleField(field)}
                                disabled={!isFieldEditable(fieldMeta, selectedType)}
                            />
                        }
                        label={fieldName}
                    />
                    {selectedRows?.length > 0 && renderValueSummary(field)}
                </Box>

                {enabledFields[field] && (
                    <Box sx={{ ml: 4 }}>
                        {renderFieldInput(field, fieldMeta, formData[field] || '', true)}
                    </Box>
                )}
            </Box>
        );
    };

    const renderFieldInput = (field, meta, value, showSetNull) => {
        // For cascading dropdowns
        if (meta.ui_settings?.control_type === 'cascading_dropdown') {
            const parentField = meta.ui_settings.cascading_parent_field;
            // Get parent value from formData, removing data__ prefix if it exists
            const parentValue = formData[parentField] || formData[`data__${parentField}`];
            const options = parentValue ? (meta.constraints?.allowed_values?.[parentValue] || []) : [];

            return (
                <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                    <FormControl fullWidth>
                        <InputLabel>{meta.ui_settings?.label || field.replace('data__', '').replace(/_/g, ' ').toUpperCase()}</InputLabel>
                        <Select
                            value={value || ''}
                            label={meta.ui_settings?.label || field.replace('data__', '').replace(/_/g, ' ').toUpperCase()}
                            onChange={(e) => handleInputChange(field, e.target.value)}
                            disabled={!enabledFields[field] || setNullFields[field]}
                        >
                            {options.map((option) => (
                                <MenuItem key={option} value={option}>
                                    {option}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    {showSetNull && (
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={setNullFields[field] || false}
                                    onChange={() => toggleSetNull(field)}
                                    color="warning"
                                    disabled={!enabledFields[field]}
                                />
                            }
                            label="Set NULL"
                        />
                    )}
                </Box>
            );
        }

        // For regular dropdowns
        if (Array.isArray(meta.constraints?.allowed_values)) {
            return (
                <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                    <FormControl fullWidth>
                        <InputLabel>{meta.ui_settings?.label || field.replace('data__', '').replace(/_/g, ' ').toUpperCase()}</InputLabel>
                        <Select
                            value={value || ''}
                            label={meta.ui_settings?.label || field.replace('data__', '').replace(/_/g, ' ').toUpperCase()}
                            onChange={(e) => handleInputChange(field, e.target.value)}
                            disabled={!enabledFields[field] || setNullFields[field]}
                        >
                            {meta.constraints.allowed_values.map((option) => (
                                <MenuItem key={option} value={option}>
                                    {option}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    {showSetNull && (
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={setNullFields[field] || false}
                                    onChange={() => toggleSetNull(field)}
                                    color="warning"
                                    disabled={!enabledFields[field]}
                                />
                            }
                            label="Set NULL"
                        />
                    )}
                </Box>
            );
        }

        // For boolean fields
        if (meta.data_type === 'boolean') {
            return (
                <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                    <FormControl fullWidth>
                        <InputLabel>{meta.ui_settings?.label || field.replace('data__', '').replace(/_/g, ' ').toUpperCase()}</InputLabel>
                        <Select
                            value={value === null ? '' : value}
                            label={meta.ui_settings?.label || field.replace('data__', '').replace(/_/g, ' ').toUpperCase()}
                            onChange={(e) => handleInputChange(field, e.target.value === '' ? null : e.target.value === 'true')}
                            disabled={!enabledFields[field] || setNullFields[field]}
                        >
                            <MenuItem value="true">Yes</MenuItem>
                            <MenuItem value="false">No</MenuItem>
                        </Select>
                    </FormControl>
                    {showSetNull && (
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={setNullFields[field] || false}
                                    onChange={() => toggleSetNull(field)}
                                    color="warning"
                                    disabled={!enabledFields[field]}
                                />
                            }
                            label="Set NULL"
                        />
                    )}
                </Box>
            );
        }

        // For number fields
        if (meta.data_type === 'number') {
            return (
                <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                    <TextField
                        fullWidth
                        type="number"
                        label={meta.ui_settings?.label || field.replace('data__', '').replace(/_/g, ' ').toUpperCase()}
                        value={value || ''}
                        onChange={(e) => handleInputChange(field, e.target.value)}
                        disabled={!enabledFields[field] || setNullFields[field]}
                        inputProps={{
                            min: meta.constraints?.min_value,
                            max: meta.constraints?.max_value
                        }}
                    />
                    {showSetNull && (
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={setNullFields[field] || false}
                                    onChange={() => toggleSetNull(field)}
                                    color="warning"
                                    disabled={!enabledFields[field]}
                                />
                            }
                            label="Set NULL"
                        />
                    )}
                </Box>
            );
        }

        // Default text input
        return (
            <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                <TextField
                    fullWidth
                    label={meta.ui_settings?.label || field.replace('data__', '').replace(/_/g, ' ').toUpperCase()}
                    value={value || ''}
                    onChange={(e) => handleInputChange(field, e.target.value)}
                    disabled={!enabledFields[field] || setNullFields[field]}
                />
                {showSetNull && (
                    <FormControlLabel
                        control={
                            <Switch
                                checked={setNullFields[field] || false}
                                onChange={() => toggleSetNull(field)}
                                color="warning"
                                disabled={!enabledFields[field]}
                            />
                        }
                        label="Set NULL"
                    />
                )}
            </Box>
        );
    };

    const handleSubmit = async (type) => {
        try {
            setLoading(true);
            setError(null);

            // Convert form data to API format
            const updateData = {
                data: {},
                setNull: {}
            };

            // Process each enabled field
            Object.entries(formData).forEach(([field, value]) => {
                if (enabledFields[field]) {
                    if (field.startsWith('data__')) {
                        // For data__ fields, remove prefix and add to data object
                        const cleanField = field.replace('data__', '');
                        updateData.data[cleanField] = value;
                    } else {
                        // For non-data fields (like type)
                        updateData.data[field] = value;
                    }
                }
            });

            // Add setNull fields
            Object.entries(setNullFields).forEach(([field, shouldSetNull]) => {
                if (shouldSetNull && enabledFields[field]) {
                    if (field.startsWith('data__')) {
                        const cleanField = field.replace('data__', '');
                        updateData.setNull[cleanField] = true;
                    } else {
                        updateData.setNull[field] = true;
                    }
                }
            });

            // Pass data back to parent
            await onSubmit({
                ids: selectedRows.map(row => row.id),
                ...updateData
            }, type);
            
            onClose();
        } catch (error) {
            setError(error.message || 'Error updating facilities');
        } finally {
            setLoading(false);
        }
    };

    // Add this function to check if there are any changes
    const hasChanges = () => {
        // Check if any fields are enabled
        const enabledFieldsList = Object.entries(enabledFields).filter(([_, enabled]) => enabled);
        if (enabledFieldsList.length === 0) return false;

        // Check if any enabled fields have values or are set to null
        return enabledFieldsList.some(([field]) => 
            field in formData || setNullFields[field]
        );
    };

    // Function to get unique values for a field
    const getUniqueValues = (field) => {
        // Get values from the flattened data structure
        const values = selectedRows.map(row => ({
            id: row.id,
            facility_name: row.data__facility_name,
            type: row.type,
            // Access the field directly since data is flattened
            value: row[field]
        }));

        // Group by value
        const grouped = values.reduce((acc, curr) => {
            let key;
            if (curr.value === null || curr.value === undefined) {
                key = 'NULL';
            } else if (curr.value === '') {
                key = '(empty string)';
            } else {
                key = String(curr.value);
            }

            if (!acc[key]) {
                acc[key] = [];
            }
            acc[key].push(curr);
            return acc;
        }, {});

        return grouped;
    };

    // Add isFieldEditable function
    const isFieldEditable = (fieldMeta, type) => {
        // If field is not editable at base and has no conditional rules, it's never editable
        if (!fieldMeta.editable && !fieldMeta.conditional_editable) {
            return false;
        }

        // If field is editable at base, it's always editable
        if (fieldMeta.editable) {
            return true;
        }

        // Check conditional editability
        if (fieldMeta.conditional_editable) {
            const { conditions, dependent_field } = fieldMeta.conditional_editable;
            
            // If dependent field is facility type and we have a selected type
            if (dependent_field === 'type' && type) {
                // Find matching condition
                const matchingCondition = conditions.find(condition => 
                    condition.value === type
                );
                return matchingCondition?.editable ?? false;
            }
        }

        return false;
    };

    return (
        <Dialog 
            open={open} 
            onClose={onClose}
            maxWidth="md"
            fullWidth
            PaperProps={{
                sx: {
                    height: '90vh',
                    maxHeight: '90vh',
                    bgcolor: 'background.paper',
                    display: 'flex',
                    flexDirection: 'column'
                }
            }}
        >
            {/* Fixed Header */}
            <Box sx={{ 
                p: 3, 
                borderBottom: 1, 
                borderColor: 'divider',
                bgcolor: 'background.paper',
                flexShrink: 0
            }}>
                <Typography variant="h5" component="h2" gutterBottom>
                    MULTI-EDIT FACILITIES
                </Typography>
                <Typography variant="body1" color="text.secondary">
                    Edit multiple facilities at once by enabling fields and setting new values.
                </Typography>
            </Box>

            <DialogContent sx={{ 
                flex: 1,
                overflowY: 'auto',
                p: 3,
                display: 'flex',
                flexDirection: 'column',
                gap: 3
            }}>
                {/* Instructions Box */}
                <Box sx={{ 
                    p: 2, 
                    bgcolor: 'action.hover',
                    borderRadius: 1,
                    border: '1px solid',
                    borderColor: 'divider'
                }}>
                    <Typography variant="subtitle2" color="primary" gutterBottom>
                        Instructions:
                    </Typography>
                    <Typography variant="body2" color="text.primary" sx={{ mb: 1 }}>
                        1. Enable the switch for each field you want to update
                    </Typography>
                    <Typography variant="body2" color="text.primary" sx={{ mb: 1 }}>
                        2. Enter new values for enabled fields
                    </Typography>
                    <Typography variant="body2" color="text.primary" sx={{ mb: 1 }}>
                        3. Use "Set NULL" switches to clear values where applicable
                    </Typography>
                    <Typography variant="body2" color="text.primary">
                        4. Choose "Safe Submit" to ensure all updates succeed, or "Force Submit" to apply partial updates
                    </Typography>
                </Box>

                {/* Summary Section */}
                <Box sx={{ 
                    p: 2,
                    bgcolor: 'action.hover',
                    borderRadius: 1,
                    border: '1px solid',
                    borderColor: 'divider'
                }}>
                    <Typography variant="subtitle1" gutterBottom>
                        Selection Summary
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Total facilities selected: {summary.total}
                    </Typography>
                    <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap', mt: 1 }}>
                        {Object.entries(summary.byType).map(([type, count]) => (
                            <Chip
                                key={type}
                                label={`${type}: ${count}`}
                                color="primary"
                                variant="outlined"
                            />
                        ))}
                    </Box>
                </Box>

                {/* Edit Fields */}
                {Object.keys(metadata).length > 0 ? (
                    <>
                        <Typography variant="subtitle1" gutterBottom>
                            Edit Fields
                        </Typography>
                        <Typography variant="body2" color="text.secondary" gutterBottom>
                            Enable switches for fields you want to update
                        </Typography>

                        {Object.keys(metadata)
                            .filter(key => key.startsWith('data__'))
                            .sort((a, b) => {
                                const orderA = metadata[a]?.display_order ?? 999;
                                const orderB = metadata[b]?.display_order ?? 999;
                                return orderA - orderB;
                            })
                            .map(field => (
                                <Box 
                                    key={field}
                                    sx={{
                                        p: 2,
                                        borderRadius: 1,
                                        border: '1px solid',
                                        borderColor: enabledFields[field] ? 'primary.main' : 'divider',
                                        bgcolor: enabledFields[field] ? 'action.hover' : 'background.paper',
                                        transition: 'all 0.2s'
                                    }}
                                >
                                    {renderField(field)}
                                </Box>
                            ))}
                    </>
                ) : (
                    <Box sx={{ textAlign: 'center', py: 4 }}>
                        <CircularProgress />
                        <Typography sx={{ mt: 2 }}>Loading metadata...</Typography>
                    </Box>
                )}
            </DialogContent>

            {/* Fixed Footer */}
            <Box sx={{ 
                p: 3, 
                borderTop: 1, 
                borderColor: 'divider',
                bgcolor: 'background.paper',
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                mt: 'auto',
                flexShrink: 0
            }}>
                {/* Submission Options Box */}
                <Box sx={{ 
                    p: 2,
                    bgcolor: 'action.hover',
                    borderRadius: 1,
                    border: '1px solid',
                    borderColor: 'divider'
                }}>
                    <Typography variant="subtitle2" gutterBottom color="primary">
                        Submission Options:
                    </Typography>
                    <Box sx={{ display: 'flex', gap: 3, mt: 1 }}>
                        <Box>
                            <Typography variant="body2" fontWeight="medium" gutterBottom>
                                Safe Submit
                            </Typography>
                            <Typography variant="caption" color="text.secondary">
                                If any facility update fails, the entire operation is cancelled. 
                                No changes will be made.
                            </Typography>
                        </Box>
                        <Box>
                            <Typography variant="body2" fontWeight="medium" gutterBottom>
                                Force Submit
                            </Typography>
                            <Typography variant="caption" color="text.secondary">
                                Updates are processed independently. Successful updates will be applied 
                                even if others fail.
                            </Typography>
                        </Box>
                    </Box>
                </Box>

                {/* Action Buttons */}
                <Box sx={{ display: 'flex', gap: 1, justifyContent: 'flex-end' }}>
                    <Button 
                        onClick={resetFields} 
                        color="inherit"
                        startIcon={<RestartAltIcon />}
                    >
                        Reset All
                    </Button>
                    <Button onClick={onClose} color="inherit">
                        Cancel
                    </Button>
                    <Button 
                        onClick={() => handleSubmit('safe')} 
                        variant="contained"
                        color="primary"
                        disabled={!hasChanges()}
                    >
                        Safe Submit
                    </Button>
                    <Button 
                        onClick={() => handleSubmit('force')} 
                        variant="contained"
                        color="warning"
                        disabled={!hasChanges()}
                    >
                        Force Submit
                    </Button>
                </Box>
            </Box>
            {renderValuePopover()}
        </Dialog>
    );
};

export default FacilityMultiEdit;
