import React from 'react';
import DataRegistryTable from '../Component/DataRegistry/DataRegistryTable';

const DataRegistryScreen = () => {
    return (
        <div>
            <h1>Data Registry</h1>
            <hr className="custom-separator"/>
            <DataRegistryTable />
        </div>
    );
};

export default DataRegistryScreen; 