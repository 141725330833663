import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import Sidebar from './Component/Common/Sidebar';
import MainIndex from './Screen/MainIndex';
import MobileIndex from './Screen/MobileIndex';
import {Switch, useNavigate, withRouter} from 'react-router-dom';
import AuthIndex from './Screen/AuthIndex';
import desktopMenu from './Component/Data/Menu/Menu.json';
import mobileMenu from './Component/Data/Menu/MobileMenu.json';
import {AuthProvider} from './Contexts/AuthContext';
import {ToastProvider} from './Contexts/ToastContext';
import { ConnectivityProvider } from './Contexts/ConnectivityContext';
import { GlobalFiltersProvider } from './Contexts/GlobalFiltersContext';

function App({Mainreducer}) {
    const [isMobileView, setIsMobileView] = useState(() => {
        const saved = localStorage.getItem("isMobileView");
        return saved ? JSON.parse(saved) : window.innerWidth <= 768;
    });
    const [menuData, setMenuData] = useState([]);
    const navigate = useNavigate();
    const themeColor = Mainreducer.themeColor || 'cyan';

    useEffect(() => {
        setMenuData(isMobileView ? [...mobileMenu.menu] : [...desktopMenu.menu]);
        localStorage.setItem("isMobileView", JSON.stringify(isMobileView));
    }, [isMobileView]);

    const savedIsSidebarMini = localStorage.getItem("isSideBarMini");
    const initIsSidebarMini = !(savedIsSidebarMini === null) ? savedIsSidebarMini === "true" : false;

    var baseUrl = process.env.PUBLIC_URL
    const activekey = () => {
        var res = window.location.pathname
        var baseUrl = process.env.PUBLIC_URL
        baseUrl = baseUrl.split("/");
        res = res.split("/");
        res = res.length > 0 ? res[baseUrl.length] : "/";
        res = res ? "/" + res : "/";
        const activeKey1 = res;
        if (activeKey1 === '/') {
            return '/home'
        } else {
            return activeKey1
        }
    }

    if (activekey() === '/home' || activekey() === '/login' || activekey() === '/404page') {
        return (
            <div id="cryptoon-layout" className={'theme-' + themeColor}>
                <ToastProvider>
                    <AuthProvider>
                        <AuthIndex setIsMobileView={setIsMobileView} />
                    </AuthProvider>
                </ToastProvider>
            </div>
        );
    }

    const GotoChangeMenu = (val) => {
        navigate(baseUrl + '/')
        setMenuData(isMobileView ? [...mobileMenu.menu] : [...desktopMenu.menu]);
    }

    return (
        <div id='cryptoon-layout' className={'theme-' + themeColor}>
            <ToastProvider>
                <AuthProvider>
                    <ConnectivityProvider>
                        <GlobalFiltersProvider>
                            {isMobileView ? (
                                <MobileIndex
                                    activekey={activekey()}
                                    GotoChangeMenu={GotoChangeMenu}
                                />
                            ) : (
                                <>
                                    <Sidebar
                                        activekey={activekey()}
                                        menuData={menuData}
                                        GotoChangeMenu={GotoChangeMenu}
                                        initIsSidebarMini={initIsSidebarMini}
                                    />
                                    <MainIndex
                                        activekey={activekey()}
                                        GotoChangeMenu={GotoChangeMenu}
                                    />
                                </>
                            )}
                        </GlobalFiltersProvider>
                    </ConnectivityProvider>
                </AuthProvider>
            </ToastProvider>
        </div>
    );
}

const mapStateToProps = ({Mainreducer}) => ({
    Mainreducer
})

export default connect(mapStateToProps, {})(App);
