// Utility/Timezone.js

import { zonedTimeToUtc, utcToZonedTime } from 'date-fns-tz';

export const SERVER_TIMEZONE = 'America/Regina';

/**
 * Returns the user's local IANA time zone, for example: 'America/Denver', 'Europe/London', etc.
 * This uses the browser's built-in Intl API, which reads from the user's OS settings.
 */
export function getUserTimeZone() {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  }

/**
 * Convert a user-selected Date to midnight in the server’s time zone, then return
 * a final Date object in UTC.
 * 
 * @param {Date} userDate         - The date from the user’s DatePicker (JS thinks it’s local time).
 * @param {string} userTZ         - The user’s IANA time zone (e.g. 'America/Denver')
 * @param {string} serverTZ       - The server’s IANA time zone (e.g. 'America/Chicago')
 * @returns {Date}                - UTC date object pinned to “server midnight.”
 */
export function toServerMidnightUTC(userDate, userTZ = 'UTC', serverTZ = 'America/Regina') {
  if (!userDate) return null;

  // Step 1: Interpret the userDate as if it’s in userTZ
  //   By default, new Date() lumps “2025-01-01” as local to the user’s system,
  //   but let's do an explicit conversion to avoid any weird offset issues:
  const userZonedDate = utcToZonedTime(userDate, userTZ);

  // Step 2: Convert user’s date/time -> server’s zone
  //   i.e. if userZonedDate is 2025-01-01 00:00 in Denver, 
  //   we convert to e.g. 2025-01-01 01:00 if Chicago is 1 hour ahead, etc.
  const serverZonedDate = zonedTimeToUtc(userZonedDate, userTZ); 
  const actualInServerTZ = utcToZonedTime(serverZonedDate, serverTZ);

  // Step 3: Force midnight in serverTZ
  actualInServerTZ.setHours(0, 0, 0, 0);

  // Step 4: Convert that midnight to an actual UTC Date object
  const finalUtcDate = zonedTimeToUtc(actualInServerTZ, serverTZ);

  return finalUtcDate;
}

/**
 *  Convert a UTC date from your DB into a Date that's pinned to
 * midnight in the server time zone (useful for display).
 *
 * @param {Date|string} utcDate - A date or ISO string stored as UTC in the DB
 * @param {string}      serverTZ - The server’s IANA time zone (e.g. 'America/Chicago')
 * @returns {Date}               - A local Date object representing midnight in server time
 */
export function utcToServerMidnight(utcDate, serverTZ = 'America/Chicago') {
    if (!utcDate) return null;
  
    const dateObj = typeof utcDate === 'string' ? new Date(utcDate) : utcDate;
  
    // Convert from UTC to the server's time zone
    const serverZoneDate = utcToZonedTime(dateObj, serverTZ);
  
    // Force midnight in server TZ
    serverZoneDate.setHours(0, 0, 0, 0);
  
    // serverZoneDate is now a Date object that, when displayed,
    // shows midnight in server time.
    return serverZoneDate;
  }