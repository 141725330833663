import React, { useState, useEffect, useMemo } from 'react';
import {
    Modal,
    Box,
    Typography,
    IconButton,
    Button,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Grid,
    Alert,
    Tooltip,
    Paper
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import CloseIcon from '@mui/icons-material/Close';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { useTheme } from '@mui/material/styles';
import { useToast } from '../../Contexts/ToastContext';
import { getUserTimeZone, toServerMidnightUTC, utcToServerMidnight, SERVER_TIMEZONE } from '../../Utility/Timezone';

const WorkOrderDetails = ({ 
    open, 
    onClose, 
    workOrder, 
    metadata,
    onUpdate,
    hiddenFields = ['id', 'facility_id', 'visit_id', 'insert_timestamp', 'update_timestamp']
}) => {
    const theme = useTheme();
    const { addToast } = useToast();
    const [formData, setFormData] = useState({});
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    const userTZ = useMemo(() => getUserTimeZone(), []);

    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '800px',
        height: '90vh',
        bgcolor: 'background.paper',
        boxShadow: 24,
        borderRadius: 1,
        display: 'flex',
        flexDirection: 'column'
    };

    useEffect(() => {
        if (workOrder) {
            setFormData({ ...workOrder });
        }
    }, [workOrder]);

    if (!workOrder) return null;

    const handleInputChange = (field, value) => {
        setFormData(prev => ({
            ...prev,
            [field]: value
        }));
    };

    const handleSubmit = async () => {
        try {
            setLoading(true);
            setError(null);

            // Get only the modified fields
            const modifiedFields = {};
            Object.keys(formData).forEach(key => {
                if (formData[key] !== workOrder[key]) {
                    // Convert dates to UTC for server
                    if (metadata[key]?.data_type === 'date' || metadata[key]?.data_type === 'date_time') {
                        modifiedFields[key] = formData[key] ? toServerMidnightUTC(formData[key], userTZ, SERVER_TIMEZONE)?.toISOString() : null;
                    } else {
                        modifiedFields[key] = formData[key];
                    }
                }
            });

            if (Object.keys(modifiedFields).length === 0) {
                onClose();
                return;
            }

            await onUpdate(workOrder.id, modifiedFields);
            onClose();

        } catch (error) {
            const errorMessage = error.response?.data?.message;
            const errorDetails = error.response?.data?.errors?.join('\n• ');
            const fullError = errorDetails 
                ? `${errorMessage}:\n• ${errorDetails}` 
                : (errorMessage || 'Error updating work order');
            
            setError(fullError);
        } finally {
            setLoading(false);
        }
    };

    const handleReset = () => {
        setFormData({ ...workOrder });
        setError(null);
    };

    const formatDate = (dateString) => {
        if (!dateString) return null;
        return utcToServerMidnight(dateString, SERVER_TIMEZONE);
    };

    const isFieldEditable = (field) => {
        if (hiddenFields.includes(field)) return false;
        
        const fieldMeta = metadata[field];
        if (!fieldMeta) return false;

        // Always allow editing notes and comments, even for completed/cancelled work orders
        if (field === 'notes' || field === 'comments') return true;

        // For completed or cancelled work orders, only allow notes and comments to be edited
        if (workOrder.status === 10 || workOrder.status === 20) return false;

        return fieldMeta.editable !== false;
    };

    const getFieldLabel = (field) => {
        const fieldMeta = metadata[field];
        return fieldMeta?.ui_settings?.label || field;
    };

    const getTooltip = (field) => {
        const fieldMeta = metadata[field];
        return fieldMeta?.ui_settings?.tooltip || fieldMeta?.description || '';
    };

    const isFieldRequired = (field) => {
        return metadata?.[field]?.constraints?.required === true;
    };

    // Define field order groups
    const fieldGroups = [
        // Facility Info (hardcoded, always first)
        ['facility_name', 'zone'],
        // Core Work Order Fields
        ['type', 'status'],
        ['priority'],
        // Dates on their own line
        ['start_timestamp', 'due_timestamp'],
        // Completion info on its own line
        ['completed_by_user', 'completed_timestamp'],
        // Notes and Comments (always last, full width)
        ['notes'],
        ['comments']
    ];

    // Flatten the groups into a single ordered array
    const orderedFields = fieldGroups.flat();

    const renderField = (field) => {
        if (hiddenFields.includes(field)) return null;

        // Special handling for facility_name and zone
        if (field === 'facility_name' || field === 'zone') {
            return (
                <Grid item xs={6}>
                    <TextField
                        fullWidth
                        label={field === 'facility_name' ? 'Facility' : 'Zone'}
                        value={workOrder[field] || ''}
                        disabled={true}
                        sx={{ 
                            mb: 2,
                            '& .MuiInputBase-root': {
                                height: '56px'
                            }
                        }}
                    />
                </Grid>
            );
        }

        const fieldMeta = metadata[field];
        if (!fieldMeta) return null;

        const value = formData[field];
        const isEditable = isFieldEditable(field);
        const isRequired = isFieldRequired(field);
        const hasError = isRequired && !value && value !== 0;
        const label = getFieldLabel(field);
        const tooltip = getTooltip(field);
        const isFullWidth = fieldMeta.ui_settings?.full_width || field === 'notes' || field === 'comments';

        // Common props for all input components
        const commonProps = {
            fullWidth: true,
            required: isRequired,
            error: hasError,
            disabled: !isEditable,
            helperText: hasError ? "This field is required" : "",
            sx: { 
                mb: 2,
                '& .MuiInputBase-root': {
                    height: field === 'notes' || field === 'comments' ? 'auto' : '56px'
                },
                '& .MuiInputLabel-root': {
                    textTransform: 'none'
                },
                '& .MuiOutlinedInput-root': {
                    '&.Mui-error .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#d32f2f'
                    }
                },
                '& .MuiFormHelperText-root.Mui-error': {
                    color: '#d32f2f'
                }
            }
        };

        // Handle different field types
        switch (fieldMeta.data_type) {
            case 'date':
            case 'date_time':
                return (
                    <Grid item xs={isFullWidth ? 12 : 6}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <Tooltip title={tooltip}>
                                <DatePicker
                                    label={label}
                                    value={formatDate(value)}
                                    onChange={(date) => handleInputChange(field, date?.toISOString())}
                                    disabled={!isEditable}
                                    renderInput={(params) => (
                                        <TextField 
                                            {...params}
                                            {...commonProps}
                                        />
                                    )}
                                />
                            </Tooltip>
                        </LocalizationProvider>
                    </Grid>
                );

            case 'integer':
                // Check if field has allowed values (like status or priority)
                if (fieldMeta.constraints?.allowed_values) {
                    return (
                        <Grid item xs={isFullWidth ? 12 : 6}>
                            <Tooltip title={tooltip}>
                                <FormControl {...commonProps}>
                                    <InputLabel error={hasError}>{label}</InputLabel>
                                    <Select
                                        value={value ?? ''}
                                        label={label}
                                        onChange={(e) => handleInputChange(field, e.target.value)}
                                    >
                                        {Object.entries(fieldMeta.constraints.allowed_values).map(([key, label]) => (
                                            <MenuItem key={key} value={Number(key)}>{label}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Tooltip>
                        </Grid>
                    );
                }
                return (
                    <Grid item xs={isFullWidth ? 12 : 6}>
                        <Tooltip title={tooltip}>
                            <TextField
                                {...commonProps}
                                type="number"
                                label={label}
                                value={value ?? ''}
                                onChange={(e) => handleInputChange(field, Number(e.target.value))}
                            />
                        </Tooltip>
                    </Grid>
                );

            case 'string':
                // Special handling for notes and comments - make them resizable
                if (field === 'notes' || field === 'comments') {
                    return (
                        <Grid item xs={12}>
                            <Tooltip title={tooltip}>
                                <TextField
                                    {...commonProps}
                                    multiline
                                    minRows={3}
                                    maxRows={10}
                                    label={label}
                                    value={value || ''}
                                    onChange={(e) => handleInputChange(field, e.target.value)}
                                />
                            </Tooltip>
                        </Grid>
                    );
                }

                // Handle dropdown fields
                if (fieldMeta.constraints?.allowed_values) {
                    return (
                        <Grid item xs={isFullWidth ? 12 : 6}>
                            <Tooltip title={tooltip}>
                                <FormControl {...commonProps}>
                                    <InputLabel error={hasError}>{label}</InputLabel>
                                    <Select
                                        value={value || ''}
                                        label={label}
                                        onChange={(e) => handleInputChange(field, e.target.value)}
                                    >
                                        {fieldMeta.constraints.allowed_values.map(option => (
                                            <MenuItem key={option} value={option}>{option}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Tooltip>
                        </Grid>
                    );
                }

                // Default text field
                return (
                    <Grid item xs={isFullWidth ? 12 : 6}>
                        <Tooltip title={tooltip}>
                            <TextField
                                {...commonProps}
                                label={label}
                                value={value || ''}
                                onChange={(e) => handleInputChange(field, e.target.value)}
                            />
                        </Tooltip>
                    </Grid>
                );

            default:
                return null;
        }
    };

    const hasChanges = () => {
        return Object.keys(formData).some(key => formData[key] !== workOrder[key]);
    };

    return (
        <Modal 
            open={open} 
            onClose={onClose}
        >
            <Box sx={modalStyle}>
                {/* Fixed Header */}
                <Box sx={{ 
                    p: 3, 
                    borderBottom: 1, 
                    borderColor: 'divider',
                    bgcolor: 'background.paper',
                    flexShrink: 0
                }}>
                    <Box display="flex" alignItems="center" justifyContent="space-between">
                        <Typography variant="h5" component="h2" gutterBottom>
                            EDIT WORK ORDER
                        </Typography>
                        <IconButton
                            onClick={onClose}
                            size="large"
                        >
                            <CloseIcon />
                        </IconButton>
                    </Box>
                    <Typography variant="body1" color="text.secondary" sx={{ mt: 1 }}>
                        Update work order details below. Fields marked with * are required.
                    </Typography>
                </Box>

                {/* Scrollable Content */}
                <Box sx={{ 
                    flex: 1,
                    overflowY: 'auto',
                    p: 3
                }}>
                    {error && (
                        <Alert 
                            severity="error" 
                            sx={{ 
                                mb: 2,
                                '& .MuiAlert-message': {
                                    whiteSpace: 'pre-line'
                                }
                            }}
                        >
                            {error}
                        </Alert>
                    )}

                    <Grid container spacing={2}>
                        {orderedFields.map(field => renderField(field))}
                    </Grid>
                </Box>

                {/* Fixed Footer */}
                <Box sx={{ 
                    p: 3, 
                    borderTop: 1, 
                    borderColor: 'divider',
                    bgcolor: 'background.paper',
                    display: 'flex', 
                    justifyContent: 'flex-end', 
                    gap: 2,
                    mt: 'auto',
                    flexShrink: 0
                }}>
                    <Button 
                        onClick={() => {
                            setFormData({ ...workOrder });
                            setError(null);
                        }}
                        color="inherit"
                        startIcon={<RestartAltIcon />}
                        disabled={!hasChanges()}
                    >
                        Reset Changes
                    </Button>
                    <Button onClick={onClose} color="inherit">
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        onClick={handleSubmit}
                        disabled={loading || !hasChanges()}
                    >
                        Save Changes
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default WorkOrderDetails; 