import React from 'react';
import ReactDOM from 'react-dom/client';
import './assets/scss/main.scss'
import './assets/css/mobile.css'
import App from './App';
import reportWebVitals from './reportWebVitals';
import {Provider, useSelector} from 'react-redux';
import {BrowserRouter} from 'react-router-dom';
import store from './store';
import './assets/css/icon.css'
import { ThemeProvider } from '@mui/material/styles';
import ThemeControl from './Component/Theme/ThemeControl.js'
import createTheme from './Component/Theme/Theme.js';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { enUS } from 'date-fns/locale';

// Configure global timezone
const timeZone = 'America/Regina';

const root = ReactDOM.createRoot(document.getElementById('root'));

// Create a new component that will be connected to Redux
function ThemedApp() {
  // Get theme values directly from Redux store
  const darkMode = useSelector((state) => state.Mainreducer.darkMode);
  const themeColor = useSelector((state) => state.Mainreducer.themeColor) || 'cyan';

  return (
    <ThemeProvider theme={createTheme(darkMode, themeColor)}>
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enUS}>
        <App/>
      </LocalizationProvider>
    </ThemeProvider>
  );
}

function Root() {
  const handleThemeChange = React.useCallback((theme, color) => {
    localStorage.setItem('theme', theme);
    if (color) {
      localStorage.setItem('themeColor', color);
    }
  }, []);

  return (
    <React.StrictMode>
      <Provider store={store}>
        <BrowserRouter>
          <ThemeControl onThemeChange={handleThemeChange}/>
          <ThemedApp />
        </BrowserRouter>
      </Provider>
    </React.StrictMode>
  );
}

root.render(<Root />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
